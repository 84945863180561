<div class="content-area-wrapper">
    <!-- sidebar Left -->
    <core-sidebar
      class="sidebar-left"
      name="email-sidebar"
      overlayClass="body-content-overlay"
      collapsibleSidebar="bs-gt-lg"
    >
      <!-- <app-email-sidebar></app-email-sidebar> -->
      <!-- email-sidebar -->
<div class="sidebar">
    <!-- <div class="sidebar-content email-app-sidebar" [perfectScrollbar]> -->
    <div class="sidebar-content email-app-sidebar">
    <div class="email-app-menu">
        <div class="form-group-compose text-center compose-btn">
          <button
            type="button"
            class="compose-email btn btn-primary btn-block"
            data-target="#compose-mail"
            (click)="openCompose()"
            rippleEffect>
            Compose
          </button>
        </div>
        <div class="sidebar-menu-list">
          <div class="list-group list-group-messages">
            <a
              *ngFor="let folder of folders"
              [routerLink]="'/apps/email/' + folder.handle"
              [routerLinkActive]="'active'"
              class="list-group-item list-group-item-action"
              (click)="toggleSidebar('email-sidebar')"
            >
              <i [data-feather]="folder.icon" [size]="18" class="mr-75"></i>
              <span class="align-middle">{{ folder.title }}</span>
              <span
                *ngIf="folder.handle === 'draft' || folder.handle === 'inbox'"
                class="badge badge-pill float-right"
                [ngClass]="{
                  'badge-light-warning': folder.handle === 'draft',
                  'badge-light-primary': folder.handle === 'inbox'
                }"
                >{{
                  folder.handle === 'draft' ? draftCount : folder.handle === 'inbox' ? unReadInboxCount : unReadInboxCount
                }}</span
              >
            </a>
          </div>
          <!-- <hr /> -->
          <h6 class="section-label mt-3 mb-1 px-2">Labels</h6>
          <div class="list-group list-group-labels">
            <a
              *ngFor="let label of labels"
              [routerLink]="'/apps/email/label/' + label.handle"
              [routerLinkActive]="'active'"
              class="list-group-item list-group-item-action"
              (click)="toggleSidebar('email-sidebar')"
              ><span class="bullet bullet-sm mr-1 {{ label.color }}"></span>{{ label.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/ email-sidebar -->

  
    </core-sidebar>
  
    <!--/ sidebar Left -->
  
    <!-- content-right -->
    <div class="content-right">
      <!-- <app-email-compose></app-email-compose> -->
      
      <div class="content-wrapper">
        <div class="content-body">
          <!-- email list -->
          <!-- <app-email-list></app-email-list> -->
          <!-- Email list Area -->
<div class="email-app-list">
    <!-- Email search starts -->
    <div class="app-fixed-search d-flex align-items-center">
      <div class="sidebar-toggle d-block d-lg-none ml-1">
        <button class="btn p-0" (click)="toggleSidebar('email-sidebar')"><i data-feather="menu" [size]="21"></i></button>
      </div>
      <div class="d-flex align-content-center justify-content-between w-100">
        <div class="input-group input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i data-feather="search" class="text-muted"></i></span>
          </div>
          <input
            type="text"
            class="form-control"
            id="email-search"
            placeholder="Search email"
            aria-label="Search..."
            aria-describedby="email-search"
            (keyup)="queryUpdate($event)"
          />
        </div>
      </div>
    </div>
    <!-- Email search ends -->
  
    <!-- Email actions starts -->
    <div class="app-action">
      <div class="action-left">
        <div class="custom-control custom-checkbox selectAll">
          <input
            type="checkbox"
            class="custom-control-input"
            id="selectAllCheck"
            [checked]="hasSelectedMails"
            [indeterminate]="isIndeterminate"
            (click)="toggleSelectAll()"
          />
          <label class="custom-control-label font-weight-bolder pl-25" for="selectAllCheck">Select All</label>
        </div>
      </div>
      <div class="action-right" *ngIf="hasSelectedMails > 0">
        <ul class="list-inline m-0">
          <li class="list-inline-item">
            <div ngbDropdown>
              <a
                href="javascript:void(0);"
                ngbDropdownToggle
                id="folder"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i data-feather="folder" [size]="18"></i>
              </a>
              <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="folder">
                <a
                  ngbDropdownItem
                  (click)="updateFolderOnSelectedMails('draft')"
                  class="d-flex align-items-center"
                  href="javascript:void(0);"
                >
                  <i data-feather="edit-2" class="font-small-4 mr-50"></i>
                  <span>Draft</span>
                </a>
                <a
                  ngbDropdownItem
                  (click)="updateFolderOnSelectedMails('spam')"
                  class="d-flex align-items-center"
                  href="javascript:void(0);"
                >
                  <i data-feather="info" class="font-small-4 mr-50"></i>
                  <span>Spam</span>
                </a>
                <a
                  ngbDropdownItem
                  (click)="updateFolderOnSelectedMails('trash')"
                  class="d-flex align-items-center"
                  href="javascript:void(0);"
                >
                  <i data-feather="trash" class="font-small-4 mr-50"></i>
                  <span>Trash</span>
                </a>
              </div>
            </div>
          </li>
          <li class="list-inline-item">
            <div ngbDropdown>
              <a
                href="javascript:void(0);"
                ngbDropdownToggle
                id="tag"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i data-feather="tag" [size]="18"></i>
              </a>
              <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="tag">
                <a ngbDropdownItem (click)="updateLabelOnSelectedEmails('personal')" href="javascript:void(0);"
                  ><span class="mr-50 bullet bullet-success bullet-sm"></span>Personal</a
                >
                <a ngbDropdownItem (click)="updateLabelOnSelectedEmails('company')" href="javascript:void(0);"
                  ><span class="mr-50 bullet bullet-primary bullet-sm"></span>Company</a
                >
                <a ngbDropdownItem (click)="updateLabelOnSelectedEmails('important')" href="javascript:void(0);"
                  ><span class="mr-50 bullet bullet-warning bullet-sm"></span>Important</a
                >
                <a ngbDropdownItem (click)="updateLabelOnSelectedEmails('private')" href="javascript:void(0);"
                  ><span class="mr-50 bullet bullet-danger bullet-sm"></span>Private</a
                >
              </div>
            </div>
          </li>
          <li class="list-inline-item mail-unread">
            <button class="action-icon btn p-0" (click)="unRead()"><i data-feather="mail" [size]="18"></i></button>
          </li>
          <li class="list-inline-item mail-delete">
            <button class="action-icon btn p-0" (click)="updateFolderOnSelectedMails('trash')">
              <i data-feather="trash-2" [size]="18"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- Email actions ends -->
  
    <!-- Email list starts -->
    <!-- <div class="email-user-list" [perfectScrollbar]> -->
    <div class="email-user-list">
      <ul class="email-media-list">
        <li class="media" [ngClass]="{ 'mail-read': email.isRead === true, 'selected-row-bg': selected === true }">
            <div class="media-left pr-50">
              <div class="avatar">
                <img [src]="email.from.avatar" alt="avatar img holder" />
              </div>
              <div class="user-action">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck"
                    name="customCheck"
                    [(ngModel)]="selected"
                    (ngModelChange)="onSelectedChange()"
                    (click)="$event.stopPropagation()"
                  />
                  <label class="custom-control-label" for="customCheck{{ email.id }}"></label>
                </div>
                <button class="email-favorite btn p-0" (click)="toggleStarred(); $event.stopPropagation()">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-star text-warning': email.isStarred === true, 'fa-star-o': email.isStarred === false }"
                  ></i>
                </button>
              </div>
            </div>
            <div class="media-body">
              <div class="mail-details">
                <div class="mail-items">
                  <h5 class="mb-25">{{ email.from.name }}</h5>
                  <span class="text-truncate">{{ email.subject }}</span>
                </div>
                <div class="mail-meta-item">
                  <ng-container *ngIf="email.attachments.length > 0">
                    <i data-feather="paperclip"></i>
                  </ng-container>
                  <span
                    class="mx-50 bullet bullet-sm"
                    *ngFor="let label of email.labels"
                    [ngClass]="{
                      'bullet-success': label === 'personal',
                      'bullet-primary': label === 'company',
                      'bullet-warning': label === 'important',
                      'bullet-danger': label === 'private'
                    }"
                  ></span>
                  <span class="mail-date">{{ email.time | date: 'short':'UTC' }}</span>
                </div>
              </div>
              <div class="mail-message">
                <p class="text-truncate mb-0">{{ email.message | striphtml }}</p>
              </div>
            </div>
          </li>
          
        <ng-container *ngIf="emails.length; else noResults"></ng-container>
      </ul>
      <ng-template #noResults>
        <div class="no-results show">
          <h5>No Items Found</h5>
        </div>
      </ng-template>
    </div>
    <!-- Email list ends -->
  </div>
  <!--/ Email list Area -->
  
          <!-- email list -->
  
          <!-- email details -->
          <!-- <app-email-details></app-email-details> -->
          <!-- email details -->
        </div>
      </div>
    </div>
    <!--/ content-right -->
  </div>
  










<!-- <form [formGroup]="myForm" (ngSubmit)="submit()">
  
    <div class="form-group">
        <label for="name">Name</label>
        <input 
            formControlName="name"
            id="name" 
            type="text" 
            class="form-control">
        <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
            <div *ngIf="f.name.errors?.required">Name is required.</div>
            <div *ngIf="f.name.errors?.minlength">Name should be 3 character.</div>
        </div>
    </div>
      
    <div class="form-group">
        <label for="file">File</label>
        <input 
            formControlName="file"
            id="file" 
            type="file" 
            class="form-control"
            (change)="onFileChange($event)">
        <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
            <div *ngIf="f.file.errors?.required">File is required.</div>
        </div>
    </div>
          
    <button class="btn btn-primary" type="submit">Submit</button>
</form>


<table style="border:1px solid black">
    <tr>
        <th>Number</th>
    </tr>
    <tr>
        <cdk-virtual-scroll-viewport style="width:400px; height: 400px;" itemSize="50">
            <ng-container *cdkVirtualFor="let n of number">
                <h2>{{n}}</h2>
            </ng-container>
        </cdk-virtual-scroll-viewport>
        
    </tr>

</table>-->





<!--<div class="content-wrapper">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>-->
            <!-- Horizontal Wizard -->
            <!--<section class="horizontal-wizard">
            <div id="stepper1" class="bs-stepper horizontal-wizard-example">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#account-details">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Account Details</span>
                                <span class="bs-stepper-subtitle">Setup Account Details</span>
                            </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#personal-info">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Personal Info</span>
                                <span class="bs-stepper-subtitle">Add Personal Info</span>
                            </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#address">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Address</span>
                                <span class="bs-stepper-subtitle">Add Address</span>
                            </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#social-links">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Social Links</span>
                                <span class="bs-stepper-subtitle">Add Social Links</span>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <form (ngSubmonit)="(HWForm.form.valid)" #HWForm="ngForm">
                        <div id="account-details" class="content">
                            <form #accountDetailsForm="ngForm">
                                <div class="content-header">
                                    <h5 class="mb-0">Account Details</h5>
                                    <small class="text-muted">Enter Your Account Details.</small>
                                </div>

                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="username">Username</label>
                                        <input [(ngModel)]="TDNameVar"
                                               #TDNameRef="ngModel"
                                               required
                                               type="text"
                                               name="username"
                                               id="username"
                                               class="form-control"
                                               [class.error]="!TDNameRef.valid && accountDetailsForm.submitted"
                                               placeholder="johndoe" />
                                        <span *ngIf="accountDetailsForm.submitted && TDNameRef.invalid" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="TDNameRef.errors.required">This field is required!</small>
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="email">Email</label>
                                        <input [(ngModel)]="TDEmailVar"
                                               #TDEmailRef="ngModel"
                                               required
                                               email
                                               type="email"
                                               name="email"
                                               id="email"
                                               class="form-control"
                                               [class.error]="!TDEmailRef.valid && accountDetailsForm.submitted"
                                               placeholder="john.doe@email.com"
                                               aria-label="john.doe" />
                                        <span *ngIf="accountDetailsForm.submitted" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="!TDEmailRef.valid">Email must be valid!</small>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group form-password-toggle col-md-6">
                                        <label class="form-label" for="password">Password</label>
                                        <input type="password"
                                               name="password"
                                               id="password"
                                               class="form-control"
                                               [class.error]="!TDPasswordOnlyRef.valid && accountDetailsForm.submitted"
                                               placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                               ngModel
                                               #TDPasswordOnlyRef="ngModel"
                                               required
                                               minlength="6"
                                               pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" />
                                        <div *ngIf="TDPasswordOnlyRef.invalid && accountDetailsForm.submitted"
                                             class="form-text text-danger">
                                            <small *ngIf="TDPasswordOnlyRef.errors.required"> Password is required. </small>
                                            <small *ngIf="TDPasswordOnlyRef.errors.pattern">
                                                Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more
                                                characters.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="form-group form-password-toggle col-md-6">
                                        <label class="form-label" for="confirm-password">Confirm Password</label>
                                        <input type="password"
                                               name="confirm-password"
                                               id="confirm-password"
                                               class="form-control"
                                               [class.error]="!TDRepeatPasswordRef.valid && accountDetailsForm.submitted"
                                               placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                               required
                                               ngModel
                                               #TDRepeatPasswordRef="ngModel"
                                               pattern="{{ TDPasswordOnlyRef.value }}"
                                               minlength="6" />
                                        <div *ngIf="TDRepeatPasswordRef.invalid && accountDetailsForm.submitted"
                                             class="form-text text-danger">
                                            <small *ngIf="TDRepeatPasswordRef.errors.required"> Confirm password is required. </small>
                                            <small *ngIf="TDRepeatPasswordRef.errors.pattern">
                                                Password & Confirm Password does not match.
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()"
                                            class="btn btn-outline-secondary btn-prev"
                                            disabled
                                            rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button (click)="horizontalWizardStepperNext(accountDetailsForm)"
                                            type="submit"
                                            class="btn btn-primary btn-next"
                                            rippleEffect>
                                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div id="personal-info" class="content">
                            <form #personalInfoForm="ngForm">
                                <div class="content-header">
                                    <h5 class="mb-0">Personal Info</h5>
                                    <small>Enter Your Personal Info.</small>
                                </div>

                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="first-name">First Name</label>
                                        <input [(ngModel)]="TDFirstNameVar"
                                               #TDFirstNameRef="ngModel"
                                               required
                                               type="text"
                                               name="first-name"
                                               id="first-name"
                                               class="form-control"
                                               [class.error]="personalInfoForm.submitted && TDFirstNameRef.invalid"
                                               placeholder="John" />
                                        <span *ngIf="personalInfoForm.submitted && TDFirstNameRef.invalid" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="TDFirstNameRef.errors.required">This field is required!</small>
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="last-name">Last Name</label>
                                        <input [(ngModel)]="TDLastNameVar"
                                               #TDLastNameRef="ngModel"
                                               required
                                               type="text"
                                               name="last-name"
                                               id="last-name"
                                               class="form-control"
                                               [class.error]="personalInfoForm.submitted && TDLastNameRef.invalid"
                                               placeholder="Doe" />
                                        <span *ngIf="personalInfoForm.submitted && TDLastNameRef.invalid" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="TDLastNameRef.errors.required">This field is required!</small>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="country">Country</label>
                                        <ng-select [items]="selectBasic" bindLabel="name"> </ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="language">Language</label>
                                        <ng-select [items]="selectMulti"
                                                   [multiple]="true"
                                                   [closeOnSelect]="false"
                                                   [searchable]="false"
                                                   bindLabel="name"
                                                   name="selectLanguage"
                                                   placeholder="Select people"
                                                   [(ngModel)]="selectMultiSelected">
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button (click)="horizontalWizardStepperNext(personalInfoForm)"
                                            class="btn btn-primary btn-next"
                                            rippleEffect>
                                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div id="address" class="content">
                            <form #addressForm="ngForm">
                                <div class="content-header">
                                    <h5 class="mb-0">Address</h5>
                                    <small>Enter Your Address.</small>
                                </div>

                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="address">Address</label>
                                        <input [(ngModel)]="addressVar"
                                               #addressRef="ngModel"
                                               required
                                               type="text"
                                               id="address"
                                               name="address"
                                               class="form-control"
                                               [class.error]="addressForm.submitted && addressRef.invalid"
                                               placeholder="98  Borough bridge Road, Birmingham" />
                                        <span *ngIf="addressForm.submitted && addressRef.invalid" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="addressRef.errors.required">This field is required!</small>
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="landmark">Landmark</label>
                                        <input [(ngModel)]="landmarkVar"
                                               #landmarkRef="ngModel"
                                               required
                                               type="text"
                                               name="landmark"
                                               id="landmark"
                                               class="form-control"
                                               [class.error]="addressForm.submitted && landmarkRef.invalid"
                                               placeholder="Borough bridge" />
                                        <span *ngIf="addressForm.submitted && landmarkRef.invalid" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="landmarkRef.errors.required">This field is required!</small>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="pincode1">Pincode</label>
                                        <input type="text" id="pincode1" class="form-control" placeholder="658921" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="city1">City</label>
                                        <input type="text" id="city1" class="form-control" placeholder="Birmingham" />
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button (click)="horizontalWizardStepperNext(addressForm)"
                                            class="btn btn-primary btn-next"
                                            rippleEffect>
                                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div id="social-links" class="content">
                            <form #socialLinkForm="ngForm">
                                <div class="content-header">
                                    <h5 class="mb-0">Social Links</h5>
                                    <small>Enter Your Social Links.</small>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="twitter">Twitter</label>
                                        <input [(ngModel)]="twitterVar"
                                               #twitterkRef="ngModel"
                                               required
                                               type="text"
                                               id="twitter"
                                               name="twitter"
                                               class="form-control"
                                               [class.error]="socialLinkForm.submitted && twitterkRef.invalid"
                                               placeholder="https://twitter.com/abc" />
                                        <span *ngIf="socialLinkForm.submitted && twitterkRef.invalid" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="twitterkRef.errors.required">This field is required!</small>
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="facebook">Facebook</label>
                                        <input [(ngModel)]="facebookVar"
                                               #facebookRef="ngModel"
                                               required
                                               type="text"
                                               id="facebook"
                                               name="facebook"
                                               class="form-control"
                                               [class.error]="socialLinkForm.submitted && facebookRef.invalid"
                                               placeholder="https://facebook.com/abc" />
                                        <span *ngIf="socialLinkForm.submitted && facebookRef.invalid" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="facebookRef.errors.required">This field is required!</small>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="google">Google+</label>
                                        <input [(ngModel)]="googleVar"
                                               #googleRef="ngModel"
                                               required
                                               type="text"
                                               id="google"
                                               name="google"
                                               class="form-control"
                                               [class.error]="socialLinkForm.submitted && googleRef.invalid"
                                               placeholder="https://plus.google.com/abc" />
                                        <span *ngIf="socialLinkForm.submitted && googleRef.invalid" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="googleRef.errors.required">This field is required!</small>
                                        </span>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="linkedin">Linkedin</label>
                                        <input [(ngModel)]="linkedinVar"
                                               #linkedinRef="ngModel"
                                               required
                                               type="text"
                                               id="linkedin"
                                               name="linkedin"
                                               class="form-control"
                                               [class.error]="socialLinkForm.submitted && linkedinRef.invalid"
                                               placeholder="https://linkedin.com/abc" />
                                        <span *ngIf="socialLinkForm.submitted && linkedinRef.invalid" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="linkedinRef.errors.required">This field is required!</small>
                                        </span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button class="btn btn-success btn-submit" rippleEffect>Submit</button>
                                </div>
                            </form>
                        </div>
                    </form>
                </div>
            </div>
        </section>-->
            <!--/ Horizontal Wizard -->
            <!-- Vertical Wizard -->
            <!--<section class="vertical-wizard">
            <div id="stepper2" class="bs-stepper vertical vertical-wizard-example">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#account-details-vertical">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Account Details</span>
                                <span class="bs-stepper-subtitle">Setup Account Details</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#personal-info-vertical">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Personal Info</span>
                                <span class="bs-stepper-subtitle">Add Personal Info</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#address-step-vertical">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Address</span>
                                <span class="bs-stepper-subtitle">Add Address</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#social-links-vertical">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">4</span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Social Links</span>
                                <span class="bs-stepper-subtitle">Add Social Links</span>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <div id="account-details-vertical" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">Account Details</h5>
                            <small class="text-muted">Enter Your Account Details.</small>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-username">Username</label>
                                <input type="text" id="vertical-username" class="form-control" placeholder="johndoe" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-email">Email</label>
                                <input type="email"
                                       id="vertical-email"
                                       class="form-control"
                                       placeholder="john.doe@email.com"
                                       aria-label="john.doe" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group form-password-toggle col-md-6">
                                <label class="form-label" for="vertical-password">Password</label>
                                <input type="password"
                                       id="vertical-password"
                                       class="form-control"
                                       placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                            </div>
                            <div class="form-group form-password-toggle col-md-6">
                                <label class="form-label" for="vertical-confirm-password">Confirm Password</label>
                                <input type="password"
                                       id="vertical-confirm-password"
                                       class="form-control"
                                       placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-outline-secondary btn-prev" disabled rippleEffect>
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                                <span class="align-middle d-sm-inline-block d-none">Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                            </button>
                        </div>
                    </div>
                    <div id="personal-info-vertical" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">Personal Info</h5>
                            <small>Enter Your Personal Info.</small>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-first-name">First Name</label>
                                <input type="text" id="vertical-first-name" class="form-control" placeholder="John" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-last-name">Last Name</label>
                                <input type="text" id="vertical-last-name" class="form-control" placeholder="Doe" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-country">Country</label>
                                <ng-select [items]="selectBasic" bindLabel="name"> </ng-select>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-language">Language</label>
                                <ng-select [items]="selectMulti"
                                           [multiple]="true"
                                           [closeOnSelect]="false"
                                           [searchable]="false"
                                           bindLabel="name"
                                           name="selectLanguage"
                                           placeholder="Select people"
                                           [(ngModel)]="selectMultiSelected">
                                </ng-select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                                <span class="align-middle d-sm-inline-block d-none">Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                            </button>
                        </div>
                    </div>
                    <div id="address-step-vertical" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">Address</h5>
                            <small>Enter Your Address.</small>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-address">Address</label>
                                <input type="text"
                                       id="vertical-address"
                                       class="form-control"
                                       placeholder="98  Borough bridge Road, Birmingham" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-landmark">Landmark</label>
                                <input type="text" id="vertical-landmark" class="form-control" placeholder="Borough bridge" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="pincode2">Pincode</label>
                                <input type="text" id="pincode2" class="form-control" placeholder="658921" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="city2">City</label>
                                <input type="text" id="city2" class="form-control" placeholder="Birmingham" />
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-primary btn-next" (click)="verticalWizardNext()" rippleEffect>
                                <span class="align-middle d-sm-inline-block d-none">Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                            </button>
                        </div>
                    </div>
                    <div id="social-links-vertical" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">Social Links</h5>
                            <small>Enter Your Social Links.</small>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-twitter">Twitter</label>
                                <input type="text" id="vertical-twitter" class="form-control" placeholder="https://twitter.com/abc" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-facebook">Facebook</label>
                                <input type="text" id="vertical-facebook" class="form-control" placeholder="https://facebook.com/abc" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-google">Google+</label>
                                <input type="text"
                                       id="vertical-google"
                                       class="form-control"
                                       placeholder="https://plus.google.com/abc" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="vertical-linkedin">Linkedin</label>
                                <input type="text" id="vertical-linkedin" class="form-control" placeholder="https://linkedin.com/abc" />
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-primary btn-prev" (click)="verticalWizardPrevious()" rippleEffect>
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-success btn-submit" rippleEffect>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->
            <!-- /Vertical Wizard -->
            <!-- Modern Horizontal Wizard -->
            <!--<section class="modern-horizontal-wizard">
            <div id="stepper3" class="bs-stepper wizard-modern modern-wizard-example">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#account-details-modern">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="file-text" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Account Details</span>
                                <span class="bs-stepper-subtitle">Setup Account Details</span>
                            </span>
                        </button>
                    </div>
                    <div class="line">
                        <i data-feather="chevron-right" class="font-medium-2"></i>
                    </div>
                    <div class="step" data-target="#personal-info-modern">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="user" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Personal Info</span>
                                <span class="bs-stepper-subtitle">Add Personal Info</span>
                            </span>
                        </button>
                    </div>
                    <div class="line">
                        <i data-feather="chevron-right" class="font-medium-2"></i>
                    </div>
                    <div class="step" data-target="#address-step-modern">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="map-pin" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Address</span>
                                <span class="bs-stepper-subtitle">Add Address</span>
                            </span>
                        </button>
                    </div>
                    <div class="line">
                        <i data-feather="chevron-right" class="font-medium-2"></i>
                    </div>
                    <div class="step" data-target="#social-links-modern">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="link" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">Social Links</span>
                                <span class="bs-stepper-subtitle">Add Social Links</span>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <div id="account-details-modern" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">Account Details</h5>
                            <small class="text-muted">Enter Your Account Details.</small>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-username">Username</label>
                                <input type="text" id="modern-username" class="form-control" placeholder="johndoe" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-email">Email</label>
                                <input type="email"
                                       id="modern-email"
                                       class="form-control"
                                       placeholder="john.doe@email.com"
                                       aria-label="john.doe" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group form-password-toggle col-md-6">
                                <label class="form-label" for="modern-password">Password</label>
                                <input type="password"
                                       id="modern-password"
                                       class="form-control"
                                       placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                            </div>
                            <div class="form-group form-password-toggle col-md-6">
                                <label class="form-label" for="modern-confirm-password">Confirm Password</label>
                                <input type="password"
                                       id="modern-confirm-password"
                                       class="form-control"
                                       placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-outline-secondary btn-prev" disabled rippleEffect>
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-primary btn-next" (click)="modernHorizontalNext()" rippleEffect>
                                <span class="align-middle d-sm-inline-block d-none">Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                            </button>
                        </div>
                    </div>
                    <div id="personal-info-modern" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">Personal Info</h5>
                            <small>Enter Your Personal Info.</small>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-first-name">First Name</label>
                                <input type="text" id="modern-first-name" class="form-control" placeholder="John" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-last-name">Last Name</label>
                                <input type="text" id="modern-last-name" class="form-control" placeholder="Doe" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-country">Country</label>
                                <ng-select [items]="selectBasic" bindLabel="name"> </ng-select>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-language">Language</label>
                                <ng-select [items]="selectMulti"
                                           [multiple]="true"
                                           [closeOnSelect]="false"
                                           [searchable]="false"
                                           bindLabel="name"
                                           name="selectLanguage"
                                           placeholder="Select people"
                                           [(ngModel)]="selectMultiSelected">
                                </ng-select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-primary btn-prev" (click)="modernHorizontalPrevious()" rippleEffect>
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-primary btn-next" (click)="modernHorizontalNext()" rippleEffect>
                                <span class="align-middle d-sm-inline-block d-none">Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                            </button>
                        </div>
                    </div>
                    <div id="address-step-modern" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">Address</h5>
                            <small>Enter Your Address.</small>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-address">Address</label>
                                <input type="text"
                                       id="modern-address"
                                       class="form-control"
                                       placeholder="98  Borough bridge Road, Birmingham" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-landmark">Landmark</label>
                                <input type="text" id="modern-landmark" class="form-control" placeholder="Borough bridge" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="pincode3">Pincode</label>
                                <input type="text" id="pincode3" class="form-control" placeholder="658921" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="city3">City</label>
                                <input type="text" id="city3" class="form-control" placeholder="Birmingham" />
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-primary btn-prev" (click)="modernHorizontalPrevious()" rippleEffect>
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-primary btn-next" (click)="modernHorizontalNext()" rippleEffect>
                                <span class="align-middle d-sm-inline-block d-none">Next</span>
                                <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                            </button>
                        </div>
                    </div>
                    <div id="social-links-modern" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">Social Links</h5>
                            <small>Enter Your Social Links.</small>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-twitter">Twitter</label>
                                <input type="text" id="modern-twitter" class="form-control" placeholder="https://twitter.com/abc" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-facebook">Facebook</label>
                                <input type="text" id="modern-facebook" class="form-control" placeholder="https://facebook.com/abc" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-google">Google+</label>
                                <input type="text" id="modern-google" class="form-control" placeholder="https://plus.google.com/abc" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="modern-linkedin">Linkedin</label>
                                <input type="text" id="modern-linkedin" class="form-control" placeholder="https://linkedin.com/abc" />
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-primary btn-prev" (click)="modernHorizontalPrevious()" rippleEffect>
                                <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-success btn-submit" rippleEffect>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->
            <!-- /Modern Horizontal Wizard -->
            <!-- Modern Vertical Wizard -->
            <!--<section class="modern-vertical-wizard">
                <div id="stepper4" class="bs-stepper vertical wizard-modern modern-vertical-wizard-example">
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#account-details-vertical-modern">
                            <button type="button" class="step-trigger">
                                <span class="bs-stepper-box">
                                    <i data-feather="file-text" class="font-medium-3"></i>
                                </span>
                                <span class="bs-stepper-label">
                                    <span class="bs-stepper-title">Basic Details</span>-->
                                    <!--<span class="bs-stepper-subtitle">Setup Basic Details</span>-->
                                <!--</span>
                            </button>
                        </div>
                        <div class="step" data-target="#address-step-vertical-modern">
                            <button type="button" class="step-trigger">
                                <span class="bs-stepper-box">
                                    <i data-feather="link" class="font-medium-3"></i>
                                </span>
                                <span class="bs-stepper-label">
                                    <span class="bs-stepper-title">Religious Details</span>-->
                                    <!--<span class="bs-stepper-subtitle">Add Religious Information</span>-->
                                <!--</span>
                            </button>
                        </div>
                        <div class="step" data-target="#social-links-vertical-modern">
                            <button type="button" class="step-trigger">
                                <span class="bs-stepper-box">
                                    <i data-feather="map-pin" class="font-medium-3"></i>
                                </span>
                                <span class="bs-stepper-label">
                                    <span class="bs-stepper-title">Location Details</span>-->
                                    <!--<span class="bs-stepper-subtitle">Add Location Information</span>-->
                                <!--</span>
                            </button>
                        </div>
                        <div class="step" data-target="#social-links-vertical-modern2">
                            <button type="button" class="step-trigger">
                                <span class="bs-stepper-box">
                                    <i data-feather="book" class="font-medium-3"></i>
                                </span>
                                <span class="bs-stepper-label">
                                    <span class="bs-stepper-title">Education/Profession Details</span>-->
                                    <!--<span class="bs-stepper-subtitle">Add Education/Profession Detail</span>-->
                                <!--</span>
                            </button>
                        </div>
                        <div class="step" data-target="#social-links-vertical-modern3">
                            <button type="button" class="step-trigger">
                                <span class="bs-stepper-box">
                                    <i data-feather="user-plus" class="font-medium-3"></i>
                                </span>
                                <span class="bs-stepper-label">
                                    <span class="bs-stepper-title">Family Details</span>-->
                                    <!--<span class="bs-stepper-subtitle">Add Family Detail</span>-->
                                <!--</span>
                            </button>
                        </div>
                        <div class="step" data-target="#social-links-vertical-modern4">
                            <button type="button" class="step-trigger">
                                <span class="bs-stepper-box">
                                    <i data-feather="user-plus" class="font-medium-3"></i>
                                </span>
                                <span class="bs-stepper-label">
                                    <span class="bs-stepper-title">Multiple Dropdown Test</span>-->
                                    <!--<span class="bs-stepper-subtitle">Add Family Detail</span>-->
                                <!--</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <form #tabOne="ngForm" (ngSubmit)="tabOne.form.valid;postProfileTabOneData(tabOne.value)">
                            <div id="account-details-vertical-modern" class="content">
                                <div class="content-header">
                                    <h5 class="mb-0">Basic Details</h5>-->
                                    <!--<small class="text-muted">Enter basic Details.</small>-->
                                <!--</div>-->
                                <!--<p style="color:white">{{ProfileTabOneWeightData.weight_en}}</p>-->
                                <!--<p class="card-text"><span style="color:red">* </span>Mandatory Fields.</p>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="first_name">First Name <span style="color:red"> *</span></label>
                                        <input type="text" id="first_name" class="form-control" [(ngModel)]="first_name" name="first_name" #FirstNameRef="ngModel"
                                               [class.error]="!FirstNameRef.valid && FirstNameRef.touched && tabOne.submitted" placeholder="Enter middle name" minlength="2" maxlength="50" required />-->
                                        <!--<div *ngIf="tabOne.submitted && FirstNameRef.invalid && FirstNameRef.touched" class="invalid-feedback">
                                            <div *ngIf="firstName.errors.required">First Name is required</div>
                                        </div>-->
                                        <!--<div *ngIf="!FirstNameRef.valid && FirstNameRef.touched || tabOne.submitted " class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="FirstNameRef.errors.required">This field is required!</small>-->
                                            <!--<small class="form-text text-danger" *ngIf="FirstNameRef.errors.minlength">This field is cotain minimum 2 charactor!</small>-->
                                            <!--<small class="form-text text-danger" *ngIf="FirstNameRef.errors.maxlength">This field is contain maximum 50 charactor!</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-middleName">Middle Name <span style="color:red"> *</span></label>
                                        <input type="text" id="vertical-modern-middleName" class="form-control" [(ngModel)]="profile_step_1.profile_middle_name" name="middle_name" #MiddleNameRef="ngModel"
                                               [class.error]="!MiddleNameRef.valid && MiddleNameRef.touched && tabOne.submitted " placeholder="Enter middle name" maxlength="50" />-->
                                        <!--<div *ngIf="!FirstNameRef.valid && FirstNameRef.touched || tabOne.submitted " class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="FirstNameRef.errors.required">This field is required!</small>
                                            <small class="form-text text-danger" *ngIf="FirstNameRef.errors.minlength">This field is cotain minimum 2 charactor!</small>
                                            <small class="form-text text-danger" *ngIf="FirstNameRef.errors.maxlength">This field is contain maximum 50 charactor!</small>
                                        </div>-->
                                    <!--</div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="last_name">Last Name <span style="color:red"> *</span></label>
                                        <input type="text" id="last_name" class="form-control" [(ngModel)]="profile_step_1.profile_last_name" name="last_name" #LastNameRef="ngModel"
                                               [class.error]="!LastNameRef.valid && LastNameRef.touched && tabOne.submitted" placeholder="Enter last name"  minlength="4" maxlength="50" required />
                                        <div *ngIf="!LastNameRef.valid && LastNameRef.touched || tabOne.submitted" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="LastNameRef.errors.required">This field is required!</small>-->
                                           <!-- <small class="form-text text-danger" *ngIf="LastNameRef.errors.minlength">This contain min two charactor!</small>-->
                                            <!--<small class="form-text text-danger" *ngIf="LastNameRef.errors.maxlength">This field is large!</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-height">Height <span style="color:red">*</span></label>
                                        <ng-select [items]="ProfileTabOneHeightData" (onSelect)="onSelectHeight($event)" [(ngModel)]="height_id" name="height_id" bindLabel="height_en" value="height_id" placeholder="Select height"></ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-weight">Weight <span style="color:red"> *</span></label>
                                        <ng-select [items]="ProfileTabOneWeightData" (onSelect)="onSelectWeight($event)" [(ngModel)]="weight_id" name="weight_id" bindLabel="weight_en" value="weight_id" placeholder="Select Weight"></ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-motherTongue">Mother Tongue <span style="color:red"> *</span></label>
                                        <ng-select [items]="ProfileTabOneMotherToungData" (onSelect)="onSelectMotherTongue($event)" [(ngModel)]="mother_tongue_id" bindLabel="mother_tongue_en" name="mother_tongue_id" value="mother_tongue_id" placeholder="Select language"></ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-maritalStatus">Marital Status <span style="color:red"> *</span></label>
                                        <ng-select [items]="ProfileTabOneMaritalStatusData" (onSelect)="onSelectMaritalStatus($event)" [(ngModel)]="marital_status_id" bindLabel="marital_status_en" name="marital_status_id" value="marital_status_id" placeholder="Select status"></ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-skinType">Skin type</label>
                                        <ng-select [items]="ProfileTabOneSkinTypeData" (onSelect)="onSelectSkinType($event)" [(ngModel)]="skin_type_id" bindLabel="skin_type_en" name="skin_type_id" value="skin_type_id" placeholder="Select"></ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-bodyType">Body Type</label>
                                        <ng-select [items]="ProfileTabOneBodyTypeData" (onSelect)="onSelectBodyType($event)" [(ngModel)]="body_type_id" bindLabel="body_type_en" name="body_type_id" value="body_type_id" placeholder="Select body type"></ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-eatingHabit">Eating Habit</label>
                                        <ng-select [items]="ProfileTabOneEatingHabitData" (onSelect)="onSelectEatingHabit($event)" [(ngModel)]="eating_habit_id" bindLabel="eating_habit_en" name="eating_habit_id" placeholder="Select habit"></ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-drinkingHabit">Drinking Habit</label>
                                        <ng-select [items]="ProfileTabOneDrinkingHabitData" (onSelect)="onSelectDrinkingHabit($event)" [(ngModel)]="drinking_habit_id" bindLabel="drinking_habit_en" name="drinking_habit_id" value="drinking_habit_id" placeholder="Select habit"></ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-smockingHabit">Smoking Habit</label>
                                        <ng-select [items]="ProfileTabOneSmokingHabitData" (onSelect)="onSelectSmokingHabit($event)" [(ngModel)]="smoking_habit_id" bindLabel="smoking_habit_en" name="smoking_habit_id" value="smoking_habit_id" placeholder="Select habit"></ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-bloodGroup">Blood Group</label>
                                        <ng-select [items]="ProfileTabOneBloodGroupData" (onSelect)="onSelectBloodGroup($event)" [(ngModel)]="blood_group_id" bindLabel="blood_group_en" name="blood_group_id" value="blood_group_id" placeholder="Select blood group"></ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-birthPlace">Birth Place</label>
                                        <input type="text" id="vertical-modern-birthPlace" [(ngModel)]="birth_place" name="birth_place" class="form-control" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-birthtime">Birth Time</label>
                                        <input type="text" id="vertical-modern-birthtime" [(ngModel)]="birth_time" name="birth_time" class="form-control" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-birthDate">Birth Date</label>
                                        <input type="text" id="vertical-modern-birthDate" [(ngModel)]="birth_date" name="birth_date" class="form-control" placeholder="yyyy-mm-dd" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label for="aboutMe">About Me <span style="color:red"> *</span></label>
                                        <fieldset class="form-group">
                                            <textarea class="form-control" id="aboutMe" [(ngModel)]="about_me" name="about_me" rows="4" placeholder="Enter details"></textarea>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label for="hobby">Hobby</label>
                                        <fieldset class="form-group">
                                            <textarea class="form-control" id="hobby" [(ngModel)]="hobby" name="hobby" rows="4" placeholder="Enter hobbies"></textarea>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button class="btn btn-outline-secondary btn-prev" disabled rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>-->
                                    <!--<button class="btn-submit"> Submit</button>-->
                                    <!--<button class="btn btn-primary btn-submit" (click)="modernVerticalNextp(tabOne)" rippleEffect>
                                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form #tabTwo="ngForm" (ngSubmit)="putProfileTabTwoData(tabTwo.value);tabTwo.reset()">
                            <div id="address-step-vertical-modern" class="content">
                                <div class="content-header">
                                    <h5 class="mb-0">Religious Details.</h5>-->
                                    <!--<small>Add Religious Information.</small>-->
                                <!--</div>
                                <p class="card-text"><span style="color:red">* </span>Mandatory Fields.</p>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="religion">Religion <span style="color:red"> *</span></label>
                                        <ng-select [items]="ProfileTab2religiousData" bindLabel="religion_en" (onSelect)="onSelectReligion($event)" [(ngModel)]="religion_id" name="religion_id" #ReligionRef="ngModel" 
                                                   [class.error]="!ReligionRef.valid && tabTwo.submitted && ReligionRef.touched" value="religion_id" placeholder="Select Religion" required> </ng-select>
                                        <div *ngIf="tabTwo.submitted && !ReligionRef.valid && ReligionRef.touched" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="ReligionRef.errors.required">This field is required!</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-caste">Caste <span style="color:red"> *</span></label>
                                        <ng-select [items]="ProfileTab2casteData" bindLabel="caste_en" (onSelect)="onSelectCaste($event)" [(ngModel)]="caste_id" name="caste_id"  #CasteRef="ngModel"
                                                 [class.error]="!CasteRef.valid && tabTwo.submitted && CasteRef.touched" placeholder="Select Caste" required> </ng-select>
                                        <div *ngIf="tabTwo.submitted && !CasteRef.valid && CasteRef.touched" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="CasteRef.errors.required">This field is required!</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="subcaste">Subcaste</label>
                                        <input type="text" id="vertical-modern-subcaste" class="form-control" [(ngModel)]="subcaste" name="subcaste" value="subcaste" placeholder="Write Your Subcaste" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="gothra">Gothra</label>
                                        <ng-select [items]="ProfileTab2gothraData" (onSelect)="onSelectGothra($event)" bindLabel="gothra_en" [(ngModel)]="gothra_id" name="gothra_id" placeholder="Select Gothra"> </ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-horoscope">Horoscope</label>
                                        <ng-select [items]="ProfileTab2horoscopeData" (onSelect)="onSelectHoroscope($event)" bindLabel="horoscope_en" [(ngModel)]="horoscope_id" name="horoscope_id" placeholder="Select horoscope"> </ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-manglik">Manglik</label>
                                        <ng-select [items]="ProfileTab2manglikData" (onSelect)="onSelectManglik($event)" bindLabel="manglik_horoscope_en" [(ngModel)]="manglik_id" name="manglik_id" placeholder="Select Mangalic"> </ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-star">Star</label>
                                        <ng-select [items]="ProfileTab2starData" (onSelect)="onSelectStar($event)" bindLabel="star_en" [(ngModel)]="star_id" name="star_id" placeholder="Select star"> </ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-moonsign">Moonsign</label>
                                        <ng-select [items]="ProfileTab2moonsignData" (onSelect)="onSelectMoonsign($event)" bindLabel="moonsign_en" [(ngModel)]="moonsign_id" name="moonsign_id" placeholder="Select moonsign"> </ng-select>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button class="btn btn-primary btn-submit" (click)="modernVerticalNext()" rippleEffect>
                                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form #tabThree="ngForm" (ngSubmit)="putProfileTabThreeData(tabThree.value);tabThree.reset()">
                            <div id="social-links-vertical-modern" class="content">
                                <div class="content-header">
                                    <h5 class="mb-0">Location Details</h5>-->
                                    <!--<small>Enter Your Social Links.</small>-->
                                <!--</div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="address">Address</label>
                                        <fieldset class="form-group">
                                            <textarea class="form-control" id="address" rows="2" [(ngModel)]="address" name="address" #AddressRef="ngModel"
                                                      [class.error]="!AddressRef.valid && tabThree.submitted && AddressRef.touched" placeholder="Enter address"></textarea>
                                        </fieldset>
                                        <div *ngIf="tabThree.submitted && !AddressRef.valid && AddressRef.touched" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="AddressRef.errors.required">This field is required!</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="country">Country <span style="color:red"> *</span></label>
                                        <ng-select [items]="ProfileTab3CountriesData" bindLabel="country_en" [(ngModel)]="country_en" name="country_id" #CountryRef="ngModel" 
                                                   placeholder="Select country"> </ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="state">State <span style="color:red"> *</span></label>
                                        <ng-select [items]="ProfileTab3StatesData" bindLabel="state_en" [(ngModel)]="state_id" name="state_id" #StateRef="ngModel" 
                                                  [class.error]="!StateRef.valid && StateRef.touched" placeholder="Select state"> </ng-select>
                                        <div *ngIf="tabThree.submitted && !StateRef.valid && StateRef.touched" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="StateRef.errors.required">This field is required!</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="city">City <span style="color:red"> *</span></label>
                                        <ng-select [items]="ProfileTab3CitiesData" bindLabel="city_en" [(ngModel)]="city_id" name="city_id" #CityRef="ngModel"
                                                   [class.error]="!CityRef.valid && CityRef.touched" placeholder="Select city"> </ng-select>
                                        <div *ngIf="!CityRef.valid && CityRef.touched" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="CityRef.errors.required">This field is required!</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-mobileNo">Mobile No <span style="color:red"> *</span></label>
                                        <input type="text" id="vertical-modern-mobile" class="form-control" [(ngModel)]="mobile" name="mobile" #MobileNoRef="ngModel" 
                                               [class.error]="!MobileNoRef.valid && tabThree.submitted && MobileNoRef.touched" placeholder="+91" maxlength="10" />
                                        <div *ngIf="tabThree.submitted && !MobileNoRef.valid && MobileNoRef.touched" class="invalid-form">
                                            <small class="form-text text-danger" *ngIf="MobileNoRef.errors.required">This field is required!</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-phone">Phone</label>
                                        <input type="text" id="vertical-modern-phone" class="form-control" [(ngModel)]="phone" name="phone" placeholder="Enter phone number" maxlength="10" />
                                    </div>
                                </div>
                                <div class="row">-->
                                    <!--<div class="form-group col-md-6">
                <label class="form-label" for="vertical-modern-timeToCall">Time To Call</label>
                <input type="text" id="vertical-modern-timeToCall" class="form-control"  [(ngModel)]="timeToCall" name="timeToCall" placeholder="Write time to call" />
            </div>-->
                                    <!--<div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-residenceStatus">Residence Status</label>
                                        <ng-select [items]="ProfileTab3Residence_statusData" bindLabel="residence_en" [(ngModel)]="residence_id" name="residence_id" placeholder="Select residenceStatus"> </ng-select>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button class="btn btn-primary btn-submit" (click)="modernVerticalNext()" rippleEffect>
                                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form #tabFour="ngForm" (ngSubmit)="putProfileTabFourData(tabFour.value)">
                            <div id="social-links-vertical-modern2" class="content">
                                <div class="content-header">
                                    <h5 class="mb-0">Education/Profession Details</h5>-->
                                    <!--<small>Enter Your Social Links.</small>-->
                                <!--</div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-education">Education</label>
                                        <ng-select [items]="ProfileTab4EducationData" bindLabel="education_en" [multiple]="true" [closeOnSelect]="false" [searchable]="false"
                                                   [(ngModel)]="education_id" name="education_id" value="education_id" (onSelect)="onSelectEducation($event)" placeholder="Select people">
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-employedIn">Employed In</label>
                                        <ng-select [items]="ProfileTab4Employed_inData" bindLabel="employed_in_en" [(ngModel)]="employed_in_id" name="employed_in_id" placeholder="Select status"> </ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-designation">Designation</label>
                                        <ng-select [items]="ProfileTab4DesignationData" bindLabel="designation_en" [(ngModel)]="designation_id" name="designation_id" placeholder="Select your designation"> </ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-occupation">Occupation</label>
                                        <ng-select [items]="ProfileTab4OccupationData" bindLabel="occupation_en" [(ngModel)]="occupation_id" name="occupation_id" placeholder="Write occupation"> </ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-income">Annual Income</label>
                                        <ng-select [items]="ProfileTab4Annual_incomeData" bindLabel="income_range" [(ngModel)]="annual_income_id" name="annual_income_id" placeholder="Select annual income"> </ng-select>
                                    </div>-->
                                    <!--<div class="form-group col-md-6">
                <label class="form-label" for="vertical-modern-occupation">Occupation</label>
                <ng-select [items]="occupation" bindLabel="name" placeholder="Write occupation"> </ng-select>
            </div>-->
                                <!--</div>
                                <div class="d-flex justify-content-between">
                                    <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button class="btn btn-primary btn-next" (click)="modernVerticalNext()" rippleEffect>
                                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form #tabFive="ngForm" (ngSubmit)="putProfileTabFiveData(tabFive.value)">
                            <div id="social-links-vertical-modern3" class="content">
                                <div class="content-header">
                                    <h5 class="mb-0">Family Details</h5>-->
                                    <!--<small>Enter Your Social Links.</small>-->
                                <!--</div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-familyType">Family Type</label>
                                        <ng-select [items]="ProfileTab5Family_typeData" bindLabel="family_type_en" [(ngModel)]="family_type_id" name="family_type_id" placeholder="Family type"> </ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-familyStatus">Family Status</label>
                                        <ng-select [items]="ProfileTab5Family_statusData" bindLabel="family_status_en" [(ngModel)]="family_status_id" name="family_status_id" placeholder="Select family status"> </ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-fatherName">Father Name</label>
                                        <input type="text" id="vertical-modern-fatherName" [(ngModel)]="father_name" name="father_name" class="form-control" placeholder="Enter Father Name" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-fatherOccupation">Father Occupation</label>
                                        <ng-select [items]="ProfileTab5OccupationData" bindLabel="f_occupation_en" [(ngModel)]="f_occupation_id" name="f_occupation_id" placeholder="Father Occupation"> </ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-motherName">Mother Name</label>
                                        <input type="text" id="vertical-modern-motherName" [(ngModel)]="mother_Name" name="mother_Name" class="form-control" placeholder="Enter Mother Name" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-motherOccupation">Mother occupation</label>
                                        <ng-select [items]="ProfileTab5M_occupationData" bindLabel="m_occupation_en" [(ngModel)]="m_occupation_id" name="m_occupation_id" placeholder="Mother Occupation"> </ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-brothers">No of Brothers</label>
                                        <input type="text" id="vertical-modern-brothers" [(ngModel)]="total_brothers" name="total_brothers" class="form-control" placeholder="Enter" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-sisters">No of Sisters</label>
                                        <input type="text" id="vertical-modern-sisters" [(ngModel)]="total_sisters" name="total_sisters" class="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-mariedBrother">Married Brother</label>
                                        <input type="text" id="vertical-modern-mariedBrother" [(ngModel)]="total_married_brother" name="total_married_brother" class="form-control" placeholder="Enter" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-mariedsisters">Married Sisters</label>
                                        <input type="text" id="vertical-modern-mariedsisters" [(ngModel)]="total_married_sister" name="total_married_sister" class="form-control" placeholder="Enter" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="aboutFamily">About My Family</label>
                                        <fieldset class="form-group">
                                            <textarea class="form-control" id="aboutFamily" [(ngModel)]="about_family" name="about_family" rows="4" placeholder="Write About family"></textarea>
                                        </fieldset>
                                    </div>-->
                                    <!--<div class="form-group col-md-6">
                <label class="form-label" for="vertical-modern-country">Country <span style="color:red"> *</span></label>
                <ng-select [items]="country" bindLabel="name" placeholder="Select country"> </ng-select>
            </div>-->
                                <!--</div>
                                <div class="d-flex justify-content-between">
                                    <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button class="btn btn-success btn-submit" rippleEffect>Submit</button>
                                </div>
                            </div>
                        </form>
                        <div id="social-links-vertical-modern4" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">Multiple test</h5>-->
                                <!--<small>Enter Your Social Links.</small>-->
                            <!--</div>
                            <form [formGroup]="multipleselection" (ngSubmit)="submitMultivalue(multipleselection.value)">
                                <h2 style="color:red">{{getOne}}</h2>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-education">Education</label>
                                        <ng-select [items]="ProfileTabOneBloodGroupData"
                                                   (onSelect)="onItemSelect($event)"
                                                   [multiple]="true"
                                                   [closeOnSelect]="false"
                                                   [searchable]="false"
                                                   bindLabel="blood_group_en"
                                                   name="selectEducation"
                                                   formControlName="course"
                                                   placeholder="Select Education Type">
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="vertical-modern-firstName">First Name <span style="color:red"> *</span></label>
                                        <input type="text" id="vertical-modern-firstName" formControlName="profile_first_name" name="profile_firstname" class="form-control" />
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()" rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button class="btn btn-success btn-submit" rippleEffect>Submit</button>
                                    <button class="btn btn-primary btn-edit" (click)="getmulti()">edit</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>-->
            <!-- /Modern Vertical Wizard -->
    <!--</div>
</div>-->

