import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { base } from 'base/base';
import { TblMembership, tbl_cards, tbl_payments, vm_model_for_subscription } from 'app/model/tbl-membership';
import { model_response } from 'app/model/tbl-profile';
import { TblSubscriptionPlan } from 'app/model/tbl-subscription-plan';
import { MembershipService } from 'app/services/membership.service';
import { ProfileListService } from 'app/services/profile-list.service';
import * as snippet from 'app/main/forms/form-elements/input-mask/input-mask.snippetcode';

@Component({
  selector: 'app-subscription-confirm',
  templateUrl: './subscription-confirm.component.html',
  styleUrls: ['./subscription-confirm.component.scss']
})
export class SubscriptionConfirmComponent implements OnInit {
  public _snippetCodeCredit = snippet.snippetCodeCredit;

  public contentHeader: object;
  public isCollapsed1 = true;
  public isCollapsed2 = false;
  public isCollapsed3 = true;
  public base = base;

  submitted = false;

  PlanListData: TblSubscriptionPlan;
  getBankData: TblMembership;
  paymentForm: any;

  constructor(private profileListService: ProfileListService, private membershipService: MembershipService,
    private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.PlanListData = JSON.parse(localStorage.getItem("membership") || '{}');

    this.paymentGetBank();
    this.paymentForm = this.formBuilder.group({
      payment_method: ['NetBanking'],
      card_number: [''],
      expiry_month: [''],
      expiry_year: [''],
      name: [''],
      bank_id: [''],
      upiID: ['']
    });
    this.validation_reset(null);
    this.paymentForm.get('payment_method').valueChanges
      .subscribe((payment_method: any) => {
        this.validation_reset(payment_method)
      });
  }

  convertModelFeatures(data) {
    return <TblSubscriptionPlan[]>JSON.parse(data)
  }

  subscriptionSend(data: TblSubscriptionPlan) {
    this.router.navigate(['/subscription-confirm']);
  }

  paymentGetBank() {
    this.membershipService.subscription_plan_get().subscribe((res: model_response) => {
      this.getBankData = res.data
      console.warn(this.getBankData)
    })
  }

  get f() { return this.paymentForm.controls; }

  payFromCardSubmit() {
    debugger
    this.submitted = true;
    console.warn(this.paymentForm.value)

    // const invalid = [];
    // const controls = this.paymentForm.controls;
    // for (const name in controls) {
    //   if (controls[name].invalid) {
    //     invalid.push(name);
    //   }
    // }
    // if (invalid.length > 0) alert(invalid[0])

    var data: vm_model_for_subscription = new vm_model_for_subscription();
      switch(this.paymentForm.value.payment_method){
        case 'Card':{
          data.tbl_cards.card_number = this.paymentForm.value.card_number
          data.tbl_cards.expiry_month = this.paymentForm.value.expiry_month
          data.tbl_cards.expiry_year = this.paymentForm.value.expiry_year
          data.tbl_cards.name = this.paymentForm.value.name
      
          data.tbl_payments.payment_method = this.paymentForm.value.payment_method
          data.tbl_payments.payment_amount = this.PlanListData.plan_price_offer
          data.tbl_payments.plan_validity_value_in_days = this.PlanListData.plan_validity_value_in_days
          data.tbl_payments.plan_id = this.PlanListData.plan_id
          break;
        }
  
        case 'NetBanking':{
          data.tbl_payments.bank_id = this.paymentForm.value.bank_id
          data.tbl_payments.payment_method = this.paymentForm.value.payment_method
          data.tbl_payments.payment_amount = this.PlanListData.plan_price_offer
          data.tbl_payments.plan_validity_value_in_days = this.PlanListData.plan_validity_value_in_days
          data.tbl_payments.plan_id = this.PlanListData.plan_id
  
  
          break;
        }
  
        case 'UPI':{ 
          data.tbl_payments.upi_address = this.paymentForm.value.upiID
          data.tbl_payments.payment_method = this.paymentForm.value.payment_method
          data.tbl_payments.payment_amount = this.PlanListData.plan_price_offer
          data.tbl_payments.plan_validity_value_in_days = this.PlanListData.plan_validity_value_in_days
          data.tbl_payments.plan_id = this.PlanListData.plan_id
          
          break;
        }
      }
      if (this.paymentForm.valid){
      this.membershipService.subscription_payment_insert(data).subscribe((res: model_response) => {
        if(res.status == "success"){
          alert(res.status)
          this.router.navigate(['/payment-gatway'], { queryParams: { plan_id: this.PlanListData.plan_id, payment_id: res.data[0].payment_id } } )
        }
      })
    }

  }

  validation_reset(payment_method) {
    var pm = payment_method == null ? this.paymentForm.get('payment_method').value : payment_method;
    switch (pm) {
      case 'UPI':
        this.paymentForm.get('card_number').setValidators('');
        this.paymentForm.get('expiry_month').setValidators('');
        this.paymentForm.get('expiry_year').setValidators('');
        this.paymentForm.get('name').setValidators('');
        this.paymentForm.get('bank_id').setValidators('');
        this.paymentForm.get('upiID').setValidators([Validators.required]);

        break;
      case 'Card':
        this.paymentForm.get('card_number').setValidators([Validators.required, Validators.pattern('[0-9]{16}')]);
        this.paymentForm.get('expiry_month').setValidators([Validators.required, Validators.pattern('[0-9]{2}')]);
        this.paymentForm.get('expiry_year').setValidators([Validators.required, Validators.pattern('[0-9]{4}')]);
        this.paymentForm.get('name').setValidators([Validators.required]);
        this.paymentForm.get('bank_id').setValidators('');
        this.paymentForm.get('upiID').setValidators('');

        break;
      case 'NetBanking':
        this.paymentForm.get('card_number').setValidators('');
        this.paymentForm.get('name').setValidators('');
        this.paymentForm.get('expiry_month').setValidators('');
        this.paymentForm.get('expiry_year').setValidators('');
        this.paymentForm.get('bank_id').setValidators([Validators.required]);
        this.paymentForm.get('upiID').setValidators('');
        break;
    }
    this.paymentForm.get('card_number').updateValueAndValidity();
    this.paymentForm.get('name').updateValueAndValidity();
    this.paymentForm.get('expiry_month').updateValueAndValidity();
    this.paymentForm.get('expiry_year').updateValueAndValidity();
    this.paymentForm.get('bank_id').updateValueAndValidity();
    this.paymentForm.get('upiID').updateValueAndValidity();

  }

}
