<section>
<h2 class="card-title mb-1">{{'MENU.PROFILES.hipping_and_delivery' | translate}}</h2> 

          <div class="cart">
                    <!-- <div class="cart-item"> -->
                    <section class="content">
                              <!-- <div class="bg-light mb-4 pl-3 pr-3 pt-3 pb-0 osahan-privacy-item"> -->
                              <!-- <div class="osahan-privacy bg-white rounded shadow p-4 mt-n5"> -->
                              <div id="intro" class="mb-1">

                                        <div class="mb-2">
                                                  <h2 class="h5 text-primary">Shipping and Delivery</h2>
                                        </div>
                                        <h6 class="h6 text-primary">What are the delivery charges?</h6>

                                        <p>Delivery/Service charge varies with each Service provider.
                                                  Service providers incur relatively higher service charge on low value
                                                  service. In
                                                  such cases, charging a nominal service charge helps them offset
                                                  logistics costs.
                                                  For Services listed in Sevagudi, a Rs 40 charge for delivery per
                                                  service may be applied if the order value is less than Rs 500. While,
                                                  orders of Rs 500 or above are delivered free.
                                        </p>
                              </div>

                              <div id="yourContent" class="active">

                                        <div class="mb-2">
                                                  <h3 class="h5 text-primary">1. Why does the delivery date not
                                                            correspond to the delivery timeline of X-Y business days?
                                                  </h3>
                                        </div>
                                        <p>It is possible that the Service provider have a holiday between
                                                  the day your placed your order and the date of delivery. In this case,
                                                  we add
                                                  a day to the estimated date. Some Service providers do not
                                                  work on Sundays and this is factored in to the delivery dates.
                                        </p>
                              </div>
                              <div id="yourContent" class="active">

                                        <div class="mb-2">
                                                  <h3 class="h5 text-primary">2. What is the estimated delivery time?
                                                  </h3>
                                                  <p>Service providers generally procure the services within the 3 to 7
                                                            working days. Business days exclude public
                                                            holidays and Sundays.</p>
                                        </div>
                                        <p>Estimated delivery time depends on the following factors:</p>
                                        <ul class="text-secondary">
                                                  <li class="pb-2">The Service provider offering the services </li>
                                                  <li class="pb-2">Service provider's availability</li>
                                                  <li class="pb-2">The destination to which you want the get service
                                                            to and location of the Service provider.</li>
                                        </ul>
                              </div>





                              <div id="yourContent" class="active">

                                        <div id="personal-data" class="mb-2 active">
                                                  <h3 class="h5 text-primary">3. Are there any hidden costs (sales tax
                                                            etc) on services provided by Service provider on Sevagudi?
                                                  </h3>
                                        </div>
                                        <p>There are NO hidden charges when you make a order on Sevagudi. List prices
                                                  are final and all-inclusive. The price you see on the service page is
                                                  exactly what you would pay.

                                                  Service charges are not hidden charges and are charged (if at all)
                                                  extra depending on the Service provider's policy.</p>
                              </div>
                              <div id="yourContent" class="active">

                                        <div class="mb-2">
                                                  <h3 class="h5 text-primary">4. Does Sevagudi provide service
                                                            internationally?
                                                  </h3>
                                                  <p>As of now, Sevagudi doesn't provide service internationally.</p>
                                        </div>
                                        <p>
                                                  You will be able to make your order on our site from anywhere in
                                                  the world with credit/debit cards issued in India and 21 other
                                                  countries, but please ensure the service address is in India.</p>
                              </div>


                              <!-- </div>
    </div> -->
                    </section>
                    <!-- </div> -->
          </div>
</section>