import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import Stepper from 'bs-stepper';
import { base } from '../../base/base'
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PreferenceService } from '../services/preference.service';
import { model_city, model_country, model_residence, model_response, model_state } from '../model/tbl-profile';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    model_PreferenceStep1, model_marital_status, model_ageFrom, model_ageTo, model_heightFrom, model_heightTo, model_mother_tongue, model_body_type,
    model_eating_habit, model_smoking_habit, model_drinking_habit, model_skin_type
} from '../model/tbl-preference';
import { Observable, observable, pipe, Subscriber, throwError } from 'rxjs';
import { catchError, map, retry, switchMap } from 'rxjs/operators';
import { timeStamp } from 'console';
import { model_caste, model_manglik, model_preferenceStep2, model_religion, model_star } from 'app/model/tbl-preference-step-two';
import { model_preferenceStep3 } from 'app/model/tbl-preference-step-three';
import { model_annual_income, model_designation, model_education, model_employed_in, model_occupation, model_preferenceStep4 } from 'app/model/tbl-preference-step-four';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class PreferencesComponent implements OnInit {
    peferenceTabOneForm: UntypedFormGroup;
    peferenceTabTwoForm: UntypedFormGroup;
    peferenceTabThreeForm: UntypedFormGroup;
    peferenceTabFourForm: UntypedFormGroup;

    preferenceStep2Data: model_preferenceStep2;
    preTab2Manglik: model_manglik[];
    preTab2Religion: model_religion[];
    preTab2Caste: model_caste[];
    preTab2Star: model_star[];

    modelPreRes: model_response;
    preferenceStep1Data: model_PreferenceStep1[];
    preTab1MaritalStatus: model_marital_status[];
    preTab1AgeFrom: model_ageFrom[];
    preTab1AgeTo: model_ageTo[];
    preTab1HeightFrom: model_heightFrom[];
    preTab1HeightTo: model_heightTo[];
    preTab1MotherTongue: model_mother_tongue[];
    preTab1BodyType: model_body_type[];
    preTab1EatingHabit: model_eating_habit[];
    preTab1SmokingHabit: model_smoking_habit[];
    preTab1DrinkingHabit: model_drinking_habit[];
    preTab1SkinType: model_skin_type[];


    preferenceStep3Data: model_preferenceStep3;
    preTab3Country: model_country[];
    preTab3State: model_state[];
    preTab3City: model_city[];
    preTab3Residence: model_residence[];

    preferenceStep4Data: model_preferenceStep4;
    preTab4Education: model_education[];
    preTab4EmployedIn: model_employed_in[];
    preTab4Occupation: model_occupation[];
    preTab4AnnualIncome: model_annual_income[];
    preTabDesignation: model_designation[];

    preTabOneData = new model_PreferenceStep1();
    preTabTwoData = new model_preferenceStep2();
    preTabThreeData = new model_preferenceStep3();
    preTabFourData: model_preferenceStep4;

    private modernVerticalWizardStepper: Stepper;
    private bsStepper;
    public contentHeader: object;
    public selectMultiSelected;
    public selectMulti = [{ name: 'English' }, { name: 'French' }, { name: 'Spanish' }];
    public selectBasic = [
        { name: 'UK' },
        { name: 'USA' },
        { name: 'Spain' },
        { name: 'France' },
        { name: 'Italy' },
        { name: 'Australia' }
    ];

    modernVerticalPrevious() {
        this.modernVerticalWizardStepper.previous();
    }

    onSubmit() {
        return false;
    }

    constructor(private http: HttpClient, private preferenceService: PreferenceService, private formBuilder: UntypedFormBuilder,
        public translate: TranslateService, private toastr: ToastrService,) { }

    get marital_status_id() {
        return this.peferenceTabOneForm.get('marital_status_id')
    }
    get age_from_id() {
        return this.peferenceTabOneForm.get('age_from_id')
    }
    get age_to_id() {
        return this.peferenceTabOneForm.get('age_to_id')
    }
    get height_from_id() {
        return this.peferenceTabOneForm.get('height_from_id')
    }
    get height_to_id() {
        return this.peferenceTabOneForm.get('height_to_id')
    }
    get mother_tongue_id() {
        return this.peferenceTabOneForm.get('mother_tongue_id')
    }
    get body_type_id() {
        return this.peferenceTabOneForm.get('body_type_id')
    }
    get eating_habit_id() {
        return this.peferenceTabOneForm.get('eating_habit_id')
    }
    get smoking_habit_id() {
        return this.peferenceTabOneForm.get('smoking_habit_id')
    }
    get drinking_habit_id() {
        return this.peferenceTabOneForm.get('drinking_habit_id')
    }
    get skin_type_hi() {
        return this.peferenceTabOneForm.get('skin_type_hi')
    }
    get expectations() {
        return this.peferenceTabOneForm.get('expectations')
    }

    ngOnInit() {
        this.peferenceTabOneForm = this.formBuilder.group({
            marital_status_id: [Validators.required],
            age_from_id: [Validators.required],
            age_to_id: [Validators.required],
            height_from_id: [Validators.required],
            height_to_id: [Validators.required],
            mother_tongue_id: [Validators.required],
            body_type_id: [Validators.required],
            eating_habit_id: [Validators.required],
            smoking_habit_id: [Validators.required],
            drinking_habit_id: [Validators.required],
            skin_type_id: [Validators.required],
            expectations: ['', Validators.required ]
        })

        this.peferenceTabTwoForm = this.formBuilder.group({
            religion_id: [Validators.required],
            caste_id: [Validators.required],
            manglik_id: [Validators.required],
            star_id: [Validators.required]
        })

        this.peferenceTabThreeForm = this.formBuilder.group({
            country_id: [Validators.required],
            state_id: [Validators.required],
            city_id: [Validators.required],
            residence_id: [Validators.required]
        })

        this.peferenceTabFourForm = this.formBuilder.group({
            education_id: [Validators.required],
            employed_in_id: [Validators.required],
            occupation_id: [Validators.required],
            annual_income_id: [Validators.required],
            designation_id: [Validators.required]
        })

        this.modernVerticalWizardStepper = new Stepper(document.querySelector('#stepper5'), {
            linear: false,
            animation: true
        });

        this.bsStepper = document.querySelectorAll('.bs-stepper');
        this.getPreferenceTabOneData();
        this.getPreferenceTabTwoData();
        this.getPreferenceTabThreeData();
        this.getPreferenceTabFourData();
    }

    getPreferenceTabOneData() {
        try {
            this.preferenceService.getPreferenceTabOne().pipe(map((res: model_response) => <model_response>res),
                retry(2)).subscribe(
                    (res: model_response) => {
                        this.modelPreRes = res;
                        // JSON.parse(d.toString());
                        if (this.modelPreRes.status == "Success") {
                            let ModifyData = JSON.parse(this.modelPreRes.data.toString());
                            this.preTab1MaritalStatus = ModifyData.Table1;
                            this.preTab1AgeFrom = ModifyData.Table3;
                            // console.log(JSON.stringify(this.preTab1AgeFrom));
                            this.preTab1AgeTo = ModifyData.Table4;
                            this.preTab1HeightFrom = ModifyData.Table5;
                            this.preTab1HeightTo = ModifyData.Table6;
                            this.preTab1MotherTongue = ModifyData.Table7;
                            this.preTab1BodyType = ModifyData.Table9;
                            this.preTab1EatingHabit = ModifyData.Table11;
                            this.preTab1SmokingHabit = ModifyData.Table13;
                            this.preTab1DrinkingHabit = ModifyData.Table15;
                            this.preTab1SkinType = ModifyData.Table17;
                            this.preferenceStep1Data = ModifyData.Table || ModifyData.Table8 || ModifyData.Table10 || ModifyData.Table12 || ModifyData.Table14 || ModifyData.Table16 || ModifyData.Table18;
                            if (this.preferenceStep1Data) {
                                var result = ModifyData.Table2.map(a => a.marital_status_id);
                                let marital_status_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                var result = ModifyData.Table8.map(a => a.mother_tongue_id);
                                let mother_tongue_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                var result = ModifyData.Table10.map(a => a.body_type_id);
                                let body_type_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                var result = ModifyData.Table12.map(a => a.eating_habit_id);
                                let eating_habit_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                var result = ModifyData.Table14.map(a => a.smoking_habit_id);
                                let smoking_habit_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                var result = ModifyData.Table16.map(a => a.drinking_habit_id);
                                let drinking_habit_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                var result = ModifyData.Table18.map(a => a.skin_type_id);
                                let skin_type_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                this.peferenceTabOneForm.controls['marital_status_id'].setValue(marital_status_idVal);
                                this.peferenceTabOneForm.controls['age_from_id'].setValue(ModifyData.Table[0].age_from_id);
                                this.peferenceTabOneForm.controls['age_to_id'].setValue(ModifyData.Table[0].age_to_id);
                                this.peferenceTabOneForm.controls['height_from_id'].setValue(ModifyData.Table[0].height_from_id);
                                this.peferenceTabOneForm.controls['height_to_id'].setValue(ModifyData.Table[0].height_to_id);
                                this.peferenceTabOneForm.controls['mother_tongue_id'].setValue(mother_tongue_idVal);
                                this.peferenceTabOneForm.controls['body_type_id'].setValue(body_type_idVal);
                                this.peferenceTabOneForm.controls['eating_habit_id'].setValue(eating_habit_idVal);
                                this.peferenceTabOneForm.controls['smoking_habit_id'].setValue(smoking_habit_idVal);
                                this.peferenceTabOneForm.controls['drinking_habit_id'].setValue(drinking_habit_idVal);
                                this.peferenceTabOneForm.controls['skin_type_id'].setValue(skin_type_idVal);
                                this.peferenceTabOneForm.controls['expectations'].setValue(ModifyData.Table[0].expectations);
                            }
                        } else this.toastr.error(res.message, 'Error');
                    })//, (error) => {window.alert(error); })
        } catch (er: any) { throw er }
    }

    getPreferenceTabTwoData() {
        try {
            this.preferenceService.getPreferenceTabTwo().pipe(map((res: model_response) => <model_response>res),
                retry(2)).subscribe(
                    (res: model_response) => {
                        this.modelPreRes = res;
                        // JSON.parse(d.toString());
                        if (this.modelPreRes.status == "Success") {
                            let ModifyTWoData = JSON.parse(this.modelPreRes.data.toString());
                            console.warn(ModifyTWoData)
                            this.preTab2Manglik = ModifyTWoData.Table5;
                            this.preTab2Religion = ModifyTWoData.Table1;
                            this.preTab2Caste = ModifyTWoData.Table3;
                            this.preTab2Star = ModifyTWoData.Table6;
                            this.preferenceStep2Data = ModifyTWoData.Table || ModifyTWoData.Table2 || ModifyTWoData.Table4 || ModifyTWoData.Table7;
                            if (this.preferenceStep2Data) {
                                var result = ModifyTWoData.Table2.map(a => a.religion_id);
                                let religion_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                var result = ModifyTWoData.Table4.map(a => a.caste_id);
                                let caste_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                var result = ModifyTWoData.Table7.map(a => a.star_id);
                                let star_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                                this.peferenceTabTwoForm.controls['religion_id'].setValue(religion_idVal);
                                this.peferenceTabTwoForm.controls['caste_id'].setValue(caste_idVal);
                                this.peferenceTabTwoForm.controls['manglik_id'].setValue(ModifyTWoData.Table[0].manglik_id);
                                this.peferenceTabTwoForm.controls['star_id'].setValue(star_idVal);
                            }
                        } else this.toastr.error(res.message, 'Error');
                    })//, (error) => {// retry(2),  window.alert("Internal server error occured during processing your request. Please try again!"); })
        } catch (ex: any) { throw ex }
    }

    getPreferenceTabThreeData() {
        try {
            this.preferenceService.getPreferenceTabThree().pipe(map((res: model_response) => <model_response>res),
                retry(2)
            ).subscribe(
                (res: model_response) => {
                    this.modelPreRes = res;
                    // console.warn(this.modelPreRes)

                    if (this.modelPreRes.status == "Success") {

                        let ModifyThreeData = JSON.parse(this.modelPreRes.data.toString());
                        //  console.warn(ModifyThreeData);

                        this.preTab3Country = ModifyThreeData.Table;
                        this.preTab3State = ModifyThreeData.Table2;
                        this.preTab3City = ModifyThreeData.Table4;
                        this.preTab3Residence = ModifyThreeData.Table6;

                        //  console.warn(this.preTab3Residence);


                        /*Start pre fill of preference step 3 */
                        this.preferenceStep3Data = ModifyThreeData.Table1 || ModifyThreeData.Table3 || ModifyThreeData.Table5 || ModifyThreeData.Table7;
                        if (this.preferenceStep3Data) {
                            var result = ModifyThreeData.Table1.map(a => a.country_id);
                            let country_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                            var result = ModifyThreeData.Table3.map(a => a.state_id);
                            let state_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                            var result = ModifyThreeData.Table5.map(a => a.city_id);
                            let city_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                            var result = ModifyThreeData.Table7.map(a => a.residence_id);
                            let residence_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                            //  console.log(this.preferenceStep3Data)

                            this.peferenceTabThreeForm.controls['country_id'].setValue(country_idVal);
                            this.peferenceTabThreeForm.controls['state_id'].setValue(state_idVal);
                            this.peferenceTabThreeForm.controls['city_id'].setValue(city_idVal);
                            this.peferenceTabThreeForm.controls['residence_id'].setValue(residence_idVal);
                        } else this.toastr.error("Plesse fill the details", 'Error!');
                        /*End pre fill of preference step 3 */

                    } else this.toastr.error(res.message, 'Error');
                })//, (error) => {// retry(2),  window.alert("Internal server error occured during processing your request. Please try again!"); })
        } catch (ex: any) { throw ex }
    }

    getPreferenceTabFourData() {
        try {
            this.preferenceService.getPreferenceTabFour().pipe(map((res: model_response) => <model_response>res),
                retry(2)
            ).subscribe(
                (res: model_response) => {
                    // let d=res.data
                    this.modelPreRes = res;
                    // JSON.parse(d.toString());
                    // this.modelPreRes =res.data;

                    //  console.warn(this.modelPreRes)

                    if (this.modelPreRes.status == "Success") {

                        let ModifyFourData = JSON.parse(this.modelPreRes.data.toString());
                        // console.warn(ModifyFourData);

                        this.preTab4Education = ModifyFourData.Table1;
                        this.preTab4EmployedIn = ModifyFourData.Table3;
                        this.preTab4Occupation = ModifyFourData.Table5;
                        this.preTab4AnnualIncome = ModifyFourData.Table7;
                        // alert(JSON.stringify(this.preTab4AnnualIncome));
                        this.preTabDesignation = ModifyFourData.Table8;

                        /*Start pre fill of preference step 4 */
                        this.preferenceStep4Data = ModifyFourData.Table || ModifyFourData.Table2 || ModifyFourData.Table4 || ModifyFourData.Table7;
                        if (this.preferenceStep4Data) {
                            //alert("aa gaya");
                            var result = ModifyFourData.Table2.map(a => a.education_id);
                            let education_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                            var result = ModifyFourData.Table4.map(a => a.employed_in_id);
                            let employed_in_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                            var result = ModifyFourData.Table6.map(a => a.occupation_id);
                            let occupation_idVal: Array<string> = JSON.parse(JSON.stringify(result));

                            var result = ModifyFourData.Table9.map(a => a.designation_id);
                            let designation_idVal: Array<string> = JSON.parse(JSON.stringify(result));


                            //  console.log(this.preferenceStep4Data)

                            this.peferenceTabFourForm.controls['education_id'].setValue(education_idVal);
                            this.peferenceTabFourForm.controls['employed_in_id'].setValue(employed_in_idVal);
                            this.peferenceTabFourForm.controls['occupation_id'].setValue(occupation_idVal);

                            // this.peferenceTabFourForm.controls['annual_income_id'].setValue(this.preTab4AnnualIncome[0].annual_income_id)
                            this.peferenceTabFourForm.controls['annual_income_id'].setValue(ModifyFourData.Table[0].annual_income_id);
                            this.peferenceTabFourForm.controls['designation_id'].setValue(designation_idVal);
                        } else this.toastr.error("Plesse fill the details", 'Error!');

                        /*End pre fill of preference step 4 */

                    } else this.toastr.error(res.message, 'Error!');
                }, (error) => { window.alert("Internal server error occured during processing your request. Please try again!"); })
        } catch (er: any) { throw er }
    }

    putPrreferenceTabOneData() {
        try {
            this.preTabOneData = this.peferenceTabOneForm.value;
            // console.warn(this.preTabOneData);
            this.preferenceService.putPreferenceTabOne(this.preTabOneData).pipe(map((res: model_response) => <model_response>res),
                retry(2)).subscribe((res: model_response) => {
                    console.warn(res);
                    if (res.status == 'Success') {
                        this.toastr.success(res.message, 'Success');
                        // this.toastr.success("Successfully Updated", 'Success!');
                        // alert("Sucessfully insert record..✔")
                        this.modernVerticalWizardStepper.next();
                        this.peferenceTabOneForm.reset();
                        this.getPreferenceTabOneData();
                    } else this.toastr.error(res.message, 'Error');
                })//, (error: HttpErrorResponse) => {
            //window.alert(error.message);});
        } catch (er: any) { throw er }
    }

    putPrreferenceTabTwoData() {
        try {
            this.preTabTwoData = this.peferenceTabTwoForm.value;
            // console.warn(this.preTabTwoData);
            this.preferenceService.putPreferenceTabTwo(this.preTabTwoData).pipe(map((res: model_response) => <model_response>res),
                retry(2)).subscribe((res: model_response) => {
                    if (res.status == 'Success') {
                        this.toastr.success(res.message, 'Success!');
                        this.peferenceTabTwoForm.reset();
                        this.modernVerticalWizardStepper.next();
                        this.getPreferenceTabTwoData();
                    } else this.toastr.error(res.message, 'Error!');
                })//, (error: HttpErrorResponse) => {   window.alert(error.message); });
        } catch (er: any) { throw er }
    }

    putPrreferenceTabThreeData() {
        try {
            this.preTabThreeData = this.peferenceTabThreeForm.value;

            // console.warn(this.preTabThreeData);

            this.preferenceService.putPreferenceThree(this.preTabThreeData).pipe(map((res: model_response) => <model_response>res),
                retry(2)
            ).subscribe((res: model_response) => {
                // console.warn(res);
                // retry(2),
                // catchError(this.errorHandle)

                if (res.status == 'Success') {
                    this.toastr.success(res.message, 'Success');
                    // alert("Sucessfully insert record..✔")
                    this.peferenceTabThreeForm.reset();
                    this.modernVerticalWizardStepper.next();
                    this.getPreferenceTabThreeData();
                } else this.toastr.error(res.message, 'Error');

            })//, (error: HttpErrorResponse) => {console.warn(error.message);  } );
        } catch (er: any) { throw er }
    }

    putPrreferenceTabFourData() {
        try {
            this.preTabFourData = this.peferenceTabFourForm.value;
            // console.warn(this.preTabFourData);
            this.preferenceService.putPreferenceTabFour(this.preTabFourData).pipe(map((res: model_response) => <model_response>res),
                retry(2)).subscribe((res: model_response) => {
                if (res.status == 'Success') {
                    this.toastr.success(res.message, 'Success');
                    this.peferenceTabThreeForm.reset();
                    this.getPreferenceTabFourData();
                } else this.toastr.error(res.message, 'Error');
            })//,(error: HttpErrorResponse) => { window.alert(error.message);});
        } catch (er: any) { throw er }
    }

    postTab1data(data: model_PreferenceStep1) {
    }

    tabOneSubmit() {
        console.warn(this.peferenceTabOneForm.value)
    }

    errorHandle(error: HttpErrorResponse) {
        console.warn("select")
        window.alert("Internal server error occured during processing your request. Please try again.")
        return throwError('Server error step1')
    }
}
