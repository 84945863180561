import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreConfigService } from '../../@core/services/config.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { RegistService } from '../services/regist.service';
import { model_delete_account, TblLogin } from 'app/model/tbl-login';
import { TranslateService } from '@ngx-translate/core';
import { MembershipService } from 'app/services/membership.service';
import { model_privacy_setting } from 'app/model/tbl-membership';
import { model_response } from 'app/model/tbl-profile';

@Component({
    selector: 'app-privacy-setting',
    templateUrl: './privacy-setting.component.html',
    styleUrls: ['./privacy-setting.component.scss'],
    // encapsulation: ViewEncapsulation.None
})
export class PrivacySettingComponent implements OnInit {
    submitted = false;
    checked = true;
    message: any
    deleteAccountData: model_delete_account;
    pivacySettingForm: FormGroup;
    privacySettingData: model_privacy_setting;
    public sizeLGCurrentRate = 5;
    public coreConfig: any;
    public passwordTextType: boolean;
    private _unsubscribeAll: Subject<any>;

    /**
     * @param {CoreConfigService} _coreConfigService
     */

    constructor(private _coreConfigService: CoreConfigService, private router: Router, private registService: RegistService,
        public translate: TranslateService, private formBuilder: FormBuilder, private membershipService: MembershipService) {
        this._unsubscribeAll = new Subject();
        this.pivacySettingForm = new FormGroup({
            privacy_profile: new FormControl('', [Validators.required]),
            privacy_contact: new FormControl('', [Validators.required]),
        })
    }

    get controls() {
        return this.pivacySettingForm.controls;
    }

    togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }

    ngOnInit(): void {
        this.pivacySettingGetData();
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }


    pivacySettingGetData() {
        this.submitted = true;
        this.membershipService.privacy_get().subscribe((res: model_response) => {
            this.privacySettingData = res.data[0];
            console.warn(this.privacySettingData)
            this.pivacySettingForm.get('privacy_profile').setValue(this.privacySettingData.privacy_profile);
            this.pivacySettingForm.get('privacy_contact').setValue(this.privacySettingData.privacy_contact);
        },
            error => alert(error));
    }
    pivacySettingFormData() {
        this.submitted = true;
        this.privacySettingData = this.pivacySettingForm.value
        // console.warn(this.privacySettingData);
        if (this.pivacySettingForm.valid) {
            this.membershipService.privacy_post_data(this.privacySettingData).subscribe((res:model_response) => {
                this.message = res.message
                // console.warn(this.message)
            },
                error => alert(error));
        }
    }

    getOtpIfSuccess(data) {
        this.membershipService.privacy_get().subscribe(res => {

            // this.router.navigate(['/otp-form']);
        },
            error => console.warn('error', error));
    };

    isInputNumber(event): boolean {
        const ch = (event.which) ? event.which : event.keyCode;
        if (ch > 31 && (ch < 48 || ch > 57)) {
            console.warn("char code restricted is :" + ch);
            return false;
        }
        return true;
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}


