export class UserProfile {
    id:number| undefined;
    name: string| undefined;
    mobile: string| undefined;
    email: string| undefined;
    subject: string| undefined;
    feedback: string| undefined;
    create_date:string="2022-08-07T15:01:22.424Z";
    create_by: number|undefined;
    status: number=0;
}
export class metadata {
    title: string| undefined;
    keyword: string| undefined;
    description: string| undefined;
}

export class blogs_comment {
id:number|undefined;
blog_id: number|undefined;
name: string|undefined;
email: string|undefined;
mobile: string|undefined;
message: string|undefined;
created_date = "2022-08-30T05:48:32.772Z";
approved: number|undefined;
deleted: number|undefined;
// status: number=0;
}
export class file_image {
    video_link: string|undefined;
    file_image: string|undefined;
    //  "[{\"file_image\":\"b1.jpg\"},{\"file_image\":\"b2.jpg\"},{\"file_image\":\"b3.jpg\"},{\"file_image\":\"b4.jpg\"}]"
}