import { Component, OnInit } from '@angular/core';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-payment-gatway',
  templateUrl: './payment-gatway.component.html',
  styleUrls: ['./payment-gatway.component.scss']
})
export class PaymentGatwayComponent implements OnInit {

  plan_id: number;
  payment_id: number;
  private _unsubscribeAll: Subject<any>;

  constructor(private router: Router, private Activatedroute: ActivatedRoute) { 
    this._unsubscribeAll = new Subject();

  }

  ngOnInit(): void {
    this.Activatedroute.queryParamMap
      .subscribe((params) => {
        this.plan_id = +params.get('plan_id') || 0;
        this.payment_id = +params.get('payment_id') || 0;
        alert("plan_id = " + this.plan_id + " " + "payment_id = " + this.payment_id)
      }
      );
  }

  PGREsponse() {
    this.router.navigate(['/payment-gateway-response'], { queryParams: { plan_id: this.plan_id, payment_id: this.payment_id }})
  }



  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
