import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from 'app/auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { User } from 'app/auth/models';
import { base } from 'base/base';

import { coreConfig } from 'app/app-config';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { model_login_response, tbl_change_language } from 'app/model/tbl-login';
import { RegistService } from 'app/services/regist.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  public base: any;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: model_login_response;
  public currentUserT: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;

  page_type$: Observable<string>
  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService, private regist: RegistService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService, private toastr:ToastrService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService, private activatedRoute: ActivatedRoute,
  ) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUserT = x));

    this.languageOptions = {
      en: { title: 'English', flag: 'us'},
      hi: {  title: 'हिन्दी', flag: 'in'},
      cg: {  title: 'छत्तीसगढ़ी', flag: 'in'},
      // fr: {  title: 'French', flag: 'fr'},
      // de: {  title: 'German',flag: 'de' },
      // pt: { title: 'Portuguese', flag: 'pt'}
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });
    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');
    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  logout() {
    localStorage.removeItem("currentUser")
    localStorage.removeItem("ltn")
    localStorage.removeItem("membership")
    localStorage.removeItem("config")
    localStorage.removeItem("user")
    // this._authenticationService.logout();
    this._router.navigate(['/login']);
  }
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
    private myBehaviorSubject = new BehaviorSubject<string>('default value');
  
  param_value: any;
  ngOnInit(): void {

  //  console.log( this._translateService.getLangs())
    
    this.base = base;
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.currentUserT = JSON.parse(localStorage.getItem('currentUser'));
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;
      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });
 this.regist.localStorage_data.subscribe((data: any) => {
      if (data != null) {
        this.currentUser = data.data;
      }
    });
  

  // this.regist.lastSegment$.subscribe(segment => {
  //   var lastSegment = segment;
  //    this.param_value = segment;
  //   // alert(this.param_value )
  //   });   
  //   this.page_type$ = this.activatedRoute.queryParamMap.pipe(map((params: ParamMap) => params.get('pt')),);
  //   this.page_type$.subscribe(param => {
  //     this.param_value = param
  //     // if (this.param_value == null) {
  //     // const page_type$: Observable<string> = of(lastSegment);
  //     //   var url = window.location.href;
  //     // var segments = url.split('/');
  //     //   var lastSegment = segments[segments.length - 1];
  //     //   alert(lastSegment);
  //     //   alert(page_type$);
  //     // }
  //   });
    
   
  }

 
      // api calling 
  change_language_data : tbl_change_language = new tbl_change_language();
  user: model_login_response = new model_login_response();
  setLanguage(language): void {
    try {
      this._translateService.use(language);
      switch (language) {
        case 'en': this.change_language_data.default_language_id = '1'; break;
        case 'hi': this.change_language_data.default_language_id = '2'; break;
        default: this.change_language_data.default_language_id = '3'; break;
      };
      this.regist.post_default_language(this.change_language_data).subscribe((res: any) => {
        if (res.status == 'success') {
          this.user.token = res.token;
          this.user.expiration = res.expiration;
          this.user.user_id = res.user_id;
          localStorage.setItem("ltn", res.token)
          localStorage.setItem("user", JSON.stringify(res))
          localStorage.setItem("currentUser", JSON.stringify(res));
          this.selectedLanguage = language;
          this.currentUserT = JSON.parse(localStorage.getItem("currentUser") || '{}');
          this.currentUserT.language = language;
          localStorage.setItem("currentUser", JSON.stringify(this.currentUserT));
           localStorage.setItem("config", '{"app":{"appName":"Bihav Matrimony","appTitle":"Bihav Matrimony","appLogoImage":"assets/images/logo/logo.svg"},"layout":{"skin":"default","type":"vertical","menu":{"hidden":false,"collapsed":false},"navbar":{"hidden":false,"type":"floating-nav","background":"navbar-light","customBackgroundColor":true,"backgroundColor":""},"footer":{"hidden":false,"type":"footer-static","background":"footer-light","customBackgroundColor":false,"backgroundColor":""},"enableLocalStorage":true,"customizer":false,"scrollTop":true,"buyNow":false}}')
        } else this.toastr.error(res.message, 'Error!');
      })//,(err:any)=>{JSON.stringify(err)})
    } catch (err: any) { throw err; alert(err.message) }


  }
  
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
