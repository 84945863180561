export class ProfileFilterModel {
    search_value: string = '';
    ftr_age_from: number = null;
    ftr_age_to: number = null;
    ftr_education: number = null;
    shortByAge: string = '';
    filter_apply: number = 0; //0 for not 1 for yes
    page_name: string | null;
}


export class md_filter {
    city_id: number = null;
    employed_in_id: number = null;
    marital_status_id: number = null;
    eating_habit_id: number = null;
    filter_apply: number = null;
    srt_value: string = null;
    
}