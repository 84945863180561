import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { RegistService } from '../services/regist.service';
import { catchError, finalize, retry } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  /**
     @param { RegistService } registService
 */
  resLimit: number = 5;
  constructor(private registService: RegistService, private spinner: NgxSpinnerService) { }
  /**
 * Add auth header with jwt if user is logged in and request is to api url
 * @param request
 * @param next
 */

      private totalRequests = 0;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.registService.getToken();
    //token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEwMDAzNSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJLaWxlc2ggOiB1c2VyX2lkICYgcGFzc3dvcmQgLSA5OTk5OTk5OTk5IiwianRpIjoiNDAyZTgwNzQtMWFhOS00ZTE4LWJiZDAtOTc0NjE4MDllZTM1IiwiZ2VuZGVyX2lkIjoiMSIsImNhc3RlX2lkIjoiMTYwMSIsImRlZmF1bHRfbGFuZ3VhZ2VfaWQiOiIxIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiVHJpYWwgTWVtYmVyIiwiZXhwIjoxNjg1MTgyODcwLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjU5OTIxIiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo0MjAwIn0.wvEdp7CcFV9iRTAtRxM7_7BTJ0nub6V9nw0l3iKJ28g";
    // return next.handle(request.clone({ setHeaders: { Authorization: 'Bearer ' + token, limit: `${this.resLimit}` } }));

   

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    if (this.totalRequests == 0) { this.spinner.show(); this.totalRequests = 1; }
    return next.handle(request).pipe(finalize(() => {
      if (this.totalRequests == 1) { this.spinner.hide(); this.totalRequests = 0; }
    })
    );
    
    // return next.handle(request).pipe(
    //   retry(2),
    //   catchError(this.errorHandle)
    // )
  

    // errorHandle(error: HttpErrorResponse){
    //   window.alert("Internal server error occured during processing your request. Please try again.")
    //   return throwError('Server error')

    // }
  }
}