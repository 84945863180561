import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreConfigService } from '../../@core/services/config.service';
import { Router } from '@angular/router';
import { base } from 'base/base';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { RegistService } from '../services/regist.service';
import { model_gender, model_response } from 'app/model/tbl-profile';
import { TblLogin } from 'app/model/tbl-login';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class RegistrationComponent implements OnInit {
    public base: any;
    pattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    pass = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}';
    registrationForm: FormGroup;
    casteList: any;

    get name() {
        return this.registrationForm.get("name");
    }
    get gender_id() {
        return this.registrationForm.get("gender_id");
    }
    get mobile() {
        return this.registrationForm.get("mobile");
    }
    get email() {
        return this.registrationForm.get("email");
    }
    get password() {
        return this.registrationForm.get("password");
    }

    public coreConfig: any;
    public passwordTextType: boolean;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     * @param {CoreConfigService} _coreConfigService
     */
    constructor(private _coreConfigService: CoreConfigService, private router: Router, private registService: RegistService,
        public formBuilder: FormBuilder, public translate: TranslateService, private toastr: ToastrService) {
        this.base = base;
        if (localStorage.getItem("ltn")) {
            this.router.navigate(['/profiles']);
        }
        // this.registrationForm = this.formBuilder.group({
        //     name: ['', [Validators.required, Validators.maxLength(100)]],
        //     gender_id: [null, [Validators.required]],
        //     mobile: ['', [Validators.required, Validators.pattern('[0-9]{10}'), Validators.minLength(10), Validators.maxLength(10)]],
        //     email: ['', [Validators.email, Validators.pattern(this.pattern), Validators.maxLength(100)]],
        //     password: ['', [Validators.required, Validators.pattern(this.pass), Validators.minLength(6), Validators.maxLength(100)]]
        // })
        this.registrationForm = new FormGroup({
            name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            gender_id: new FormControl([Validators.required]),
            caste_id: new FormControl([Validators.required]),
            mobile: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}'), Validators.minLength(10), Validators.maxLength(10)]),
            // email: new FormControl('', [Validators.email, Validators.pattern(this.pattern), Validators.maxLength(100)]),
            email: new FormControl(null , [Validators.pattern(this.base.patternemail), Validators.maxLength(100)]),
            password: new FormControl('', [Validators.required, Validators.pattern(this.pass), Validators.minLength(6), Validators.maxLength(100)])
        })
        this._unsubscribeAll = new Subject();
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }

    ngOnInit(): void {
        this.getGender();
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }

    message: string;
    registrationFormData() {
        try {
            // this.Formdata = new TblLogin();
            var Formdata = this.registrationForm.value;
            
            if (this.registrationForm.valid) {// && this.registrationForm.value.gender_id>0){
                this.registService.postRegist(Formdata).subscribe((res: model_response) => {
                    if (res.status == 'Success') {
                        // console.log(JSON.stringify(res))
                        localStorage.setItem("registerationData", JSON.stringify(Formdata))
                        this.getOtpIfSuccess(Formdata);
                        // this.toastr.success(res.message, 'Success!');
                    } else this.toastr.error(res.message, 'Error!');
                });//, error => console.warn('error', error));
            } else this.toastr.error('please enter the valid data', 'Error!');
        } catch (err: any) { throw err; alert(err.message) }
    }
    genderList: model_gender[]
    getGender() {
        try {
            this.registService.gender_get().subscribe((res: model_response) => {
                if (res.status == 'success') {
                    this.genderList = res.data.genders;
                    this.casteList = res.data.caste;
                } else this.toastr.error(res.message, 'Error!');
            })//, error => console.warn('error', error));
        } catch (err: any) { throw err; alert(err.message) }
    }

    getOtpIfSuccess(data) {
        this.registService.getOtp(data).subscribe(res => {
            this.router.navigate(['/otp-form']);
        }, error => console.warn('error', error));
    };

    isInputNumber(event): boolean {
        const ch = (event.which) ? event.which : event.keyCode;
        if (ch > 31 && (ch < 48 || ch > 57)) {
            console.warn("char code restricted is :" + ch);
            return false;
        }
        return true;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}


