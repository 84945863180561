<div class="auth-wrapper auth-v1 px-2">
          <div class="auth-inner py-2">
                    <div class="card mb-0">
                              <div class="card-body">
                                        <a href="javascript:void(0);" class="brand-logo">
                                                  <img src="{{base.admin_app_url}}/uploads/apps/logo-login.png"
                                                            alt="bihav matrimony" height="40" />
                                        </a>
                                        <h4 class="card-title mb-1">{{'MENU.RESET_PASSWORD.TITLE' | translate}}🔒</h4>
                                        <p class="card-text mb-2">{{'MENU.RESET_PASSWORD.SUB_TITLE' | translate}}</p>

                                        <form class="auth-forgot-password-form mt-2" [formGroup]="form_group"
                                                  (ngSubmit)="onSubmit()">
                                                  <div class="form-group">
                                                            <label for="forgot-password-email"
                                                                      class="form-label">{{'MENU.RESET_PASSWORD.NEW_PASSWORD'
                                                                      | translate}}</label>
                                                            <div
                                                                      class="input-group input-group-merge form-password-toggle">
                                                                      <input [type]="passwordTextType ? 'text' : 'password'"
                                                                                formControlName="password"
                                                                                class="form-control form-control-merge"
                                                                                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
                                                                                placeholder="{{'MENU.RESET_PASSWORD.NEW_PASSWORD_PLACEHOLDER' | translate}}"
                                                                                aria-describedby="login-password"
                                                                                tabindex="2" />
                                                                      <div class="input-group-append">
                                                                                <span
                                                                                          class="input-group-text cursor-pointer">
                                                                                          <i class="feather font-small-4"
                                                                                                    [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                                                                                    (click)="togglePasswordTextType()"></i>
                                                                                </span>
                                                                      </div>
                                                            </div>
                                                            <span class="text-danger"
                                                                      *ngIf="(f.password.touched || submitted) && f.password.errors?.required">
                                                                      {{'MENU.RESET_PASSWORD.PASSWORD_REQIURED' |
                                                                      translate}}
                                                            </span>
                                                            <span class="text-danger"
                                                                      *ngIf="f.password.touched && f.password.errors?.pattern">{{'MENU.RESET_PASSWORD.PASSWORD_PATERRN'
                                                                      | translate}}

                                                            </span>
                                                  </div>
                                                  <div class="form-group">
                                                            <label for="forgot-password-email"
                                                                      class="form-label">{{'MENU.RESET_PASSWORD.CONFIRM_PASSWORD'
                                                                      | translate}}</label>
                                                            <div
                                                                      class="input-group input-group-merge form-password-toggle">
                                                                      <input [type]="passwordTextTypee ? 'text' : 'password'"
                                                                                formControlName="confirm_password"
                                                                                class="form-control form-control-merge"
                                                                                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
                                                                                placeholder="{{'MENU.RESET_PASSWORD.CONFIRM_PLACEHOLDER' | translate}}"
                                                                                aria-describedby="login-password"
                                                                                tabindex="2" />
                                                                      <div class="input-group-append">
                                                                                <span
                                                                                          class="input-group-text cursor-pointer">
                                                                                          <i class="feather font-small-4"
                                                                                                    [ngClass]="{'icon-eye-off': passwordTextTypee, 'icon-eye': !passwordTextTypee}"
                                                                                                    (click)="togglePasswordTextType$()"></i>
                                                                                </span>
                                                                      </div>
                                                            </div>
                                                            <span class="text-danger"
                                                                      *ngIf="f.confirm_password.touched && f.confirm_password.errors?.required">
                                                                      {{'MENU.RESET_PASSWORD.CONFIRM_REQIURED' |
                                                                      translate}}
                                                            </span>
                                                            <div class="text-danger"
                                                                      *ngIf="f.confirm_password.errors?.mustMatch">
                                                                      {{'MENU.RESET_PASSWORD.CONFIRM_MUSTMATCH' |
                                                                      translate}}
                                                            </div>
                                                  </div>






                                                  <button class="btn btn-primary btn-block" tabindex="2" rippleEffect>
                                                            {{'MENU.RESET_PASSWORD.SUBMIT' | translate}} </button>
                                        </form>


                                        <p class="text-center mt-2">
                                                  <a routerLink="/"> <i data-feather="chevron-left"></i>
                                                            {{'MENU.RESET_PASSWORD.BACK_TO_LOGIN' |
                                                            translate }}</a>
                                        </p>
                              </div>
                    </div>
          </div>
</div>