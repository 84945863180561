<div class="content-wrapper">
  <div class="content-body">
    <section id="pricing-plan">
      <div class="text-center">
        <h1 class="mt-5">{{'MENU.MEMBERSHIP.TITLE' | translate}}</h1>
        <p class="mb-2 pb-75">{{'MENU.MEMBERSHIP.SUB_TITLE' | translate}}</p>
      </div>
      <div class="row pricing-card" *ngIf="activePlan">
        <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-12 order-1 ">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-1">{{'MENU.MEMBERSHIP.ACTIVE_PLAN_TITLE' | translate}}</h4>
                  <div class="profile-polls-info">
                    <div class="d-flex justify-content-between">
                      <label class="custom-control-label-viewers" for="bestActorPollRDJ">{{activePlan?.plan_title |
                        titlecase}}</label>
                      <label class="text-right f-f-arial">&#8377;{{activePlan?.plan_price_offer}}
                        <span>/{{activePlan?.plan_validity_value_in_days}}days</span></label>
                      <button rippleeffect="" type="submit" class="btn btn-primary waves-effect"
                        [ngStyle]="{'cursor': activePlan?.plan_validity_status == 'Active'? 'not-allowed' : '' }"
                        [disabled]="activePlan?.plan_validity_status == 'Active'"
                        (click)="subscriptionSend(PlanListData[0])">{{'MENU.MEMBERSHIP.ACTIVE' | translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <div class="row pricing-card"  *ngIf="isloded == true " >
        <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
          <div class="row" >
            <div class="col-12 col-md-4" >
              <div class="card basic-pricing text-center">
                <div class="card-body">
                  <img src="assets/images/illustration/Pot1.svg" class="mb-2 mt-5" alt="svg img" />
                  <h3>{{PlanListData[0].plan_title}}</h3>
                  <p class="card-text">A simple start for everyone</p>
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 font-weight-bold text-primary f-f-arial">&#8377; </sup>
                      <span
                        class="pricing-basic-value font-weight-bolder text-primary">{{PlanListData[0].plan_price_offer}}</span>
                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month </sub>
                    </div>
                    <small class="annual-pricing d-none text-muted"></small>
                  </div>
                  <ul class="list-group list-group-circle text-left">
                    <li class="list-group-item" *ngFor="let features of convertModelFeatures(PlanListData[0].features)">
                      {{features.features_en}}
                    </li>
                  </ul>
                  <button class="btn btn-block btn-outline-success  mt-2"
                    *ngIf="PlanListData[0].plan_id == activePlan?.plan_id;else upgradePlan1"
                    [ngClass]="PlanListData[0].plan_id == activePlan?.plan_id ? 'btn btn-block btn-outline-success':'btn btn-block btn-primary'"
                    [ngStyle]="{'cursor': PlanListData[0].plan_id == activePlan?.plan_id?'not-allowed' : 'pointer' }"
                    [disabled]="PlanListData[0].plan_id == activePlan?.plan_id"
                    (click)="subscriptionSend(PlanListData[0])" rippleEffect>{{'MENU.MEMBERSHIP.YOUR_CURRENT_PLAN' |
                    translate}}</button>
                  <ng-template #upgradePlan1>
                    <button class="btn btn-block btn-primary  mt-2"
                      [ngClass]="PlanListData[0].plan_id == activePlan?.plan_id ? 'btn btn-block btn-outline-success':'btn btn-block btn-primary'"
                      [ngStyle]="{'cursor': PlanListData[0].plan_id == activePlan?.plan_id?'not-allowed' : 'pointer' }"
                      [disabled]="PlanListData[0].plan_id == activePlan?.plan_id"
                      (click)="subscriptionSend(PlanListData[0])" rippleEffect>{{'MENU.MEMBERSHIP.UPGRADE' |
                      translate}}</button>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="card standard-pricing popular text-center">
                <div class="card-body">
                  <div class="pricing-badge text-right">
                    <div class="badge badge-pill badge-light-primary">Popular</div>
                  </div>
                  <img src="assets/images/illustration/Pot2.svg" class="mb-1" alt="svg img" />
                  <h3>{{PlanListData[1].plan_title}}</h3>
                  <p class="card-text">For small to medium businesses</p>
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 font-weight-bold text-primary f-f-arial">&#8377; </sup>
                      <span
                        class="pricing-standard-value font-weight-bolder text-primary">{{PlanListData[1].plan_price_offer}}</span>
                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                    </div>
                  </div>
                  <ul class="list-group list-group-circle text-left">
                    <li class="list-group-item" *ngFor="let features of convertModelFeatures(PlanListData[1].features)">
                      {{features.features_en}}
                    </li>
                  </ul>
                  <button class="btn btn-block btn-outline-success  mt-2"
                    *ngIf="PlanListData[1].plan_id == activePlan?.plan_id;else upgradePlan2"
                    [ngClass]="PlanListData[1].plan_id == activePlan?.plan_id ? 'btn btn-block btn-outline-success':'btn btn-block btn-primary'"
                    [ngStyle]="{'cursor': PlanListData[1].plan_id == activePlan?.plan_id?'not-allowed' : 'pointer' }"
                    [disabled]="PlanListData[1].plan_id == activePlan?.plan_id"
                    (click)="subscriptionSend(PlanListData[1])" rippleEffect>{{'MENU.MEMBERSHIP.YOUR_CURRENT_PLAN' |
                    translate}}</button>
                  <ng-template #upgradePlan2>
                    <button class="btn btn-block btn-primary  mt-2"
                      [ngClass]="PlanListData[1].plan_id == activePlan?.plan_id ? 'btn btn-block btn-outline-success':'btn btn-block btn-primary'"
                      [ngStyle]="{'cursor': PlanListData[1].plan_id == activePlan?.plan_id?'not-allowed' : 'pointer' }"
                      [disabled]="PlanListData[1].plan_id == activePlan?.plan_id"
                      (click)="subscriptionSend(PlanListData[1])" rippleEffect>{{'MENU.MEMBERSHIP.UPGRADE' |
                      translate}}</button>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="card enterprise-pricing text-center">
                <div class="card-body">
                  <img src=" assets/images/illustration/Pot3.svg" class="mb-2" alt="svg img" />
                  <h3>{{PlanListData[2].plan_title}}</h3>
                  <p class="card-text">Solution for big organizations</p>
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 font-weight-bold text-primary f-f-arial">&#8377; </sup>
                      <span
                        class="pricing-enterprise-value font-weight-bolder text-primary">{{PlanListData[2].plan_price_offer}}</span>
                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                    </div>
                  </div>
                  <ul class="list-group list-group-circle text-left">
                    <li class="list-group-item" *ngFor="let features of convertModelFeatures(PlanListData[2].features)">
                      {{features.features_en}}
                    </li>
                  </ul>
                  <button class="btn btn-block btn-outline-success mt-2"
                    *ngIf="PlanListData[2].plan_id == activePlan?.plan_id;else upgradePlan3"
                    [ngClass]="PlanListData[2].plan_id == activePlan?.plan_id ? 'btn btn-block btn-outline-success':'btn btn-block btn-primary'"
                    [ngStyle]="{'cursor': PlanListData[2].plan_id == activePlan?.plan_id?'not-allowed' : 'pointer' }"
                    [disabled]="PlanListData[2].plan_id == activePlan?.plan_id"
                    (click)="subscriptionSend(PlanListData[2])" rippleEffect>{{'MENU.MEMBERSHIP.YOUR_CURRENT_PLAN' |
                    translate}}</button>
                  <ng-template #upgradePlan3>
                    <button class="btn btn-block btn-primary mt-2"
                      [ngClass]="PlanListData[2].plan_id == activePlan?.plan_id ? 'btn btn-block btn-outline-success':'btn btn-block btn-primary'"
                      [ngStyle]="{'cursor': PlanListData[2].plan_id == activePlan?.plan_id?'not-allowed' : 'pointer' }"
                      [disabled]="PlanListData[2].plan_id == activePlan?.plan_id"
                      (click)="subscriptionSend(PlanListData[2])" rippleEffect>{{'MENU.MEMBERSHIP.UPGRADE' |
                      translate}}</button>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>