import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { ProfileUpdateComponent } from './profile-update.component';
// import { ProfileEditComponent} from 'app/profile-edit/profile-edit.component';

const routes: Routes = [
  // {
  //   path:'editprofile',
  //   component:ProfileUpdateComponent
  // },
  // {
  //   path:'profileedit',
  //   component:ProfileEditComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileUpdateRoutingModule { }
