<ng-container *ngIf="!item.hidden">
  <a class="d-flex align-items-center" [ngClass]="item.classes" *ngIf="!item.url" (click)="toggleOpen($event)">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>
  <ng-template #itemContent>
    <span [data-feather]="item.icon" *ngIf="item.icon"></span>
    <span class="menu-title text-truncate" [translate]="item.translate"></span>
    <!-- <span class="float-right badge badge-pill badge-primary">{{item.counts}}</span> -->
  </ng-template>
  <ul class="menu-content">
    <ng-container *ngFor="let item of item.children">
      <li core-menu-vertical-item [item]="item" [profileCount]="profileCount"
        [ngClass]="{ disabled: item.disabled === true }" [routerLink]="item.openInNewTab ? [] : [item.url]"
        [queryParams]="{pt: item.qs}" [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
        [routerLinkActiveOptions]="{ exact: false }">
      </li>
      <!-- <li core-menu-vertical-collapsible *ngIf="item.type == 'collapsible'" [item]="item" class="nav-item has-sub"></li> -->
    </ng-container>
  </ul>
</ng-container>