<form [formGroup]="paymentForm" (ngSubmit)="payFromCardSubmit()">
    <h2 class="card-title mb-1">{{'MENU.MEMBERSHIP.TITLE' | translate}}</h2>
    <section id="profile-info">
        <div class="row">
            <div class="col-lg-9 col-12 order-2 order-lg-1">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">{{'MENU.SUBSCRIPTION.PLAN_METHOD_TITLE' | translate}}</h4>
                        <div class="collapse-default collapse-payment-method">
                            <div class="card">
                                <div (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1"
                                    aria-controls="collapseExample" class="card-header" data-toggle="collapse">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" formControlName="payment_method" value="Card"
                                            class="form-check-input">
                                        <span for="inlineRadio1" class="collapse-title">{{'MENU.SUBSCRIPTION.CREDIT_DEBIT_CARD' | translate}}</span>
                                    </div>
                                </div>
                                <div [ngbCollapse]="isCollapsed1">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="cardNo">{{'MENU.SUBSCRIPTION.CARD_NUMBER' | translate}}</label>
                                                        <input type="text" placeholder="{{'MENU.SUBSCRIPTION.CARD_NUMBER_PLACEHOLDER' | translate}}"
                                                            mask="0000 0000 0000 0000" class="form-control"
                                                            formControlName="card_number" (keypress)="base.isMobileNumber($event)" 
                                                            ng-reflect-mask-expression="0000 0000 0000 0000">
                                                        <div *ngIf="(submitted && f.card_number.errors) || (f.card_number.touched && f.card_number.invalid)" class="text-danger">
                                                            <div class="text-danger" *ngIf="f.card_number.errors.required">{{'MENU.SUBSCRIPTION.CARD_NUMBER_REQUIRED' | translate}}</div>
                                                            <div class="text-danger" *ngIf="f.card_number.errors.pattern">{{'MENU.SUBSCRIPTION.VALID_CARD_NUMBER' | translate}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label class="form-label" for="expiry_month">{{'MENU.SUBSCRIPTION.VALID_THROUGH_MM' | translate}}</label>
                                                    <input required type="text" formControlName="expiry_month"
                                                        class="form-control" placeholder="{{'MENU.SUBSCRIPTION.VALID_THROUGH_PLACEHOLDER_MM' | translate}}"  (keypress)="base.isMobileNumber($event)" />
                                                        <div *ngIf="(submitted && f.expiry_month.errors) || (f.expiry_month.touched && f.expiry_month.invalid)" class="text-danger">
                                                            <div class="text-danger" *ngIf="f.expiry_month.errors.required">{{'MENU.SUBSCRIPTION.MONTH_REQUIRED' | translate}}</div>
                                                            <div class="text-danger" *ngIf="f.expiry_month.errors.pattern">{{'MENU.SUBSCRIPTION.VALID_MONTH_REQUIRED' | translate}}</div>
                                                        </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label class="form-label" for="expiry_year">{{'MENU.SUBSCRIPTION.VALID_THROUGH_YY' | translate}}</label>
                                                    <input required type="text" formControlName="expiry_year"
                                                        class="form-control" placeholder="{{'MENU.SUBSCRIPTION.VALID_THROUGH_PLACEHOLDER_YY' | translate}}" (keypress)="base.isMobileNumber($event)" />
                                                    <div *ngIf="(submitted && f.expiry_year.errors) || (f.expiry_year.touched && f.expiry_year.invalid)" class="text-danger">
                                                        <div class="text-danger" *ngIf="f.expiry_year.errors.required">{{'MENU.SUBSCRIPTION.YEAR_REQUIRED' | translate}}</div>
                                                        <div class="text-danger" *ngIf="f.expiry_year.errors.pattern">{{'MENU.SUBSCRIPTION.VALID_YEAR_REQUIRED' | translate}}</div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="cardHolderName">{{'MENU.SUBSCRIPTION.CARD_HOLDER_NAME' | translate}}</label>
                                                        <input type="text" class="form-control" formControlName="name"
                                                            placeholder="{{'MENU.SUBSCRIPTION.CARD_HOLDER_NAME' | translate}}" />
                                                        <div *ngIf="(submitted && f.name.errors) || (f.name.touched && f.name.invalid)" class="text-danger">
                                                            <div class="text-danger" *ngIf="f.name.errors.required">{{'MENU.SUBSCRIPTION.CARD_HOLDER_NAME_REQUIRED' | translate}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div (click)="isCollapsed2 = !isCollapsed2" [attr.aria-expanded]="!isCollapsed2"
                                    aria-controls="" class="card-header" data-toggle="collapse">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" formControlName="payment_method"
                                            value="NetBanking" />
                                        <Span class="collapse-title" for="inlineRadio1">{{'MENU.SUBSCRIPTION.NET_BANKING' | translate}}</Span>
                                    </div>
                                </div>
                                <div [ngbCollapse]="isCollapsed2">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="bankName">{{'MENU.SUBSCRIPTION.BANK_NAME' | translate}}</label>
                                                        <select formControlName="bank_id"
                                                            class="form-control ng-pristine ng-valid ng-touched"
                                                            ng-reflect-name="accountSelect" ng-reflect-model="USA">
                                                            <option value="">{{'MENU.SUBSCRIPTION.BANK_PLACEHOLDER' | translate}}</option>
                                                            <option *ngFor="let bankList of getBankData"
                                                                [value]="bankList.bank_id">{{bankList.bank_name}}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="(submitted && f.bank_id.errors) || (f.bank_id.touched && f.bank_id.invalid)" class="text-danger">
                                                            <div class="text-danger" *ngIf="f.bank_id.errors.required">{{'MENU.SUBSCRIPTION.BANK_NAME_REQUIRED' | translate}}</div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="form-group">
                                                        <label class="form-label" for="bankName">Bank Name</label>
                                                        <ng-select [items]="getBankData" bindLabel="bank_name" bindValue="bank_id" name="bank_id"> </ng-select>
                                                      </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div (click)="isCollapsed3 = !isCollapsed3" [attr.aria-expanded]="!isCollapsed3"
                                    aria-controls="collapseExample2" class="card-header" data-toggle="collapse">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" formControlName="payment_method" value="UPI"
                                            class="form-check-input">
                                        <span for="inlineRadio3" class="collapse-title">{{'MENU.SUBSCRIPTION.UPI' | translate}}</span>
                                    </div>
                                </div>
                                <div [ngbCollapse]="isCollapsed3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="upiID">{{'MENU.SUBSCRIPTION.UPI_ID' | translate}}</label>
                                                        <input type="text" class="form-control" formControlName="upiID"
                                                            placeholder="{{'MENU.SUBSCRIPTION.UPI_ADDRESS' | translate}}" />
                                                            <div *ngIf="(submitted && f.upiID.errors) || (f.upiID.touched && f.upiID.invalid)" class="text-danger">
                                                                <div class="text-danger" *ngIf="f.upiID.errors.required">{{'MENU.SUBSCRIPTION.UPI_ADDRESS_REQUIRED' | translate}}</div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="submit" rippleeffect class="btn btn-primary mt-1 waves-effect w-100"
                                [disabled]="!paymentForm.valid">{{'MENU.SUBSCRIPTION.PAY' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 order-1">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-1">{{'MENU.SUBSCRIPTION.TITLE' | translate}}</h4>
                        <div class="profile-polls-info">
                            <div class="d-flex justify-content-between">
                                <label class="custom-control-label-viewers"
                                    for="bestActorPollRDJ">{{'MENU.SUBSCRIPTION.PLAN' | translate}}</label>
                                <label class="text-right f-f-arial">{{PlanListData.plan_title}}</label>
                            </div>
                        </div>
                        <div class="profile-polls-info">
                            <div class="d-flex justify-content-between">
                                <label class="custom-control-label-viewers"
                                    for="bestActorPollRDJ">{{'MENU.SUBSCRIPTION.PRICE' | translate}}</label>
                                <label class="text-right f-f-arial">&#8377;{{PlanListData.plan_price_offer}}</label>
                            </div>
                        </div>
                        <div class="profile-polls-info">
                            <div class="d-flex justify-content-between">
                                <label class="custom-control-label-viewers"
                                    for="bestActorPollRDJ">{{'MENU.SUBSCRIPTION.VALID' | translate}}</label>
                                <label class="text-right f-f-arial">{{PlanListData.plan_validity_en}}</label>
                            </div>
                        </div>
                        <button rippleeffect="" type="submit"
                            class="btn btn-block btn-outline-success mt-2 waves-effect"
                            [disabled]="!paymentForm.valid">{{'MENU.SUBSCRIPTION.PAY' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>

<!-- <div class="form-check">
    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
    <label class="form-check-label" for="flexRadioDefault1">
      Default radio
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
    <label class="form-check-label" for="flexRadioDefault2">
      Default checked radio
    </label>
  </div> -->

<!-- for radio button collapse tamplate -->

<!-- 
  <div class="content-body">
    <section id="pricing-plan">
      <div class="pricing-faq">
        <h3 class="text-center">FAQ's</h3>
        <p class="text-center">Let us help answer the most common questions.</p>
        <div class="row my-2">
          <div class="col-12 col-lg-10 col-lg-offset-2 mx-auto">
            <div class="accordion collapse-margin">
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" activeIds="deliveryId1">
                <ngb-panel
                  id="deliveryId{{ i }}"
                  [cardClass]="'collapse-margin'"
                  *ngFor="let qandARef of data.pricing.qandA; let i = index"
                >
                  <ng-template ngbPanelTitle>
                    <span>{{ qandARef.question }}</span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    {{ qandARef.ans }}
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div> -->