import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { base } from 'base/base';
import{ TblSendmessage } from 'app/model/tbl-sendmessage';

@Injectable({
  providedIn: 'root'
})
export class SendmessageService {

  constructor(private http:HttpClient) { }


  sendMessageData(profile_id,message){
    // debugger;
  //  return this.http.post<TblSendmessage>(`${base.apiUrl}/chat?profile_id=${data}`,result);
   return this.http.post<TblSendmessage>(`${base.apiUrl}/chat/send_message?profile_id=${profile_id}&message=${message}`,"");
  }
}
