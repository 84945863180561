import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tbl_pyment_update } from 'app/model/tbl-membership';
import { model_response } from 'app/model/tbl-profile';
import { TblSubscriptionPlan } from 'app/model/tbl-subscription-plan';
import { MembershipService } from 'app/services/membership.service';
import { Subject } from 'rxjs';
import { base } from 'base/base';


@Component({
  selector: 'app-payment-gateway-response',
  templateUrl: './payment-gateway-response.component.html',
  styleUrls: ['./payment-gateway-response.component.scss']
})
export class PaymentGatewayResponseComponent implements OnInit {
  base=base;
  paymentUpdate:tbl_pyment_update =new tbl_pyment_update();
  bgResponseData:TblSubscriptionPlan =new TblSubscriptionPlan();
  private _unsubscribeAll: Subject<any>;
  constructor(private membershipService:MembershipService, private router: Router, 
    private Activatedroute: ActivatedRoute) {
  this._unsubscribeAll = new Subject();

     }

  ngOnInit(): void {
    this.Activatedroute.queryParamMap.subscribe((paramss) => {
        this.paymentUpdate.plan_id = +paramss.get('plan_id') || 0;
        this.paymentUpdate.payment_id = +paramss.get('payment_id') || 0;
      });
    this.payment_update();

  }
  bgResponseStatus:string  ='faild'
  payment_update(){
    this.membershipService.payment_update(this.paymentUpdate).subscribe((res: model_response) => {
      // this.bgResponseStatus =res.status
      if(res.status == "success"){
        this.bgResponseData = res.data[0]
      }
    })
  }
  print() {
    
    // var printContents = document.getElementById("printArea").innerHTML;
			// var originalContents = document.body.innerHTML;
			// document.body.innerHTML = printContents;
      // document.getElementById("body_allin").style.display = "none";

      // document.getElementById("printArea").style.visibility="hidden";
			window.print();
      // document.getElementById("printArea").style.visibility="visible";
  }
  retrySubmission() {
    this.router.navigate(['/member-ship'])
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
