import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { base } from 'base/base';
import { Subject } from 'rxjs';
import { CoreConfigService } from '../../@core/services/config.service';
import { TblLogin, model_login_response } from 'app/model/tbl-login';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistService } from 'app/services/regist.service';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, map, retry, takeUntil } from 'rxjs/operators';
import { LoginService } from 'app/services/login.service';
import { AuthenticationService } from 'app/auth/service';
import { AuthenticationGuardGuard } from 'app/authenticationGuards/authentication-guard.guard';
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ForgotPasswordComponent implements OnInit {
    public coreConfig: any;
    public form_group: FormGroup;
    public submitted = false;
    public base: any;
    user: model_login_response = new model_login_response();
    private _unsubscribeAll: Subject<any>;
    defaultLanguage: any;
    loginData: TblLogin = new TblLogin();
    /**
     * @param {CoreConfigService} _coreConfigService
     */

    constructor(private _coreConfigService: CoreConfigService, private _formBuilder: UntypedFormBuilder, private toastr: ToastrService,
        private _route: ActivatedRoute, private router: Router, public registService: RegistService,
        public translate: TranslateService,
    ) {
        this.base = base
        if (localStorage.getItem("ltn")) {
            this.router.navigate(['/profiles']);
        }
        this._unsubscribeAll = new Subject();
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    get f() {
        return this.form_group.controls;
    }

    ngOnInit(): void {
        this.base = base;
        this.form_group = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(base.mobile_emailvalidator)]],
        })
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onSubmit() {
        try {
                this.submitted = true;
            this.loginData = this.form_group.value;
            if (this.form_group.valid) {
                this.loginData.otp_for = '3';
                this.registService.setQS(this.loginData)
                // this.router.navigate(['/otp-forgot-password']);
                // return
                this.registService.getOtp_forgetpassword(this.loginData).subscribe((res: any) => {
                    if (res.status == 'Success') {
                        this.toastr.success(res.message, 'Success!');
                        this.router.navigate(['/otp-forgot-password']);
                    } else this.toastr.error(res.message, 'Error!');
                });//, (error: any) => { alert(JSON.stringify(error)) });
            } else this.toastr.error('Please enter the valid data', 'Error!');
        } catch (err: any) {throw err;alert(err.message)}
    }



    getOtpIfSuccess(data) {
        try {
            this.registService.getOtp(data).subscribe(res => {
                this.router.navigate(['/otp-form']);
            }, (error: any) => { alert(JSON.stringify(error)) });
        } catch (err: any) {
            alert(err.message)
            //throw err;
        }
    }
}





    //     // Public
    //     public emailVar;
    //     public base:any;
    //     public coreConfig: any;
    //     submitted = false;
    //     // Private
    //     private _unsubscribeAll: Subject<any>;

    //     /**
    //      * Constructor
    //      *
    //      * @param {CoreConfigService} _coreConfigService
    //      */

    //     form_group:FormGroup

    //     constructor(private _coreConfigService: CoreConfigService,
    //         private toastr: ToastrService,private router: Router, private registService: RegistService,
    //         public formBuilder: FormBuilder, public translate: TranslateService,) {
    //         this._unsubscribeAll = new Subject();

    //         this.form_group = this.formBuilder.group({
    //             email: ['', [Validators.required, Validators.pattern(this.base.MobileValidator), Validators.minLength(10)]],
    //         })

    //         // Configure the layout

    //         this._coreConfigService.config = {
    //             layout: {
    //                 navbar: {
    //                     hidden: true
    //                 },
    //                 footer: {
    //                     hidden: true
    //                 },
    //                 menu: {
    //                     hidden: true
    //                 },
    //                 customizer: false,
    //                 enableLocalStorage: false
    //             }
    //         };
    //     }

    //     // Lifecycle Hooks
    //     // -----------------------------------------------------------------------------------------------------

    //     /**
    //      * On init
    //      */
    //     ngOnInit(): void {
    //         this.base = base;
    //         // Subscribe to config changes
    //         this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
    //             this.coreConfig = config;
    //         });
    //     }

    //     /**
    //      * On destroy
    //      */
    //     ngOnDestroy(): void {
    //         // Unsubscribe from all subscriptions
    //         this._unsubscribeAll.next();
    //         this._unsubscribeAll.complete();
    //     }
    //  get form_controls() {
    //     return this.form_group.controls;
    //   }
    //     // https://api.bihav.co.in/api/Authenticate/get_otp
    //     loginData: TblLogin = new TblLogin();
    //      on_submit() {
    //         try {
    //             // this.Formdata = new TblLogin();
    //             // var Formdata = this.registrationForm.value;
    //             // // console.warn('error'+this.registrationForm.value);return
    //             // // alert("form data is "+this.registrationForm.value.valid+" and GI is "+ this.registrationForm.value.gender_id )
    //             // if (this.registrationForm.valid) {// && this.registrationForm.value.gender_id>0){
    //             //     this.registService.postRegist(Formdata).subscribe((res: model_response) => {
    //             //         if (res.status == 'Success') {
    //             //             localStorage.setItem("registerationData", JSON.stringify(Formdata))
    //             //             this.getOtpIfSuccess(Formdata);
    //             //         } else this.toastr.error(res.message, 'Error!');
    //             //     });//, error => console.warn('error', error));
    //             // } else this.toastr.error('please enter the valid data');
    //             // , (error:any)=> {JSON.stringify(error)});
    //         } catch (err: any) { throw err; alert(err.message) }
    //     }

    // }
