import { Token } from '@angular/compiler';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from 'app/login/login.component';
import { TblLogin, model_login_response } from 'app/model/tbl-login';
import { model_response } from 'app/model/tbl-profile';
import { Subject } from 'rxjs';
import { base } from 'base/base';
import { takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '../../@core/services/config.service';
import { RegistService } from '../services/regist.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {ConfirmPasswordValidator } from '../auth/helpers/custom-validators';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
 public otp: number;
  registerationData;
  public base: any;
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public form_group: FormGroup;
  submitted = false;
  user_id: any;
  loginData: TblLogin = new TblLogin();
  email: any;
  otp_for: any;
  public passwordTextType: boolean;
  user: model_login_response = new model_login_response();
  otp_val: any;
  /**
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(private _coreConfigService: CoreConfigService, private router: Router, private registService: RegistService,
    private toastr: ToastrService, private _formBuilder: UntypedFormBuilder,
    public translate: TranslateService) {
    this.base = base;
    this.base = base
    if (localStorage.getItem("ltn")) {
      this.router.navigate(['/profiles']);
    }
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }
  passwordTextTypee: any;
   togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }
  togglePasswordTextType$() {
    this.passwordTextTypee = !this.passwordTextTypee;
    }

  ngOnInit(): void {
    this.registerationData = JSON.parse(localStorage.getItem('registerationData') || '{}');
    var otp_data = this.registService.getQS();
    this.otp_for = otp_data.otp_for;
    this.email = otp_data.email;
    this.otp_val = otp_data.otp;


    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });


    this.form_group = this._formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(this.base.PasswordValidator)]],
     confirm_password: ['', [Validators.required]],
    },
      {
        validator: ConfirmPasswordValidator('password', 'confirm_password')
      }
    )
  }

  otpIsInputNumber(event) {
    const ch = (event.which) ? event.which : event.keyCode;
    if (ch > 31 && (ch < 48 || ch > 57)) {
      console.warn("char code restricted is :" + ch);
      return false;
    }
    return true;
  }

  get f() {
    return this.form_group.controls;
  }

  onSubmit() {
    try {
      this.submitted = true;
      if(this.form_group.valid){
      var value = this.form_group.value;
      this.loginData = value;
      this.loginData.email = this.email;
      this.loginData.otp = this.otp_val;
      this.loginData.otp_for = this.otp_for;
        this.registService.post_reset_password(this.loginData).subscribe((res: model_login_response) => {
          if (res.status == 'success') {
            this.toastr.success(res.message, 'Success!')
            this.user.token = res.token;
            this.user.expiration = res.expiration;
            this.user.user_id = res.user_id;
            localStorage.setItem("ltn", res.token)
            localStorage.setItem("user", JSON.stringify(res))
            localStorage.setItem("currentUser", JSON.stringify(res));
            localStorage.setItem("config", '{"app":{"appName":"Bihav Matrimony","appTitle":"Bihav Matrimony","appLogoImage":"assets/images/logo/logo.svg"},"layout":{"skin":"default","type":"vertical","menu":{"hidden":false,"collapsed":false},"navbar":{"hidden":false,"type":"floating-nav","background":"navbar-light","customBackgroundColor":true,"backgroundColor":""},"footer":{"hidden":false,"type":"footer-static","background":"footer-light","customBackgroundColor":false,"backgroundColor":""},"enableLocalStorage":true,"customizer":false,"scrollTop":true,"buyNow":false}}')
            
            // localStorage.setItem("currentUser", '{"id":1, "user_id":100035, "email":"admin@demo.com","firstName":"John","lastName":"Doe","avatar":"avatar-s-11.jpg","role":"Admin","token":"fake-jwt-token.1"}')
            // localStorage.setItem("config", '{"app":{"appName":"Bihav Matrimony","appTitle":"Bihav Matrimony","appLogoImage":"assets/images/logo/logo.svg"},"layout":{"skin":"default","type":"vertical","menu":{"hidden":false,"collapsed":true},"navbar":{"hidden":false,"type":"floating-nav","background":"navbar-light","customBackgroundColor":true,"backgroundColor":""},"footer":{"hidden":false,"type":"footer-static","background":"footer-light","customBackgroundColor":false,"backgroundColor":""},"enableLocalStorage":true,"customizer":false,"scrollTop":true,"buyNow":false}}')
            localStorage.removeItem('token_push_notification');
            this.router.navigateByUrl("profiles")
            // this.router.navigate(['profiles']);
          } else {
            this.toastr.error(res.message, 'Error!');
          }
        });//, error => alert(JSON.stringify(error)));
        }else {this.toastr.error('Input details are not valid', 'Error!');}
    } catch (err: any) { throw err; alert(err.message) }

  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
