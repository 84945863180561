export class model_preferenceStep3 {
    country_id:number;
    state_id:number;
    city_id:number;
    residence_id:number;
}
export class model_country {
    country_id:number;
    country_hi:string;
    country_en:string;
   
}
export class model_state {
    state_id:number;
    state_hi:string;
    state_en:string;
    
}
export class model_city{
    city_id:number;
    city_hi:string;
    city_en:string;
}
export class model_residence {
    residence_id:number;
    residence_hi:string;
    residence_en:string;
}
