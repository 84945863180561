import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { RegistService } from 'app/services/regist.service';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  public notifications: notification;
  public notificationss: any;

  constructor(private _notificationsService: NotificationsService, private registservice:RegistService ) { }

  notification = {
    contact: [
      {
        "title": "High memory",
        "messge": "You have 10 unread messages"
      },
      {
        "title": "Alva",
        "messge": "Pennigton"
      },
      {
        "title": "Andrew",
        "messge": "Och"
      }
    ]
  }


  ngOnInit(): void {
    this.registservice.set_push_notifications({ data: this.notification.contact });
    
    // this._notificationsService.onApiDataChange.subscribe(res => {
    //   this.notifications = res;
    // });

   
    this.registservice.push_notifications.subscribe((data: any) => {
      this.notificationss = data.data;
    // var  notificationsss = data.data.length;
  // alert(JSON.stringify( this.notificationss ));
});
  }
}
