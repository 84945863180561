<ng-container *ngIf="!item.hidden">
  <!-- item with router url -->
  <!-- *ngIf="item.url && !item.externalUrl" -->
  <a class="" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl" [routerLink]="[item.url]"
    [queryParams]="{pt: item.qs}" [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>
  <!-- item with external url -->
  <a class="" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl" [href]="item.url" [queryParams]="{pt: item.qs}" 
    [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <span [data-feather]="item.icon" *ngIf="item.icon"></span>
    <span class="menu-title text-truncate" [translate]="item.translate"></span>
    <span class="float-right badge badge-pill badge-primary count-min-width" style="overflow: hidden;" >{{item.counts}}</span>

    <!-- <span class="float-right badge badge-pill badge-primary ">{{profileCount[0].shortListCount}} </span> -->
    <!-- <div [ngSwitch]="item.id">
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="allsent">{{profileCount[0].sentIntAllCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="sentpending">{{profileCount[0].sentIntPendingCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="sentrejected">{{profileCount[0].sentIntRejectedCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="sentaccepted">{{profileCount[0].sentIntAcceptedCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="allreceived">{{profileCount[0].recievedntAllCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="receivedpending">{{profileCount[0].recievedIntPendingCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="receivedrejected">{{profileCount[0].recievedIntRejectedCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="receivedaccepted">{{profileCount[0].recievedIntAcceptedCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="shortlist">{{profileCount[0].shortListCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="blocklist">{{profileCount[0].blockedListCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="iviewedprofile">{{profileCount[0].viewedByMeCount}}</span>
      <span class="float-right badge badge-pill badge-primary" *ngSwitchCase="myviewedprofile">{{profileCount[0].viewedByOtherCount}}</span>
    </div> -->
    <!-- <span class="menu-title text-truncate" [translate]="item.translate">{{ item.title }}</span> -->
    <span class="badge ml-auto mr-1" *ngIf="item.badge" [translate]="item.badge.translate"
      [ngClass]="item.badge.classes">
    </span>
  </ng-template>
</ng-container>