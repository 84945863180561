<section>
<h2 class="card-title mb-1">{{ 'MENU.PROFILES.refun_cancellation_policy' | translate}}</h2> 

          <div class="cart">
                    <section class="content">
                              <div id="intro" class="mb-1">
                                        <div class="mb-2">
                                                  <h2 class="h5 text-primary">Refund and Cancellation Policy
                                                  </h2>
                                        </div>

                                        <p>Thank you for choosing Sevagudi. This Refund and Cancellation
                                                  Policy explains our guidelines regarding refunds and
                                                  cancellations for the services provided through our
                                                  platform. By using our services, you agree to comply with
                                                  this policy.
                                        </p>

                              </div>

                              <div id="yourContent" class="active">

                                        <div class="mb-1">
                                                  <h3 class="h5 text-primary">1. Cancellation of Services
                                                  </h3>
                                        </div>
                                        <div id="personal-data" class="mb-2 active">
                                                  <h4 class="h6 text-primary">1.1 User-initiated
                                                            Cancellations:</h4>
                                        </div>
                                        <p>As a user, you have the right to cancel a service request before it
                                                  is accepted by a service provider. To cancel a service
                                                  request, please contact our customer support team as soon as
                                                  possible. We will make every effort to accommodate your
                                                  cancellation request. However, please note that once a
                                                  service request is accepted by a service provider, it may be
                                                  subject to the terms and conditions set forth by the
                                                  provider.</p>
                              </div>
                              <div id="yourContent" class="active">
                                        <div id="personal-data" class="mb-2 active">
                                                  <h4 class="h6 text-primary">1.2 Service Provider-initiated
                                                            Cancellations:</h4>
                                        </div>
                                        <p>In rare cases, a service provider may need to cancel a confirmed
                                                  service request due to unforeseen circumstances or reasons
                                                  beyond their control. If a service provider cancels a
                                                  confirmed service request, we will notify you as soon as
                                                  possible and assist you in finding an alternative service
                                                  provider or obtaining a refund, subject to the conditions
                                                  outlined in this policy.</p>
                              </div>
                              <div id="yourContent" class="active">

                                        <div class="mb-1">
                                                  <h3 class="h5 text-primary">2. Refunds
                                                  </h3>
                                        </div>
                                        <div id="personal-data" class="mb-2 active">
                                                  <h4 class="h6 text-primary">2.1 Service Provider Liability:
                                                  </h4>
                                        </div>
                                        <p>Sevagudi acts as a platform connecting users with service
                                                  providers. While we make efforts to ensure the quality and
                                                  reliability of the service providers on our platform, we do
                                                  not directly provide the services. Therefore, Sevagudi is
                                                  not liable for any refunds for services rendered by service
                                                  providers.</p>
                              </div>
                              <div id="yourContent" class="active">

                                        <div id="personal-data" class="mb-2 active">
                                                  <h4 class="h6 text-primary">2.2 Assistance with Refunds:
                                                  </h4>
                                        </div>
                                        <p>However, in the event of unsatisfactory service or a dispute with a
                                                  service provider, Sevagudi is committed to assisting you in
                                                  resolving the issue and obtaining a fair resolution. We will
                                                  facilitate communication between you and the service
                                                  provider and provide guidance to help you seek a refund, if
                                                  applicable.</p>
                              </div>
                              <div id="yourContent" class="active">


                                        <div id="personal-data" class="mb-2 active">
                                                  <h4 class="h6 text-primary">2.3 Third-Party Payment
                                                            Processors:</h4>
                                        </div>
                                        <p>If you made a payment through a third-party payment processor
                                                  (e.g., PayPal, Stripe), the refund process may be subject to
                                                  their terms and conditions. Any fees or charges imposed by
                                                  the payment processor in connection with the refund will be
                                                  your responsibility.</p>
                              </div>
                              <div id="yourContent" class="active">

                                        <div class="mb-2">
                                                  <h3 class="h5 text-primary">3. Dispute Resolution
                                                  </h3>
                                        </div>
                                        <p>If you encounter any issues with the services provided or have a
                                                  dispute regarding a refund, we encourage you to contact our
                                                  customer support team. We will diligently review your case
                                                  and work towards a fair resolution.</p>
                              </div>
                              <div id="yourContent" class="active">

                                        <div class="mb-2">
                                                  <h3 class="h5 text-primary">4. Changes to the Policy
                                                  </h3>
                                        </div>

                                        <p>Sevagudi reserves the right to modify or update this Refund and
                                                  Cancellation Policy at any time. Any changes will be
                                                  effective immediately upon posting the revised policy on our
                                                  website or app. By continuing to use our services after the
                                                  policy
                                                  has been updated, you agree to be bound by the revised
                                                  terms.</p>
                              </div>
                              <div id="yourContent" class="active">

                                        <div class="mb-2">
                                                  <h3 class="h5 text-primary">5. Contact Us
                                                  </h3>
                                        </div>
                                        <p>If you have any questions or concerns about our Refund and
                                                  Cancellation Policy, please contact us at<br>

                                                  Email: <strong>contact@sevagudi.com</strong> <br>
                                                  Phone: <strong>+91 85179 69727</strong><br>
                                                  Office Address: <strong>HN-53, W-20, Nr. Sahu Furniture,, Bhnd RTO
                                                            office, Aakash Nagar,
                                                            Rawabhata, Birgaon, Raipur, Chattisgarh, India,
                                                            493221</strong>.<br><br>
                                                  Our customer support team will be happy to assist you.</p>
                              </div>
                    </section>
          </div>
</section>