<div class="content-wrapper">
    <div class="content-body">
        <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->
        <!-- card-view -->
        <div class="container-fluid mt-3">
            <section class="card-view">
                <h2>Basic Details</h2><br>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-fn" placeholder="null">First Name</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-fnv">{{modify.first_name}}
                            <div *ngIf="!modify.first_name">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-ln">Middle Name</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-lnv" textWrap="true">{{modify.middle_name}}
                            <div *ngIf="!modify.middle_name">--</div>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-1">Last Name</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-2" textWrap="true">{{modify.last_name}}
                            <div *ngIf="!modify.last_name">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-3">Height</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-4">{{modify.height_hi}}
                            <div *ngIf="!modify.height_hi">--</div>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-5">Weight</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-6">{{modify.weight_hi}}
                            <div *ngIf="!modify.weight_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-7">Mother Tongue</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-8">{{modify.mother_tongue_hi}}
                            <div *ngIf="!modify.mother_tongue_hi">--</div>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-9">Marital Status</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-10">{{modify.marital_status_hi}}
                            <div *ngIf="!modify.marital_status_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-11">Skin Type</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-12">{{modify.skin_type_hi}}
                            <div *ngIf="!modify.skin_type_hi">--</div>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-13">Body Type</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-14">{{modify.body_type_hi}}
                            <div *ngIf="!modify.body_type_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-15">Eating Habit</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-16">{{modify.eating_habit_hi}}
                            <div *ngIf="!modify.eating_habit_hi">--</div>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Drinking Habit</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.drinking_habit_hi}}
                            <div *ngIf="!modify.drinking_habit_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Smoking Habit</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.smoking_habit_hi}}
                            <div *ngIf="!modify.smoking_habit_hi">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Blood Group</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.blood_group_hi}}
                            <div *ngIf="!modify.blood_group_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Birth Place</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.birth_place}}
                            <div *ngIf="!modify.birth_place">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Birth Time</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.birth_time}}
                            <div *ngIf="!modify.birth_time">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Birth Date</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.birth_date}}
                            <div *ngIf="!modify.birth_date">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">About Me</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.about_me}}
                            <div *ngIf="!modify.about_me">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">hobby</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.hobby}}
                            <div *ngIf="!modify.hobby">--</div>
                        </label>
                    </div>
                </div> <br><br>
                <h2>Religious Details</h2>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Religion</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.religion_hi}}
                            <div *ngIf="!modify.religion_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Caste</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.caste_hi}}
                            <div *ngIf="!modify.caste_hi">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Subcaste</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.subcaste}}
                            <div *ngIf="!modify.subcaste">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Gothra</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.gothra_hi}}
                            <div *ngIf="!modify.gothra_hi">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Horoscope</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.horoscope_hi}}
                            <div *ngIf="!modify.horoscope_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Manglic</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.manglik_hi}}
                            <div *ngIf="!modify.manglik_hi">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Star</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.star_hi}}
                            <div *ngIf="!modify.star_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Moonsign</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.moonsign_hi}}
                            <div *ngIf="!modify.moonsign_hi">--</div>
                        </label>
                    </div>
                </div><br><br>
                <h2>Location detail</h2>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17" placeholder="null">Address</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.address}}
                            <div *ngIf="!modify.address">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Country</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.country_hi}}
                            <div *ngIf="!modify.country_hi">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">State </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.state_hi}}
                            <div *ngIf="!modify.state_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">City </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.city_hi}}
                            <div *ngIf="!modify.city_hi">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Mobile No.</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.mobile}}
                            <div *ngIf="!modify.mobile">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Phone</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.phone}}
                            <div *ngIf="!modify.phone">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Resifdence Status</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">{{modify.residence_hi}}
                            <div *ngIf="!modify.residence_hi">--</div>
                        </label>
                    </div>
                </div><br><br>
                <h2>Education/Profession Details</h2>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Education</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.educations}}
                            <div *ngIf="!modify.educations">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Employed In</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.employed_in_hi}}
                            <div *ngIf="!modify.employed_in_hi">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Designation</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.designation_hi}}
                            <div *ngIf="!modify.designation_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Occupation</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.occupation_hi}}
                            <div *ngIf="!modify.occupation_hi">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Annual Income</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">{{modify.income_range}}
                            <div *ngIf="!modify.income_range">--</div>
                        </label>
                    </div>
                </div><br><br>
                <h2>Family Details</h2>
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Family Type</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.family_type_hi}}
                            <div *ngIf="!modify.family_type_hi">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Family Status</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.family_status_hi}}
                            <div *ngIf="!modify.family_status_hi">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Father Name</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.father_name}}
                            <div *ngIf="!modify.father_name">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Father Occupation</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.father_occupation}}
                            <div *ngIf="!modify.father_occupation">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Mother Name</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.mother_Name}}
                            <div *ngIf="!modify.mother_Name">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Mother Occupation</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.mother_occupation}}
                            <div *ngIf="!modify.mother_occupation">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Number of Brother</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.total_brothers}}
                            <div *ngIf="!modify.total_brothers">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Number of Sister</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.total_sisters}}
                            <div *ngIf="!modify.total_sisters">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">Married Brother</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-18">{{modify.total_married_brother}}
                            <div *ngIf="!modify.total_married_brother">--</div>
                        </label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-19">Married Sister</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.total_married_sister}}
                            <div *ngIf="!modify.total_married_sister">--</div>
                        </label>
                    </div>
                </div> 
                <div class="row">
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-17">About Family</label>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="form-label" for="vertical-modern-20">{{modify.about_family}}
                            <div *ngIf="!modify.about_family">--</div>
                        </label>
                    </div>
                </div> 
            </section>
        </div>
        <!-- /card-view -->
    </div>
</div>
