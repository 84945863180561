import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { model_response, TblProfile, TblProfile2, TblProfile3, TblProfile4, TblProfile5 } from '../../app/model/tbl-profile';
import { BehaviorSubject, Observable } from 'rxjs';
import { base } from 'base/base'
import { ProfileListComponent } from 'app/profiles/profile-list/profile-list.component';
import { debug } from 'node:console';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RegistService {
    a = null;
    tN;
    myHeaders = new Headers(); // Currently empty
    private _registry: { [key: string]: ProfileListComponent }
    // url get 
    private lastSegmentSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public lastSegment$: Observable<string> = this.lastSegmentSubject.asObservable();
    
    constructor(private http: HttpClient, private _coreMenuService: CoreMenuService, private router: Router) {

   this.router.events.pipe( filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        const segments = url.split('/');
        const lastSegment = segments[segments.length - 1];
          this.lastSegmentSubject.next(lastSegment);
      });

    }


    

    public qs = new BehaviorSubject<any>(null);  base: any;

    public setQS = (data: any) => this.qs.next(data); //alert(JSON.stringify(data))
    public getQS(): any { return this.qs.value; }
    
    
    public push_notifications = new BehaviorSubject<any>(null);
    
    public set_push_notifications = (data: any) => this.push_notifications.next(data);
    
    public get_push_notifications(): any { return this.push_notifications.value; };

    // localStorage
    
    public localStorage_data= new BehaviorSubject<any>(null);
    
    public set_new_localStorage_data= (data: any) => this.localStorage_data.next(data);
    
    public get_new_localStorage_data(): any { return this.localStorage_data.value; }
    // filter_record
    public fr= new BehaviorSubject<any>(null);
    
    public set_fr_data = (data: any) => this.fr.next(data);
    
    public get_fr_data(): any { return this.fr.value; }
    

    public setMenuCount(entity: any, value: number) {
        var menucount = this._coreMenuService.getCurrentMenu();
     
        switch (entity) {
            case 'shortListCount':
                menucount[2].counts = menucount[2].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            // case 'sentIntAllCount':
            //     menucount[1].children[0].counts = menucount[1].children[0].counts + value;
            //     this.setQS({ type: 'menu', data: menucount }); break;
            case 'sentIntPendingCount':
                menucount[1].children[1].counts = menucount[1].children[1].counts + value;
                menucount[1].children[0].counts = menucount[1].children[0].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            case 'sentIntRejectedCount':
                menucount[1].children[2].counts = menucount[1].children[2].counts + value;
                menucount[1].children[0].counts = menucount[1].children[0].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            case 'sentIntAcceptedCount':
                menucount[1].children[3].counts = menucount[1].counts + value;
                menucount[1].children[0].counts = menucount[1].children[0].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            case 'recievedntAllCount':
                menucount[1].children[4].counts = menucount[1].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            case 'recievedIntPendingCount':
                menucount[1].children[5].counts = menucount[1].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            case 'recievedIntRejectedCount':
                menucount[1].children[6].counts = menucount[1].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            case 'recievedIntAcceptedCount':
                menucount[1].children[7].counts = menucount[1].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            case 'blockedListCount':
                menucount[3].counts = menucount[3].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            case 'viewedByMeCount':
                menucount[4].counts = menucount[4].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            case 'viewedByOtherCount':
                menucount[5].counts = menucount[5].counts + value;
                this.setQS({ type: 'menu', data: menucount }); break;
            
            //             this.profileCount[0].shortListCount;
            // this.menucount[1].children[0].counts = this.profileCount[0].sentIntAllCount;
            // this.menucount[1].children[1].counts = this.profileCount[0].sentIntPendingCount;

            // this.menucount[1].children[2].counts = this.profileCount[0].sentIntRejectedCount;
            // this.menucount[1].children[3].counts = this.profileCount[0].sentIntAcceptedCount;
            // this.menucount[1].children[4].counts = this.profileCount[0].recievedntAllCount;
            // this.menucount[1].children[5].counts = this.profileCount[0].recievedIntPendingCount;

            // this.menucount[1].children[6].counts = this.profileCount[0].recievedIntRejectedCount;
            // this.menucount[1].children[7].counts = this.profileCount[0].recievedIntAcceptedCount;
            // this.menucount[3].counts=this.profileCount[0].blockedListCount;
            // this.menucount[4].counts=this.profileCount[0].viewedByMeCount;
            //         this.menucount[5].counts = this.profileCount[0].viewedByOtherCount;


        }
    }

    getProfileTab6(): Observable<any> {
        return this.http.get("http://localhost:9631/api/Profile_step4/Get_Profile_step_4");
    }

    postRegist(data) {
        return this.http.post<any>(`${base.apiUrl}/Authenticate/check_user_exists`, data);
    }
    changePassword(data) {
        return this.http.post<any>(`${base.apiUrl}/Authenticate/change_password`, data);
    }
    delete_account(data) {
        return this.http.post<any>(`${base.apiUrl}/Authenticate/delete_account`, data);
    }

    getOtp(data) {
        return this.http.post<any>(`${base.apiUrl}/Authenticate/new_reg_get_otp`, data);
    }
    validateOTP(otp: number, data: any) {
        return this.http.post<any>(`${base.apiUrl}/Authenticate/new_reg_validate_otp?otp=${otp}`, data);
    }
    getOtp_forgetpassword(data) {
        return this.http.post<any>(`${base.apiUrl}/Authenticate/get_otp`, data);
    }

    validateOTP_forgetpassword(otp: any) {
        return this.http.post<any>(`${base.apiUrl}/Authenticate/verify_otp`, otp);
    }

    post_reset_password(data: any) {
        return this.http.post<any>(`${base.apiUrl}/Authenticate/reset_password`, data);
    }

    getToken() {
        return localStorage.getItem('ltn');
    }

    getProfile() {
        return this.http.get(`${base.apiUrl}/Profile/get_step_1`);
    }

    getProfileTab2() {
        return this.http.get(`${base.apiUrl}/profile_step2/GetProfile_step_2`);
    }

    getProfileTab3() {
        return this.http.get(`${base.apiUrl}/Profile_step3/Get_Profile_step_3`);
    }
    getProfileTab4() {
        return this.http.get<TblProfile4>(`${base.apiUrl}/Profile_step4/Get_Profile_step_4`);
    }

    getProfileTab5() {
        return this.http.get(`${base.apiUrl}/Profile_step5/Get_Profile_step_5`);
    }

    getProfileByUserId() {
        return this.http.get(`${base.apiUrl}/Profile`);
    }

    getProfileBy(firstName) {
        return this.http.get(`${base.apiUrl}/Profile/${firstName}`);
    }

    postProfileTabOne(data: TblProfile): Observable<model_response> {
        return this.http.post<model_response>(`${base.apiUrl}/Profile/insert_step_1`, data);
    }

    putProfileTabTwo(data: TblProfile2): Observable<model_response> {
        return this.http.put<model_response>(`${base.apiUrl}/profile_step2/Puttbl_profile_step_2`, data);
    }

    putProfileTabThree(data: TblProfile3): Observable<model_response> {
        return this.http.post<model_response>(`${base.apiUrl}/Profile_step3`, data);
    }

    putProfileTabFour(data: TblProfile4): Observable<model_response> {
        return this.http.post<model_response>(`${base.apiUrl}/Profile_step4`, data);
    }

    putProfileTabFive(data: TblProfile5): Observable<model_response> {
        return this.http.put<model_response>(`${base.apiUrl}/Profile_step5/Put_Profile_step_5`, data);
    }

    upload_profile_photo(photo, photo_type: string) {
        return this.http.post<any>(`${base.apiUrl}/Profile/upload_image?photo_type=${photo_type}`, photo);
    }
    upload_gallery_photo(photo) {
        return this.http.post<any>(`${base.apiUrl}/Profile/upload_image?photo_type=profile_image`, photo);
    }
    gender_get() {
        return this.http.get<model_response>(`${base.apiUrl}/Profile/form_data_get_new_reg`);
    }
    
    

    post_default_language(values: any) {
        return this.http.post<any>(`${base.apiUrl}/Authenticate/update_default_language`, values);
    }

 
    
}