
<div class="auth-wrapper auth-v1 px-2">
          <div class="auth-inner py-2">
                    <div class="card mb-0">
                             <div class="card-body">
                                        <a class="brand-logo"><img src="{{base.admin_app_url}}/uploads/apps/logo-login.png"  alt="bihav matrimony" height="40" /> </a>

                                        <!-- <h4 class="card-title mb-1">{{'MENU.OTP_VALIDATE.TITLE' | translate}} <b>{{email}}</b> 🔒</h4> -->
                                        <h4 class="card-title mb-1">{{'MENU.LOGIN.login_with_otp' | translate }}</h4>

                  
                <form [formGroup]="formgroup$" (ngSubmit)="otpSubmit()" class="mt-2 mb-4">
                    <div class="form-group">
                        <label class="form-label" for="login-email">{{'MENU.LOGIN.MOBILE' | translate}}</label>
                        <div class="form-group-sm mb-0 input-group" >
                            <input type="tel" class="form-control" name="email"  formControlName="email" id="email" placeholder="{{'MENU.LOGIN.mobile_number_placeholder' | translate }}" autofocus
                            aria-describedby="emailHelp" autocomplete="off" (keypress)="base.isMobileNumber($event)"   (click)="customEmailErrorMessage=false" maxlength="10">
                            <div class="input-group-append">
                                <button type="button" class="btn btn-primary" [disabled]="ForFormExpand === true && time >0" (click)="getOTPForLogin(60)">
                                    <span *ngIf="ForFormExpand === false; else vsible">{{'MENU.LOGIN.get_otp' | translate }}</span>
                                    <ng-template #vsible>
                                        <span>{{'MENU.LOGIN.resend_otp'| translate}}<span *ngIf="resendOTP === true"> in {{display}}</span></span>
                                    </ng-template>
                                </button>  
                                
                            </div>
                        </div>
                        <span class="text-danger" *ngIf="(f.email.touched || submitted) && f.email.errors?.required">{{'MENU.LOGIN.mobile_number_required'| translate}}</span>
                        <span class="text-danger" *ngIf="f.email.errors?.pattern">{{'MENU.LOGIN.valid_mobile_number'| translate}}</span>
                        <span class="text-danger" *ngIf="customEmailErrorMessage">{{message}}</span>
                    </div>
                    <div class="form-group" *ngIf="ForFormExpand === true">
                        <input type="tel" class="form-control" name="otp" formControlName="otp" autocomplete="off" 
                       placeholder="{{'MENU.LOGIN.otp_here_placeholder' | translate }}" aria-describedby="emailHelp" (keypress)="base.isMobileNumber($event)" maxlength="6" (click)="customOTPErrorMessage=false">
                        <span class="text-danger" *ngIf="(f.otp.touched || submitted) && f.otp.errors?.required">{{'MENU.LOGIN.otp_is_required' | translate }}" </span>
                        <span class="text-danger" *ngIf="f.otp.errors?.minlength">{{'MENU.LOGIN.enter_valid_digit_otp' | translate }}"</span>
                        <span class="text-danger" *ngIf="customOTPErrorMessage">{{otpMessage}}</span>
                    </div>

                    <button class="btn btn-primary btn-lg btn-block" *ngIf="ForFormExpand === true">{{'MENU.LOGIN.SIGN_IN' | translate }}</button>
                </form>

                 <div class="divider my-2">
                    <div class="divider-text">{{'MENU.LOGIN.OR' | translate }}</div>
                </div>
            <div class="text-center mt-1">
                <p class="text-center mt-1">
                    <span>{{'MENU.LOGIN.REGISTER_NEW_USER' | translate }}</span><a routerLink="../registration">
                        <span>&nbsp;{{'MENU.LOGIN.CREATE_AN_ACCOUNT' | translate }}</span>
                    </a>
                </p>
                 <p class="text-center mt-1">
                    <span>{{'MENU.NEW_USER_REGISTRATION.ALREADY_ACCOUNT' | translate }} </span>
                    <a routerLink="/">
                        <span>{{'MENU.NEW_USER_REGISTRATION.SIGN_IN_INSTEAD' | translate }}</span>
                    </a>
                </p>
                <!-- <a  routerLink="../login" routerLinkActive="active" class="">
                   Login with password
                </a> -->
                <!-- <br/><br/> -->
                <!-- <a routerLink="../registration" routerLinkActive="active" class="">
                    Don't have an account? Sign up
                </a><br/><br/> -->
               
            </div>

                                        <!-- <form class="auth-forgot-password-form mt-2" [formGroup]="otpForm"
                                                  (ngSubmit)="otpSubmit()">
                                                  <div class="form-group">
                                                            <label for="forgot-password-email"
                                                                      class="form-label">{{'MENU.OTP_VALIDATE.SUB_TITLE'
                                                                      | translate}}</label>

                                                            <input type="number" formControlName="otp"
                                                                      class="form-control"
                                                                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                                      placeholder="{{'MENU.OTP_VALIDATE.OTP_PLACEHOLDER' | translate }}"
                                                                      aria-describedby="login-email" autofocus=""
                                                                      tabindex="1" minlength="6" maxlength="6" />

                                                            <span class="text-danger"
                                                                      *ngIf="(f.otp.touched || submitted) && f.otp.errors?.required">{{'MENU.OTP_VALIDATE.OTP_RQUIRED'
                                                                      | translate }}</span>
                                                  </div>
                                                  <button class="btn btn-primary btn-block" tabindex="2"
                                                            rippleEffect>{{'MENU.OTP_VALIDATE.SUBMIT' |
                                                            translate}}</button>
                                        </form>


                                        <p class="text-center mt-2">
                                                  <a routerLink="/"> <i
                                                                      data-feather="chevron-left"></i>{{'MENU.OTP_VALIDATE.BACK_TO_LOGIN'
                                                            | translate}}</a>
                                        </p> -->
                              </div> 
                    </div>
          </div>
</div> 


