<!-- <div class="card col-"> -->
    <div class="col-md-6 col-xl-6">
        <div class="row w-100 bg-primary text-white m-0">
            <h4 class="card-title text-white">Welcome to Payment Gateway</h4>
        </div>
        <div class="card text-white">
            <div class="card-body">
                <button rippleeffect="" (click)="PGREsponse()" type="submit" class="btn btn-block btn-outline-success mt-2 waves-effect w-25 " >PAY</button>
            </div>
        </div>
    </div>