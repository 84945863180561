import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { model_city, model_country, model_residence, model_response, model_state, model_Step3, TblProfile, TblProfile2, TblProfile3, TblProfile4, TblProfile5 } from '../model/tbl-profile';
import { RegistService } from '../services/regist.service';
import { buffer, map, toArray } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ProfileComponent implements OnInit {

    isFormDisabled: boolean;
    mySelectModel: number[];

    selectOptions = [
        {
            customId: 1,
            customText: 'Some Random Text'
        }, {
            customId: 2,
            customText: 'To Show Custom Values'
        }, {
            customId: 3,
            customText: 'With Custom Text Binding'
        }, {
            customId: 4,
            customText: 'My Validation'
        },
    ]

    tData;
    prot;
    tabOne;
    tab2;
    tab3;
    tab4;
    tab5;
    public country_en;
    public contentHeader: object;
    multipleselection: UntypedFormGroup;
    public first_name;
    public middle_name;
    public last_name;
    public height_id;
    public weight_id = '';
    public mother_tongue_id;
    public marital_status_id;
    public skin_type_id;
    public body_type_id;
    public eating_habit_id;
    public drinking_habit_id;
    public smoking_habit_id;
    public blood_group_id;
    public birth_place;
    public birth_time;
    public birth_date;
    public about_me;
    public hobby;

    public religion_id: any;
    public caste_id;
    public subcaste;
    public manglik_id;
    public horoscope_id;
    public star_id;
    public moonsign_id;
    public gothra_id;

    public address;
    public country_id;
    public state_id;
    public city_id;
    public mobile;
    public phone;
    public residence_id;

    public education_id;
    d = [];
    public employed_in_id;
    public designation_id;
    public occupation_id;
    public annual_income_id;

    public family_type_id;
    public family_status_id;
    public father_name;
    public father_occupation_id;
    public mother_Name;
    public mother_occupation_id;
    public total_brothers;
    public total_sisters;
    public total_married_brother;
    public total_married_sister;
    public about_family;

    profile_step_1;
    mst_height;
    ProfileTabOneHeightData;
    ProfileTabOneWeightData;
    ProfileTabOneMotherToungData;
    ProfileTabOneMaritalStatusData;
    ProfileTabOneSkinTypeData
    ProfileTabOneBodyTypeData;
    ProfileTabOneEatingHabitData;
    ProfileTabOneDrinkingHabitData;
    ProfileTabOneSmokingHabitData;
    ProfileTabOneBloodGroupData;

    ProfileTab2religiousData: any[];
    ProfileTab2casteData;
    ProfileTab2manglikData;
    ProfileTab2gothraData;
    ProfileTab2horoscopeData;
    ProfileTab2starData;
    ProfileTab2moonsignData;

    ProfileTab4PreFilLEducationData;
    ProfileTab4EducationData;
    ProfileTab4Employed_inData;
    ProfileTab4DesignationData;
    ProfileTab4OccupationData;
    ProfileTab4Annual_incomeData;

    ProfileTab5Family_typeData;
    ProfileTab5Family_statusData;
    ProfileTab5OccupationData;
    ProfileTab5M_occupationData;

    private modernVerticalWizardStepper: Stepper;
    private bsStepper;
    modernWizardStepper: any;
    horizontalWizardStepper: Stepper;
    verticalWizardStepper: Stepper;

    dropDownList;
    getOne;
    weight;

    /**
      @param data
     **/

    /**
     * Horizontal Wizard Stepper Next
     *
      @param dataPro
     **/
    modernVerticalNext() {
        this.modernVerticalWizardStepper.next();
    }
    modernVerticalNextp(dataPro) {
        this.modernVerticalWizardStepper.next();
        // console.warn(dataPro.form)
        // if (dataPro.form.valid === true) {

        // }
    }

    modernVerticalPrevious() {
        this.modernVerticalWizardStepper.previous();
    }

    onSubmit() {
        return false;
    }

    constructor(private formBuilder: UntypedFormBuilder, private registService: RegistService, private toastr: ToastrService,
        public translate: TranslateService, private router: Router) {

    }

    ngOnInit() {

        // this.dropDownList = this.getData();
        // this.weight = this.getWeight();
        this.initForm();
        this.getProfileOneData();
        this.getProfileTabTwoData();
        this.getProfileTabThreeData();
        this.getProfileTabFourData();
        this.getProfileTabFiveData();
        this.onSelectEducation(event);
        this.getProfileDAtaById();
        this.getmulti();
        this.modernVerticalWizardStepper = new Stepper(document.querySelector('#stepper4'), {
            linear: false,
            animation: true
        });

        this.bsStepper = document.querySelectorAll('.bs-stepper');
        this.contentHeader = {
            headerTitle: 'Form Wizard',
            actionButton: true,
            breadcrumb: {
                type: '',
                links: [
                    {
                        name: 'Home',
                        isLink: true,
                        link: '/'
                    },
                    {
                        name: 'Forms',
                        isLink: true,
                        link: '/'
                    },
                    {
                        name: 'Form Wizard',
                        isLink: false
                    }
                ]
            }
        };

    }


    onSelectEducation(event) {
        // console.warn(this.education_id);
    }
    onItemSelect(event) {
        console.warn('$event is:', event);
    }

    onSelectHeight(event) {
        console.warn('$event is:', event);
    }

    onSelectWeight(event) {
        console.warn('$event is:', event);
    }

    onSelectMotherTongue(event) {
        console.warn('$event is:', event);
    }

    onSelectMaritalStatus(event) {
        console.warn('$event is:', event);
    }

    onSelectSkinType(event) {
        console.warn('$event is:', event);
    }

    onSelectBodyType(event) {
        console.warn('$event is:', event);
    }

    onSelectEatingHabit(event) {
        console.warn('$event is:', event);
    }

    onSelectDrinkingHabit(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectSmokingHabit(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectBloodGroup(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }


    //Tab2

    onSelectReligion(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectCaste(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectManglik(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectHoroscope(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectStar(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectMoonsign(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectGothra(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    submitMultivalue(data) {
        console.warn('data is:', data);
        let one = JSON.stringify(data);
        this.getOne = console.warn(one);
        this.multipleselection.reset();
    }

    initForm() {
        this.multipleselection = this.formBuilder.group({
            course: ['', [Validators.required]],
            profileFirstName: ['', [Validators.required]]
        })
    }

    getmulti() {
        this.registService.getProfile().subscribe(response => {
        })
    }

    getProfileDAtaById() {
        this.registService.getProfileByUserId().subscribe(response => {
            this.tData = response;
            if (this.tData.data.profileData[0]) {
                this.first_name = this.tData.data.profileData[0].first_name
                this.middle_name = this.tData.data.profileData[0].middle_name
                this.last_name = this.tData.data.profileData[0].last_name
                this.height_id = this.tData.data.profileData[0].height_id
                this.weight_id = this.tData.data.profileData[0].weight_id
                this.mother_tongue_id = this.tData.data.profileData[0].mother_tongue_id
                this.marital_status_id = this.tData.data.profileData[0].marital_status_id
                this.skin_type_id = this.tData.data.profileData[0].skin_type_id
                this.body_type_id = this.tData.data.profileData[0].body_type_id
                this.eating_habit_id = this.tData.data.profileData[0].eating_habit_id
                this.drinking_habit_id = this.tData.data.profileData[0].drinking_habit_id
                this.smoking_habit_id = this.tData.data.profileData[0].smoking_habit_id
                this.blood_group_id = this.tData.data.profileData[0].blood_group_id
                this.about_me = this.tData.data.profileData[0].about_me
                this.hobby = this.tData.data.profileData[0].hobby
                this.birth_place = this.tData.data.profileData[0].birth_place
                this.birth_date = this.tData.data.profileData[0].birth_date
                this.birth_time = this.tData.data.profileData[0].birth_time
            }
        })
    }

    getProfileOneData() {
        this.registService.getProfile().subscribe(res => {
            if (res)
                this.tabOne = res;
            // var d = this.translate.instant('MENU.PROFILE_DETAILS.BASIC_DETAILS.HEIGHT_PLACEHOLDER')
            // alert(JSON.stringify(this.tabOne));
            this.profile_step_1 = this.tabOne.data.profileData[0];
            this.ProfileTabOneHeightData = this.tabOne.data.heightData
            this.ProfileTabOneHeightData.unshift({ "height_id": '', "height_en": 'Select height' });
            this.ProfileTabOneWeightData = this.tabOne.data.weightData;
            this.ProfileTabOneWeightData.unshift({ "weight_id": '', "weight_en": 'Select weight' });
            this.ProfileTabOneMotherToungData = this.tabOne.data.languageData;
            this.ProfileTabOneMotherToungData.unshift({ "mother_tongue_id": '', "mother_tongue_en": 'Select mother tongue' });
            this.ProfileTabOneMaritalStatusData = this.tabOne.data.martialStatusData;
            this.ProfileTabOneMaritalStatusData.unshift({ "marital_status_id": '', "marital_status_en": 'Select marital status' });
            this.ProfileTabOneSkinTypeData = this.tabOne.data.skinTypeData;
            this.ProfileTabOneSkinTypeData.unshift({ "skin_type_id": '', "skin_type_en": 'Select skin type' });
            this.ProfileTabOneBodyTypeData = this.tabOne.data.bodyTypeData;
            this.ProfileTabOneBodyTypeData.unshift({ "body_type_id": '', "body_type_en": 'Select body type' });
            this.ProfileTabOneEatingHabitData = this.tabOne.data.eatingHabitData;
            this.ProfileTabOneEatingHabitData.unshift({ "eating_habit_id": '', "eating_habit_en": 'Select eating habit' });
            this.ProfileTabOneDrinkingHabitData = this.tabOne.data.drinkingHabitData;
            this.ProfileTabOneDrinkingHabitData.unshift({ "drinking_habit_id": '', "drinking_habit_en": 'Select drinking habit' });
            this.ProfileTabOneSmokingHabitData = this.tabOne.data.smokingHabitData;
            this.ProfileTabOneSmokingHabitData.unshift({ "smoking_habit_id": '', "smoking_habit_en": 'Select smoking habit' });
            this.ProfileTabOneBloodGroupData = this.tabOne.data.bloodGroupData;
            this.ProfileTabOneBloodGroupData.unshift({ "blood_group_id": '', "blood_group_en": 'Select blood group' });
        })
    }


    getProfileTabTwoData() {
        this.registService.getProfileTab2().subscribe(res => {
            this.tab2 = res;
            //  alert(JSON.stringify( this.tab2));
            this.ProfileTab2religiousData = this.tab2.data.religiousData;
            this.ProfileTab2religiousData.unshift({ "religion_id": '', "religion_en": 'Select religion' });
            this.ProfileTab2casteData = this.tab2.data.casteData;
            this.ProfileTab2casteData.unshift({ "caste_id": '', "caste_en": 'Select caste' });
            this.ProfileTab2manglikData = this.tab2.data.manglikData;
            this.ProfileTab2manglikData.unshift({ "manglik_id": '', "manglik_en": 'Select manglik' });
            this.ProfileTab2gothraData = this.tab2.data.gothraData;
            this.ProfileTab2gothraData.unshift({ "gothra_id": '', "gothra_en": 'Select gothra' });
            this.ProfileTab2horoscopeData = this.tab2.data.horoscopeData;
            this.ProfileTab2horoscopeData.unshift({ "horoscope_id": '', "horoscope_en": 'Select horoscope' });
            this.ProfileTab2starData = this.tab2.data.starData;
            this.ProfileTab2starData.unshift({ "star_id": '', "star_en": 'Select star' });
            this.ProfileTab2moonsignData = this.tab2.data.moonsignData;
            this.ProfileTab2moonsignData.unshift({ "moonsign_id": '', "moonsign_en": 'Select moonsign' });
            if (this.tab2.data.profileData[0]) {
                this.religion_id = this.tab2.data.profileData[0].religion_id;
                this.caste_id = this.tab2.data.profileData[0].caste_id
                this.subcaste = this.tab2.data.profileData[0].subcaste
                this.manglik_id = this.tab2.data.profileData[0].manglik_id
                this.gothra_id = this.tab2.data.profileData[0].gothra_id
                this.horoscope_id = this.tab2.data.profileData[0].horoscope_id
                this.star_id = this.tab2.data.profileData[0].star_id
                this.moonsign_id = this.tab2.data.profileData[0].moonsign_id
            }
        })
    }
    model_state
    modelRes = new model_response();
    ProfileTab3CountriesData = [] //= model_country[]//new Array<model_country>[]

    ProfileTab3StatesData = []//: model_state[];
    ProfileTab3CitiesData = []//: model_city[];
    ProfileTab3Residence_statusData = []//: model_residence[];
    modelStep3 = new model_Step3();
    getProfileTabThreeData() {
        this.registService.getProfileTab3().subscribe((res: model_response) => {
            this.modelRes = res;
            if (this.modelRes.status == "Success") {
                this.ProfileTab3CountriesData = this.modelRes.data.countriesData;
                const index: number = this.ProfileTab3CountriesData.findIndex(item => item.country_en == 'India');
                if (index !== -1) {
                    this.country_id = this.ProfileTab3CountriesData[index].country_id
                }
                this.ProfileTab3CountriesData.unshift({ "country_id": '', "country_en": 'Select country' });
                this.ProfileTab3StatesData = this.modelRes.data.statesData;
                this.ProfileTab3StatesData.unshift({ "state_id": '', "state_en": 'Select state' });
                this.ProfileTab3CitiesData = this.modelRes.data.citiesData;
                this.ProfileTab3CitiesData.unshift({ "city_id": '', "city_en": 'Select city' });
                this.ProfileTab3Residence_statusData = this.modelRes.data.residence_statusData;
                this.ProfileTab3Residence_statusData.unshift({ "residence_id": '', "residence_en": 'Select residence' });
                if (this.modelRes.data.profileData[0]) {
                    this.modelStep3 = this.modelRes.data.profileData[0];
                }
            } else {
                this.toastr.error('Internal server error occured during processing your requset. Please try again.');
            }
        }, error => { this.toastr.error(error.message) });
    }

    getProfileTabFourData() {
        this.registService.getProfileTab4().subscribe((res: TblProfile4) => {
            let value = res.data
            let tab4 = JSON.parse(value.toString())
            this.ProfileTab4EducationData = tab4.Table1
            this.ProfileTab4EducationData.unshift({ "education_id": '', "education_en": 'Select education' });
            this.ProfileTab4Employed_inData = tab4.Table3
            this.ProfileTab4Employed_inData.unshift({ "employed_in_id": '', "employed_in_en": 'Select employed in' });
            this.ProfileTab4DesignationData = tab4.Table4
            this.ProfileTab4DesignationData.unshift({ "designation_id": '', "designation_en": 'Select designation' });
            this.ProfileTab4OccupationData = tab4.Table5
            this.ProfileTab4OccupationData.unshift({ "occupation_id": '', "occupation_en": 'Select occupation' });
            this.ProfileTab4Annual_incomeData = tab4.Table6
            this.ProfileTab4Annual_incomeData.unshift({ "income_id": '', "income_en": 'Select income' });
            if (tab4.Table) {
                var n = tab4.Table2;
                var g = JSON.parse(JSON.stringify(Object.values(n)))
                var result = g.map(a => a.education_id);
                let val: Array<string> = JSON.parse(JSON.stringify(result));
                const nuevo1 = val.map((i) => Number(i));
                this.education_id = nuevo1;
                this.employed_in_id = tab4.Table[0].employed_in_id;
                this.designation_id = tab4.Table[0].designation_id;
                this.occupation_id = tab4.Table[0].occupation_id;
                this.annual_income_id = tab4.Table[0].income_id;
            }
        })
    }

    getProfileTabFiveData() {
        this.registService.getProfileTab5().subscribe((res) => {
            this.tab5 = res;
            this.ProfileTab5Family_typeData = this.tab5.data.family_typeData;
            this.ProfileTab5Family_typeData.unshift({ "family_type_id": '', "family_type_en": 'Select family type' });
            this.ProfileTab5Family_statusData = this.tab5.data.family_statusData;
            this.ProfileTab5Family_statusData.unshift({ "family_status_id": '', "family_status_en": 'Select family status' });
            this.ProfileTab5OccupationData = this.tab5.data.occupationData;
            this.ProfileTab5OccupationData.unshift({ "f_occupation_id": '', "f_occupation_en": 'Select father occupation' });
            this.ProfileTab5M_occupationData = this.tab5.data.m_occupationData;
            this.ProfileTab5M_occupationData.unshift({ "m_occupation_id": '', "m_occupation_en": 'Select mother occupation' });
            if (this.tab5.data.profileData[0]) {
                this.family_type_id = this.tab5.data.profileData[0].family_type_id;
                this.family_status_id = this.tab5.data.profileData[0].family_status_id;
                this.father_name = this.tab5.data.profileData[0].father_name;
                this.father_occupation_id = this.tab5.data.profileData[0].father_occupation_id;
                this.mother_Name = this.tab5.data.profileData[0].mother_Name;
                this.mother_occupation_id = this.tab5.data.profileData[0].mother_occupation_id;
                this.total_brothers = this.tab5.data.profileData[0].total_brothers;
                this.total_sisters = this.tab5.data.profileData[0].total_sisters;
                this.total_married_brother = this.tab5.data.profileData[0].total_married_brother;
                this.total_married_sister = this.tab5.data.profileData[0].total_married_sister;
                this.about_family = this.tab5.data.profileData[0].about_family;
            }
        })
    }

    postProfileTabOneData(data: TblProfile) {
        try {
            var postData: TblProfile
            postData = new TblProfile();
            postData = data;
            
            this.registService.postProfileTabOne(postData).subscribe((res: any) => {
                this.getProfileOneData();
             
                if (res.status == 'Success') {
                    this.toastr.success(res.message, 'Success!');
                } else this.toastr.error(res.message, 'Error!');
            }, (error: any) => { this.toastr.error(JSON.stringify(error)) });
        } catch (err: any) { throw err }
    }

    putProfileTabTwoData(data: TblProfile2) {
        try {
            var putData: TblProfile2
            putData = new TblProfile2();
            putData = data;
            this.registService.putProfileTabTwo(putData).subscribe((res: model_response) => {
                if (res.status == 'Success') {
                    // alert("Successfully Updated")
                    this.toastr.success("Successfully Updated", 'Success!');

                } else this.toastr.error(res.message, 'Error!');
            });//, error => console.warn('error', error));
        } catch (err: any) { throw err; alert(err.message) }
    }

    putProfileTabThreeData(data: TblProfile3) {
        try {
            var putDataThree: TblProfile3
            putDataThree = new TblProfile3();
            putDataThree = data;
            console.warn(putDataThree)
            this.registService.putProfileTabThree(putDataThree).subscribe((res: model_response) => {
                if (res.status == 'Success') {
                    this.toastr.success("Successfully Updated", 'Success!');

                } else this.toastr.error(res.message, 'Error!');
            });//, error => console.warn('error', error));
        } catch (err: any) { throw err; alert(err.message) }
    }
    putProfileTabFourData(data: TblProfile4) {
        try {
            var putDataFour: TblProfile4
            putDataFour = new TblProfile4();
            putDataFour = data;
            this.registService.putProfileTabFour(putDataFour).subscribe(res => {
                if (res.status == 'Success') {
                    this.getProfileTabFourData();
                    this.toastr.success("Successfully Updated", 'Success!');
                    this.modernVerticalWizardStepper.next();
                } else this.toastr.error(res.message, 'Error!');
            });//, error => console.warn('error', error));
        } catch (err: any) { throw err; alert(err.message) }
    }

    putProfileTabFiveData(data: TblProfile5) {
        try {
            var putDataFive: TblProfile5
            putDataFive = new TblProfile5();
            putDataFive = data;
            this.registService.putProfileTabFive(putDataFive).subscribe((res: any) => {
                if (res.status == 'Success') {
                    this.toastr.success(res.message, 'Success!');
                    this.router.navigateByUrl("/preferences")
                } else this.toastr.error(res.message, 'Error!');
            });//, error => console.warn('error', error));
        } catch (err: any) { throw err; alert(err.message) }
    }
}
