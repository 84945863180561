import { Token } from '@angular/compiler';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from 'app/login/login.component';
import { TblLogin, model_login_response } from 'app/model/tbl-login';
import { model_response } from 'app/model/tbl-profile';
import { Subject } from 'rxjs';
import { base } from 'base/base';
import { takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '../../@core/services/config.service';
import { RegistService } from '../services/regist.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-otp-forgot-password',
  templateUrl: './otp-forgot-password.component.html',
  styleUrls: ['./otp-forgot-password.component.scss']
})
export class OtpForgotPasswordComponent implements OnInit {
  public otp: number;
  registerationData;
  public base: any;
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public otpForm: FormGroup;
  submitted = false;
  user_id: any;
  loginData: TblLogin = new TblLogin();
  email: any;
  otp_for: any;

  /**
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(private _coreConfigService: CoreConfigService, private router: Router, private registService: RegistService,
    private toastr: ToastrService, private _formBuilder: UntypedFormBuilder,
    public translate: TranslateService) {
    this.base = base;
    this.base = base
    if (localStorage.getItem("ltn")) {
      this.router.navigate(['/profiles']);
    }
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }


  ngOnInit(): void {
    this.registerationData = JSON.parse(localStorage.getItem('registerationData') || '{}');
    
    var otp_data = this.registService.getQS();
    this.otp_for = otp_data.otp_for;
    this.email = otp_data.email;

    
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });


    this.otpForm = this._formBuilder.group({
      // otp: ['', [Validators.required, Validators.pattern('/^[0-9]\d*$/')]],
      otp: ['', [Validators.required]],
    })
  }

  otpIsInputNumber(event) {
    const ch = (event.which) ? event.which : event.keyCode;
    if (ch > 31 && (ch < 48 || ch > 57)) {
      console.warn("char code restricted is :" + ch);
      return false;
    }
    return true;
  }

  get f() {
    return this.otpForm.controls;
  }

  otpSubmit() {
    try {
      this.submitted = true;
      if (this.otpForm.valid) {
        var value = this.otpForm.value;
        this.loginData = value;
        this.loginData.email = this.email;
        this.loginData.otp_for = this.otp_for;
        this.registService.setQS(value);
        //  this.router.navigate(['/reset-password']);
        // return
        this.registService.validateOTP_forgetpassword(this.loginData).subscribe((res: any) => {
          if (res.status == 'Success') {
             this.toastr.success(res.message, 'Success!');
            this.router.navigate(['/reset-password']);
          } else {
            this.toastr.error(res.message, 'Error!');
          }
        });//, error => alert(JSON.stringify(error)));
      } else {this.toastr.error('Input details are not valid', 'Error!');}
      
    } catch (err: any) { throw err; alert(err.message) }
    // validateOTP_forgetpassword
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
