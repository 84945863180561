<section>
    <h2 class="card-title mb-1">{{'MENU.PROFILES.terms_conditions' | translate}}</h2>

    <div class="cart">
        <section class="content">
            <div id="intro" class="mb-1">

                <div class="mb-2">
                    <h2 class="h5 text-primary">Terms and Conditions</h2>
                </div>

                <p>Please read these Terms and Conditions ("Terms") carefully before
                    using our website, app and services operated by Sevagudi.
                    These
                    Terms apply to all visitors, users, and others who access or
                    use our website, app and services.
                </p>
            </div>

            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">1. Acceptance of Terms
                    </h3>
                </div>
                <p>By accessing or using our website, app and services, you agree to
                    be
                    bound by these Terms. If you do not agree to these Terms,
                    you may not access or use our website, app and services.
                </p>
            </div>
            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">2. Use of the website or app
                    </h3>
                </div>
                <div id="personal-data" class="mb-3 active">
                    <h4 class="h6 text-primary">2.1 Eligibility:</h4>
                </div>
                <p>You must be at least 18 years old to use our website, app and
                    services.
                    By using our website, app and services, you represent and
                    warrant
                    that you are at least 18 years old.</p>
            </div>
            <div id="yourContent" class="active">

                <div id="personal-data" class="mb-2 active">
                    <h4 class="h6 text-primary">2.2 User Account:</h4>
                </div>
                <p>To access certain features of our website, app and services, you
                    may
                    need to create a user account. You are responsible for
                    maintaining the confidentiality of your account information
                    and for all activities that occur under your account. You
                    agree to provide accurate and complete information when
                    creating an account and to promptly update your information
                    as necessary.</p>
            </div>
            <div id="yourContent" class="active">

                <div id="personal-data" class="mb-2 active">
                    <h4 class="h6 text-primary">2.3 Prohibited Activities:</h4>
                </div>
                <p>When using our website, app and services, you agree not to engage
                    in any
                    of the following prohibited activities:</p>
                <ul class="text-secondary">
                    <li class="pb-2">Violating any applicable law or regulation
                    </li>
                    <li class="pb-2">Infringing upon the intellectual property
                        rights of others</li>
                    <li class="pb-2">Posting or transmitting unauthorized or
                        unsolicited promotional material or spam</li>
                    <li class="pb-2">Interfering with or disrupting the
                        operation of our website, app and services</li>
                    <li class="pb-2">Harassing, intimidating, or harming others
                    </li>
                    <li class="pb-2">Engaging in any fraudulent or deceptive
                        activity</li>
                </ul>
            </div>
            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">3. Intellectual Property Rights
                    </h3>
                </div>
                <p>Our website, app and services, including all content, features,
                    and
                    functionality, are owned by Sevagudi and protected by
                    intellectual property laws. You may not copy, modify,
                    distribute, sell, or lease any part of our website, app and
                    services without our prior written consent.</p>
            </div>
            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">4. Third-Party Links
                    </h3>
                </div>
                <p>Our website, app and services may contain links to third-party
                    website -
                    apps
                    or services that are not owned or controlled by Sevagudi. We
                    have no control over and assume no responsibility for the
                    content, privacy policies, or practices of any third-party
                    website, app or services. You access and use third-party
                    website, app or services at your own risk.</p>
            </div>
            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">5. Disclaimer of Warranties
                    </h3>
                </div>
                <p>Our website, app and services are provided on an "as is" and "as
                    available" basis. We make no warranties or representations,
                    whether express or implied, regarding the operation of our
                    website, app and services. We disclaim all warranties,
                    including
                    but not limited to the implied warranties of
                    merchantability, fitness for a particular purpose, and
                    non-infringement.</p>
            </div>
            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">6. Limitation of Liability
                    </h3>
                </div>
                <p>In no event shall Sevagudi or its directors, officers, employees,
                    or agents be liable for any indirect, consequential,
                    incidental, special, or punitive damages arising out of or
                    in connection with your use of our website, app and
                    services,
                    even if advised of the possibility of such damages.</p>
            </div>
            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">7. Indemnification
                    </h3>
                </div>
                <p>You agree to indemnify and hold Sevagudi and its directors,
                    officers, employees, and agents harmless from and against
                    any claims, liabilities, damages, losses, and expenses,
                    including reasonable attorneys' fees, arising out of or in
                    connection with your use of our website, app and services
                    or
                    violation of these Terms.</p>
            </div>
            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">8. Governing Law and
                        Jurisdiction
                    </h3>
                </div>
                <p>These Terms shall be governed by and construed in accordance with
                    the laws of [Jurisdiction]. Any legal action or proceeding
                    arising out of or relating to these Terms or your use of our
                    website, app and services shall be exclusively brought in
                    the
                    courts located in [Jurisdiction].</p>
            </div>
            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">9. Changes to These Terms
                    </h3>
                </div>
                <p>We reserve the right to modify or replace these Terms at any time.
                    The updated Terms will be posted on this page with the
                    revised date. By continuing to access or use our website -
                    app and
                    services after any revisions become effective, you agree to
                    be bound by the revised Terms.</p>
            </div>
            <div id="yourContent" class="active">

                <div class="mb-2">
                    <h3 class="h5 text-primary">10. Contact Us
                    </h3>
                </div>
                <p>If you have any questions or concerns about these Terms, please
                    contact us at <br>

                    Email: <strong>contact@sevagudi.com</strong> <br>
                    Phone: <strong>+91 85179 69727</strong><br>
                    Office Address: <strong>HN-53, W-20, Nr. Sahu Furniture,Bhnd RTO
                        office, Aakash Nagar,
                        Rawabhata, Birgaon, Raipur, Chattisgarh, India,
                        493221</strong>.<br><br></p>
            </div>
        </section>
    </div>
</section>