export class TblSubscriptionPlan {
    plan_id: number;
    plan_title: string;
    plan_validity_en: string;
    plan_validity_value_in_days: number;
    plan_price: number;
    plan_price_offer: number;
    features:any;
    features_en: string;
    plan_validity_status:string;
    plan_validity_hi:string;
    plan_validity_ot:string;
    payment_method:string;
    payment_amount:number;
    transaction_number:string;
    transaction_status:string;
    transaction_date:Date;
}