<!-- vertical-layout -->
<!-- <section style="overflow: hidden;"> -->

  <ngx-spinner type="ball-clip-rotate" size="default"> </ngx-spinner>

  <!-- "node_modules/ngx-spinner/animations/ball-clip-rotate.css" -->


  <ng-container *ngIf="coreConfig.layout.type === 'vertical'">
    <vertical-layout></vertical-layout>
  </ng-container>
  <!-- / vertical-layout -->

  <!-- horizontal-layout -->
  <ng-container *ngIf="coreConfig.layout.type === 'horizontal'">

    <horizontal-layout></horizontal-layout>
  </ng-container>
  <!-- / horizontal-layout -->

  <!-- theme customizer -->
  <core-sidebar name="themeCustomizer" class="customizer d-none d-md-block" [invisibleOverlay]="true"
    *ngIf="coreConfig.layout.customizer">
    <a class="customizer-toggle d-flex align-items-center justify-content-center"
      (click)="toggleSidebar('themeCustomizer')">
      <span [data-feather]="'settings'" [class]="'spinner'"></span>
    </a>
    <core-theme-customizer></core-theme-customizer>
  </core-sidebar>
<!-- </section> -->
<!-- / theme customizer -->