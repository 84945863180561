import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class MatrimontyServiceService {

  constructor(private http:HttpClient) { }

  getdata(id: any) {
    // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEwMDAzNSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJLaWxlc2ggOiB1c2VyX2lkICYgcGFzc3dvcmQgLSA5OTk5OTk5OTk5IiwianRpIjoiYzQ2ZTI1ZjUtNWIyNi00Nzc5LWFhOGItNjc5MDQ3NWU5Y2ZkIiwiZ2VuZGVyX2lkIjoiMiIsImNhc3RlX2lkIjoiMTYwMSIsImRlZmF1bHRfbGFuZ3VhZ2VfaWQiOiIxIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiVHJpYWwgTWVtYmVyIiwiZXhwIjoxNjkyNzEwMDMwLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjU5OTIxIiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo0MjAwIn0.D9LcFaTGVHrU24qYKLByw5zfTIvwGDLiqWcyWq4c1z0
    // `http://billingmanager.shivaminfosoft.in/api/restaurant/restaurant_get?page_size=8&current_page=1`
    return this.http.get<any>(`http://mmapi.shivaminfosoft.in/api/front_end_home/home_page_data_get?default_language_id=${id}`);
    // return this.http.get(`http://billingmanager.shivaminfosoft.in/api/restaurant/restaurant_get_by_id?restaurant_id=${id}`);

  }  //api/front_end_home/home_page_data_get
  get_bog_data(id:any) {
    // debugger
 

    // return this.http.get<any>(`http://mmapi.shivaminfosoft.in/api/blogs/get_blogs`);
    return this.http.get<any>(`http://mmapi.shivaminfosoft.in/api/blogs/get_blogs?page_index=${id}`);
   
  }  
  get_bog_data_byid(id:any) {
    http://mmapi.shivaminfosoft.in/api/blogs/get_blogs_by_id?blog_id=1
    return this.http.get<any>(`http://mmapi.shivaminfosoft.in/api/blogs/get_blogs_by_id?blog_id=${id}`);
   
  }  
}