    <div class="card">
        <div class=" card-body" >
            <div *ngIf="bgResponseStatus === 'success'; else faild">
                <div class="row" style=" display:row;text-align: center" >
                    <div class="col-12">
                        <svg [style.fill]="'green'" style="width: 50px; height:80px">
                            <use xlink:href="#ic_Check" />
                        </svg>
                </div>
            </div>
                <div class="row justify-content-center mb-4" style="display:row;text-align: center" >
                                      <h4 class="card-title">Payment is successfully done </h4>
                </div>
            </div>
            <ng-template #faild>
                <div class="row" style=" display:row;text-align: center" >
                    <div class="col-12">
                        <svg style="width: 50px; height:80px">
                            <use xlink:href="#ic_cancel" />
                        </svg>
                </div>
                </div>
                <div class="row justify-content-center mb-4" style="display:row;text-align: center">
                    <h4 class="card-title">Payment is Failed </h4>
                </div>
            </ng-template>
           
            
            <div class="profile-polls-info">
                <div class="d-flex justify-content-between">
                    <span class="custom-control-label-viewers"
                        for="bestActorPollRDJ">{{'MENU.PG_RESPONSE.PLAN_TITLE' | translate}}</span>
                    <span class="text-right f-f-arial">{{bgResponseData.plan_title}}</span>
                </div>
            </div>
            <div class="profile-polls-info">
                <div class="d-flex justify-content-between">
                    <span class="custom-control-label-viewers"
                        for="bestActorPollRDJ">{{'MENU.PG_RESPONSE.PLAN_PRICE_OFFER' | translate}}</span>
                    <span class="text-right f-f-arial">&#8377;{{bgResponseData.plan_price_offer}}</span>
                </div>
            </div>
            <div class="profile-polls-info">
                <div class="d-flex justify-content-between">
                    <span class="custom-control-label-viewers"
                        for="bestActorPollRDJ">{{'MENU.PG_RESPONSE.PLAN_VALIDITY_EN' | translate}}</span>
                    <span class="text-right f-f-arial">{{bgResponseData.plan_validity_en}}</span>
                </div>
            </div>
            <div class="profile-polls-info">
                <div class="d-flex justify-content-between">
                    <span class="custom-control-label-viewers"
                        for="bestActorPollRDJ">{{'MENU.PG_RESPONSE.PAYMENT_METHOD' | translate}}</span>
                    <span class="text-right f-f-arial">{{bgResponseData.payment_method}}</span>
                </div>
            </div>
            <div class="profile-polls-info">
                <div class="d-flex justify-content-between">
                    <span class="custom-control-label-viewers"
                        for="bestActorPollRDJ">{{'MENU.PG_RESPONSE.TRANSACTION_STATUS' | translate}}</span>
                    <span class="text-right f-f-arial">{{bgResponseData.transaction_status}}</span>
                </div>
            </div>
            <div class="profile-polls-info">
                <div class="d-flex justify-content-between">
                    <span class="custom-control-label-viewers"
                        for="bestActorPollRDJ">{{'MENU.PG_RESPONSE.TRANSACTION_NUMBER' | translate}}</span>
                    <span class="text-right f-f-arial">{{bgResponseData.transaction_number}}</span>
                </div>
            </div>
            <div class="profile-polls-info">
                <div class="d-flex justify-content-between">
                    <span class="custom-control-label-viewers"
                        for="bestActorPollRDJ">{{'MENU.PG_RESPONSE.TRANSACTION_DATE' | translate}}</span>
                    <span class="text-right f-f-arial">{{bgResponseData.transaction_date | date:base.transection_date_formate}}</span>
                </div>
            </div>
            <div class="mt-4 w-100" style="display: flex">
                <button rippleeffect="" type="submit" (click)="print()" 
                class="btn btn-block btn-outline-success waves-effect "
                >{{'MENU.PG_RESPONSE.PRINT' | translate}}</button>
                <button rippleeffect="" type="submit" (click)="retrySubmission()" *ngIf="bgResponseStatus == 'faild'"
                class="btn btn-block btn-outline-primary waves-effect" style="margin-top: unset"
                >{{'MENU.PG_RESPONSE.RETRY' | translate}}</button>
            </div>
        </div>
    </div>
    

<svg display="none">
    <symbol width="50px" height="50px" id="ic_Check" version="1.1" xmlns="http://www.w3.org/2000/svg" 
    shape-rendering="geometricPrecision" text-rendering="geometricPrecision" preserveAspectRatio="xMidYMin meet"
        image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512">
        <path d="M256 0c70.68 0 134.69 28.66 181.02 74.98C483.34 121.3 512 185.31 512 256c0 70.68-28.66 134.69-74.98 181.02C390.69 483.34 326.68 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.69 0 326.68 0 256c0-70.69 28.66-134.7 74.98-181.02C121.3 28.66 185.31 0 256 0zm-91.6 236.61c18.39 10.59 30.36 19.4 44.61 35.11 36.96-59.51 77.08-92.47 129.26-139.26l5.1-1.97h57.09c-76.55 85.02-135.9 155.12-189.04 257.63-27.67-59.16-52.33-99.99-107.51-137.85l60.49-13.66z" />
    </symbol>
    <!-- <symbol xmlns="http://www.w3.org/2000/svg" id="ic_cancel" xmlns:xlink="http://www.w3.org/1999/xlink" width="50px" height="50px" version="1.1" id="Layer_1" x="0px"
        y="0px" width="122.881px" height="122.88px" viewBox="0 0 122.881 122.88" enable-background="new 0 0 122.881 122.88"
        xml:space="preserve">
        <g>
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M61.44,0c33.933,0,61.441,27.507,61.441,61.439 c0,33.933-27.508,61.44-61.441,61.44C27.508,122.88,0,95.372,0,61.439C0,27.507,27.508,0,61.44,0L61.44,0z M81.719,36.226 c1.363-1.363,3.572-1.363,4.936,0c1.363,1.363,1.363,3.573,0,4.936L66.375,61.439l20.279,20.278c1.363,1.363,1.363,3.573,0,4.937 c-1.363,1.362-3.572,1.362-4.936,0L61.44,66.376L41.162,86.654c-1.362,1.362-3.573,1.362-4.936,0c-1.363-1.363-1.363-3.573,0-4.937 l20.278-20.278L36.226,41.162c-1.363-1.363-1.363-3.573,0-4.936c1.363-1.363,3.573-1.363,4.936,0L61.44,56.504L81.719,36.226 L81.719,36.226z" />
        </g>
    </symbol> -->

    <symbol xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50px" height="50px" id="ic_cancel" viewBox="0 0 256 256" xml:space="preserve">

        <defs>
        </defs>
        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
            <path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
            <path d="M 28.5 65.5 c -1.024 0 -2.047 -0.391 -2.829 -1.172 c -1.562 -1.562 -1.562 -4.095 0 -5.656 l 33 -33 c 1.561 -1.562 4.096 -1.562 5.656 0 c 1.563 1.563 1.563 4.095 0 5.657 l -33 33 C 30.547 65.109 29.524 65.5 28.5 65.5 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
            <path d="M 61.5 65.5 c -1.023 0 -2.048 -0.391 -2.828 -1.172 l -33 -33 c -1.562 -1.563 -1.562 -4.095 0 -5.657 c 1.563 -1.562 4.095 -1.562 5.657 0 l 33 33 c 1.563 1.562 1.563 4.095 0 5.656 C 63.548 65.109 62.523 65.5 61.5 65.5 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
        </g>
        </symbol>
  </svg>