export const locale = {
  lang: 'en',
  data: {
    MENU: {
      LOGIN: {
        TITLE: 'Welcome to Bihav Matrimony 👋',
        SUB_TITLE: 'Please sign-in to your account and start the adventure',
        MOBILE_OR_EMAIL: 'Mobile number/Email',
        MOBILE: 'Mobile number',
        mobile_number_placeholder:'Enter Mobile number',
        otp_here_placeholder: 'Enter your OTP here',
        get_otp:'Get OTP',
        resend_otp:' Resend OTP',
        mobile_number_required:' Mobile number is required',
        valid_mobile_number: ' Enter 10 digit valid mobile number',
        otp_is_required:'OTP is required',
        enter_valid_digit_otp: 'Please enter 6 digit OTP here',
        
        MOBILE_EMAIL_PLACEHOLDER: 'Enter Mobile number/Email',
        MOBILE_REQUIRE: 'Mobile number/Email is required',
        VALID_MOBILE_REQUIRE: 'Enter a valid mobile number',
        VALID_EMAIL_REQUIRE: 'Enter a valid email',
        PASSWORD: 'Password',
        PASSWORD_PLACEHOLDER: '············',
        FORGOT_PASSWORD: 'Forgot password',
        PASSWORD_REQUIRED: 'Password is required',
        REMEMBER_ME: 'Remember me',
        SIGN_IN: 'Sign in',
        REGISTER_NEW_USER: 'New on our platform?',
        CREATE_AN_ACCOUNT: 'Create an account',
        OR: 'or',
        login_with_otp:'Login With Otp'
      },
      FORGOT_PASSWORD: {
        TITLE: 'Forgot Password? 🔒',
        SUB_TITLE: "Enter your Mobile number/Email ID and we'll send you instructions to reset your password",
        EMAIL: 'Mobile number/Email ID',
        EMAIL_PLACEHOLDER: 'Enter Mobile number/Email ID',
        EMAIL_RQUIRED: 'Please Enter your Mobile number/Email ID',
        INVALID_EMAIL: 'Please input valid Mobile number/Email ID',
        SEND_OTP: 'Send OTP',
        BACK_TO_LOGIN: ' Back to login',
      },
      NEW_USER_REGISTRATION: {
        TITLE: 'Registration Form',
        NAME: "Name",
        NAME_PLACEHOLDER: 'Enter name',
        NAME_REQUIRED: 'Name is required',
        NAME_RQUIRED_MAXIMUM: 'Name is rEquired at maximum 100 characters',
        GENDER: 'Gender',
        GENDER_PLACEHOLDER: 'Select gender',
        GENDER_REQUIRED: 'Gender is required',
        CASTE: 'Caste',
        CASTE_PLACEHOLDER: 'Select Caste',
        CASTE_REQUIRED: 'Caste is required',
        MOBILE_NO: 'Mobile number',
        MOBILE_PLACEHOLDER: '+91',
        MOBILE_NO_REQUIRED: 'Mobile number is required',
        VALID_MOBILE_REQUIRED: 'Mobile number is required',
        EMAIL: 'Email',
        EMAIL_PLACEHOLDER: 'email@example.com',
        EMAIL_REQUIRED: 'Please enter the email',
        VALID_EMAIL_REQUIRED: 'Please enter the valid email',
        EMAIL_MAXIMUM: 'Enter the email at least "maximum 100" Character',
        PASSWORD: 'Password',
        PASSWORD_PLACEHOLDER: '············',
        PASSWORD_REQIURED: 'Password is reqiured',
        PASSWORD_PATTERN_REQIURED: 'Password is reqiured at least "One aphabet, 1 number and 1 special character',
        PASSWORD_MINIMUM: 'Password is reqiured at least 6 character',
        PASSWORD_MAXIMUM: 'Enter password maximum 30 character',
        ALREADY_ACCOUNT: 'Already have an account?',
        SIGN_IN_INSTEAD: 'sign in instead',
      },
      OTP_VALIDATE: {
        TITLE: 'send OTP to this',
        SUB_TITLE: "Enter One time password and complete the registration process",
        OTP: 'OTP',
        OTP_PLACEHOLDER: 'Enter One Time Password',
        OTP_RQUIRED: 'Please enter One Time Password',
        INVALID_OTP: 'Please enter a valid OTP',
        SUBMIT: 'Submit',
        BACK_TO_LOGIN: ' Back to login',
      },
      RESET_PASSWORD: {
        TITLE: 'Reset password',
        SUB_TITLE: "Set new password",
        NEW_PASSWORD: 'New Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        CONFIRM_REQIURED: 'Confirm password is required',
        CONFIRM_MUSTMATCH: 'Password and confirm password should be same',
        PASSWORD_REQIURED: 'Password is reqiured',
        PASSWORD_PATERRN: '  Password must contain at least one number, one uppercase and a lowercase letter and at least 8 characters.Password cannot contain whitespace',
        NEW_PASSWORD_PLACEHOLDER: 'Enter Password',
       CONFIRM_PLACEHOLDER: 'Re-Enter  New Password ',
        SUBMIT: 'Reset',
        BACK_TO_LOGIN: ' Back to login',
      },
      PROFILES: {
        PROFILE: 'Profile',
        EDITPROFILE: 'Edit Profile',
        edit:'Edit',
        DELETEPROFILE: 'Delete Profile',
        CHANGEPASSWORD: 'Change Password',
        PRIVACYSETTING: 'Privacy Setting',
        MEMBERSHIP: 'Membership',
        LOGOUT: 'Logout',
        PROFILES: 'Profiles',
        INTEREST: 'Interest',
        ALLSENT: 'All Sent',
        SENTPENDING: 'Sent Pending',
        SENTREJECTED: 'Sent Rejected',
        SENTACCEPTED: 'Sent Accepted',
        ALLRECEIVED: 'All Received',
        RECEIVEDPENDING: 'Received Pending',
        RECEIVEDREJECTED: 'Received Rejected',
        RECEIVEDACCEPTED: 'Received Accepted',
        SHORTLIST: 'Shortlist',
        BLOCKLIST: 'Blocklist',
        IVIEWEDPROFILE: 'I Viewed Profile',
        MYVIEWEDPROFILE: 'My Viewed Profile',
        CHAT: 'Chat',
        PHOTOGALLERY: 'Photo Gallery',
        FILTER: 'Filter',
        select_option: 'Select option',
        cities: 'Cities',
        employee: 'Employee',
        age_heigh_to_low:'Age Heigh To Low',
        age_low_to_higth:'Age Low To Heigh',
        income_heigh_to_low:'Income Heigh To  Low',
        income_low_to_heigh:'Income Low To Heigh',
        viewers_low_to_heigh:'Viewers Low To Heigh',
        viewers_heigh_to_low:'Viewers Heigh To Low',
                                              
        SEARCHPROFILE: 'Search profile',
        LANGUAGE: 'English',
        PROFILEDETAILS: 'Profile Details',
        BUTTONSHORTLIST: 'Shortlist',
        BUTTONUNSHORTLIST: 'Unshort',
        BUTTONSENTINTEREST: 'Send Interest',
        BUTTONRESENDINTEREST: 'Resend Interest',
        ButtonInterestRejected: 'Interest Rejected',
        BUTTONCANCELSENTINTEREST: 'Cancel Interest',
        BUTTONACCEPTEINTEREST: 'Interest Received',
        BUTTONACCEPTEDINTEREST: 'Interest Acccepted',
        BUTTONBLOCKLIST: 'Block',
        BUTTONUNBLOCKLIST: 'Unblock',
        BUTTONSENDMESSAGE: 'Send Message',
        BUTTONMESSAGEBOXTITLE: 'Please Write your message',
        BUTTONMESSAGEBOXPLACEHOLDER: 'Message',
        BUTTONMESSAGEBOXSEND: 'Send',
        BUTTONMESSAGEBOXCANCEL: 'Cancel',
        BUTTONGALLERY: 'Gallery',
        ABOUT: 'About',
        profile_preview: 'Profile Preview',
        DATEOFBIRTH: 'Date Of Birth',
        EDUCATIONPROFESSION: 'Education/Profession',
        EDUCATION: 'Education',
        EMPLOYEDIN: 'Employed In',
        DESIGNATION: 'Designation',
        OCCUPATION: 'Occupation',
        ANNUALINCOME: 'Annual Income',
        LOCATION: 'Location',
        contact_details:'Contact Details',
        ADDRESS: 'Address',
        COUNTRY: 'Country',
        STATE: 'State',
        CITY: 'City',
        RELIGIOUSDETAIL: 'Religious Detail',
        RELIGION: 'Religion',
        CASTE: 'Caste',
        SUBCASTE: 'Subcaste',
        GOTHRA: 'Gothra',
        HOROSCOPE: 'Horoscope',
        MANGLIK: 'Manglik',
        STAR: 'Star',
        MOONSIGN: 'Moonsign',
        ABOUTFAMILY: 'About Family',
        FAMILYTYPE: 'Family Type',
        FAMILYSTATUS: 'Family Status',
        FATHERNAME: 'Father Name',
        MOTHERNAME: 'Mother Name',
        FATHEROCCUPATION: 'Father Occupation',
        MOTHEROCCUPATION: 'Mother Occupation',
        BROTHERS: 'Brothers',
        SISTERS: 'Sisters',
        MARRIEDBROTHER: 'married Brother',
        MARRIEDSISTER: 'married Sister',
        HOBBY: 'Hobby',
        EXPECTAIONS: 'Expectaions',
        LATESTPHOTOS: 'Latest Photos',
        VIEWERS: 'Viewers',
        SHORTLISTEDPROFILES: 'Shortlisted Profiles',
        RECEIVEDINTEREST: 'Received Interest',
        VIEWEDMYPROFILE: 'Viewed My Profile',
        CLEAR: 'Clear',
        APPLY: 'Apply',
        SHORT: 'Short',
        AGEFROM: 'Age From',
        AGETO: 'Age To',
         send_interest:'Send Interest',
        interest_cancel: 'Interest cancel',
         interest_accepte_reject:'Interest Accepte/Reject',
         interest_reject:'Interest Reject',
        interest_accepted: 'Interest Accepted',
        swal_alert_reject: 'Are you sure you want to reject interest request',
        swal_alert_cancel: 'Are you sure you want to cancel interest request',
        swal_alert_accept_reject: 'Accept or reject interest request',
        swal_mobile_visibility_privacy :'Due to privacy settings, you are not able to see the contact number!',
        swal_mobile_visibility_inactive_member :'Please activate the membership plan to continue!',
        swal_mobile_visibility_expire_member :'Your membership plan has expired, please upgrade your plan to continue!',
       swal_mobile_visibility_update:'Please update the membership plan to continue!',
        height:'Height',
        weight:'Weight',
        marital_status:'Marital Status',
        skin_type:'Skin Type',
        mother_tongue:'Mother Tongue',
        body_type: 'Body Type',
        other_detail:'Other Detail',
        eating_habit:'Eating Habit',
        drinking_habit:'Drinking Habit',
        smoking_habit:'Smoking Habit',
        blood_group:'Blood Group',
        birth_place: 'Birth Place',
        mobile_no: 'Mobile Number',
        phone: 'Phone',
        residence_status: 'Residence Status',
        change_profile:'Change Profile photo',
        change_background:'Change Background photo',
        edit_profile: 'Edit Profile',
        privacy: 'Privacy',
        privacy_policy: 'Privacy Policy',
        refun_cancellation_policy: 'Refun & Cancellation Policy',
      terms_conditions: 'Terms & Conditions',
       hipping_and_delivery:'Shipping and Delivery'
      },
      DELETE_ACCOUNT: {
        TITLE: 'Delete Account',
        REASON_QESTION_ONE: '1. Why do you want to delete your account?',
        REASON_OPTION_1: 'I found my soulmate.',
        REASON_OPTION_2: 'Not useful for me.',
        REASON_OPTION_3: 'Privacy concern.',
        REASON_OPTION_4: 'Other',
        REASON_SUGGESTION: '2. Leave your suggestion or feedback.',
        REASON_SUGGESTION_PLACEHOLDER: 'suggestion or feedback.',
        REASON_RATE: '3. Please rate this app',
        DELETE_BUTTON: 'Delete'
      },
      CHANGE_PASSWORD: {
        TITLE: 'Change Password',
        OLD_PASSWORD: 'Old Password',
        OLD_PASSWORD_VALIDATION_REQUIRED: 'Password is reqiured.',
        PASSWORD_VALIDATION_REQUIRED: 'Password is reqiured.',
        ASSWORD_VALIDATION_PATTERN: 'Invalid Pattern.',
         NEW_PASSWORD: 'New Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        BUTTON_CHANGE_PASSWORD: 'Change Password',
      },
      MEMBERSHIP: {
        TITLE: 'Pricing Plans',
        SUB_TITLE: 'All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.',
        CURRENT_PLAN: 'Current Plan',
        ACTIVE_PLAN_TITLE: 'Your active plan',
        YOUR_CURRENT_PLAN: 'Your current plan',
        ACTIVE: 'Active',
        UPGRADE: 'Upgrade'
      },
      SUBSCRIPTION: {
        TITLE: 'Plan Details',
        PLAN: 'Plan',
        PRICE: 'Price',
        VALID: 'Valid',
        PLAN_METHOD_TITLE: 'Select payment method',
        CREDIT_DEBIT_CARD: 'Credit Card/Debit Card',
        CARD_NUMBER: 'Card Number',
        CARD_NUMBER_PLACEHOLDER: '1234 5678 8765 4321',
        CARD_NUMBER_REQUIRED: 'Card Number is required',
        VALID_CARD_NUMBER: 'Card Number Is Must 16 Digit',
        VALID_THROUGH_MM: 'Valid Through(MM)',
        VALID_THROUGH_PLACEHOLDER_MM: 'MM',
        MONTH_REQUIRED: 'Expiry month is required',
        VALID_MONTH_REQUIRED: 'Expiry month is must 2 digit',
        VALID_THROUGH_YY: 'Valid Through(YYYY)',
        VALID_THROUGH_PLACEHOLDER_YY: 'YYYY',
        YEAR_REQUIRED: 'Expiry year is required',
        VALID_YEAR_REQUIRED: 'Expiry year is must 4 digit',
        CARD_HOLDER_NAME: 'Card Holder Name',
        CARD_HOLDER_NAME_PLACEHOLDER: 'Enter card holder name',
        CARD_HOLDER_NAME_REQUIRED: 'Card holder name required',
        NET_BANKING: 'Net Banking',
        BANK_NAME: 'Bank Name',
        BANK_PLACEHOLDER: 'Select Bank',
        BANK_NAME_REQUIRED: 'Bank name required required',
        UPI: 'UPI',
        UPI_ID: 'UPI ID',
        UPI_ADDRESS: 'UPI Address',
        PAY: 'PAY',
      },
      PG_RESPONSE: {
        TITLE: 'Payment Detail',
        TITLE_SUCESS: 'Successful',
        TITLE_FAILD: 'Faild',
        PLAN_TITLE: 'Plan Name',
        PLAN_PRICE_OFFER: 'Amount Pay',
        PLAN_VALIDITY_EN: 'Plan Validity',
        PAYMENT_METHOD: 'Payment Method',
        TRANSACTION_STATUS: 'Transaction Status',
        TRANSACTION_NUMBER: 'Transaction Number',
        TRANSACTION_DATE: 'Transaction Date',
        PRINT: 'Print',
        RETRY: 'Retry'
      },
      PRIVACY_SETTING: {
        TITLE: 'Privacy Settings',
        QUESTION_1: 'Profile Visibility ',
        OPTION1: 'Visible To All.',
        OPTION2: 'Visible To Paid Member Only.',
        OPTION3: 'Visible To All Interest Accepted Profiles.',
        QUESTION_2: 'Contact Details.',
        OPTION2_1: 'Hide For All.',
        OPTION2_2: 'Show To All Paid Member.',
        OPTION2_3: 'Show To All Interest Accepted Profiles.',
        OPTION2_4: 'Show To All.',
        SAVE_SETTING: 'SAVE',
      },
      PHOTO_GALLERY: {
        TITLE: 'Gallery',
        UPLOAD_QUEUE: 'Upload_queue',
        DROP_ZONE_TITLE: 'DROP FILES HERE TO UPLOAD',
        QUEUE_LENGTH: 'Queue length:',
        NAME: 'Name',
        SIZE: 'Size',
        PROGRESS: 'Progress',
        STATUS: 'Status',
        ACTIONS: 'Actions',
        QUEUE_PROGRESS: 'Queue progress:',
        UPLOADALL: 'Upload all',
        REMOVEALL: 'Remove all',
      },
      PROFILE_DETAILS: {
        TITLE: 'Profile Details',
        MANDATORY: 'Mandatory.',
        PREVIOUS: 'Previous',
        NEXT: 'Next',
        SUBMIT: 'Submit',
        save:'Save',
        BASIC_DETAILS: {
          TITLE: 'Basic Details',
          FIRST_NAME: 'First Name ',
          FIRST_NAME_PLACEHOLDER: 'Enter first name ',
          FIRST_NAME_REQUIRED: 'First name is required',
          MIDDLE_NAME: 'Middle Name',
          MIDDLE_NAME_PLACEHOLDER: 'Enter middle name',
          LAST_NAME: 'Last Name',
          LAST_NAME_PLACEHOLDER: 'Enter last name ',
          LASTNAME_REQUIRED: 'Last name is required',
          HEIGHT: 'Height ',
          HEIGHT_PLACEHOLDER: 'Select height ',
          WEIGHT: 'Weight',
          WEIGHT_PLACEHOLDER: 'Select weight ',
          MOTHER_TONGUE: 'Mother Tongue',
          MOTHER_TONGUE_PLACEHOLDER: 'Select mother tongue ',
          MARITAL_STATUS: 'Marital Status',
          MARITAL_STATUS_PLACEHOLDER: 'Select marital status ',
          SKIN_TYPE: 'Skin Type',
          SKIN_TYPE_PLACEHOLDER: 'Select skin type ',
          BODY_TYPE: 'Body Type',
          BODY_TYPE_PLACEHOLDER: 'Select body type ',
          EATING_HABIT: 'Eating Habit',
          EATING_HABIT_PLACEHOLDER: 'Select eating habit ',
          DRINKING_HABIT: 'Drinking Habit',
          DRINKING_HABIT_PLACEHOLDER: 'Select drinking habit ',
          SMOKING_HABIT: 'Smoking Habit',
          SMOKING_HABIT_PLACEHOLDER: 'Select smoking habit ',
          BLOOD_GROUP: 'Blood Group',
          BLOOD_GROUP_PLACEHOLDER: 'Select blood group ',
          BIRTH_PLACE: 'Birth Place',
          BIRTH_PLACE_PLACEHOLDER: 'Enetr birth place ',
          BIRTH_TIME: 'Birth Time',
          BIRTH_TIME_PLACEHOLDER: 'Enetr birth time ',
          BIRTH_DATE: 'Birth Date',
          BIRTH_DATE_PLACEHOLDER: 'Enetr birth date ',
          ABOUT_ME: 'About Me',
          ABOUT_ME_PLACEHOLDER: 'Write about your self',
          HOBBY: 'Hobby',
          HOBBY_PLACEHOLDER: 'Write your hobbies ',
          fields_marked_with_asterisk:'Fields marked with asterisk',
          are_mendatory: 'are mendatory',
          
        },
        RELIGION_DETAILS: {
          TITLE: 'Religious Details.',
          RELIGION: 'Religion',
          RELIGION_REQUIRED: 'Religion is required',
          CASTE: 'Caste',
          CASTE_REQUIRED: 'Caste Rrequired',
          SUBCASTE: 'Subcaste',
          GOTHRA: 'Gothra',
          HOROSCOPE: 'Horoscope',
          MANGLIK: 'Manglik',
          STAR: 'Star',
          MOONSIGN: 'Moonsign',
        },
        LOCATION_DETAILS: {
          TITLE: 'Location Details',
          ADDRESS: 'Address',
          COUNTRY: 'Country',
          STATE: 'State',
          CITY: 'City ',
          MOBILE_NO: 'Mobile Number',
          PHONE: 'Phone Number',
          RESIDENCE_STATUS: 'Residence Status',
        },
        EDUCATION_PROFESSION_DETAILS: {
          TITLE: 'Education/Profession Details',
          EDUCATION: 'Education',
          EMPLOYED_IN: 'Employed in',
          DESIGNATION: 'Designation',
          OCCUPATION: 'Occupation',
          ANNUAL_INCOME: 'Annual Income',
        },
        FAMILY_DETAILS: {
          TITLE: 'Family Details',
          FAMILY_TYPE: 'Family Type',
          FAMILY_STATUS: 'Family Status',
          FATHER_NAME: 'Father Name',
          FATHER_OCCUPATION: 'Father Occupation',
          MOTHER_NAME: 'Mother Name',
          MOTHER_OCCUPATION: 'Mother Occupation',
          NO_OF_BORTHER: 'No Of Borther',
          NO_OF_SISTER: 'No Of Sister',
          MARRIED_BORTHER: 'Married Borther',
          MARRIED_SISTER: 'Married Sister',
          ABOUT_FAMILY: 'About Family',

        },
      },
      PREFERENCE_DETAILS: {
        TITLE: 'Preference',
        BASIC_PREFERENCE_DETAILS: {
          TITLE: 'Basic Preference Details',
          LOOKING_FOR: 'Looking For',
          AGE_FROM: 'Age From',
          AGE_TO: 'Age T0',
          HEIGHT_FROM: 'Height From',
          HEIGHT_TO: 'Height To',
          MOTHER_TONGUE: 'Mother Tongue',
          BODY_TYPE: 'Body Type',
          EATING_HABIT: 'Eating Habit',
          SMOKING_HABIT: 'Smoking Habit',
          DRINKING_HABIT: 'Drinking Habit',
          SKIN_TONE: 'Skin Tone',
          EXPECTATIONS: 'Expectations',
        },
        RELIGIOUS_PREFERENCE: {
          TITLE: 'Religious Preference',
          RELIGION: 'Religion',
          CASTE: 'Caste',
          MANGLIK: 'Manglik',
          STAR: 'Star',
        },
        LOCATION_PREFERENCE: {
          TITLE: 'Location Preference',
          COUNTRY: 'Country',
          STATE: 'State',
          CITY: 'City ',
          RESIDENCE_STATUS: 'Residence Status',
        },
        EDUCATION_PROFESSION_PREFERENCE: {
          TITLE: 'Education/Profession Preference',
          EDUCATION: 'Education',
          EMPLOYED_IN: 'Employed in',
          OCCUPATION: 'Occupation',
          ANNUAL_INCOME: 'Annual Income',
          DESIGNATION: 'Designation',
        },
      },
      DASHBOARD: {
        COLLAPSIBLE: 'Dashboards',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ECOMMERCE: 'eCommerce'
      },
      APPS: {
        SECTION: 'Apps & Pages',
        EMAIL: 'Email',
        CHAT: 'Chat',
        TODO: 'Todo',
        CALENDAR: 'Calendar',
        INVOICE: {
          COLLAPSIBLE: 'Invoice',
          LIST: 'List',
          PREVIEW: 'Preview',
          EDIT: 'Edit',
          ADD: 'Add'
        },
        ECOMMERCE: {
          COLLAPSIBLE: 'eCommerce',
          SHOP: 'Shop',
          DETAIL: 'Details',
          WISHLIST: 'Wish List',
          CHECKOUT: 'Checkout'
        },
        USER: {
          COLLAPSIBLE: 'User',
          LIST: 'List',
          VIEW: 'View',
          EDIT: 'Edit'
        }
      },
      PAGES: {
        SECTION: 'Pages',
        AUTH: {
          COLLAPSIBLE: 'Authentication',
          LOGIN1: 'Login V1',
          LOGIN2: 'Login V2',
          REGISTER1: 'Register V1',
          REGISTER2: 'Register V2',
          FORGOTPASSWORD1: 'Forgot Password V1',
          FORGOTPASSWORD2: 'Forgot Password V2',
          RESETPASSWORD1: 'Reset Password V1',
          RESETPASSWORD2: 'Reset Password V2'
        },
        ACCOUNTSETTINGS: 'Account Settings',
        PROFILE: 'Profile',
        FAQ: 'FAQ',
        KB: 'Knowledge Base',
        PRICING: 'Pricing',
        BLOG: {
          COLLAPSIBLE: 'Blog',
          LIST: 'List',
          DETAILS: 'Details',
          EDIT: 'Edit'
        },
        MAIL: {
          COLLAPSIBLE: 'Mail Template',
          WELCOME: 'Welcome',
          RESET: 'Reset Password',
          VERIFY: 'Verify',
          DEACTIVATE: 'Deactivate',
          INVOICE: 'Invoice',
          PROMOTIONAL: 'Promotional'
        },
        MISC: {
          COLLAPSIBLE: 'Miscellaneous',
          COMINGSOON: 'Coming Soon',
          NOTAUTH: 'Not Authorized',
          MAINTENANCE: 'Maintenance',
          ERROR: 'Error'
        }
      },
      UI: {
        SECTION: 'User Interface',
        TYPOGRAPHY: 'Typography',
        COLORS: 'Colors',
        FEATHER: 'Feather',
        CARDS: {
          COLLAPSIBLE: 'Cards',
          BADGE: 'New',
          BASIC: 'Basic',
          ADVANCE: 'Advance',
          STATISTICS: 'Statistics',
          ANALYTICS: 'Analytics',
          ACTIONS: 'Actions'
        },
        COMPONENTS: {
          COLLAPSIBLE: 'Components',
          ALERTS: 'Alerts',
          AVATAR: 'Avatar',
          BADGES: 'Badges',
          BREADCRUMBS: 'Breadcrumbs',
          BUTTONS: 'Buttons',
          CAROUSEL: 'Carousel',
          COLLAPSE: 'Collapse',
          DIVIDER: 'Divider',
          DROPDOWNS: 'Dropdowns',
          GROUP: 'List Group',
          OBJECTS: 'Media Objects',
          MODALS: 'Modals',
          COMPONENT: 'Navs',
          PAGINATION: 'Pagination',
          PBADGES: 'Pill Badges',
          PILLS: 'Pills',
          POPOVERS: 'Popovers',
          PROGRESS: 'Progress',
          RATINGS: 'Ratings',
          SPINNER: 'Spinner',
          TABS: 'Tabs',
          TIMELINE: 'Timeline',
          TOASTS: 'Toasts',
          TOOLTIPS: 'Tooltips'
        },
        EX: {
          COLLAPSIBLE: 'Extension',
          SWEET_ALERTS: 'Sweet Alerts',
          BLOCKUI: 'BlockUI',
          TOASTER: 'Toastr',
          SLIDER: 'Slider',
          DRAGDROP: 'Drag & Drop',
          TOUR: 'Tour',
          CLIPBOARD: 'Clipboard',
          MEDIAPLAYER: 'Media Player',
          CONTEXTMENU: 'Context Menu',
          SWIPER: 'Swiper',
          TREEVIEW: 'Tree View',
          I18N: 'I18n'
        },
        LAYOUTS: {
          COLLAPSIBLE: 'Page Layouts',
          COLLAPSED_MENU: 'Collapsed Menu',
          BOXED_LAYOUT: 'Boxed Layout',
          WITHOUT_MENU: 'Without Menu',
          LAYOUT_EMPTY: 'Layout Empty',
          LAYOUT_BLANK: 'Layout Blank'
        }
      },
      FT: {
        SECTION: 'Forms & Tables',
        ELEMENT: {
          COLLAPSIBLE: 'Form Elements',
          INPUT: 'Input',
          INPUTGROUPS: 'Input Groups',
          INPUTMASK: 'Input Mask',
          TEXTAREA: 'Textarea',
          CHECKBOX: 'Checkbox',
          RADIO: 'Radio',
          SWITCH: 'Switch',
          SELECT: 'Select',
          NUMBERINPUT: 'Number Input',
          FILEUPLOADER: 'File Uploader',
          QUILLEDITOR: 'Quill Editor',
          FLATPICKER: 'Flatpicker',
          DATETIMEPICKER: 'Date & Time Picker'
        },
        LAYOUTS: 'Form Layouts',
        WIZARD: 'Form Wizard',
        VALIDATION: 'Form Validations',
        REPEATER: 'Form Repeater',
        TABLE: 'Table',
        DATATABLES: 'DataTables'
      },
      CM: {
        SECTION: 'Charts & Maps',
        CHARTS: {
          COLLAPSIBLE: 'Charts',
          BADGE: '2',
          APEX: 'Apex',
          CHARTJS: 'ChartJS'
        },
        MAPS: 'Google Maps'
      },
      OTHERS: {
        SECTION: 'Others',
        LEVELS: {
          COLLAPSIBLE: 'Menu Levels',
          SECOND: 'Second Level',
          SECOND1: {
            COLLAPSIBLE: 'Second Level',
            THIRD: 'Third Level',
            THIRD1: 'Third Level'
          }
        },
        DISABLED: 'Disabled Menu',
        DOCUMENTATION: 'Documentation',
        SUPPORT: 'Raise Support'
      }
    }
    ,
    home:{
       
      "Home":"Home",
      "Couple":"Couple",
      "Story":"Story",
      "Events":"Pricing",
      "Gallery":"Gallery",
      "True":"True",
      "Love":"Love",
      "People":"People",
      "RSVP":" RSVP",
      "We_are_getting":"बिहाव",
      "Married":"परिणय सूत्र",
      "Relationship":"रिश्ते सदा से, सदा के लिए",
      "Wedding_Announcement":"Wedding Announcement",
      "Curabitur_clacus":"Curabitur ac lacus arcu. Sed vehicula varius lectus auctor viverra. Nulla vehicula nibh vel ante commodo feugiat.",
      "Romeo":"Romeo",
      "The_groom":"The groom",
      "Lorem_ipsam":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum culpa hic in maxime perferendis modi. Nihil porro inventore similique quisquam ipsam illum, beatae eius.",
      "Juliet":"Juliet",
      "The_bride":"The bride",
      "Our_Love_Story":"Our Story",
      "Video":"Video",
      "The_Wedding_Events":"The Wedding Events",
      "Read_more":"Read more",
      "Photo_Album":"Photo Album",
      "All":"All",
      "Groom_smen_Bridesmaids":"Groomsmen &amp; Bridesmaids",
      "Grooms":"Grooms",
      "Edward_Prince":"Edward Prince",
      "Brother":"Brother",
      "Darien_Hari":"Darien Hari",
      "Friend":"Friend",
      "Bridesmaids":"Bridesmaids",
      "Alice":"Alice",
     "Alice_Lues":"Alice Lues",
      "Sister":"Sister",
      "Wedding_Location":"Wedding Location",
     "Location":"Location",
      "Gmail":"Gmail",
      "Contact":"Contact",
      "Contact_us":"Contact us",
      "dont":"Don't hesitate to send us your request and our consultants will get back to you to discuss how we can help best We process requests 24/7.",
      "Name":" Name",
      "Name_is_required":"Name is required",
      "Mobile_Number":"Mobile Number",
      "Mobile_Number_is_required":"Mobile Number is required",
      "Email":"Email",
      "required_Email":"Email ID is required",
      "invalid_Email":"InVailid Email Id ",
      "Subject":"Subject",
  
      "subject_is_required":"subject is required",
  
      "Feedback":"Feedback",
  
      "Feedback_is_required":"Feedback is required",
       "Submit":"Submit",

       "Find_a_plan":"Find a plan that's right for you.",
       "subtitle":"Every plan comes with a 30-day free trial.",
       "Buy_Now":"Buy Now",
       "All_plans":"All plans include",
      "Advanced_Admin":"Advanced Admin & Controls",
      "Access_Control":"Fully Access Control",
      "Support":"24/7 Support Service",
      "Reports":"Reports With Tabular and Graphical",
      "Defaul":"33+ Default Language & Unlimited Options",
      "Default_Theme":"12 Default Them",
      "Database_Backup":"Database Backup",
      "Secure_Authentication":"Secure Authentication",
      "Generate_ID":"Generate ID Card & Certificate",
      "Maintenance":" Maintenance and support fee .",
      "Maintenance1":" 2000/year will be charged after one year.",

      "Mobile_App":"Mobile App",
      "Lorem_ipsum":"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
      "Search_by":"Search by location, community, profession & more.",
      "Verified":"Verified stamp added to profile.",
      "Profile_and":"Profile and pictures with advanced privacy settings..",
     
      "Get_connected":" Get connected with us on social networks",
      "About_Us":"About Us",
      "Dont_hesitate":"Don't hesitate to send us your request and our consultants will get back to you to discuss how we can help best We process requests 24/7.",
      "Links":"Links",
      "Login":"Login",
      "Register":"Register",
      "Download":"Download",
      
     "Our_Blog":"Our Blog",
  "Curabitur_blog":"Curabitur ac lacus arcu. Sed vehicula varius lectus auctor viverra. Nulla vehicula nibh vel ante commodo feugiat.",

  "Blog_Details":"Blog Details",
  "Comments":"Comments",
  "Leave_Your_Comment":"Leave Your Comment",
 
  "Enter_Message":"Enter Message",
  "Message_required":"Message is required",
  "Blog":"Blog"
 
      }

  }
};
