import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreConfigService } from '../../@core/services/config.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { RegistService } from '../services/regist.service';
import { model_delete_account, TblLogin } from 'app/model/tbl-login';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-delete-account',
    templateUrl: './delete-account.component.html',
    styleUrls: ['./delete-account.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeleteAccountComponent implements OnInit {
    pattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    // pass = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}';
    pass = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$){6, 100}$';
    submitted = false;
    checked = true;
    message: any
    deleteAccountData: model_delete_account;
    public sizeLGCurrentRate = 5;
    deleteProfileForm: FormGroup;
    public coreConfig: any;
    public passwordTextType: boolean;
    private _unsubscribeAll: Subject<any>;

    /**
     * @param {CoreConfigService} _coreConfigService
     */

    constructor(private _coreConfigService: CoreConfigService, private toastr: ToastrService,
        private router: Router, private registService: RegistService,
        public translate: TranslateService, private formBuilder: FormBuilder) {
        this._unsubscribeAll = new Subject();
        this.deleteProfileForm = new FormGroup({
            delete_reason: new FormControl('', [Validators.required]),
            delete_remark: new FormControl('', [Validators.maxLength(100)]),
            // app_rating: new FormControl('', [Validators.required]),
        })
    }

    get controls() {
        return this.deleteProfileForm.controls;
    }

    togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }

    ngOnInit(): void {
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    deleteProfileFormData() {
        this.submitted = true;
        this.deleteAccountData = this.deleteProfileForm.value
        this.deleteAccountData.app_rating = this.sizeLGCurrentRate
        // console.warn(this.deleteAccountData);
        if (this.deleteProfileForm.valid) {
            this.registService.delete_account(this.deleteAccountData).subscribe(res => {
                this.message = res.message
                // alert(this.message)
                this.toastr.success(res.message, 'Success!');
            });//,error => this.message = error);
        } else this.toastr.error('please enter the valid data');
    }

    getOtpIfSuccess(data) {
        this.registService.getOtp(data).subscribe(res => {
            this.router.navigate(['/otp-form']);
        },
            error => console.warn('error', error));
    };

    isInputNumber(event): boolean {
        const ch = (event.which) ? event.which : event.keyCode;
        if (ch > 31 && (ch < 48 || ch > 57)) {
            console.warn("char code restricted is :" + ch);
            return false;
        }
        return true;
    }
}


