<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <div class="card mb-0">
            <div class="card-body">
                <a href="javascript:void(0);" class="brand-logo">
                    <!-- <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" /> -->
                    <img src="{{base.admin_app_url}}/uploads/apps/logo-login.png" alt="bihav matrimony" height="40" />
                    <!-- <h2 class="brand-text text-primary ml-1">VM</h2> -->
                </a>

                <h4 class="card-title mb-1">{{'MENU.OTP_VALIDATE.TITLE' | translate}} <b>{{ registerationData.mobile }}</b> 🔒</h4>
                <p class="card-text mb-2">{{'MENU.OTP_VALIDATE.SUB_TITLE' | translate}}</p>

                <form class="auth-forgot-password-form mt-2"
                    (ngSubmit)="authForm.form.valid && otpSubmit(authForm.value)" #authForm="ngForm">
                    <div class="form-group">
                        <label for="forgot-password-email" class="form-label">{{'MENU.OTP_VALIDATE.OTP' | translate}}</label>
                        <input type="text" class="form-control" id="otp" name="otp"
                            placeholder="{{'MENU.OTP_VALIDATE.OTP_PLACEHOLDER' | translate}}" aria-describedby="otp" tabindex="1" autofocus
                            [class.error]="authForm.submitted && emailRef.invalid" [(ngModel)]="otp"
                            #emailRef="ngModel" (keypress)="otpIsInputNumber($event)" minlength="6" maxlength="6" required />
                        <span *ngIf="authForm.submitted && emailRef.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="emailRef.errors.required">{{'MENU.OTP_VALIDATE.OTP_RQUIRED' | translate}}</small>
                        </span>
                        <span *ngIf="emailRef.errors && (emailRef.touched || emailRef.dirty)" class="invalid-form">
                            <small class="form-text text-danger" [hidden]="!emailRef.errors?.minlength"> {{'MENU.OTP_VALIDATE.INVALID_OTP' | translate}}</small>
                        </span>
                    </div>
                    <button class="btn btn-primary btn-block" tabindex="2" rippleEffect>{{'MENU.OTP_VALIDATE.SUBMIT' | translate}}</button>
                </form>

                <p class="text-center mt-2">
                    <a routerLink="/"> <i data-feather="chevron-left"></i> {{'MENU.OTP_VALIDATE.BACK_TO_LOGIN' | translate}}</a>
                </p>
            </div>
        </div>
    </div>
</div>