import { Token } from '@angular/compiler';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from 'app/login/login.component';
import { TblLogin, model_login_response } from 'app/model/tbl-login';
import { model_response } from 'app/model/tbl-profile';
import { Subject } from 'rxjs';
import { base } from 'base/base';
import { takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '../../@core/services/config.service';
import { RegistService } from '../services/regist.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-login-with-otp',
  templateUrl: './login-with-otp.component.html',
  styleUrls: ['./login-with-otp.component.scss']
})
export class LoginWithOtpComponent implements OnInit {
 public otp: number;
  registerationData;
  public base: any;
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public formgroup$: FormGroup;
  submitted = false;
  user_id: any;
  loginData: TblLogin = new TblLogin();
  email: any;
  otp_for: any;

    user: model_login_response = new model_login_response();
    message!:string | undefined; 
  otpMessage!:string | undefined; 
   time: number = 0;
  resendOTP!: boolean
  getOTP: boolean = true
  display: any
  interval: any = 8
  response!: any
  ForFormExpand= false;
    customEmailErrorMessage=false;
  customOTPErrorMessage=false;
  /**
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(private _coreConfigService: CoreConfigService, private router: Router, private registService: RegistService,
    private toastr: ToastrService, private formBuilder: UntypedFormBuilder,
    public translate: TranslateService) {
    this.base = base;
    if (localStorage.getItem("ltn")) {
      this.router.navigate(['/profiles']);
    }
          this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
    
       this.formgroup$ = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.base.mobile_emailvalidator), Validators.minLength(10)]],
      otp: ['', [Validators.required, Validators.minLength(6)]]
    })
  }


  ngOnInit(): void {
      this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
  
  }

 

  get f() {
    return this.formgroup$.controls;
  }
  
  
  getOTPForLogin(timer: number) {
     try{ 
    this.submitted = true;
    if (this.formgroup$.value.email) {
      this.submitted = false;
      this.time=timer;
      this.interval = setInterval(() => {
        if (this.time != 0) {
          this.getOTP=false;
          this.time--;
          this.resendOTP=true;
        } else {
          this.resendOTP=false;
          clearInterval(this.interval)
          return
        }
        this.display = this.transform(this.time)
      }, 1000);
      this.loginData.email = this.formgroup$.value.email;
      this.loginData.otp_for = '2';
      // return
      this.registService.getOtp_forgetpassword(this.loginData).subscribe((res: any) => {
        if (res.status == 'Success') {
          this.ForFormExpand = true;
        }else{
          this.message = res.message;
          this.formgroup$.controls['email'].setErrors({incorrect:true})//this.message
          this.customEmailErrorMessage = true;
          // this.logginWithOTPForm.controls('email').updateValueAndValidity();   
        }
      },(err:any)=>{alert(err.message)});
    }
       }catch(err){alert(err.message)}
   }
  
    transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }

  otpSubmit() {
    try {
      this.submitted = true;
      if (this.formgroup$.valid) {
        var value = this.formgroup$.value;
        this.loginData = value;
        this.loginData.email = this.formgroup$.value.email;
        this.loginData.otp_for = '2';
        this.registService.validateOTP_forgetpassword(this.loginData).subscribe((res: any) => {
            if (res?.token != null) {
                    this.user.token = res.token;
                    this.user.expiration = res.expiration;
                    this.user.user_id = res.user_id;
                    localStorage.setItem("ltn", res.token)
                    localStorage.setItem("user", JSON.stringify(res))
                    localStorage.setItem("currentUser", JSON.stringify(res));
                    localStorage.setItem("config", '{"app":{"appName":"Bihav Matrimony","appTitle":"Bihav Matrimony","appLogoImage":"assets/images/logo/logo.svg"},"layout":{"skin":"default","type":"vertical","menu":{"hidden":false,"collapsed":false},"navbar":{"hidden":false,"type":"floating-nav","background":"navbar-light","customBackgroundColor":true,"backgroundColor":""},"footer":{"hidden":false,"type":"footer-static","background":"footer-light","customBackgroundColor":false,"backgroundColor":""},"enableLocalStorage":true,"customizer":false,"scrollTop":true,"buyNow":false}}')
                    // localStorage.setItem("currentUser", '{"id":1, "language":"hi", "user_id":100035, "email":"admin@demo.com","firstName":"John","lastName":"Doe","avatar":"avatar-s-11.jpg","role":"Admin","token":"fake-jwt-token.1"}')
                     // localStorage.setItem("config", '{"app":{"appName":"Bihav Matrimony","appTitle":"Bihav Matrimony","appLogoImage":"assets/images/logo/logo.svg"},"layout":{"skin":"default","type":"vertical","menu":{"hidden":false,"collapsed":true},"navbar":{"hidden":false,"type":"floating-nav","background":"navbar-light","customBackgroundColor":true,"backgroundColor":""},"footer":{"hidden":false,"type":"footer-static","background":"footer-light","customBackgroundColor":false,"backgroundColor":""},"enableLocalStorage":true,"customizer":false,"scrollTop":true,"buyNow":false}}')
                     localStorage.removeItem('token_push_notification');
                    this.router.navigateByUrl("/profiles")
                    // this.router.navigate(['profiles']);
                }else{this.toastr.error(res.message, 'Error!');}
        }, error => alert(JSON.stringify(error)));
      } else {this.toastr.error('Input details are not valid', 'Error!');}
      
    } catch (err: any) { alert(err.message) }
  }

//  loginWithOtp() {
//     // var token_push_notification = (localStorage.getItem("token_push_notification"));
//     this.submitted = true;
//     this.loginData = this.logginWithOTPForm.value;
//     this.loginData.source_app=this.base.source_app;
//     this.loginData.otP_for = '2';
 
//     // if (token_push_notification != null) {
//     //   this.loginData.token_push_notification = token_push_notification
//     // }else  this.loginData.token_push_notification = " "
//     // return
//     this.loginService.login_with_otp(this.loginData).subscribe((res: tbl_varifyied_response) => {
//       if (res.status == 'Success') {
//         localStorage.setItem("ltn", JSON.stringify(res.token))
//         localStorage.setItem("currentUser", JSON.stringify(res))
//         localStorage.removeItem('token_push_notification');
//         switch (res.role) {
//           case Role.SuperAdmin: {
//             this.router.navigate(['admin']);
//             break;
//           }
//           case Role.Admin: {
//             this.router.navigate(['admin']);
//             break;
//           }
//           // case Role.Customer: {
//           //   this.router.navigate(['Service']);

//           //   break;
//           // }
//           case 'Customer': {
//             if(localStorage.getItem("return_url") == 'Payment_Details'){
//              // this.toastr.error("Your cart is empty, please select service or product to continue");
//            this.router.navigate(['/Payment_Details']);
             
//            }else
//            this.router.navigate(['/Service']);
 
//            // this.router.navigate(['Service']);
          

//            break;
//          }
//           case Role.ServiceProvider: {
//             this.router.navigate(['service-provider']);
//             break;
//           }          
//           case Role.Cook: {
//             this.router.navigate(['cook']);
//             break;
//           }
//         }
//       } else {
//         this.otpMessage = res.message; //invalid otp message
//         this.logginWithOTPForm.controls['otp'].setErrors({incorrect:true})//this.message
//         this.customOTPErrorMessage = true;
//       }
//     }
//     );
//   }
  
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
