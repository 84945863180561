import { Component, OnInit, ViewEncapsulation,  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { model_city, model_country, model_residence, model_response, model_state, model_Step3, TblProfile, TblProfile2, TblProfile3, TblProfile4, TblProfile5 } from '../model/tbl-profile';
import { RegistService } from '../services/regist.service';
import{buffer, map, toArray} from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ProfileUpdateComponent implements OnInit {
 
  isFormDisabled: boolean;
    mySelectModel: number[];

    selectOptions = [
      {
        customId: 1,
        customText: 'Some Random Text'
      }, {
        customId: 2,
        customText: 'To Show Custom Values'
      }, {
        customId: 3,
        customText: 'With Custom Text Binding'
      }, {
        customId: 4,
        customText: 'My Validation'
      },
    ]

    tData;
    prot;
    tabOne;
    tab2;
    tab3;
    tab4;
    tab5;
    public country_en;
    // public tabOne;
    // public
    public contentHeader: object;
    multipleselection: UntypedFormGroup;
    //  basicDetailForm: FormGroup;
    // public TDNameVar;
    //public TDEmailVar;
    //public TDFirstNameVar;
    //public TDLastNameVar;
    //public twitterVar;
    //public facebookVar;
    //public googleVar;
    //public linkedinVar;
    //public landmarkVar;
    //public addressVar; 
    public first_name;
    public middle_name;
    public last_name;
    public height_id;
    public weight_id;
    public mother_tongue_id; 
    public marital_status_id;
    public skin_type_id;
    public body_type_id;
    public eating_habit_id;
    public drinking_habit_id;
    public smoking_habit_id;
    public blood_group_id;
    public birth_place;
    public birth_time;
    public birth_date;
    public about_me ;
    public hobby;

    public religion_id;
    public caste_id;
    public subcaste;
    public manglik_id;
    public horoscope_id;
    public star_id;
    public moonsign_id;
    public gothra_id;

    public address;
    public country_id;
    public state_id;
    public city_id;
    public mobile;
    public phone;
    public residence_id;

    public education_id;
    d=[];
    public employed_in_id;
    public designation_id;
    public occupation_id;
    public annual_income_id;

    public family_type_id;
    public family_status_id;
    public father_name;
    public f_occupation_id;
    public mother_Name;
    public m_occupation_id;
    public total_brothers;
    public total_sisters;
    public total_married_brother;
    public total_married_sister;
    public about_family;




    // TblProfile = profile_step_1

    //res
    //res.staus
    //.data1[
    //    profile_step_1[

    //    ],
    //mst_height[
    //height_en
    //    height_id

    //]
    //]
    //.message

    //data2 = data1;

    //public itemsHeight = [
    //    { height_en: '5 ft', height_id: 1 },
    //    { height_en: '6 ft', height_id: 2 },
    //    { height_en: '7 ft', height_id: 3 },
    //    { height_en: '8 ft', height_id: 4 }
    //];

    //public itemsWeight = [
    //    { name: '50kg' },
    //    { name: '60kg' },
    //    { name: '70kg' },
    //    { name: '80kg' }
    //];
  

    profile_step_1;
    mst_height;
    ProfileTabOneHeightData;
    ProfileTabOneWeightData;
    ProfileTabOneMotherToungData;
    ProfileTabOneMaritalStatusData;
    ProfileTabOneSkinTypeData
    ProfileTabOneBodyTypeData;
    ProfileTabOneEatingHabitData;
    ProfileTabOneDrinkingHabitData;
    ProfileTabOneSmokingHabitData;
    ProfileTabOneBloodGroupData;

    ProfileTab2religiousData;
    ProfileTab2casteData;
    ProfileTab2manglikData;
    ProfileTab2gothraData;
    ProfileTab2horoscopeData;
    ProfileTab2starData;
    ProfileTab2moonsignData;

   // ProfileTab3CountriesData;
    // ProfileTab3StatesData;
    //ProfileTab3CitiesData;
    //ProfileTab3Residence_statusData;

    ProfileTab4PreFilLEducationData;
    ProfileTab4EducationData;
    ProfileTab4Employed_inData;
    ProfileTab4DesignationData;
    ProfileTab4OccupationData;
    ProfileTab4Annual_incomeData;

    ProfileTab5Family_typeData;
    ProfileTab5Family_statusData;
    ProfileTab5OccupationData;
    ProfileTab5M_occupationData;


    //get firstName() {
    //    return this.basicDetailForm.get("firstName");
    //}
    //get middleName() {
    //    return this.basicDetailForm.get("middleName");
    //}
    //get lastName() {
    //    return this.basicDetailForm.get("lastName");
    //}
    //get height() {
    //    return this.basicDetailForm.get("height");
    //}
    //get weight() {
    //    return this.basicDetailForm.get("weight");
    //}
    //get motherTongue() {
    //    return this.basicDetailForm.get("motherTongue");
    //}
    //get maritalStatus() {
    //    return this.basicDetailForm.get("maritalStatus");
    //}
    //get skinTone() {
    //    return this.basicDetailForm.get("skinTone");
    //}
    //get bodyType() {
    //    return this.basicDetailForm.get("bodyType");
    //}
    //get eatingHabit() {
    //    return this.basicDetailForm.get("drinkingHabit");
    //}
    //get drinkingHabit() {
    //    return this.basicDetailForm.get("drinkingHabit");
    //}
    //get smockingHabit() {
    //    return this.basicDetailForm.get("smockingHabit");
    //}
    //get bloodGroup() {
    //    return this.basicDetailForm.get("bloodGroup");
    //}
    //get birthPlace() {
    //    return this.basicDetailForm.get("birthPlace");
    //}
    //get birthtime() {
    //    return this.basicDetailForm.get("birthtime");
    //}
    //get OtherKnownLanguage() {
    //    return this.basicDetailForm.get("OtherKnownLanguage");
    //}
    //get aboutMe() {
    //    return this.basicDetailForm.get("aboutMe");
    //}
    //get hobby() {
    //    return this.basicDetailForm.get("hobby");
    //}
    //get religion() {
    //    return this.basicDetailForm.get("religion");
    //}
    //get caste() {
    //    return this.basicDetailForm.get("caste");
    //}
    //get subcaste() {
    //    return this.basicDetailForm.get("subcaste");
    //}
    //get manglik() {
    //    return this.basicDetailForm.get("manglik");
    //}
    //get horoscope() {
    //    return this.basicDetailForm.get("horoscope");
    //}
    //get star() {
    //    return this.basicDetailForm.get("star");
    //}
    //get moonsign() {
    //    return this.basicDetailForm.get("moonsign");
    //}
    //get gothra() {
    //    return this.basicDetailForm.get("gothra");
    //}
    //get address() {
    //    return this.basicDetailForm.get("address");
    //}
    //get country() {
    //    return this.basicDetailForm.get("country");
    //}
    //get state() {
    //    return this.basicDetailForm.get("state");
    //}
    //get city() {
    //    return this.basicDetailForm.get("city");
    //}
    //get mobileNo() {
    //    return this.basicDetailForm.get("mobileNo");
    //}
    //get phone() {
    //    return this.basicDetailForm.get("phone");
    //}
    //get timeToCall() {
    //    return this.basicDetailForm.get("timeToCall");
    //}
    //get residenceStatus() {
    //    return this.basicDetailForm.get("residenceStatus");
    //}
    //get eduaction() {
    //    return this.basicDetailForm.get("education");
    //}
    //get employedIn() {
    //    return this.basicDetailForm.get("employedIn");
    //}
    //get designation() {
    //    return this.basicDetailForm.get("designation");
    //}
    //get occupation() {
    //    return this.basicDetailForm.get("occupation");
    //}
    //get income() {
    //    return this.basicDetailForm.get("income");
    //}
    //get familyType() {
    //    return this.basicDetailForm.get("familyType");
    //}
    //get familyStatus() {
    //    return this.basicDetailForm.get("familyStatus");
    //}
    //get fatherName() {
    //    return this.basicDetailForm.get("fatherName");
    //}
    //get fatherOccupation() {
    //    return this.basicDetailForm.get("fatherOccupation");
    //}
    //get motherName() {
    //    return this.basicDetailForm.get("montherName");
    //}
    //get motherOccupation() {
    //    return this.basicDetailForm.get("motherOccupation");
    //}
    //get brothers() {
    //    return this.basicDetailForm.get("brothers");
    //}
    //get sisters() {
    //    return this.basicDetailForm.get("sisters");
    //}
    //get marriedBrother() {
    //    return this.basicDetailForm.get("marriedBrother");
    //}
    //get marriedSister() {
    //    return this.basicDetailForm.get("marriedSister");
    //}
    //get aboutFamily() {
    //    return this.basicDetailForm.get("aboutFamily");
    //}



    //public selectBasic = [
    //    { name: 'UK' },
    //    { name: 'USA' },
    //    { name: 'Spain' },
    //    { name: 'France' },
    //    { name: 'Italy' },
    //    { name: 'Australia' }
    //];

    //public selectMulti = [{ name: 'English' }, { name: 'French' }, { name: 'Spanish' }];
    //public selectMultiSelected;

    // private
    //private horizontalWizardStepper: Stepper;
    //private verticalWizardStepper: Stepper;
    //private modernWizardStepper: Stepper;

    private modernVerticalWizardStepper: Stepper;
    private bsStepper;
    modernWizardStepper: any;
    horizontalWizardStepper: Stepper;
    verticalWizardStepper: Stepper;

    dropDownList;
    getOne;
    weight;

    /**
     * Horizontal Wizard Stepper Next
     *
      @param data
     **/
    //horizontalWizardStepperNext(data) {
    //    if (data.form.valid === true) {
    //        this.horizontalWizardStepper.next();
    //    }
    //}
    /**
     * Horizontal Wizard Stepper Previous
     */
    //horizontalWizardStepperPrevious() {
    //    this.horizontalWizardStepper.previous();
    //}

    /**
     * Vertical Wizard Stepper Next
     */
    //verticalWizardNext() {
    //    this.verticalWizardStepper.next();
    //}
    /**
     * Vertical Wizard Stepper Previous
     */
    //verticalWizardPrevious() {
    //    this.verticalWizardStepper.previous();
    //}
    /**
     * Modern Horizontal Wizard Stepper Next
     */
    //modernHorizontalNext() {
    //    this.modernWizardStepper.next();
    //}
    /**
     * Modern Horizontal Wizard Stepper Previous
     */
    //modernHorizontalPrevious() {
    //    this.modernWizardStepper.previous();
    //}
    /**
     * Modern Vertical Wizard Stepper Next
     */
    /**
     * Horizontal Wizard Stepper Next
     *
      @param dataPro
     **/
    modernVerticalNext() {
        this.modernVerticalWizardStepper.next();
    }
    modernVerticalNextp(dataPro) {

        if (dataPro.form.valid === true) {
            this.modernVerticalWizardStepper.next();

        }
    }
    /**
     * Modern Vertical Wizard Stepper Previous
     */
    modernVerticalPrevious() {
        this.modernVerticalWizardStepper.previous();
    }

    /**
     * On Submit
     */
    onSubmit() {
        return false;
    }

    constructor(private formBuilder: UntypedFormBuilder, private registService: RegistService) { }


    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On Init
     */
    ngOnInit() {
      //  this.mySelectModel = [1,2];

        
       // this.testGetnewstep4();

        // this.mySelectModel = [this.education_id];

        //this.basicDetailForm = new FormGroup({
        //    first_name: new FormControl(this.profile_step_1[0].profile_first_name),
        //    middle_name: new FormControl(this.profile_step_1[0].profile_middle_name),
        //    last_name: new FormControl(this.profile_step_1[0].profile_last_name),
        //    height_id: new FormControl(''),
        //    weight_id: new FormControl(''),
        //    mother_tongue_id: new FormControl(''),
        //    marital_status_id: new FormControl(''),
        //    skin_type_id: new FormControl(''),
        //    body_type_id: new FormControl(''),
        //    eating_habit_id: new FormControl(''),
        //    drinking_habit_id: new FormControl(''),
        //    smoking_habit_id: new FormControl(''),
        //    blood_group_id: new FormControl(''),
        //    birth_place: new FormControl(''),
        //    birth_time: new FormControl(''),
        //    other_known_language: new FormControl(''),
        //    about_me: new FormControl(''),
        //    // hobby: new FormControl('', [Validators.required]),
        //    // religion: new FormControl('', [Validators.required]),
        //    // caste: new FormControl('', [Validators.required]),
        //    //    subcaste: new FormControl('', [Validators.required]),
        //    //    manglik: new FormControl('', [Validators.required]),
        //    //    horoscope: new FormControl('', [Validators.required]),
        //    //    star: new FormControl('', [Validators.required]),
        //    //    moonsign: new FormControl('', [Validators.required]),
        //    //    gothra: new FormControl('', [Validators.required]),
        //    //    address: new FormControl('', [Validators.required]),
        //    //    country: new FormControl('', [Validators.required]),
        //    //    state: new FormControl('', [Validators.required]),
        //    //    city: new FormControl('', [Validators.required]),
        //    //    mobileNo: new FormControl('', [Validators.required]),
        //    //    phone: new FormControl('', [Validators.required]),
        //    //    timeToCall: new FormControl('', [Validators.required]),
        //    //    residenceStatus: new FormControl('', [Validators.required]),
        //    //    education: new FormControl('', [Validators.required]),
        //    //    employedIn: new FormControl('', [Validators.required]),
        //    //    designation: new FormControl('', [Validators.required]),
        //    //    occupation: new FormControl('', [Validators.required]),
        //    //    income: new FormControl('', [Validators.required]),
        //    //    familyType: new FormControl('', [Validators.required]),
        //    //    familyStatus: new FormControl('', [Validators.required]),
        //    //    fatherName: new FormControl('', [Validators.required]),
        //    //    fatherOccupation: new FormControl('', [Validators.required]),
        //    //    motherName: new FormControl('', [Validators.required]),
        //    //    motherOccupation: new FormControl('', [Validators.required]),
        //    //    brothers: new FormControl('', [Validators.required]),
        //    //    sisters: new FormControl('', [Validators.required]),
        //    //    mariedBrother: new FormControl('', [Validators.required]),
        //    //    marriedSister: new FormControl('', [Validators.required]),
        //    //    aboutFamily: new FormControl('', [Validators.required]),
        //})

        this.dropDownList = this.getData();
        this.weight = this.getWeight();
        this.initForm();
        this.getProfileOneData();
        this.getProfileTabTwoData();
        this.getProfileTabThreeData();
        this.getProfileTabFourData();
        // this.getTest();
        this.getProfileTabFiveData();
        this.onSelectEducation(event);
        this.getProfileDAtaById();


        //this.getProfileTabFiveData();


        this.getmulti();
        // this.profileOneForm();


        //this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});

        //this.verticalWizardStepper = new Stepper(document.querySelector('#stepper2'), {
        //    linear: false,
        //    animation: true
        //});

        //this.modernWizardStepper = new Stepper(document.querySelector('#stepper3'), {
        //    linear: false,
        //    animation: true
        //});

        this.modernVerticalWizardStepper = new Stepper(document.querySelector('#stepper4'), {
            linear: false,
            animation: true
        });

        this.bsStepper = document.querySelectorAll('.bs-stepper');

        // content header
        this.contentHeader = {
            headerTitle: 'Form Wizard',
            actionButton: true,
            breadcrumb: {
                type: '',
                links: [
                    {
                        name: 'Home',
                        isLink: true,
                        link: '/'
                    },
                    {
                        name: 'Forms',
                        isLink: true,
                        link: '/'
                    },
                    {
                        name: 'Form Wizard',
                        isLink: false
                    }
                ]
            }
        };
    }
    onSelectEducation(event) {

        console.warn(this.education_id);
    }
    //Tab1
    onItemSelect(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectHeight(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectWeight(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectMotherTongue(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectMaritalStatus(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectSkinType(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectBodyType(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectEatingHabit(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectDrinkingHabit(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectSmokingHabit(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectBloodGroup(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }


    //Tab2

    onSelectReligion(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectCaste(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectManglik(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectHoroscope(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectStar(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectMoonsign(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }

    onSelectGothra(event) {
        console.warn('$event is:', event);
        //let data = JSON.stringify(event);
        //this.data1 = data;
    }




    getData(): Array<any> {

        return [
            { id: 1, name: 'BE' },
            { id: 2, name: 'B Tech' },
            { id: 3, name: 'M Tech' },
            { id: 4, name: 'MBA' }
        ];
    }
    getWeight() {



    }
    submitMultivalue(data) {
        console.warn('data is:', data);
        //  console.log(this.blood_group_id)
        let one = JSON.stringify(data);
        this.getOne =  console.warn(one);
        this.multipleselection.reset();
    }

    //profileOneForm() {

    //    this.basicDetailForm = this.formBuilder.group({
    //        profile_first_name: ['']
    //    })

    //}



    initForm() {
        this.multipleselection = this.formBuilder.group({
            course: ['', [Validators.required]],
            profileFirstName: ['', [Validators.required]]
        })
    }

    getmulti() {
        this.registService.getProfile().subscribe(response => {
            // let value = response;
            // this.multipleselection.controls['profile_first_name'].setValue(this.tabOne.data.profileData.profile_step_1.profile_first_name);
            // this.employeeForm.controls['LastName'].setValue(employee.LastName);

        })
    }

    getProfileDAtaById() {
        this.registService.getProfileByUserId().subscribe(response => {
            console.warn(response);
            this.tData=response;
            if(this.tData.data.profileData[0]){
            this.first_name=this.tData.data.profileData[0].first_name
            this.middle_name=this.tData.data.profileData[0].middle_name
            this.last_name=this.tData.data.profileData[0].last_name
            this.height_id=this.tData.data.profileData[0].height_id
            this.weight_id=this.tData.data.profileData[0].weight_id
            this.mother_tongue_id=this.tData.data.profileData[0].mother_tongue_id
            this.marital_status_id=this.tData.data.profileData[0].marital_status_id
            this.skin_type_id=this.tData.data.profileData[0].skin_type_id
            this.body_type_id=this.tData.data.profileData[0].body_type_id
            this.eating_habit_id=this.tData.data.profileData[0].eating_habit_id
            this.drinking_habit_id=this.tData.data.profileData[0].drinking_habit_id
            this.smoking_habit_id=this.tData.data.profileData[0].smoking_habit_id
            this.blood_group_id=this.tData.data.profileData[0].blood_group_id
            this.about_me=this.tData.data.profileData[0].about_me
            this.hobby=this.tData.data.profileData[0].hobby
            this.birth_place=this.tData.data.profileData[0].birth_place
            this.birth_date=this.tData.data.profileData[0].birth_date
            this.birth_time=this.tData.data.profileData[0].birth_time
            }
        })
    }

/* start dropdown binding */
    getProfileOneData() {
         //debugger
        this.registService.getProfile().subscribe(res => {
            //  console.warn(res);
            this.tabOne = res;

            // if (res) {
            //     let tempAD: any = [];
            //     this.profile_step_1 = this.tabOne.data.profileData;
            //     for (let tem of this.profile_step_1) {
            //         this.registService.getProfileBy(tem.profile_first_name).subscribe(res => {
            //             tempAD.push(res.list[0])
            //         })
            //     }
            //     //let tempAD: any = [];

            //     //for (let temp of this.profile_step_1) {
            //     //    this.registService.getProfile().subscribe(Response: any => {
            //     //        tempAD.push(Response.profileData[0])
            // }
            // //)
            //   //  }
            //// }

            this.profile_step_1 = this.tabOne.data.profileData[0];
            // console.warn(this.profile_step_1.profile_first_name);

            //console.warn(this.profile_step_1);
           

            this.ProfileTabOneHeightData = this.tabOne.data.heightData

            this.ProfileTabOneWeightData = this.tabOne.data.weightData;
            // console.warn(this.ProfileTabOneWeightData);

            this.ProfileTabOneMotherToungData = this.tabOne.data.languageData;

            this.ProfileTabOneMaritalStatusData = this.tabOne.data.martialStatusData;

            this.ProfileTabOneSkinTypeData = this.tabOne.data.skinTypeData;

            this.ProfileTabOneBodyTypeData = this.tabOne.data.bodyTypeData;

            this.ProfileTabOneEatingHabitData = this.tabOne.data.eatingHabitData;

            this.ProfileTabOneDrinkingHabitData = this.tabOne.data.drinkingHabitData;

            this.ProfileTabOneSmokingHabitData = this.tabOne.data.smokingHabitData;

            this.ProfileTabOneBloodGroupData = this.tabOne.data.bloodGroupData;



            //for (let PD of this.PDD) {
            //    const PDDa = PD;
            //    console.warn(PDDa.weight_hi);
            //}
            //this.mst_height = this.tabOne.data.mst_height;
            // const myJD = JSON.parse();
            // console.warn(onj.data.profile_step_1[1].first_name);
            //console.warn(this.profile_step_1[0].first_name);
            //this.prot = this.tabOne.data.profile_step_1.first_name;

        })
    }
/* End dropdown binding */



    getProfileTabTwoData() {
         // debugger
        this.registService.getProfileTab2().subscribe(res => {
           // console.warn(res);
            this.tab2 = res;

            // this.profile_step_1 = this.tabOne.data.profileData[0];
            // this.ProfileTabOneHeightData = this.tabOne.data.heightData

            //   this.ProfileTabOneWeightData = this.tabOne.data.weightData;
            //  console.warn(this.ProfileTabOneWeightData);
            
          
           /*    Start get data for bind dropdown*/

            this.ProfileTab2religiousData = this.tab2.data.religiousData;
           // console.warn(this.ProfileTab2religiousData);

            this.ProfileTab2casteData = this.tab2.data.casteData;

            this.ProfileTab2manglikData = this.tab2.data.manglikData;

            this.ProfileTab2gothraData = this.tab2.data.gothraData;

            this.ProfileTab2horoscopeData = this.tab2.data.horoscopeData;

            this.ProfileTab2starData = this.tab2.data.starData;

            this.ProfileTab2moonsignData = this.tab2.data.moonsignData;

            if(this.tab2.data.profileData[0]){
                console.warn("calling tab2 function")

            /*start prefill dropdown*/
             this.religion_id = this.tab2.data.profileData[0].religion_id;
             this.caste_id=this.tab2.data.profileData[0].caste_id
             this.subcaste=this.tab2.data.profileData[0].subcaste
             this.manglik_id=this.tab2.data.profileData[0].manglik_id
             this.gothra_id=this.tab2.data.profileData[0].gothra_id
             this.horoscope_id=this.tab2.data.profileData[0].horoscope_id
             this.star_id=this.tab2.data.profileData[0].star_id
             this.moonsign_id=this.tab2.data.profileData[0].moonsign_id
             
            /*end prefill dropdown*/

            }

        })

           /*     End get data for bind dropdown*/

    }
    model_state

modelRes = new model_response();
ProfileTab3CountriesData = new model_country();
ProfileTab3StatesData = new model_state();
ProfileTab3CitiesData = new model_city();
ProfileTab3Residence_statusData = new model_residence();
modelStep3 = new model_Step3();
    getProfileTabThreeData() {
       // debugger;
        this.registService.getProfileTab3().subscribe((res:model_response) => {
this.modelRes = res;
if(this.modelRes.status == "Success"){
    this.ProfileTab3CountriesData = this.modelRes.data.countriesData;
    this.ProfileTab3StatesData = this.modelRes.data.statesData;
    this.ProfileTab3CitiesData = this.modelRes.data.citiesData;
    this.ProfileTab3Residence_statusData = this.modelRes.data.residence_statusData;
 if(this.modelRes.data.profileData[0]){
    this.modelStep3 = this.modelRes.data.profileData[0];

 }
 //this.modelStep3.address = "Kilesh";
 console.log(this.modelStep3);
//console.log(this.modelState[0]);


}else{
alert('Internal server error occured during processing your requset. Please try again.');
}
           // console.warn(JSON.parse(JSON.stringify(res)));
            //this.tab3 = res;

           /*     End get data for bind dropdown*/

            // this.ProfileTab3CountriesData = res.data.countriesData;
            // //this.ProfileTab3StatesData = res.data.statesData;
            // this.ProfileTab3CitiesData = this.tab3.data.citiesData;
            // this.ProfileTab3Residence_statusData = this.tab3.data.residence_statusData;

           /*     End get data for bind dropdown*/


        //    if(this.tab3.data.pofileData[0]){

            /*     start prefill dropdown*/

            // this.address = this.tab3.data.profileData[0].address;
            // console.warn(this.address);
            // this.country_id = this.tab3.data.profileData[0].country_id;
            // console.warn(this.country_id);
            // this.state_id = this.tab3.data.profileData[0].state_id;
            // this.city_id = this.tab3.data.profileData[0].city_id;
            // this.mobile = this.tab3.data.profileData[0].mobile;
            // this.phone = this.tab3.data.profileData[0].phone;
            // this.residence_id = this.tab3.data.profileData[0].residence_id;
            
           /*     End prefill dropdown*/

        //    }else{
        //        alert("tab3 else")
        //    }
        })
    }

    //#region "Profile Tab 1"



    //#endregion

    // getProfileTabFourData() {
    //     // debugger
    //     this.registService.getProfileTab4().subscribe(res => {
    //       //  console.warn(res);
    //        this.tab4=res;

    //         // this.profile_step_1 = this.tabOne.data.profileData[0];
    //         //this.ProfileTabOneHeightData = this.tabOne.data.heightData

    //         // this.ProfileTabOneWeightData = this.tabOne.data.weightData;
    //         //console.warn(this.ProfileTabOneWeightData);

    //       //  this.education_id=this.tab4.data.Table.employed_in_id;
    //       //  this.employed_in_id = this.tab4.data.Table[0].employed_in_id;
    //       //  console.warn(this.employed_in_id);
    //         //this.designation_id = this.tab4.data.profileData[0].designation_id;
    //         //this.occupation_id = this.tab4.data.profileData[0].occupation_id;
    //         //this.annual_income_id = this.tab4.data.profileData[0].income_id;

    //      //   console.warn(this.education_id);
    //       //  debugger;

    //         // this.ProfileTab4EducationData = this.tab4.data.educationData;
    //    //  console.warn(this.ProfileTab4EducationData)
    //         // for(i=0;i<educationData.length;i++){
                
    //         // }
    //         // if(this.ProfileTab4EducationData.selected==true){
    //         //    this.education_id= this.ProfileTab4EducationData.education_id
    //         // }else{
    //         //     alert("nt get");
    //         // }

    //         // this.ProfileTab4Employed_inData = this.tab4.data.employed_inData;

    //         // this.ProfileTab4DesignationData = this.tab4.data.designationData;

    //         // this.ProfileTab4OccupationData = this.tab4.data.occupationData;

    //         // this.ProfileTab4Annual_incomeData = this.tab4.data.annual_incomeData;


    //     })
    // }

    // getTest(){
    //     this.registService.getEmployeeById().subscribe(res => {
    //     //    debugger
            
    //        // let test = newwarn.status;
    //        console.warn('My Data ' + res.data.Table)
    //        // console.warn('My Data ' + res['data']['Table'])
    //     })
    // }

    getProfileTabFourData(){
        //debugger;
        this.registService.getProfileTab4().subscribe((res)=>{
            console.warn("mystep 4 data is:"+res);
            let value=res.data
            let tab4=JSON.parse(value.toString())
            console.warn(tab4);
            /*Start Dropdown Bind*/

             this.ProfileTab4EducationData = tab4.Table1
             this.ProfileTab4Employed_inData = tab4.Table3
             this.ProfileTab4DesignationData =tab4.Table4
             this.ProfileTab4OccupationData = tab4.Table5
             this.ProfileTab4Annual_incomeData =tab4.Table6

            /*End Dropdown Bind*/

            /*Start pre fill the step four form*/

             if(tab4.Table){
               // alert("ok");

                var n =tab4.Table2;
                var g = JSON.parse(JSON.stringify(Object.values(n)))
                console.log(g);

               // const tag=[{"display":"1","value":"2"},{"display":"3","value":"4"}]
                var result=g.map(a=>a.education_id);
                let val:Array<string>=JSON.parse(JSON.stringify(result));
                console.log(val);
                const nuevo1 = val.map((i) => Number(i));
                console.log(nuevo1);

                this.education_id=nuevo1;
                this.employed_in_id=tab4.Table[0].employed_in_id;
                this.designation_id=tab4.Table[0].designation_id;
                this.occupation_id=tab4.Table[0].occupation_id;
                this.annual_income_id=tab4.Table[0].income_id;
             }



            /*End pre fill the step four form*/

             //let mai=xy:<any>.employed_in_id
            // r(let item of xy.Table1){
            //   this.education_id =JSON.parse(item.education_id)
            //   console.warn(this.education_id)
            // }
           // this.education_id=[601,602];
           // this.education_id=xy.Table1[1].education_id
           console.warn(this.education_id)
           //   this.ProfileTab4EducationData = tab4.Table2
             
             
              console.warn(tab4.Table);
  
//               let d = [];
  
//               let y = tab4.Table2;
//              // const mappedArr = y.map(y)
//               // console.warn(mappedArr);
//               //var arrayOfNumbers = y.map(Number);
  
//               for(var index in tab4.Table2)
//   { 
//       console.log(y[index].education_id);  // output: Apple Orange Banana
//       var boj: Array<string>=[y[index].education_id]
//   }
//   console.warn(boj)
  
  
      // console.warn(arrayOfNumbers);

            //  const persons = { 
            //     john: { age: 23, year:2010},
            //     jack: { age: 22, year:2011},
            //     jenny: { age: 21, year:2012}
            // }
            // var resultArray = Object.keys(persons).map(function(personNamedIndex){
            //     let person = persons[personNamedIndex];
            //     // do something with person
            //     return person;
            // });
            
           // console.log(resultArray)
            //console.warn(resultArray);

            
            
            //  for(let i=0 ; i<tab4.Table2.length; i++){

            //     d=tab4.Table2[i].education_id;
            //     console.warn(d.push(d))

            //          let z= Object.values(y[i]);
            //        //  this.education_id.push(z);
            //        let cv = y.map((str, index) => ({ value: str, id: index + 1 }));
            //        console.warn(this.education_id);

            //  }


            // mylist   = list_of_list.map(x=>{
            //     let obj={
            //          education_id: x[0]
            //      }  
              
            //   return obj; 
            //   })
            
           
            

            // let obj=JSON.stringify(d.value);
           //  const nuevo = d.map((i) => Number(i));

//             // const nuevo= Object.values(d.map((Number)));
// /*Stat convert object keys to array*/
//              var object = {'Apple':1,'Banana':8,'Pineapple':null};
//     //convert object keys to array
//     var k = Object.keys(object);
//     //convert object values to array
//     var v = Object.values(object);
//     console.log(v)
// /*End convert object keys to array*/
             
// // this.education_id = d.map(x=>{
// //   let obj={
// //        education_id: x[0]
// //    }  

// // return obj; 
// // })
// console.warn(k)
// console.warn(v)



        })
    }
   

    getProfileTabFiveData() {
        // debugger
        this.registService.getProfileTab5().subscribe(res => {
           // console.warn(res);
            this.tab5 = res;


            //this.profile_step_1 = this.tabOne.data.profileData[0];
            //this.ProfileTabOneHeightData = this.tabOne.data.heightData

            //this.ProfileTabOneWeightData = this.tabOne.data.weightData;
            //console.warn(this.ProfileTabOneWeightData);

            this.ProfileTab5Family_typeData = this.tab5.data.family_typeData;

            this.ProfileTab5Family_statusData = this.tab5.data.family_statusData;

            this.ProfileTab5OccupationData = this.tab5.data.occupationData;

            this.ProfileTab5M_occupationData = this.tab5.data.m_occupationData;

            if(this.tab5.data.profileData[0]){

                
            /*start pre fill dropdown  */

            this.family_type_id=this.tab5.data.profileData[0].family_type_id;
            this.family_status_id=this.tab5.data.profileData[0].family_status_id;
            this.father_name=this.tab5.data.profileData[0].father_name;
            this.f_occupation_id=this.tab5.data.profileData[0].father_occupation_id;
            this.mother_Name=this.tab5.data.profileData[0].mother_Name;
            this.m_occupation_id=this.tab5.data.profileData[0].mother_occupation_id;
            this.total_brothers=this.tab5.data.profileData[0].total_brothers;
            this.total_sisters=this.tab5.data.profileData[0].total_sisters;
            this.total_married_brother=this.tab5.data.profileData[0].total_married_brother;
            this.total_married_sister=this.tab5.data.profileData[0].total_married_sister;
            this.about_family=this.tab5.data.profileData[0].about_family;

            /*end pre fill dropdown */

            }


        })
    }

    submitE(data){
        
    }


    postProfileTabOneData(data: TblProfile) {

       // console.warn(data.height_id["height_id"]);
      //  debugger;
        //const params = new HttpParams()
        //    .set('first_name', data.first_name)
        //    .set('middle_name', data.middle_name)
        //    .set('last_name', data.last_name)
        //    .set('height_id', data.height_id[0].height_id)
        //    .set('weight_id', data.weight_id[0].weight_id)
        //    .set('mother_tongue_id', data.mother_tongue_id[0].mother_tongue_id)
        //    .set('marital_status_id', data.marital_status_id[0].marital_status_id)
        //    .set('skin_type_id', data.skin_type_id[0].skin_type_id)
        //    .set('body_type_id', data.body_type_id[0].body_type_id)
        //    .set('eating_habit_id', data.eating_habit_id[0].eating_habit_id)
        //    .set('drinking_habit_id', data.drinking_habit_id[0].drinking_habit_id)
        //    .set('smoking_habit_id', data.smoking_habit_id[0].smoking_habit_id)
        //    .set('blood_group_id', data.blood_group_id[0].blood_group_id)
        //    .set('birth_place', data.birth_place)
        //    .set('birth_date', data.birth_date)
        //    .set('birth_time', data.birth_time)
        //    .set('about_me', data.about_me)
        //    .set('hobby', data.hobby);

        var postData: TblProfile
        postData = new TblProfile();
        postData.first_name = data.first_name;
        postData.middle_name = data.middle_name;
        postData.last_name = data.last_name;
        postData.height_id = data.height_id;
        postData.weight_id = data.weight_id;
        postData.mother_tongue_id = data.mother_tongue_id;
        postData.marital_status_id = data.marital_status_id;
        postData.skin_type_id = data.skin_type_id;
        postData.body_type_id = data.body_type_id;
        postData.eating_habit_id = data.eating_habit_id;
        postData.drinking_habit_id = data.drinking_habit_id;
        postData.smoking_habit_id = data.smoking_habit_id;
        postData.blood_group_id = data.blood_group_id;
        postData.birth_place = data.birth_place;
        postData.birth_date = data.birth_date;
        postData.birth_time = data.birth_time;
        postData.about_me = data.about_me;
        postData.hobby = data.hobby;

        console.log(postData)

        this.registService.postProfileTabOne(postData).subscribe(res => {
            console.warn(res);
            
        })
    }

    putProfileTabTwoData(data: TblProfile2) {

        var putData: TblProfile2
        putData = new TblProfile2();
        putData.religion_id = data.religion_id;
        putData.caste_id = data.caste_id;
        putData.subcaste = data.subcaste;
        putData.manglik_id = data.manglik_id;
        putData.horoscope_id = data.horoscope_id;
        putData.star_id = data.star_id;
        putData.moonsign_id = data.moonsign_id;
        putData.gothra_id = data.gothra_id;


        console.log(putData)

        this.registService.putProfileTabTwo(putData).subscribe(res => {
            console.warn(res);
        })

    }

    putProfileTabThreeData(data: TblProfile3) {
        var putDataThree: TblProfile3
        putDataThree = new TblProfile3();
        putDataThree.address = data.address;
        putDataThree.country_id = data.country_id;
        putDataThree.state_id = data.state_id;
        putDataThree.city_id = data.city_id;
        putDataThree.mobile = data.mobile;
        putDataThree.phone = data.phone;
        putDataThree.residence_id = data.residence_id;


        console.log(putDataThree)

        this.registService.putProfileTabThree(putDataThree).subscribe(res => {
            console.warn(res);
        })

    }
    putProfileTabFourData(data: TblProfile4) {
        var putDataFour: TblProfile4
        putDataFour = new TblProfile4();
        // putDataFour.education_id = data.education_id["education_id"];
        //putDataFour.education_id = this.education_id;
        putDataFour.education_id = data.education_id;
        putDataFour.employed_in_id = data.employed_in_id;
        putDataFour.designation_id = data.designation_id;
        putDataFour.occupation_id = data.occupation_id;
        putDataFour.annual_income_id = data.annual_income_id;

        console.log(putDataFour)

        console.log(this.education_id)


        this.registService.putProfileTabFour(putDataFour).subscribe(res => {
            console.warn(res);
        })

    }

    putProfileTabFiveData(data) {

        var putDataFive: TblProfile5
        putDataFive = new TblProfile5();
        putDataFive.family_type_id = data.family_type_id;
        putDataFive.family_status_id = data.family_status_id;
        putDataFive.father_name = data.father_name;
        putDataFive.father_occupation_id = data.f_occupation_id;
        putDataFive.mother_Name = data.mother_Name;
        putDataFive.mother_occupation_id = data.m_occupation_id;
        putDataFive.total_brothers = data.total_brothers;
        putDataFive.total_sisters = data.total_sisters;
        putDataFive.total_married_brother = data.total_married_brother;
        putDataFive.total_married_sister = data.total_married_sister;
        putDataFive.about_family = data.about_family;

        //console.log(putDataFive)

        //  console.log(this.education_id)


        this.registService.putProfileTabFive(putDataFive).subscribe(res => {
            console.warn(res);
        })


    }

    //tk() {
        
    //    console.log(this.registService.getToken())
    //}







}
