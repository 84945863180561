import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { base } from 'base/base';
import { TblInterest } from 'app/model/tbl-interest';
import { TblShortlist } from 'app/model/tbl-shortlist';
import { BehaviorSubject } from 'rxjs';
import { ProfileFilterModel } from 'app/model/profile-filter-model';

@Injectable({
  providedIn: 'root'
})
export class InterestService {
  public onOpenedProfileChanged: BehaviorSubject<any>;
  public isEmailDetailOpen
  public onEmailDetailChanged: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.onOpenedProfileChanged = new BehaviorSubject([]);
    this.onEmailDetailChanged = new BehaviorSubject(false);
    this.isEmailDetailOpen = false;
  }

  getCommonFilterRecord() {
    var search_value: ProfileFilterModel = JSON.parse(localStorage.getItem('FilterRecord')) || new ProfileFilterModel()
    return search_value.search_value;
  }

  postInterestData(data) {
    return this.http.post(`${base.apiUrl}/interest?profile_id=${data}`, '');
  }

  accept_reject_delete_interest(data, accept_reject_status) {
    return this.http.get(`${base.apiUrl}/interest/interest_accept_reject?profile_id=${data}&accept_reject_status=${accept_reject_status}`)
  }

  getAllSentInterest(pageSize: number, currentPage: number) {
    return this.http.get(`${base.apiUrl}/interest/interest_get_all_sent?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
  }
  getSentPendingInterest(pageSize: number, currentPage: number) {
    return this.http.get(`${base.apiUrl}/interest/interest_get_sent_pending?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
  }
  getSentRejectedInterest(pageSize: number, currentPage: number) {
    return this.http.get(`${base.apiUrl}/interest/interest_get_sent_rejected?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
  }
  getSentAcceptedInterest(pageSize: number, currentPage: number) {
    return this.http.get(`${base.apiUrl}/interest/interest_get_sent_accepted?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
  }
  getReceivedPendingInterest(pageSize: number, currentPage: number) {
    return this.http.get(`${base.apiUrl}/interest/interest_get_received_pending?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
  }
  getReceivedRejectedInterest(pageSize: number, currentPage: number) {
    return this.http.get(`${base.apiUrl}/interest/interest_get_received_rejected?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
  }
  getReceivedAcceptedInterest(pageSize: number, currentPage: number) {
    return this.http.get(`${base.apiUrl}/interest/interest_get_received_accepted?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
  }
  getAllReceivedInterest(pageSize: number, currentPage: number) {
    return this.http.get(`${base.apiUrl}/interest/interest_get_all_received?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
  }

  openProfileDetails(data) {
    this.onOpenedProfileChanged.next(data);
  }
}



////////////////

// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { base } from 'base/base';
// import { TblInterest } from 'app/model/tbl-interest';
// import { TblShortlist } from 'app/model/tbl-shortlist';
// import { BehaviorSubject, Observable } from 'rxjs';
// import { ProfileFilterModel } from 'app/model/profile-filter-model';

// @Injectable({
//   providedIn: 'root'
// })
// export class InterestService {
//   public onOpenedProfileChanged: BehaviorSubject<any>;
//   public isEmailDetailOpen
//   public onEmailDetailChanged: BehaviorSubject<boolean>;

//   data: any
//   obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
//   profile_detail_data: Observable<any> = this.obsArray.asObservable();
//   public detaildata: BehaviorSubject<any>;

//   constructor(private http: HttpClient) {
//     this.onOpenedProfileChanged = new BehaviorSubject([]);
//     this.onEmailDetailChanged = new BehaviorSubject(false);
//     this.isEmailDetailOpen = false;
//     this.detaildata = new BehaviorSubject(this.data);
//   }

//   getCommonFilterRecord() {
//     var search_value: ProfileFilterModel = JSON.parse(localStorage.getItem('FilterRecord')) || new ProfileFilterModel()
//     return search_value.search_value;
//   }

//   postInterestData(data) {
//     return this.http.post(`${base.apiUrl}/interest?profile_id=${data}`,'');
//   }

//   accept_reject_delete_interest(data, accept_reject_status) {
//     return this.http.get(`${base.apiUrl}/interest/interest_accept_reject?profile_id=${data}&accept_reject_status=${accept_reject_status}`)
//   }
//   // https://api.bihav.co.in/api/interest/interest_get_all_sent?page_size=10&current_page=1

//   getAllSentInterest(pageSize: number, currentPage: number) {
//     return this.http.get(`${base.apiUrl}/interest/interest_get_all_sent?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
//   }
//   getSentPendingInterest(pageSize: number, currentPage: number, search_value: string) {
//     return this.http.get(`${base.apiUrl}/interest/interest_get_sent_pending?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
//   }
//   getSentRejectedInterest(pageSize: number, currentPage: number, search_value: string) {
//     return this.http.get(`${base.apiUrl}/interest/interest_get_sent_rejected?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
//   }
//   getSentAcceptedInterest(pageSize: number, currentPage: number, search_value: string) {
//     return this.http.get(`${base.apiUrl}/interest/interest_get_sent_accepted?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
//   }
//   getReceivedPendingInterest(pageSize: number, currentPage: number, search_value: string) {
//     return this.http.get(`${base.apiUrl}/interest/interest_get_received_pending?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
//   }
//   getReceivedRejectedInterest(pageSize: number, currentPage: number, search_value: string) {
//     return this.http.get(`${base.apiUrl}/interest/interest_get_received_rejected?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
//   }
//   getReceivedAcceptedInterest(pageSize: number, currentPage: number, search_value: string) {
//     return this.http.get(`${base.apiUrl}/interest/interest_get_received_accepted?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
//   }
//   getAllReceivedInterest(pageSize: number, currentPage: number, search_value: string) {
//     return this.http.get(`${base.apiUrl}/interest/interest_get_all_received?page_size=${pageSize}&current_page=${currentPage}&search_value=${this.getCommonFilterRecord()}`)
//   }
//   openProfileDetails(data) {
//     this.onOpenedProfileChanged.next(data);
//   }
//   profile_update(data) {
//     this.obsArray.next(data)
//   }
// }

