export const locale = {
  lang: 'hi',
  data: {
    MENU: {
      LOGIN: {
        TITLE: 'बिहाव मैट्रिमोनी में आपका स्वागत है 👋',
        SUB_TITLE: 'कृपया अपने खाते में साइन-इन करें और साहसिक कार्य शुरू करें',
        MOBILE_OR_EMAIL: 'मोबाइल नंबर/ईमेल',
        MOBILE: 'मोबाइल नंबर',
        mobile_number_placeholder: 'मोबाइल नंबर दर्ज करें',
        otp_here_placeholder: 'यहां अपना ओटीपी दर्ज करें',
        get_otp: 'ओटीपी प्राप्त करें',
        resend_otp: 'ओटीपी पुनः भेजें',
        mobile_number_required: 'मोबाइल नंबर आवश्यक है',
        valid_mobile_number: '10 अंकों का वैध मोबाइल नंबर दर्ज करें',
        otp_is_required: 'ओटीपी आवश्यक है',
        enter_valid_digit_otp: 'कृपया यहां 6 अंकों का ओटीपी दर्ज करें',
        MOBILE_EMAIL_PLACEHOLDER: 'मोबाइल नंबर/ईमेल दर्ज करें',
        MOBILE_REQUIRE: 'मोबाइल नंबर/ईमेल आवश्यक है',
        VALID_MOBILE_REQUIRE: 'एक मान्य मोबाइल नंबर दर्ज करें',
        VALID_EMAIL_REQUIRE: 'एक वैध ईमेल प्रविष्ट करें',
        PASSWORD: 'पासवर्ड',
        PASSWORD_PLACEHOLDER: '············',
        FORGOT_PASSWORD: 'पासवर्ड भूल गए',
        PASSWORD_REQUIRED: 'पासवर्ड की आवश्यकता है',
        REMEMBER_ME: 'याद रखना',
        SIGN_IN: 'साइन इन करें',
        REGISTER_NEW_USER: 'हमारे प्लैटफ़ॉर्म पर नया?',
        CREATE_AN_ACCOUNT: 'खाता बनाएं',
        OR: 'या',
        login_with_otp: 'ओटीपी से लॉगिन करें'
      },
      FORGOT_PASSWORD: {
        TITLE: 'पासवर्ड भूल गए? 🔒',
        SUB_TITLE: "अपना मोबाइल नंबर/ईमेल दर्ज करें और हम आपको अपना पासवर्ड रीसेट करने के लिए निर्देश भेजेंगे",
        EMAIL: 'मोबाइल नंबर/ईमेल',
        EMAIL_PLACEHOLDER: 'मोबाइल नंबर/ईमेल दर्ज करें',
        EMAIL_RQUIRED: 'कृपया अपना मोबाइल नंबर/ईमेल दर्ज करें',
        INVALID_EMAIL: 'कृपया मान्य मोबाइल नंबर/ईमेल दर्ज करें',
        SEND_OTP: 'ओटीपी भेजें',
        BACK_TO_LOGIN: 'लॉगिन पर वापस जाएं',
      },
      NEW_USER_REGISTRATION: {
        TITLE: 'पंजीकरण फॉर्म',
        NAME: 'नाम',
        NAME_PLACEHOLDER: 'नाम दर्ज करें',
        NAME_REQUIRED: 'नाम आवश्यक है',
        NAME_REQUIRED_MAXIMUM: 'अधिकतम 100 वर्णों में नाम आवश्यक है',
        GENDER: 'लिंग',
        GENDER_PLACEHOLDER: 'लिंग चुनें',
        GENDER_REQUIRED: 'लिंग आवश्यक है',
        CASTE: 'जाति',
        CASTE_PLACEHOLDER: 'जाति का चयन करें',
        CASTE_REQUIRED: 'जाति आवश्यक है',
        MOBILE_NO: 'मोबाइल नंबर',
        MOBILE_PLACEHOLDER: '+91',
        MOBILE_NO_REQUIRED: 'मोबाइल नंबर आवश्यक है',
        VALID_MOBILE_REQUIRED: 'कृपया मान्य मोबाइल नंबर दर्ज करें',
        EMAIL: 'ईमेल',
        EMAIL_PLACEHOLDER: 'email@example.com',
        EMAIL_REQUIRED: 'कृपया ईमेल दर्ज करें',
        VALID_EMAIL_REQUIRED: 'कृपया मान्य ईमेल दर्ज करें',
        EMAIL_MAXIMUM: 'ईमेल कम से कम "अधिकतम 100" वर्ण दर्ज करें',
        PASSWORD: 'पासवर्ड',
        PASSWORD_PLACEHOLDER: '···············',
        PASSWORD_REQIURED: 'पासवर्ड की आवश्यकता है',
        PASSWORD_PATTERN_REQIURED: 'पासवर्ड कम से कम "एक अक्षर, 1 संख्या और 1 विशेष वर्ण" दर्ज करें',
        PASSWORD_MINIMUM: 'पासवर्ड कम से कम 6 कैरेक्टर का होना आवश्यक है',
        PASSWORD_MAXIMUM: 'पासवर्ड अधिकतम 30 वर्ण दर्ज करें',
        ALREADY_ACCOUNT: 'क्या आपके पास पहले से एक खाता मौजूद है?',
        SIGN_IN_INSTEAD: 'इसके बजाय साइन इन करें',
      },
      OTP_VALIDATE: {
        TITLE: 'इस पर ओटीपी भेजें',
        SUB_TITLE: "वन टाइम पासवर्ड दर्ज करें और पंजीकरण प्रक्रिया पूरी करें",
        OTP: 'ओटीपी',
        OTP_PLACEHOLDER: 'वन टाइम पासवर्ड डालें',
        OTP_RQUIRED: 'कृपया वन टाइम पासवर्ड दर्ज करें',
        INVALID_OTP: 'कृपया मान्य ओटीपी दर्ज करें',
        SUBMIT: 'सबमिट',
        BACK_TO_LOGIN: 'लॉगिन पर वापस जाएं',
      },
      RESET_PASSWORD: {
        TITLE: 'पासवर्ड रीसेट ',
        SUB_TITLE: "नया पासवर्ड सेट करें",
        NEW_PASSWORD: 'नया पासवर्ड',
        CONFIRM_PASSWORD: 'पासवर्ड की पुष्टि कीजिये',
        CONFIRM_REQIURED: 'पुष्टि पासवर्ड आवश्यक है',
        CONFIRM_MUSTMATCH: 'पासवर्ड और कन्फर्म पासवर्ड एक ही होना चाहिए',
        PASSWORD_REQIURED: 'पासवर्ड की आवश्यकता है',
        PASSWORD_PATERRN: 'पासवर्ड में कम से कम एक नंबर, एक अपरकेस और एक लोअरकेस अक्षर और कम से कम 8 अक्षर होने चाहिए। पासवर्ड में खाली जगह नहीं होनी चाहिए',
        NEW_PASSWORD_PLACEHOLDER: 'पास वर्ड दर्ज करें',
        CONFIRM_PLACEHOLDER: 'नया पासवर्ड पुनः दर्ज करें',
        SUBMIT: 'रीसेट',
        BACK_TO_LOGIN: 'लॉगिन पर वापस जाएं',
      },
      PROFILES: {
        PROFILE: 'प्रोफाइल',
        EDITPROFILE: 'प्रोफ़ाइल संपादित करें',
         edit:'संपादन',
        DELETEPROFILE: 'प्रोफ़ाइल हटाएं',
        CHANGEPASSWORD: 'पासवर्ड बदलें',
        PRIVACYSETTING: 'गोपनीयता सेटिंग',
        MEMBERSHIP: 'सदस्यता',
        LOGOUT: 'लॉग आउट',
        PROFILES: 'प्रोफाइल्स',
        INTEREST: 'रुचि',
        ALLSENT: 'सभी प्रेषित प्रोफाइल',
        SENTPENDING: 'अपूर्ण प्रेषित',
        SENTREJECTED: 'अस्वीकृत प्रेषित',
        SENTACCEPTED: 'स्वीकार प्रेषित',
        ALLRECEIVED: 'सभी प्राप्त रूचि',
        RECEIVEDPENDING: 'अपूर्ण प्राप्त',
        RECEIVEDREJECTED: 'अस्वीकृत प्राप्त',
        RECEIVEDACCEPTED: 'प्राप्त स्वीकार',
        SHORTLIST: 'चयनबद्ध प्रोफाइल',
        BLOCKLIST: 'अवरोधित प्रोफाइल',
        IVIEWEDPROFILE: 'देखा गया',
        MYVIEWEDPROFILE: 'देखि गयी मेरी प्रोफाइल',
        CHAT: 'चैट',
        PHOTOGALLERY: 'छवि',
        FILTER: 'फ़िल्टर',
         select_option: 'विकल्प चुनें',
        cities: 'शहरों',
        employee: 'कर्मचारी',
        age_heigh_to_low:'आयु उच्च से निम्न',
        age_low_to_higth:'उम्र कम से अधिक',
        income_heigh_to_low:'आय उच्च से निम्न',
        income_low_to_heigh:'आय कम से अधिक',
        viewers_low_to_heigh:'दर्शक कम से अधिक',
        viewers_heigh_to_low: 'दर्शक उच्च से निम्न',
        
        SEARCHPROFILE: 'प्रोफाइल खोजे',
        LANGUAGE: 'अंग्रेजी',
        PROFILEDETAILS: 'प्रोफाइल विवरण',
        BUTTONSHORTLIST: 'शॉर्टलिस्ट',
        BUTTONUNSHORTLIST: 'अनशॉर्ट',
        BUTTONSENTINTEREST: 'रूचि प्रेषित करे',
        BUTTONRESENDINTEREST: 'रिसेंड इंटरेस्ट',
        ButtonInterestRejected: 'इंटरेस्ट अस्वीकार',
        BUTTONCANCELSENTINTEREST: 'रूचि नही',
        BUTTONACCEPTEINTEREST: 'एक्सेप्ट',
        BUTTONACCEPTEDINTEREST: 'एक्सेप्टेड',
        BUTTONBLOCKLIST: 'ब्लॉक',
        BUTTONUNBLOCKLIST: 'अनब्लॉक',
        BUTTONSENDMESSAGE: 'सन्देश',
        BUTTONMESSAGEBOXTITLE: 'कृपया अपना संदेश लिखें',
        BUTTONMESSAGEBOXPLACEHOLDER: 'संदेश',
        BUTTONMESSAGEBOXSEND: 'प्रेषित करे',
        BUTTONMESSAGEBOXCANCEL: 'कैंसिल',
        BUTTONGALLERY: 'गैलरी',
        ABOUT: 'मेरे बारे में',
        profile_preview: 'प्रोफ़ाइल पूर्वावलोकन',
        DATEOFBIRTH: 'जन्म',
        EDUCATIONPROFESSION: 'शिक्षा/पेशा',
        EDUCATION: 'शिक्षा',
        EMPLOYEDIN: 'कार्यरत',
        DESIGNATION: 'पद',
        OCCUPATION: 'व्यवसाय',
        ANNUALINCOME: 'वार्षिक आय',
        LOCATION: 'स्थान',
        contact_details: 'सम्पर्क करने का विवरण',
        ADDRESS: 'पता',
        COUNTRY: 'देश',
        STATE: 'राज्य',
        CITY: 'शहर',
        RELIGIOUSDETAIL: 'धार्मिक विवरण',
        RELIGION: 'धर्म',
        CASTE: 'जाति',
        SUBCASTE: 'उप जाति',
        GOTHRA: 'गोत्र',
        HOROSCOPE: 'राशिफल',
        MANGLIK: 'मांगलिक',
        STAR: 'सितारा',
        MOONSIGN: 'राशि',
        ABOUTFAMILY: 'पारिवारिक विवरण',
        FAMILYTYPE: 'परिवार का प्रकार',
        FAMILYSTATUS: 'पारिवारिक स्थिति',
        FATHERNAME: 'पिता का नाम',
        MOTHERNAME: 'माता का नाम',
        FATHEROCCUPATION: 'पिता का व्यवसाय',
        MOTHEROCCUPATION: 'माता का व्यवसाय',
        BROTHERS: 'भाई',
        SISTERS: 'बहन',
        MARRIEDBROTHER: 'विवाहित भाई',
        MARRIEDSISTER: 'विवाहित बहन',
        HOBBY: 'शौक',
        EXPECTAIONS: 'अपेक्षाएं',
        LATESTPHOTOS: 'नवीनतम फोटो',
        VIEWERS: 'दर्शक',
        SHORTLISTEDPROFILES: 'शॉर्टलिस्टेड प्रोफाइल',
        RECEIVEDINTEREST: 'रुचि प्रोफाइल',
        VIEWEDMYPROFILE: 'व्यूव्ड माय प्रोफाइल ',
        CLEAR: 'क्लियर',
        APPLY: 'अप्लाई',
        SHORT: 'शार्ट',
        AGEFROM: 'उम्र',
        AGETO: 'उम्र',
        send_interest: 'रुचि भेजें',
        interest_cancel: 'रूचि नही',
        interest_accepte_reject: 'रुचि स्वीकार/अस्वीकार',
        interest_reject: 'रुचि अस्वीकार',
        interest_accepted: 'रुचि स्वीकृत',
        swal_alert_reject: 'क्या आप वाकई रुचि अनुरोध को अस्वीकार करना चाहते हैं?',
        swal_alert_cancel: 'क्या आप वाकई अपना रुचि अनुरोध रद्द करना चाहते हैं?',
        swal_alert_accept_reject: 'रुचि अनुरोध स्वीकार या अस्वीकार करें',
        swal_mobile_visibility_privacy: 'गोपनीयता सेटिंग्स के कारण, आप संपर्क नंबर नहीं देख पा रहे हैं!',
        swal_mobile_visibility_inactive_member: 'जारी रखने के लिए कृपया सदस्यता योजना सक्रिय करें!',
        swal_mobile_visibility_expire_member: 'आपकी सदस्यता योजना समाप्त हो गई है, कृपया जारी रखने के लिए अपनी योजना सक्रिय करें!',
        swal_mobile_visibility_update: 'जारी रखने के लिए कृपया सदस्यता योजना को अपडेट करें!',
        height: 'ऊंचाई',
        weight: 'वज़न',
        marital_status: 'वैवाहिक स्थिति',
        skin_type: 'त्वचा प्रकार',
        mother_tongue: 'मातृ भाषा',
        body_type: 'शरीर के प्रकार',
        other_detail: 'अन्य विवरण',
        eating_habit: 'खाने की आदत',
        drinking_habit: 'शराब पीने की आदत',
        smoking_habit: 'धूम्रपान की आदत',
        blood_group: 'ब्लड ग्रुप',
        birth_place: 'जन्म स्थान',
        mobile_no: 'मोबाइल नंबर',
        phone: 'फ़ोन',
        residence_status: 'आवास की स्थिति',
        change_profile: 'प्रोफ़ाइल फ़ोटो बदलें',
        change_background: 'बैकग्राउंड फोटो बदलें',
        edit_profile: 'प्रोफ़ाइल संपादित करें',
        privacy_policy: 'गोपनीयता नीति',
        privacy: 'गोपनीयता',
        refun_cancellation_policy: 'धनवापसी एवं रद्दीकरण नीति',
        terms_conditions: 'नियम एवं शर्तें',
        hipping_and_delivery: 'शिपिंग और डिलीवरी'

      },
      DELETE_ACCOUNT: {
        TITLE: 'अकाउंट डिलीट करे',
        REASON_QESTION_ONE: '1. आप अपना अकाउंट क्यों हटाना चाहते हैं ?',
        REASON_OPTION_1: 'मुझे मेरा हमसफर मिल गया.',
        REASON_OPTION_2: 'मेरे लिए उपयोगी नहीं है.',
        REASON_OPTION_3: 'गोपनीयता संबंधी चिंताएं.',
        REASON_OPTION_4: 'अन्य',
        REASON_SUGGESTION: '2. अपना सुझाव या प्रतिक्रिया दें.',
        REASON_SUGGESTION_PLACEHOLDER: 'सुझाव या प्रतिक्रिया.',
        REASON_RATE: '3. कृपया इस ऐप को रेट करें',
        DELETE_BUTTON: 'डिलीट'
      },
      CHANGE_PASSWORD: {
        TITLE: 'पासवर्ड बदलें',
        OLD_PASSWORD: 'पुराना पासवर्ड',
        PASSWORD_VALIDATION_REQUIRED: 'पासवर्ड की आवश्यकता है.',
        ASSWORD_VALIDATION_PATTERN: 'अमान्य पैटर्न.',
        NEW_PASSWORD: 'नया पासवर्ड',
        CONFIRM_PASSWORD: 'पुन: पासवर्ड दर्ज करें',
        BUTTON_CHANGE_PASSWORD: 'पासवर्ड बदलें',
      },
      MEMBERSHIP: {
        TITLE: 'मूल्य निर्धारण योजनाएं',
        SUB_TITLE: 'सभी प्लान में आपके उत्पाद को बढ़ावा देने के लिए 40+ उन्नत उपकरण और सुविधाएँ शामिल हैं। अपनी जरूरतों को पूरा करने के लिए सबसे अच्छी योजना चुनें।',
        CURRENT_PLAN: 'वर्तमान प्लान',
        ACTIVE_PLAN_TITLE: 'आपका सक्रिय प्लान',
        YOUR_CURRENT_PLAN: 'आपका वर्तमान प्लान',
        ACTIVE: 'सक्रिय',
        UPGRADE: 'अपग्रेड',
      },
      SUBSCRIPTION: {
        TITLE: 'प्लान विवरण',
        PLAN: 'प्लान',
        PRICE: 'कीमत',
        VALID: 'वैध',
        PLAN_METHOD_TITLE: 'प्लान पद्धति का चयन करें',
        CREDIT_DEBIT_CARD: 'क्रेडिट कार्ड/डेबिट कार्ड',
        CARD_NUMBER: 'कार्ड संख्या',
        CARD_NUMBER_PLACEHOLDER: '1234 5678 8765 4321',
        CARD_NUMBER_REQUIRED: 'कार्ड संख्या आवश्यक है',
        VALID_CARD_NUMBER: 'कृपया सही कार्ड नंबर दर्ज करें',
        VALID_THROUGH_MM: 'वैध माहिना(MM)',
        VALID_THROUGH_PLACEHOLDER_MM: 'MM',
        MONTH_REQUIRED: 'समाप्ति माह आवश्यक है',
        VALID_MONTH_REQUIRED: 'समाप्ति माह 2 अंकों का होना चाहिए',
        VALID_THROUGH_YY: 'वैध साल(YYYY)',
        VALID_THROUGH_PLACEHOLDER_YY: 'YYYY',
        YEAR_REQUIRED: 'समाप्ति वर्ष आवश्यक है',
        VALID_YEAR_REQUIRED: 'समाप्ति माह 4 अंकों का होना चाहिए',
        CARD_HOLDER_NAME: 'कार्डधारक का नाम',
        CARD_HOLDER_NAME_PLACEHOLDER: 'कार्ड धारक का नाम दर्ज करें',
        CARD_HOLDER_NAME_REQUIRED: 'कार्ड धारक का नाम आवश्यक है',
        NET_BANKING: 'नेट बैंकिंग',
        BANK_NAME: 'बैंक का नाम',
        BANK_PLACEHOLDER: 'बैंक चुनें',
        BANK_NAME_REQUIRED: 'कृपया बैंक का चयन करे',
        UPI: 'यूपीआई',
        UPI_ID: 'यूपीआई आईडी',
        UPI_ADDRESS: 'यूपीआई पता',
        PAY: 'भुगतान करे',
      },
      PG_RESPONSE: {
        TITLE: 'Payment Detail',
        TITLE_SUCESS: 'Successful',
        TITLE_FAILD: 'Faild',
        PLAN_TITLE: 'Plan Name',
        PLAN_PRICE_OFFER: 'Amount Pay',
        PLAN_VALIDITY_EN: 'Plan Validity',
        PAYMENT_METHOD: 'Payment Method',
        TRANSACTION_STATUS: 'Transaction Status',
        TRANSACTION_NUMBER: 'Transaction Number',
        TRANSACTION_DATE: 'Transaction Date',
        PRINT: 'Print',
        RETRY: 'Retry'
      },
      PRIVACY_SETTING: {
        TITLE: 'गोपनीय सेटिंग',
        QUESTION_1: 'प्रोफ़ाइल दृश्यत',
        OPTION1: 'सभी के लिए दृश्यमान.',
        OPTION2: 'केवल सशुल्क सदस्य के लिए दृश्यमान.',
        OPTION3: 'सभी इच्छुक स्वीकृत प्रोफ़ाइलों के लिए दृश्यमान.',
        QUESTION_2: 'सम्पर्क करने का विवरण',
        OPTION2_1: 'सभी के लिए छुपाएं.',
        OPTION2_2: 'सभी सशुल्क सदस्य को दिखाएं.',
        OPTION2_3: 'सभी इच्छुक स्वीकृत प्रोफ़ाइलों को दिखाएं.',
        OPTION2_4: 'सभी को दिखाएँ.',
        SAVE_SETTING: 'सेव करे',
      },
      PHOTO_GALLERY: {
        TITLE: 'गेलरी',
        DROP_ZONE_TITLE: 'अपलोड करने के लिए फ़ाइलें यहाँ रखे ',
        UPLOAD_QUEUE: 'अपलोड कतार',
        QUEUE_LENGTH: 'कतार की लंबाई:',
        NAME: 'नाम',
        SIZE: 'आकार',
        PROGRESS: 'प्रगति',
        STATUS: 'दर्जा',
        ACTIONS: 'कार्रवाई',
        QUEUE_PROGRESS: 'कतार प्रगति:',
        UPLOADALL: 'अपलोड करें',
        REMOVEALL: 'सभी हटाएं',
      },
      PROFILE_DETAILS: {
        TITLE: 'प्रोफ़ाइल विवरण',
        MANDATORY: 'अनिवार्य.',
        PREVIOUS: 'पिछला',
        NEXT: 'आगे बढ़े',
        SUBMIT: 'सबमिट करे',
        save: 'सेव़्‌',
        BASIC_DETAILS: {
          TITLE: 'मूल विवरण',
          FIRST_NAME: 'पहला नाम',
          FIRST_NAME_PLACEHOLDER: 'पहला नाम लिखें',
          FIRST_NAME_REQUIRED: 'पहला नाम आवश्यक है',
          MIDDLE_NAME: 'मध्य नाम',
          MIDDLE_NAME_PLACEHOLDER: 'उपनाम लिखें',
          LAST_NAME: 'उपनाम',
          LAST_NAME_PLACEHOLDER: 'अंतिम नाम लिखें',
          LASTNAME_REQUIRED: 'अंतिम नाम आवश्यक है',
          HEIGHT: 'कद ',
          HEIGHT_PLACEHOLDER: 'कद का चयन करे',
          WEIGHT: 'वज़न',
          WEIGHT_PLACEHOLDER: 'वज़न का चयन करे',
          MOTHER_TONGUE: 'मातृ भाषा',
          MOTHER_TONGUE_PLACEHOLDER: 'मातृ भाषा का चयन करे',
          MARITAL_STATUS: 'वैवाहिक स्थिति',
          MARITAL_STATUS_PLACEHOLDER: 'वैवाहिक स्थिति का चयन करे',
          SKIN_TYPE: 'त्वचा प्रकार',
          SKIN_TYPE_PLACEHOLDER: 'त्वचा प्रकार का चयन करे',
          BODY_TYPE: 'शरीर के प्रकार',
          BODY_TYPE_PLACEHOLDER: 'शरीर के प्रकार का चयन करे',
          EATING_HABIT: 'खाने की आदत',
          EATING_HABIT_PLACEHOLDER: 'खाने की आदत का चयन करे',
          DRINKING_HABIT: 'पीने की आदत',
          DRINKING_HABIT_PLACEHOLDER: 'पीने की आदत का चयन करे',
          SMOKING_HABIT: 'धूम्रपान की आदत',
          SMOKING_HABIT_PLACEHOLDER: 'धूम्रपान की आदत का चयन करे',
          BLOOD_GROUP: 'ब्लड ग्रुप',
          BLOOD_GROUP_PLACEHOLDER: 'ब्लड ग्रुप का चयन करे',
          BIRTH_PLACE: 'जन्म स्थान',
          BIRTH_PLACE_PLACEHOLDER: 'जन्म स्थान दर्ज करें',
          BIRTH_TIME: 'जन्म समय',
          BIRTH_TIME_PLACEHOLDER: 'जन्म समय दर्ज करें',
          BIRTH_DATE: 'जन्म तिथि',
          BIRTH_DATE_PLACEHOLDER: 'जन्म तिथि दर्ज करें',
          ABOUT_ME: 'मेरे बारे में',
          ABOUT_ME_PLACEHOLDER: 'अपने बारे में लिखें',
          HOBBY: 'शौक',
          HOBBY_PLACEHOLDER: 'अपने शौक लिखें',
          fields_marked_with_asterisk: 'फ़ील्ड तारांकन चिह्न से चिह्नित',
          are_mendatory: 'अनिवार्य हैं'
        },
        RELIGION_DETAILS: {
          TITLE: 'धार्मिक विवरण',
          RELIGION: 'धर्म',
          RELIGION_REQUIRED: 'यह शाखा भरना अनिवर्या है',
          CASTE: 'जाति',
          CASTE_REQUIRED: 'यह शाखा भरना अनिवर्या है',
          SUBCASTE: 'उप जाति',
          GOTHRA: 'गोत्र',
          HOROSCOPE: 'राशिफल',
          MANGLIK: 'मांगलिक',
          STAR: 'सितारा',
          MOONSIGN: 'राशि',
        },
        LOCATION_DETAILS: {
          TITLE: 'स्थान विवरण',
          ADDRESS: 'पता',
          COUNTRY: 'देश',
          STATE: 'राज्य',
          CITY: 'शहर',
          MOBILE_NO: 'मोबाइल नंबर',
          PHONE: 'फ़ोन नंबर',
          RESIDENCE_STATUS: 'आवास की स्थिति',
        },
        EDUCATION_PROFESSION_DETAILS: {
          TITLE: 'शिक्षा/पेशे का विवरण',
          EDUCATION: 'शिक्षा',
          EMPLOYED_IN: 'नियुक्त',
          DESIGNATION: 'पद',
          OCCUPATION: 'व्यवसाय',
          ANNUAL_INCOME: 'वार्षिक आय',
        },
        FAMILY_DETAILS: {
          TITLE: 'पारिवारिक विवरण',
          FAMILY_TYPE: 'परिवार का प्रकार',
          FAMILY_STATUS: 'पारिवारिक स्थिति',
          FATHER_NAME: 'पिता का नाम',
          FATHER_OCCUPATION: 'पिता का व्यवसाय',
          MOTHER_NAME: 'माता का नाम',
          MOTHER_OCCUPATION: 'माता का व्यवसाय',
          NO_OF_BORTHER: 'भाई',
          NO_OF_SISTER: 'बहन',
          MARRIED_BORTHER: 'विवाहित भाई',
          MARRIED_SISTER: 'विवाहित बहन',
          ABOUT_FAMILY: 'परिवार के बारे में',
        },
      },
      PREFERENCE_DETAILS: {
        TITLE: 'वरीयता विवरण',
        BASIC_PREFERENCE_DETAILS: {
          TITLE: 'मूल वरीयता विवरण',
          LOOKING_FOR: 'वरीयता का प्रकार',
          AGE_FROM: 'आयु से',
          AGE_TO: 'आयु तक',
          HEIGHT_FROM: 'ऊँचाई से',
          HEIGHT_TO: 'ऊँचाई तक',
          MOTHER_TONGUE: 'मातृ भाषा',
          BODY_TYPE: 'शरीर के प्रकार',
          EATING_HABIT: 'खाने की आदत',
          SMOKING_HABIT: 'धूम्रपान करने की आदत',
          DRINKING_HABIT: 'पीने की आदत',
          SKIN_TONE: 'त्वचा का रंग',
          EXPECTATIONS: 'अपेक्षाएं',
        },
        RELIGIOUS_PREFERENCE: {
          TITLE: 'धार्मिक वरीयता विवरण',
          RELIGION: 'धर्म',
          CASTE: 'जाति',
          MANGLIK: 'मांगलिक',
          STAR: 'स्टार',
        },
        LOCATION_PREFERENCE: {
          TITLE: 'स्थान वरीयता विवरण',
          COUNTRY: 'देश',
          STATE: 'राज्य',
          CITY: 'शहर',
          RESIDENCE_STATUS: 'आवास की स्थिति'
        },
        EDUCATION_PROFESSION_PREFERENCE: {
          TITLE: 'शिक्षा/पेशे वरीयता विवरण',
          EDUCATION: 'शिक्षा',
          EMPLOYED_IN: 'नियुक्त',
          OCCUPATION: 'व्यवसाय',
          ANNUAL_INCOME: 'वार्षिक आय',
          DESIGNATION: 'पद',
        },
      },
      DASHBOARD: {
        COLLAPSIBLE: 'Dashboards',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ECOMMERCE: 'eCommerce'
      },
      APPS: {
        SECTION: 'Apps & Pages',
        EMAIL: 'Email',
        CHAT: 'Chat',
        TODO: 'Todo',
        CALENDAR: 'Calendar',
        INVOICE: {
          COLLAPSIBLE: 'Invoice',
          LIST: 'List',
          PREVIEW: 'Preview',
          EDIT: 'Edit',
          ADD: 'Add'
        },
        ECOMMERCE: {
          COLLAPSIBLE: 'eCommerce',
          SHOP: 'Shop',
          DETAIL: 'Details',
          WISHLIST: 'Wish List',
          CHECKOUT: 'Checkout'
        },
        USER: {
          COLLAPSIBLE: 'User',
          LIST: 'List',
          VIEW: 'View',
          EDIT: 'Edit'
        }
      },
      PAGES: {
        SECTION: 'Pages',
        AUTH: {
          COLLAPSIBLE: 'Authentication',
          LOGIN1: 'Login V1',
          LOGIN2: 'Login V2',
          REGISTER1: 'Register V1',
          REGISTER2: 'Register V2',
          FORGOTPASSWORD1: 'Forgot Password V1',
          FORGOTPASSWORD2: 'Forgot Password V2',
          RESETPASSWORD1: 'Reset Password V1',
          RESETPASSWORD2: 'Reset Password V2'
        },
        ACCOUNTSETTINGS: 'Account Settings',
        PROFILE: 'Profile',
        FAQ: 'FAQ',
        KB: 'Knowledge Base',
        PRICING: 'Pricing',
        BLOG: {
          COLLAPSIBLE: 'Blog',
          LIST: 'List',
          DETAILS: 'Details',
          EDIT: 'Edit'
        },
        MAIL: {
          COLLAPSIBLE: 'Mail Template',
          WELCOME: 'Welcome',
          RESET: 'Reset Password',
          VERIFY: 'Verify',
          DEACTIVATE: 'Deactivate',
          INVOICE: 'Invoice',
          PROMOTIONAL: 'Promotional'
        },
        MISC: {
          COLLAPSIBLE: 'Miscellaneous',
          COMINGSOON: 'Coming Soon',
          NOTAUTH: 'Not Authorized',
          MAINTENANCE: 'Maintenance',
          ERROR: 'Error'
        }
      },
      UI: {
        SECTION: 'User Interface',
        TYPOGRAPHY: 'Typography',
        COLORS: 'Colors',
        FEATHER: 'Feather',
        CARDS: {
          COLLAPSIBLE: 'Cards',
          BADGE: 'New',
          BASIC: 'Basic',
          ADVANCE: 'Advance',
          STATISTICS: 'Statistics',
          ANALYTICS: 'Analytics',
          ACTIONS: 'Actions'
        },
        COMPONENTS: {
          COLLAPSIBLE: 'Components',
          ALERTS: 'Alerts',
          AVATAR: 'Avatar',
          BADGES: 'Badges',
          BREADCRUMBS: 'Breadcrumbs',
          BUTTONS: 'Buttons',
          CAROUSEL: 'Carousel',
          COLLAPSE: 'Collapse',
          DIVIDER: 'Divider',
          DROPDOWNS: 'Dropdowns',
          GROUP: 'List Group',
          OBJECTS: 'Media Objects',
          MODALS: 'Modals',
          COMPONENT: 'Navs',
          PAGINATION: 'Pagination',
          PBADGES: 'Pill Badges',
          PILLS: 'Pills',
          POPOVERS: 'Popovers',
          PROGRESS: 'Progress',
          RATINGS: 'Ratings',
          SPINNER: 'Spinner',
          TABS: 'Tabs',
          TIMELINE: 'Timeline',
          TOASTS: 'Toasts',
          TOOLTIPS: 'Tooltips'
        },
        EX: {
          COLLAPSIBLE: 'Extension',
          SWEET_ALERTS: 'Sweet Alerts',
          BLOCKUI: 'BlockUI',
          TOASTER: 'Toastr',
          SLIDER: 'Slider',
          DRAGDROP: 'Drag & Drop',
          TOUR: 'Tour',
          CLIPBOARD: 'Clipboard',
          MEDIAPLAYER: 'Media Player',
          CONTEXTMENU: 'Context Menu',
          SWIPER: 'Swiper',
          TREEVIEW: 'Tree View',
          I18N: 'I18n'
        },
        LAYOUTS: {
          COLLAPSIBLE: 'Page Layouts',
          COLLAPSED_MENU: 'Collapsed Menu',
          BOXED_LAYOUT: 'Boxed Layout',
          WITHOUT_MENU: 'Without Menu',
          LAYOUT_EMPTY: 'Layout Empty',
          LAYOUT_BLANK: 'Layout Blank'
        }
      },
      FT: {
        SECTION: 'Forms & Tables',
        ELEMENT: {
          COLLAPSIBLE: 'Form Elements',
          INPUT: 'Input',
          INPUTGROUPS: 'Input Groups',
          INPUTMASK: 'Input Mask',
          TEXTAREA: 'Textarea',
          CHECKBOX: 'Checkbox',
          RADIO: 'Radio',
          SWITCH: 'Switch',
          SELECT: 'Select',
          NUMBERINPUT: 'Number Input',
          FILEUPLOADER: 'File Uploader',
          QUILLEDITOR: 'Quill Editor',
          FLATPICKER: 'Flatpicker',
          DATETIMEPICKER: 'Date & Time Picker'
        },
        LAYOUTS: 'Form Layouts',
        WIZARD: 'Form Wizard',
        VALIDATION: 'Form Validations',
        REPEATER: 'Form Repeater',
        TABLE: 'Table',
        DATATABLES: 'DataTables'
      },
      CM: {
        SECTION: 'Charts & Maps',
        CHARTS: {
          COLLAPSIBLE: 'Charts',
          BADGE: '2',
          APEX: 'Apex',
          CHARTJS: 'ChartJS'
        },
        MAPS: 'Google Maps'
      },
      OTHERS: {
        SECTION: 'Others',
        LEVELS: {
          COLLAPSIBLE: 'Menu Levels',
          SECOND: 'Second Level',
          SECOND1: {
            COLLAPSIBLE: 'Second Level',
            THIRD: 'Third Level',
            THIRD1: 'Third Level'
          }
        },
        DISABLED: 'Disabled Menu',
        DOCUMENTATION: 'Documentation',
        SUPPORT: 'Raise Support'
      }
    },
   home:{
        
    "Home":"होम",
    "Couple":"जोड़ा",
    "Story":"कहानी",
    "Events":"मूल्य निर्धारण",
    "Gallery":"गेलरी",
    "True":"इश्क वाला",
    "Love":"लव",
    "People":"लोग",
    "RSVP":" RSVP",
    "We_are_getting":"बिहाव",
    "Married":"परिणय सूत्र",
    "Relationship":"रिश्ते सदा से, सदा के लिए",
    "Wedding_Announcement":"शादी की घोषणा",
    "Curabitur_clacus":"कुराबिटुर एसी लैकस आर्कू। सेड व्हीकल वेरियस लेक्टस औक्टर विवरा। नुल्ला व्हीकल निभ वेल एंटे कमोडो फ्यूगिएट।",
    "Romeo":"रोमियो",
    "The_groom":"दूल्हा",
    "Lorem_ipsam":"लोरेम इप्सम डोलर सिट एमेट, कॉन्सेक्टेटूर एडिपिसिसिंग एलीट। मैक्सिमे परफेरेंडिस मोदी में डोलोरम अपराधी। निहिल पोरो इन्वेंटर सिमिलिक क्विस्कम इप्सम इल्लुम, बीटा ईयुस।",
    "Juliet":"जूलियट",
    "The_bride":"दुल्हन",
    "Our_Love_Story":"हमारी  कहानी",
    "Video":"वीडियो",
    "The_Wedding_Events":"शादी के कार्यक्रम",
    "Read_more":"अधिक पढ़ें",
    "Photo_Album":"फोटो एलबम",
    "All":"सभी",
    "Groom_smen_Bridesmaids":"दूल्हे और वर-वधू",
    "Grooms":"दूल्हे",
    "Edward_Prince":"एडवर्ड प्रिंस",
    "Brother":"भाई",
    "Darien_Hari":"डेरेन हरीश",
    "Friend":"दोस्त",
    "Brides_maids":"वर",
    "Alice":"ऐलिस",
   "Alice_Lues":"ऐलिस ल्यूस",
    "Sister":"बहन",
    "Wedding_Location":"शादी का स्थान",
    "Location":"स्थान",
    "Gmail":"जीमेल",
    "Contact":"संपर्क",
    "Contact_us":"संपर्क करें",
    "dont":"हमें अपना अनुरोध भेजने में संकोच न करें और हमारे सलाहकार इस बात पर चर्चा करने के लिए आपके पास वापस आएंगे कि हम 24/7 अनुरोधों को संसाधित करने में सबसे अच्छी मदद कैसे कर सकते हैं।",
    "Name":"  नाम",
    "Name_is_required":"नाम आवश्यक है",
    "Mobile_Number":"मोबाइल नंबर",
    "Mobile_Number_is_required":"मोबाइल नंबर आवश्यक है",
    "Email":"ईमेल",
    
    "required_Email":"ईमेल आईडी की आवश्यकता है",
    "invalid_Email":"अमान्य ईमेल आईडी",
    "Subject":"विषय",

    "subject_is_required":"विषय की आवश्यकता है",

    "Feedback":"प्रतिक्रिया",

    "Feedback_is_required":"प्रतिक्रिया की आवश्यकता है",
    "Submit":"सबमिट",

    "Find_a_plan":"एक योजना खोजें जो आपके लिए सही हो.",
    "subtitle":"प्रत्येक योजना 30-दिन के निःशुल्क परीक्षण के साथ आती है.",
    "Buy_Now":"अभी खरीदें",
    "All_plans":"सभी योजनाओं में शामिल हैं",
   "Advanced_Admin":"उन्नत व्यवस्थापक और नियंत्रण",
   "Access_Control":"पूरी तरह से एक्सेस कंट्रोल",
   "Support":"24/7 सहायता सेवा",
   "Reports":"सारणीबद्ध और ग्राफिकल के साथ रिपोर्ट",
   "Defaul":"33+ डिफ़ॉल्ट भाषा और असीमित विकल्प",
   "Default_Theme":"12 डिफ़ॉल्ट उन्हें",
   "Database_Backup":"डेटाबेस बैकअप",
   "Secure_Authentication":"सुरक्षित प्रमाणीकरण",
   "Generate_ID":"आईडी कार्ड और सर्टिफिकेट जेनरेट करें",
   "Maintenance":"रखरखाव और समर्थन शुल्क",
   "Maintenance1":" 2000/वर्ष एक वर्ष के बाद शुल्क लिया जाएगा।",

   "Mobile_App":"मोबाइल एप्लिकेशन",
   "Lorem_ipsum":"लोरेम इप्सम डोलर सिट एमेट, कंसेटेटूर सैडिप्सिंग एलीट्र, सेड डायम नोनुमी एर्मोड।",
   "Search_by":"स्थान, समुदाय, पेशे आदि के आधार पर खोजें।.",
   "Verified":"प्रोफ़ाइल में सत्यापित स्टाम्प जोड़ा गया।",
   "Profile_and":"उन्नत गोपनीयता सेटिंग्स के साथ प्रोफ़ाइल और चित्र ..",

   "Get_connected":"सामाजिक नेटवर्क पर हमारे साथ जुड़ें",
    "About_Us":"हमारे बारे में",
    "Dont_hesitate":"हमें अपना अनुरोध भेजने में संकोच न करें और हमारे सलाहकार इस बात पर चर्चा करने के लिए आपके पास वापस आएंगे कि हम 24/7 अनुरोधों को संसाधित करने में सबसे अच्छी मदद कैसे कर सकते हैं।",
    "Links":"लिंक",
  
    "Login":"लॉगिन",
    "Register":"रजिस्टर करें",
    "Download":"डाउनलोड",

    "Our_Blog":"हमारा ब्लॉग",
    "Curabitur_blog":"कुराबिटुर एसी लैकस आर्कू। सेड व्हीकल वेरियस लेक्टस औक्टर विवरा। नुल्ला वाहन निभ वेल एंटे कमोडो फ्यूगिएट।",
    
    "Blog_Details":"ब्लॉग विवरण",
"Comments":"टिप्पणियाँ",
"Leave_Your_Comment":"अपनी टिप्पणी दर्ज करें",

"Enter_Message":"संदेश दर्ज करें",
"Message_required":"संदेश की आवश्यकता है",
"Blog":"ब्लॉग"

}
  }
};
