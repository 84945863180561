import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RegistService } from '../services/regist.service';
import { TblLogin } from 'app/model/tbl-login';
import { ProfileListService } from 'app/services/profile-list.service';
import { map, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TblSubscriptionPlan } from 'app/model/tbl-subscription-plan';
import { model_response } from 'app/model/tbl-profile';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-member-ship',
  templateUrl: './member-ship.component.html',
  styleUrls: ['./member-ship.component.scss']
})

export class MemberShipComponent implements OnInit {
  Monthly = true
  // title='hello angular'
  activePlan:TblSubscriptionPlan;
  PlanListData:TblSubscriptionPlan;
  @Output() testData: EventEmitter<any> = new EventEmitter();
   public isloded = false;
  constructor(private profileListService:ProfileListService, private router:Router, private toster: ToastrService) { }
  ngOnInit(): void {
    this.subscription_plan_get();
  }

  subscription_plan_get() {
    try {
    this.profileListService.subscription_plan_get().pipe(map((res: model_response) => <model_response>res),
      retry(2)).subscribe((res: model_response) => {
        if (res.status == 'Success') {
          this.activePlan = res.data.subscribed_plan_details[0];
          this.PlanListData = res.data.plan_list;
          if (this.PlanListData != null) { this.isloded = true;}
          //    console.warn('activePlan = ', this.activePlan)
          // console.warn('PlanListData = ', JSON.stringify(this.PlanListData));
        } else this.toster.error(res.message, 'Error!');
      })//,(error) => { alert(JSON.stringify(error)); });
    } catch (err: any) { throw err;}
  }

  convertModelFeatures(data) {
    // alert(data)
    return <TblSubscriptionPlan[]>JSON.parse(data);
    
  }
  
  subscriptionSend(data:TblSubscriptionPlan){
    if(JSON.parse(localStorage.getItem("membership"))){
      localStorage.removeItem("membership");
    }
    localStorage.setItem("membership", JSON.stringify(data));
    this.router.navigate(['/subscription-confirm']);
  }
}
