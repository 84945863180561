import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {model_response} from '../model/tbl-profile';
import { TblLogin, model_login_response } from '../model/tbl-login';
import { base } from '../../base/base';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http:HttpClient,private router:Router) { }

  login(data){
    return this.http.post<model_login_response>(`${base.apiUrl}/Authenticate/login`,data);  
  }
  // isLoggedIn(){
  //   var currentUser:model_login_response =  JSON.parse(localStorage.getItem("user"));
  //   return currentUser;
  // }

  isLoggedIn(){
    var currentUser:model_login_response =  JSON.parse(localStorage.getItem("user") || null);
    return currentUser;
  }
  
   
  loggedOut() {
  localStorage.removeItem("currentUser")
    localStorage.removeItem("ltn")
    localStorage.removeItem("membership")
    localStorage.removeItem("config")
    localStorage.removeItem("user")
    // this._authenticationService.logout();
     this.router.navigate(['login']);
  }
  //  get_otp(loginData: model_authentication) {
  //   return this.http.post<response>(`${Base.baseUrl}/Authenticate/get_otp`, loginData);
  // }
  // https://api.bihav.co.in/api/Authenticate/get_otp
}
