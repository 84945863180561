import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { Profilcount } from 'app/model/profilcount';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
  @Input() item: CoreMenuItem;
}
