<div class="content-wrapper">
    <div class="content-body">
        <h4>{{'MENU.PREFERENCE_DETAILS.TITLE' | translate}}</h4>
        <section class="modern-vertical-wizard">
            <div id="stepper5" class="bs-stepper vertical wizard-modern modern-vertical-wizard-example">
                <div class="bs-stepper-header">

                    <div class="step" data-target="#personal-info-vertical-modernP">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="user" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span
                                    class="bs-stepper-title">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.TITLE'
                                    | translate}}</span>
                                <!-- <span class="bs-stepper-subtitle">Add Personal Info</span> -->
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#religious-details">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="link" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">{{'MENU.PREFERENCE_DETAILS.RELIGIOUS_PREFERENCE.TITLE' |
                                    translate}}</span>
                                <!-- <span class="bs-stepper-subtitle">Add Address</span> -->
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#Location-links-vertical-modernP">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="map-pin" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">{{'MENU.PREFERENCE_DETAILS.LOCATION_PREFERENCE.TITLE' |
                                    translate}}</span>
                                <!-- <span class="bs-stepper-subtitle">Add Social Links</span> -->
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#professional-details">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="book" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span
                                    class="bs-stepper-title">{{'MENU.PREFERENCE_DETAILS.EDUCATION_PROFESSION_PREFERENCE.TITLE'
                                    | translate}}</span>
                                <!-- <span class="bs-stepper-subtitle">Add Social Links</span> -->
                            </span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <!-- <div id="account-details-vertical-modernP" class="content"> -->

                    <form [formGroup]="peferenceTabOneForm" (ngSubmit)="putPrreferenceTabOneData()">
                        <div id="personal-info-vertical-modernP" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.TITLE' |
                                    translate}}</h5>
                                <!-- <small>Enter Your Personal Info.</small> -->
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label class="form-label"
                                        for="vertical-modern-lookingFor">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.LOOKING_FOR'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1MaritalStatus" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="marital_status_en" bindValue="marital_status_id"
                                        name="marital_status_id" formControlName="marital_status_id"
                                        placeholder="Select people"></ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-ageFrom">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.AGE_FROM'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1AgeFrom" bindLabel="age_en" name="age_from_id"
                                        bindValue="age_from_id" formControlName="age_from_id"
                                        placeholder="Select Age"></ng-select>

                                    <!-- <ng-select [items]="preTab1AgeFrom" bindLabel="age_en" name="age_from_id" bindValue="age_from_id" formControlName="age_from_id" placeholder="Select Age"></ng-select> -->
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-ageTo">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.AGE_TO'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1AgeTo" bindLabel="age_en" name="age_to_id"
                                        bindValue="age_to_id" formControlName="age_to_id"
                                        placeholder="Select Age"></ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-heightFrom">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.HEIGHT_FROM'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1HeightFrom" bindLabel="height_en"
                                        bindValue="height_from_id" name="marital_status_id"
                                        formControlName="height_from_id"> </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-heightTo">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.HEIGHT_TO'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1HeightTo" bindLabel="height_en" bindValue="height_to_id"
                                        formControlName="height_to_id"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-motherTongue">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.MOTHER_TONGUE'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1MotherTongue" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="mother_tongue_en" bindValue="mother_tongue_id"
                                        formControlName="mother_tongue_id" placeholder="Select people">
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-bodyType">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.BODY_TYPE'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1BodyType" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="body_type_en" bindValue="body_type_id"
                                        formControlName="body_type_id" placeholder="Select people">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-eatingHabit">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.EATING_HABIT'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1EatingHabit" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="eating_habit_en" bindValue="eating_habit_id"
                                        formControlName="eating_habit_id" placeholder="Select people">
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-smokingHabit">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.SMOKING_HABIT'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1SmokingHabit" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="smoking_habit_en" bindValue="smoking_habit_id"
                                        formControlName="smoking_habit_id" placeholder="Select people">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-drinkinHabit">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.DRINKING_HABIT'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1DrinkingHabit" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="drinking_habit_en" name="smoking_habit_id"
                                        bindValue="drinking_habit_id" formControlName="drinking_habit_id"
                                        placeholder="Select people">
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-skinTone">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.SKIN_TONE'
                                        | translate}}</label>
                                    <ng-select [items]="preTab1SkinType" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="skin_type_en" name="skin_type_id"
                                        bindValue="skin_type_id" formControlName="skin_type_id"
                                        placeholder="Select Skin type">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label
                                        for="expectations">{{'MENU.PREFERENCE_DETAILS.BASIC_PREFERENCE_DETAILS.EXPECTATIONS'
                                        | translate}}</label>
                                    <fieldset class="form-group">
                                        <textarea class="form-control" id="expectations" name="expectations"
                                            formControlName="expectations" rows="4"
                                            placeholder="Enter details"></textarea>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-outline-secondary btn-prev" disabled rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span
                                        class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.PREVIOUS'
                                        | translate}}</span>
                                </button>
                                <button class="btn btn-primary btn-submit" rippleEffect>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.NEXT' |
                                        translate}}</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div id="religious-details" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">{{'MENU.PREFERENCE_DETAILS.RELIGIOUS_PREFERENCE.TITLE' | translate}}</h5>
                            <!-- <small>Enter Your Address.</small> -->
                        </div>
                        <form [formGroup]="peferenceTabTwoForm">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Religion">{{'MENU.PREFERENCE_DETAILS.RELIGIOUS_PREFERENCE.RELIGION'
                                        | translate}}</label>
                                    <ng-select [items]="preTab2Religion" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="religion_en" name="religion_id"
                                        bindValue="religion_id" formControlName="religion_id"
                                        placeholder="Select people">
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Caste">{{'MENU.PREFERENCE_DETAILS.RELIGIOUS_PREFERENCE.CASTE'
                                        | translate}}</label>
                                    <ng-select [items]="preTab2Caste" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="caste_en" name="caste_id" bindValue="caste_id"
                                        formControlName="caste_id" placeholder="Select Skin type">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Manglik">{{'MENU.PREFERENCE_DETAILS.RELIGIOUS_PREFERENCE.MANGLIK'
                                        | translate}}</label>
                                    <ng-select [items]="preTab2Manglik" bindLabel="manglik_en" name="manglik_id"
                                        bindValue="manglik_id" formControlName="manglik_id" placeholder="Select people">
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Star">{{'MENU.PREFERENCE_DETAILS.RELIGIOUS_PREFERENCE.STAR'
                                        | translate}}</label>
                                    <ng-select [items]="preTab2Star" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="star_en" name="star_id" bindValue="star_id"
                                        formControlName="star_id" placeholder="Select Skin type">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()"
                                    rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span
                                        class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.PREVIOUS'
                                        | translate}}</span>
                                </button>
                                <button class="btn btn-primary btn-next" rippleEffect
                                    (click)="putPrreferenceTabTwoData()">
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.NEXT' |
                                        translate}}</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div id="Location-links-vertical-modernP" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">{{'MENU.PREFERENCE_DETAILS.LOCATION_PREFERENCE.TITLE' | translate}}</h5>
                            <!-- <small>Enter Your Social Links.</small> -->
                        </div>
                        <form [formGroup]="peferenceTabThreeForm">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Country">{{'MENU.PREFERENCE_DETAILS.LOCATION_PREFERENCE.COUNTRY'
                                        | translate}}</label>
                                    <ng-select [items]="preTab3Country" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="country_en" name="country_en"
                                        bindValue="country_id" formControlName="country_id"
                                        placeholder="Select Country">
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-State">{{'MENU.PREFERENCE_DETAILS.LOCATION_PREFERENCE.STATE'
                                        | translate}}</label>
                                    <ng-select [items]="preTab3State" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="state_en" name="state_en" bindValue="state_id"
                                        formControlName="state_id" placeholder="Select State">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-City">{{'MENU.PREFERENCE_DETAILS.LOCATION_PREFERENCE.CITY'
                                        | translate}}</label>
                                    <ng-select [items]="preTab3City" bindLabel="city_en" name="city_en"
                                        [multiple]="true" [closeOnSelect]="false" [searchable]="false"
                                        bindValue="city_id" formControlName="city_id" placeholder="Select City">
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Residence-Status">{{'MENU.PREFERENCE_DETAILS.LOCATION_PREFERENCE.RESIDENCE_STATUS'
                                        | translate}}</label>
                                    <ng-select [items]="preTab3Residence" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="residence_en" name="residence_en"
                                        bindValue="residence_id" formControlName="residence_id"
                                        placeholder="Select Residence">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()"
                                    rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span
                                        class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.PREVIOUS'
                                        | translate}}</span>
                                </button>
                                <button class="btn btn-primary btn-next" rippleEffect
                                    (click)="putPrreferenceTabThreeData()">
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.NEXT' |
                                        translate}}</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div id="professional-details" class="content">
                        <div class="content-header">
                            <h5 class="mb-0">{{'MENU.PREFERENCE_DETAILS.EDUCATION_PROFESSION_PREFERENCE.TITLE' |
                                translate}}</h5>
                            <!-- <small>Enter Your Address.</small> -->
                        </div>
                        <form [formGroup]="peferenceTabFourForm">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Religion">{{'MENU.PREFERENCE_DETAILS.EDUCATION_PROFESSION_PREFERENCE.EDUCATION'
                                        | translate}}</label>
                                    <ng-select [items]="preTab4Education" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="education_en" name="education_en"
                                        bindValue="education_id" formControlName="education_id"
                                        placeholder="Select Education Type">
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Caste">{{'MENU.PREFERENCE_DETAILS.EDUCATION_PROFESSION_PREFERENCE.EMPLOYED_IN'
                                        | translate}}</label>
                                    <ng-select [items]="preTab4EmployedIn" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="employed_in_en" name="employed_in_en"
                                        bindValue="employed_in_id" formControlName="employed_in_id"
                                        placeholder="Select Employment type">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Star">{{'MENU.PREFERENCE_DETAILS.EDUCATION_PROFESSION_PREFERENCE.OCCUPATION'
                                        | translate}}</label>
                                    <ng-select [items]="preTab4Occupation" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="occupation_en" name="occupation_en"
                                        bindValue="occupation_id" formControlName="occupation_id"
                                        placeholder="Select Occupation type">
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-Manglik">{{'MENU.PREFERENCE_DETAILS.EDUCATION_PROFESSION_PREFERENCE.ANNUAL_INCOME'
                                        | translate}}</label>
                                    <ng-select [items]="preTab4AnnualIncome" bindLabel="income_range"
                                        name="income_range" bindValue="annual_income_id"
                                        formControlName="annual_income_id" placeholder="Select Annual Income">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label class="form-label"
                                        for="vertical-modern-Star">{{'MENU.PREFERENCE_DETAILS.EDUCATION_PROFESSION_PREFERENCE.DESIGNATION'
                                        | translate}}</label>
                                    <ng-select [items]="preTabDesignation" [multiple]="true" [closeOnSelect]="false"
                                        [searchable]="false" bindLabel="designation_en" name="designation_en"
                                        bindValue="designation_id" formControlName="designation_id"
                                        placeholder="Select designation type">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-primary btn-prev"
                                    (click)="modernVerticalPrevious(); $event.stopPropagation()" rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span
                                        class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.PREVIOUS'
                                        | translate}}</span>
                                </button>
                                <button class="btn btn-success btn-submit" rippleEffect (click)="putPrreferenceTabFourData()">{{'MENU.PROFILE_DETAILS.save' | translate}}</button>

                                <!-- <button class="btn btn-primary btn-next" rippleEffect (click)="putPrreferenceTabFourData()">
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.NEXT' |  translate}}</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!-- /Modern Vertical Wizard -->
    </div>
</div>