import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { base } from 'base/base';
import { TblShortlist } from 'app/model/tbl-shortlist';
import { model_response } from 'app/model/tbl-profile';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShortlistService {

//  const http = inject(HttpClient)
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  profile_detail_data: Observable<any> = this.obsArray.asObservable();
  public onOpenedProfileChanged: BehaviorSubject<any>;


  constructor(private http: HttpClient) {
    this.onOpenedProfileChanged = new BehaviorSubject([]);

   }

  getToken() {
    return localStorage.getItem('ltn');
  }
  postShortListData(data) {
    return this.http.post<TblShortlist>(`${base.apiUrl}/shortlist?profile_id=${data}`, '');
  }
  deleteShortListData(data) {
    return this.http.get<TblShortlist>(`${base.apiUrl}/shortlist/delete_shortlist?profile_id=${data}`);
  }
  getShortListData(pageSize: number, pageNumber: number) {
    return this.http.get<model_response>(`${base.apiUrl}/shortlist/get_shortlisted_profile?page_size=${pageSize}&current_page=${pageNumber}`);
  }
  getProfilePhoto(user_id: number) {
    return this.http.get<model_response>(`${base.apiUrl}/photo_gallary/get_photo_gallery?user_id=${user_id}`);
    // return this.http.get<model_response>(`${base.apiUrl}/dashboard/get_photo_gallery?user_id=${user_id}`);
  }
  profile_update(data) {
    this.obsArray.next(data)
  }
  openProfileDetails(data) {
    this.onOpenedProfileChanged.next(data);
    
  }
}
