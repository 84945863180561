export class TblMembership {  
    bank_id: number;
    bank_name: string;
}
export class tbl_payments{
    id : number;
    user_id : number = 0;
    payment_method : string;
    payment_amount : number;
    transaction_number : string;
    transaction_status : string;
    status : number;
    plan_id : number;
    bank_id : number;
    upi_address : string;
    create_by : number;
    create_date : Date;
    plan_validity_value_in_days : number;
}
export class tbl_cards {
    user_id: number = 0;
    card_id: number;
    card_number: number;
    name: string;
    expiry_month: number;
    expiry_year: number;
    is_saved: number;
    status: number;
    create_date: Date;
    create_by: number;
}
export class vm_model_for_subscription {
    tbl_payments:tbl_payments = new tbl_payments();
    tbl_cards:tbl_cards = new tbl_cards();
}
export class tbl_pyment_update {
    plan_id: number;
    payment_id: number;
    transaction_number: string = "";
    transaction_status: string = "String";
    pg_response: string = "";
}
export class model_privacy_setting {
    privacy_profile!: number;
    privacy_contact!: number;
}