import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';
import { menu } from 'app/menu/menu';
import { locale as menuEnglish } from 'app/menu/i18n/en';
import { locale as menuHindi } from 'app/menu/i18n/hi';
import { locale as menuChhattisgarhi } from 'app/menu/i18n/cg';
import { locale as menuFrench } from 'app/menu/i18n/fr';
import { locale as menuGerman } from 'app/menu/i18n/de';
import { locale as menuPortuguese } from 'app/menu/i18n/pt';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionPerformed,
  PushNotificationSchema, PushNotifications, Token,
  PushNotificationActionPerformed,
} from '@capacitor/push-notifications';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Swal from 'sweetalert2';
import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
import { Location } from '@angular/common';
import { base } from 'base/base';
import { RegistService } from './services/regist.service';
const { capacitorSplashScreen } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  defaultLanguage: any; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'hi'; // Set application default language i.e fr

  private _unsubscribeAll: Subject<any>;
  /**
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */
  base: any;
  constructor(
    private router: Router,
    private location: Location,
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private ativatedRoute: ActivatedRoute,
    private registservice: RegistService,
  ) {
    this.base = base
    // this.backButton();
    // this.appUrlOpen();
    // this.rightClickDisable.disableRightClick();
    var plateform = Capacitor.getPlatform();


    switch (plateform) {

      case 'ios': this.native_push_request_permission(); break;
      case 'android': this.native_push_request_permission(); break;
      // case 'web': this.web_push_request_permission(); this.web_push_listen(); break;
    }
    var currentUser = JSON.parse(localStorage.getItem("currentUser") || '{}');
    this.defaultLanguage = currentUser.language;
    if (this.defaultLanguage == null) this.defaultLanguage = 'hi';


    this.menu = menu;
    this._coreMenuService.register('main', this.menu);
    this._coreMenuService.setCurrentMenu('main');
    // this._translateService.addLangs(['en', 'hi', 'fr', 'de', 'pt']);
    // this._coreTranslationService.translate(menuEnglish, menuHindi, menuFrench, menuGerman, menuPortuguese);
    this._translateService.addLangs(['en', 'hi', 'cg']);
    this._coreTranslationService.translate(menuEnglish, menuHindi, menuChhattisgarhi);
    this._translateService.setDefaultLang(this.defaultLanguage);
    this._translateService.use(this.defaultLanguage);
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    // var lang ='hi'
    // this.defaultLanguage =  'en';
    // this.ativatedRoute.queryParamMap.subscribe((paramss) => {
    //   this.defaultLanguage = lang//+paramss.get('lang') ;
    // });
    Waves.init();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });
    this._title.setTitle(this.coreConfig.app.appTitle);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  native_push_request_permission() {
    try {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          PushNotifications.register();
        } else {
        }
      });
      PushNotifications.addListener('registration',
        (token: Token) => {
          console.log('PushNotifications kilesh :::: ', token.value);
          localStorage.setItem('token_push_notification', token.value);
        }
      );
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          alert(JSON.stringify(error.value));
        }
      );
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (data: PushNotificationSchema) => {
          if (data.data.redirect_component_name != null || data.data.redirect_component_name != '') {
            Swal.fire({
              title: data.title,
              text: data.body,
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Open',
              cancelButtonText: 'Close'
            }).then((result) => {
              if (result.value) {
                window.location.href = '/' + data.data.redirect_component_name;
              }
            })
          }
          else {
            Swal.fire({
              title: data.title,
              text: data.body,
              icon: 'info',
              showCancelButton: true,
              cancelButtonText: 'Ok'
            }).then((result) => {
            })
          }
        }
      );
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        async (notification: ActionPerformed) => {
          const data = notification.notification.data;
          if (data.redirect_component_name) {
            this.router.navigateByUrl('/' + data.redirect_component_name);
          }
          (error: any) => {
            throw error;
          }
        }
      );
    } catch (error: any) { throw error; }
  }
  web_push_request_permission() {
    try {
      const messaging = getMessaging();
      getToken(messaging,
        { vapidKey: environment.firebase.vapidKey }).then(
          (token) => {
            if (token) {
              localStorage.setItem('token_push_notification', token);
              //  alert(JSON.stringify(token));
              console.log(JSON.stringify(token));
            } else {
            }
          })//,(err:any)=>(alert(err.message));
    } catch (error: any) { throw error; }
  }
  web_push_listen() {
    try {
      const messaging = getMessaging();
      onMessage(messaging, (data: any) => {
        var parsedNotification = JSON.parse(data.notification);
        var title = parsedNotification.title;
        // this.registservice.set_push_notifications({ data: data.notification });
        //  localStorage.setItem('token_push_notification', value);
        Swal.fire({
          title: data.notification?.title,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Open',
          cancelButtonText: 'Close'
        }).then((result) => {
          if (result.value) {
            window.location.href = '/' + data.data?.redirect_component_name;
            // this.router.navigateByUrl('/' + data.data?.redirect_component_name);
            //     console.log(title);
            // var value = data
            // console.warn("web msg  = ", JSON.stringify(value.data))
          }
        })
      });
    } catch (error: any) {
      alert(error.message); // throw error; }
    }
    // backButton() {
    //   App.addListener('backButton', ({ canGoBack }) => { // Back button not working with In App Browser
    //     // if (!canGoBack) {
    //     switch (this.loginservice.getRoles()) {
    //       case 'Service Provider':
    //         if (this.location.path() == '/dashboard') {
    //           Swal.fire({
    //             title: 'Do you want to Exit?',
    //             icon: 'question', // success, error, warning, info, question
    //             showCancelButton: true,
    //             confirmButtonText: 'Yes',
    //             cancelButtonText: 'No'
    //           }).then((result) => {
    //             if (result.value) App.exitApp();
    //           })
    //         }
    //         else window.location.href = "dashboard"; break;
    //       default: if (this.location.path() == '/Service') {
    //         Swal.fire({
    //           title: 'Do you want to Exit?',
    //           icon: 'question', // success, error, warning, info, question
    //           showCancelButton: true,
    //           confirmButtonText: 'Yes',
    //           cancelButtonText: 'No'
    //         }).then((result) => {
    //           if (result.value) App.exitApp();
    //         })
    //       }
    //       else {
    //         if (this.location.path() == '/PaymentSuccess' || this.location.path() == '/PaymentFailed') {
    //           window.location.href = "get-all-orders";
    //         }
    //         else window.location.href = "Service";
    //       } break;
    //     }
    //   });
    // }



  }
}