<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <div class="card mb-0">
            <div class="card-body">
                <a href="javascript:void(0);" class="brand-logo">
                    <img src="{{base.admin_app_url}}/uploads/apps/logo-login.png" alt="bihav matrimony" height="40" />
                </a>
                <!--<h4 class="card-title mb-1">Registration Frr🚀</h4>-->
                <h2 class="card-title mb-1">{{'MENU.NEW_USER_REGISTRATION.TITLE' | translate }}</h2>
                <!--<p class="card-text mb-2">Make your app management easy and fun!</p>-->
                <form [formGroup]="registrationForm" (ngSubmit)="registrationFormData()">
                    <div class="form-group">
                        <label for="register-firstName" class="form-label">{{'MENU.NEW_USER_REGISTRATION.NAME' |
                            translate }}</label>
                        <input type="text" class="form-control" id="register-name" name="register-name"
                            placeholder="{{'MENU.NEW_USER_REGISTRATION.NAME_PLACEHOLDER' | translate }}"
                            aria-describedby="register-name" tabindex="1" autofocus
                            [ngClass]="{'is-invalid': registrationForm.get('name').touched && registrationForm.get('name').invalid}"
                            formControlName="name" required />
                        <div *ngIf="registrationForm.get('name').touched && registrationForm.get('name').invalid"
                            class="invalid-feedback">
                            <div *ngIf="registrationForm.get('name').errors.required">
                                {{'MENU.NEW_USER_REGISTRATION.NAME_REQUIRED' | translate }}</div>
                            <div *ngIf="registrationForm.get('name').errors.maxlength">
                                {{'MENU.NEW_USER_REGISTRATION.NAME_RQUIRED_MAXIMUM' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- <label class="form-label" for="vertical-modern-residenceStatus">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.RESIDENCE_STATUS' | translate}}</label> -->
                        <label class="form-label">{{'MENU.NEW_USER_REGISTRATION.GENDER' | translate }}</label>
                        <ng-select [items]="genderList" bindLabel="gender" bindValue="gender_id"
                            formControlName="gender_id"
                            placeholder="{{'MENU.NEW_USER_REGISTRATION.GENDER_PLACEHOLDER' | translate }}"></ng-select>
                        <div *ngIf="registrationForm.get('gender_id').touched && registrationForm.get('gender_id').invalid"
                            class="invalid-feedback">
                            <div *ngIf="registrationForm.get('gender_id').errors.required">
                                {{'MENU.NEW_USER_REGISTRATION.GENDER_REQUIRED' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- <label class="form-label" for="vertical-modern-residenceStatus">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.RESIDENCE_STATUS' | translate}}</label> -->
                        <label class="form-label">{{'MENU.NEW_USER_REGISTRATION.CASTE' | translate }}</label>
                        <ng-select [items]="casteList" bindLabel="caste" bindValue="caste_id" formControlName="caste_id"
                            placeholder="{{'MENU.NEW_USER_REGISTRATION.CASTE_PLACEHOLDER' | translate }}"></ng-select>
                        <div *ngIf="registrationForm.get('caste_id').touched && registrationForm.get('caste_id').invalid"
                            class="invalid-feedback">
                            <div *ngIf="registrationForm.get('caste_id').errors.required">
                                {{'MENU.NEW_USER_REGISTRATION.CASTE_REQUIRED' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="register-mobile" class="form-label">{{'MENU.NEW_USER_REGISTRATION.MOBILE_NO' |
                            translate }}</label>
                        <input type="text" class="form-control" id="register-mobile" name="register-mobile"
                            placeholder="{{'MENU.NEW_USER_REGISTRATION.MOBILE_PLACEHOLDER' | translate }}"
                            aria-describedby="register-mobile" tabindex="2"
                            [ngClass]="{'is-invalid': registrationForm.get('mobile').touched && registrationForm.get('mobile').invalid}"
                            formControlName="mobile" (keypress)="isInputNumber($event)" maxlength="10" required />
                        <div *ngIf="registrationForm.get('mobile').touched && registrationForm.get('mobile').invalid"
                            class="invalid-feedback">
                            <div *ngIf="registrationForm.get('mobile').errors.required">
                                {{'MENU.NEW_USER_REGISTRATION.MOBILE_NO_REQUIRED' | translate }}</div>
                            <div *ngIf="registrationForm.get('mobile').errors.pattern">
                                {{'MENU.NEW_USER_REGISTRATION.VALID_MOBILE_REQUIRED' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="register-email" class="form-label">{{'MENU.NEW_USER_REGISTRATION.EMAIL' | translate
                            }}</label>
                        <input type="email" class="form-control" id="register-email" name="register-email"
                            placeholder="{{'MENU.NEW_USER_REGISTRATION.EMAIL_PLACEHOLDER' | translate }}"
                            aria-describedby="register-email" tabindex="2"
                            [ngClass]="{'is-invalid': registrationForm.get('email').touched && registrationForm.get('email').invalid}"
                            formControlName="email"/>
                        <div *ngIf="registrationForm.get('email').touched && registrationForm.get('email').invalid"
                            class="invalid-feedback">
                            <!-- <div *ngIf="registrationForm.get('email').errors.email">
                                {{'MENU.NEW_USER_REGISTRATION.EMAIL_REQUIRED' | translate }}</div> -->
                            <div *ngIf="registrationForm.get('email').errors.pattern">
                                {{'MENU.NEW_USER_REGISTRATION.VALID_EMAIL_REQUIRED' | translate }}</div>
                            <div *ngIf="registrationForm.get('email').errors.maxlength">
                                {{'MENU.NEW_USER_REGISTRATION.EMAIL_MAXIMUM' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="register-password" class="form-label">{{'MENU.NEW_USER_REGISTRATION.PASSWORD' |
                            translate }}</label>

                        <div class="input-group input-group-merge form-password-toggle">
                            <input [type]="passwordTextType ? 'text' : 'password'"
                                class="form-control form-control-merge" id="register-password" name="register-password"
                                placeholder="{{'MENU.NEW_USER_REGISTRATION.PASSWORD_PLACEHOLDER' | translate }}"
                                aria-describedby="register-password" tabindex="3"
                                [ngClass]="{'is-invalid': registrationForm.get('password').touched && registrationForm.get('password').invalid}"
                                formControlName="password" required />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                    <i class="feather font-small-4"
                                        [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                        (click)="togglePasswordTextType()"></i>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="registrationForm.get('password').touched && registrationForm.get('password').invalid"
                            class="invalid-feedback">
                            <div *ngIf="registrationForm.get('password').errors.required">
                                {{'MENU.NEW_USER_REGISTRATION.PASSWORD_REQIURED' | translate }}</div>
                            <div *ngIf="registrationForm.get('password').errors.pattern">
                                {{'MENU.NEW_USER_REGISTRATION.PASSWORD_PATTERN_REQIURED' | translate }}</div>
                            <div *ngIf="registrationForm.get('password').errors.minlength">
                                {{'MENU.NEW_USER_REGISTRATION.PASSWORD_MINIMUM' | translate }}</div>
                            <div *ngIf="registrationForm.get('password').errors.maxlength">
                                {{'MENU.NEW_USER_REGISTRATION.PASSWORD_MAXIMUM' | translate }}</div>
                        </div>
                    </div>
                    <label>{{message}}</label>
                    <button class="btn btn-primary btn-block" tabindex="5" rippleEffect>{{'MENU.FORGOT_PASSWORD.SEND_OTP' | translate }}</button>
                </form>
                <p class="text-center mt-2">
                    <span>{{'MENU.NEW_USER_REGISTRATION.ALREADY_ACCOUNT' | translate }} </span>
                    <a routerLink="/">
                        <span>{{'MENU.NEW_USER_REGISTRATION.SIGN_IN_INSTEAD' | translate }}</span>
                    </a>
                </p>

                <div class="divider my-2">
                    <div class="divider-text">{{'MENU.LOGIN.OR' | translate }}</div>
                </div>

                <div class="auth-footer-btn d-flex justify-content-center">
                    <a href="javascript:void(0)" class="btn btn-facebook">
                        <i data-feather="facebook"></i>
                    </a>
                    <a href="javascript:void(0)" class="btn btn-twitter white">
                        <i data-feather="twitter"></i>
                    </a>
                    <a href="javascript:void(0)" class="btn btn-google">
                        <i data-feather="mail"></i>
                    </a>
                    <a href="javascript:void(0)" class="btn btn-github">
                        <i data-feather="github"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>