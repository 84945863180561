// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
  // hmr: false,
//   apiUrl: 'http://localhost:4000'
// };

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


export const environment = {
  hmr: false,
  apiUrl: 'http://localhost:4000',
  // delet krna h
  production: false,
  EncryptKey: '1203199320052021',
  EncryptIV: '1203199320052021',
  gm_apiKey:'AIzaSyAHDwtYdL5s3kiB6mabFik9efHnTf4cGyA',
  firebase: {
  apiKey: "AIzaSyAaBhgWubnVraO50kiXfc9D6f7L8yx5JY4",
  authDomain: "bihav-matrimony-5dbef.firebaseapp.com",
  projectId: "bihav-matrimony-5dbef",
  storageBucket: "bihav-matrimony-5dbef.appspot.com",
  messagingSenderId: "116729623269",
  appId: "1:116729623269:web:569eb9527d635551159a0c",
  measurementId: "G-V9K29FVZRW",
  vapidKey: "BLkAuTE6gpjVx_dObkA6E_wx538gsDZfDxpwkHLPDZQWYopqnlqqaC-7gEQeuZ_nU5P2zkGd-dVbuPIh4gb7DKM"
  }
};
