<h2 class="card-title mb-1">{{'MENU.DELETE_ACCOUNT.TITLE' | translate}}</h2>
<div class="card mb-1 shadow-none w-100">
    <div class="card-body">
        <form [formGroup]="deleteProfileForm" (ngSubmit)="deleteProfileFormData()">
            <div class="form-group">
                <span for="register-password" class="form-label"><b>{{'MENU.DELETE_ACCOUNT.REASON_QESTION_ONE' |
                        translate}}</b></span>
                <!-- <div class="form-check mt-1">
                    <input class="form-check-input" type="radio" formControlName="delete_reason" value="I found my soulmate."
                        id="flexRadioDefault1" checked>
                    <span class="form-check-label" for="flexRadioDefault1">
                        {{'MENU.DELETE_ACCOUNT.REASON_OPTION_1' | translate}}
                    </span>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="delete_reason" value="Not usefull for me."
                        id="flexRadioDefault2">
                    <span class="form-check-label" for="flexRadioDefault2">
                        {{'MENU.DELETE_ACCOUNT.REASON_OPTION_2' | translate}}
                    </span>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="delete_reason" value="Privacy concern."
                        id="flexRadioDefault3">
                    <span class="form-check-label" for="flexRadioDefault3">
                        {{'MENU.DELETE_ACCOUNT.REASON_OPTION_3' | translate}}
                    </span>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" formControlName="delete_reason" value="Other"
                        id="flexRadioDefault4">
                    <span class="form-check-label" for="flexRadioDefault4">
                        {{'MENU.DELETE_ACCOUNT.REASON_OPTION_4' | translate}}
                    </span>
                </div> -->

                <div class="col-md-12 ">
                    <div class="card-body mb-0 pb-0">
                        <ul class="list-unstyled user-status">
                            <div class="form-group col-md-6">
                                <li class="pb-0">
                                    <div class="custom-control custom-control-primary custom-radio">

<!-- <input class="form-check-input" type="radio" formControlName="delete_reason" value="I found my soulmate."
                        id="flexRadioDefault1" checked>
                    <span class="form-check-label" for="flexRadioDefault1">
                        {{'MENU.DELETE_ACCOUNT.REASON_OPTION_1' | translate}}
                    </span> -->

                                        <input type="radio" class="custom-control-input" formControlName="delete_reason" value="I found my soulmate."
                                            id="flexRadioDefault1" checked />
                                        <label class="custom-control-label ml-25"
                                            for="flexRadioDefault1">{{'MENU.DELETE_ACCOUNT.REASON_OPTION_1' |
                                            translate}}</label>
                                    </div>
                                </li>
                            </div>
                            <div class="form-group col-md-6">
                                <li class="pb-0 ">
                                    <div class="custom-control custom-control-primary custom-radio">

                                        <input type="radio" id="flexRadioDefaultt2" class="custom-control-input"
                                            formControlName="delete_reason" value="Not usefull for me."
                                            id="flexRadioDefault2" />
                                        <label class="custom-control-label ml-25"
                                            for="flexRadioDefault2">{{'MENU.DELETE_ACCOUNT.REASON_OPTION_2' |
                                            translate}}</label>
                                    </div>
                                </li>
                            </div>
                            <div class="form-group col-md-6">
                                <li class="pb-0">
                                    <div class="custom-control custom-control-primary custom-radio">
                                        <input type="radio" class="custom-control-input" formControlName="delete_reason"
                                            value="Privacy concern." id="flexRadioDefault3" />
                                        <label class="custom-control-label ml-25" for="flexRadioDefault3">
                                            {{'MENU.DELETE_ACCOUNT.REASON_OPTION_3' | translate}}</label>
                                    </div>
                                </li>
                            </div>
                            <div class="form-group col-md-6">
                                <li class="pb-0">
                                    <div class="custom-control custom-control-primary custom-radio">
                                        <input type="radio" class="custom-control-input" formControlName="delete_reason"
                                            value="Other" id="flexRadioDefault4" />
                                        <label class="custom-control-label ml-25" for="flexRadioDefault4">
                                            {{'MENU.DELETE_ACCOUNT.REASON_OPTION_4' | translate}}</label>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <span for="basicTextarea"><b> {{'MENU.DELETE_ACCOUNT.REASON_SUGGESTION' | translate}}</b></span>
                <textarea class="form-control mt-1" formControlName="delete_remark" id="basicTextarea" rows="3"
                    placeholder="{{'MENU.DELETE_ACCOUNT.REASON_SUGGESTION_PLACEHOLDER' | translate}}"></textarea>
            </div>
            <div class="form-group">
                <span for="rating"><b>{{'MENU.DELETE_ACCOUNT.REASON_RATE' | translate}}</b></span>
                <div class="col-12 col-xl-4 my-auto mt-1">
                    <div class="rating rating-lg">
                        <ngb-rating [(rate)]="sizeLGCurrentRate" max="5" class="outline-none" id="rating"></ngb-rating>
                    </div>
                </div>
            </div>
            <span *ngIf="message">{{message}}</span>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-primary btn-block" tabindex="5" rippleEffect>Delete</button>
                </div>
            </div>
        </form>
    </div>
</div>