import { HttpClient } from "@angular/common/http";
import { HttpClientBackendService } from "angular-in-memory-web-api";
import { model_response } from "app/model/tbl-profile";
import { ShortlistService } from "app/services/shortlist.service";
import moment from "moment";
import { map, retry } from "rxjs/operators";
import { ListDetail } from 'app/model/list-detail';
import { pipe } from "rxjs";
import { Location, formatDate } from "@angular/common";
import { ProfileFilterModel } from "app/model/profile-filter-model";
import { model_login_response } from "app/model/tbl-login";
import { tbl_chat_messages } from "app/model/tbl-contact";
import { parse } from "date-fns";
import { environment } from "../environments/environment";

import * as CryptoJS from 'crypto-js';
export class base_methods {

    constructor() { }

    delete_filter_record(path: string, event_type: number = 1) {  // 1 - oninit, 2 - btn click
        var data: ProfileFilterModel = JSON.parse(localStorage.getItem("FilterRecord")) || '';
        if (data) {
            switch (event_type) {
                case 1: if (data.page_name != path) {
                    data.page_name = path;
                    data.filter_apply = 0;
                    data.ftr_age_from = null;
                    data.ftr_age_to = null;
                    data.ftr_education = null;
                    data.search_value = '';
                } break;
                case 2:
                    data.page_name = path;
                    data.filter_apply = 0;
                    data.ftr_age_from = null;
                    data.ftr_age_to = null;
                    data.ftr_education = null;
                    break;
            }
        }
    }
}


export const base = {
    // https://api.bihav.co.in/swagger/index.html
    chatFileUrl: 'https://api.bihav.co.in/',
    // apiBaseUrl: 'https://api.bihav.co.in/',
    // apiUrl: 'https://api.bihav.co.in/api',
    // cdn_profile_image: 'https://api.bihav.co.in/uploads/Profile_Photo',
    apiBaseUrl: 'http://mmapi.shivaminfosoft.in/',
    apiUrl: 'http://mmapi.shivaminfosoft.in/api',
    cdn_profile_image: 'http://mmapi.shivaminfosoft.in/uploads/Profile_Photo',
    admin_logo_desktop: 'http://admin.bihav.co.in/uploads/apps/logo.png',
    admin_logo_nav: 'http://admin.bihav.co.in/uploads/apps/logo-nav.png',
    admin_logo_spinner: 'http://admin.bihav.co.in/uploads/apps/spinner.png',
    admin_logo_favicon: 'http://admin.bihav.co.in/uploads/apps/favcon.png',
    admin_logo_mobile: 'http://admin.bihav.co.in/uploads/apps/logo-sm.png',
    admin_app_url: 'http://admin.bihav.co.in',
    default_female_profile_icon: 'default_female_icon.png',

    default_male_profile_icon: 'default_male_icon.png',
    default_date_formate: 'dd-MMM-yyyy',
    transection_date_formate: 'dd-MMM-yyyy HH:mm:ss',
    //   regExpPatterEMAILmobile="^(((\\+91-?)|0)?[0-9]{10})$|^([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})$";
    patternemail: "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
    mobile_emailvalidator: "^(((\\+91-?)|0)?[0-9]{10})$|^([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})$",
    EmailValidator: '^(((\\+91-?)|0)?[0-9]{10})$|^([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})$',
    MobileValidator: '^((\\+91-?)|0)?[0-9]{10}$',
    PasswordValidator: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$',
    cureentPage: 1,
    pageSize: 3,
    default_date_formatet() {
        // alert("hvhjds")
    },


    dateTimeFormat: "dd-MMM-yyyy hh:mm aa",
    dateFormat: 'dd-MMM-yyyy',
    dateTimeFormatDbStanderd: "yyyy-MM-dd'T'HH:mm:ss",

    getDate() { return formatDate(new Date().toISOString(), this.dateFormat, 'en-IN'); },
    getDateTime() { return formatDate(new Date().toISOString(), this.dateTimeFormat, 'en-IN'); },
    getDateTimeDbStanderd() { return formatDate(new Date().toISOString(), this.dateTimeFormatDbStanderd, 'en-IN'); },
    formatDate(date: any) { if (date != null) { date = date.slice(0, 19); const value = parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date()); return value.toString() !== 'Invalid Date' ? formatDate(value.toISOString(), this.dateFormat, 'en-IN') : ''; } return ''; },
    formatDateTime(date: any) { if (date != null) { date = date.slice(0, 19); const value = parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date()); return value.toString() !== 'Invalid Date' ? formatDate(value.toISOString(), this.dateTimeFormat, 'en-IN') : ''; } return ''; },
    formatDate_standard(date: any) { if (date != null) { const value = parse(date, this.dateTimeFormat, new Date()); return value.toString() !== 'Invalid Date' ? formatDate(value.toISOString(), 'yyyy-MM-dd', 'en-IN') : ''; } return ''; },
    formatDateTime_standard(date: any, currentDtFormat: any = null) { if (date != null) { const value = parse(date, currentDtFormat == null ? this.dateTimeFormat : currentDtFormat, new Date()); return value.toString() !== 'Invalid Date' ? formatDate(value.toISOString(), "yyyy-MM-dd'T'HH:mm:ss.fff", 'en-IN') : ''; } return ''; },
    
    convertStrToDate(date: any) { if (date != null) { date = date.slice(0, 11); const value = parse(date, this.dateFormat, new Date()); return value.toString() !== 'Invalid Date' ? value : ''; } return ''; },
  convertStrToDateTime(date: any) { if (date != null) { date = date.slice(0, 19); const value = parse(date, this.dateTimeFormat, new Date()); return value.toString() !== 'Invalid Date' ? value : ''; } return ''; },

subtractDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  },

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },
    
    
    
    

    get_year_from_date(birth_date: Date): any {
        var years = birth_date != null ? moment().diff(birth_date, 'years') + ' years' : '';
        if (years != "") {
            return birth_date != null ? moment().diff(birth_date, 'years') + ' years' : '';
        }
    },
    profile_detail_first_row(pListData: ListDetail): any {

        var birth_date = this.get_year_from_date(pListData.birth_date);
        var firstRowData = birth_date + ", " + pListData.height_en + ", " + pListData.religion_en + ", " + pListData.caste_en +
            ", " + pListData.subcaste + ", " + pListData.marital_status_en + ", " + pListData.mother_tongue_en;

        firstRowData = firstRowData.replace(/ null,/gi, '').replace(/null,/gi, '').replace(/null/gi, '').replace(/undefined,/gi, '').replace(', , /gi', ', ').trim();
        if (firstRowData.startsWith(', ')) firstRowData = firstRowData.slice(2);
        if (firstRowData.endsWith(',')) firstRowData = firstRowData.slice(0, -1);
        return firstRowData;
    },
    profile_detail_second_row(pListData: ListDetail): any {
        // var firstRowData = pListData.educations + ", " + pListData.occupation_en + ", " + pListData.designation_en + ", " + pListData.income_range
        var firstRowData = pListData.occupation_en + ", " + pListData.designation_en + ", " + pListData.income_range
        firstRowData = firstRowData.replace(/ null,/gi, '').replace(/null,/gi, '').replace(/null/gi, '').replace(/undefined,/gi, '').replace(', , /gi', ', ').trim();
        if (firstRowData.startsWith(', ')) firstRowData = firstRowData.slice(2);
        if (firstRowData.endsWith(',')) firstRowData = firstRowData.slice(0, -1);
        return firstRowData;
    },
    profile_detail_third_row(pListData: ListDetail): any {
        var firstRowData = pListData.address + ", " + pListData.city_en + ", " + pListData.state_en + ", " + pListData.country_en
        firstRowData = firstRowData.replace(/ null,/gi, '').replace(/null,/gi, '').replace(/null/gi, '').replace(/undefined,/gi, '').replace(', , /gi', ', ').trim();
        if (firstRowData.startsWith(', ')) firstRowData = firstRowData.slice(2);
        if (firstRowData.endsWith(',')) firstRowData = firstRowData.slice(0, -1);
        return firstRowData;
    },
    profile_detail_concated_for_mobile(pListData: ListDetail): any {
        var birth_date = pListData.birth_date != null ? moment().diff(pListData.birth_date, 'years') + ' years' : '';
        var firstRowData = birth_date + ", " + pListData.height_en + ", " + pListData.religion_en + ", " + pListData.caste_en +
            ", " + pListData.subcaste + ", " + pListData.marital_status_en + ", " + pListData.mother_tongue_en + ", " + pListData.educations + ", " + pListData.occupation_en + ", " + pListData.designation_en + ", " + pListData.income_range + ", " +
            pListData.address + ", " + pListData.city_en + ", " + pListData.state_en + ", " + pListData.country_en;
        firstRowData = firstRowData.replace(/ null,/gi, '').replace(/null,/gi, '').replace(/null/gi, '').replace(/undefined,/gi, '').replace(', , /gi', ', ').trim();
        if (firstRowData.startsWith(', ')) firstRowData = firstRowData.slice(2);
        if (firstRowData.endsWith(',')) firstRowData = firstRowData.slice(0, -1);
        return firstRowData;
    },
    filter_status() {
        // debugger
        var filter_apply_check_for_home: ProfileFilterModel = JSON.parse(localStorage.getItem("FilterRecord")) || '';
        if (filter_apply_check_for_home) {
            return filter_apply_check_for_home.filter_apply;
        } else {
            return 0;
        }
    },

    isMobileNumber(event): boolean {
        const ch = (event.which) ? event.which : event.keyCode;
        if (ch > 31 && (ch < 48 || ch > 57)) {
            console.warn("char code restricted is :" + ch);
            return false;
        }
        return true;
    },

    //      isMobileNumber(event: any) {
    //     const ch = (event.which) ? event.which : event.keyCode;
    //     if (ch > 31 && (ch < 48 || ch > 57)) {
    //       return false;
    //     }
    //     return true;
    //   }

    token() {
        var user: model_login_response = JSON.parse(localStorage.getItem('user') || '')
        // return user.token;
        return user.token;
    },
    chatLoggedOutTime(chatDate: any) {
        // const dt = inject()
        var currentDateTime = new Date();
        // var getDate = currentDateTime.getDate();
        // currentDateTime.toISOString();
        currentDateTime.getDate();
        chatDate.getDate();
        if (currentDateTime.getDate() === chatDate.getDate()) {
            alert("jhj");
            return 'HH:mm'
        } else {
            return 'dd-MM-yyyy HH:mm'
        }
    },

    convert_into_vm_model(data) {
        // return <tbl_chat_messages[]>(data);
        return <tbl_chat_messages[]>JSON.parse(data)
    },

    isImage(file_name: string) {
        var extension = file_name.split('.').pop().toLowerCase();
        var image_types: string[] = ['png', 'gif', 'jpeg', 'jpg'];
        return image_types.includes(extension) ? true : false
    },
    getLastActiveTime(last_seen?: any, logged_in_time?: any, logged_out_time?: any, last_message_time?: any) {
        return this.formatDateTime(last_seen);
    },
    get_last_seen_time(last_seen:any) {
        return this.formatDateTime(last_seen);
    },

    key: CryptoJS.enc.Utf8.parse(environment.EncryptKey),
    iv: CryptoJS.enc.Utf8.parse(environment.EncryptIV),

    encryptUsingAES256(text: any): any {
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    },
    decryptUsingAES256(decString: any) {
        try {
            var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
                keySize: 128 / 8,
                iv: this.iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (e) {
        }
        return null;
    },



    convert_HexToString(hex: any) {
        hex = hex.toString();//force conversion
        var str = '';
        for (var i = 0; i < hex.length; i += 2)
            str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
        return str
    },

    encryptChatMsg(msg: any, chat_id: any, user_id: any, profile_id: any): any {
        return msg;
    },
    decryptChatMsg(msg: any, chat_id: any, user_id: any, profile_id: any): any {
        return msg;
    },


    // from front end

    get_youtube_video_id(url:any){
      var array_1 =   url.split("/youtu.be/", 2);
      if(array_1.length == 2){
         return array_1[1];
      }
      array_1 =   url.split("watch?v=", 2);
      if(array_1.length == 2){
         return array_1[1];
      }
      array_1 =   url.split("/embed/", 2);
      if(array_1.length == 2){
         return array_1[1];
      }
      return "";
 },
 
 get_youtube_thumbnail(url:any){
     return "https://img.youtube.com/vi/" + this.get_youtube_video_id(url) + "/hqdefault.jpg";
 },
 
 socialSites : {
    fb:"https://facebook.com",
    twitter:"https://twitter.com",
    linkedin:"https://linkedin.com"
 },
 appLink : {
    android:"https://facebook.com",
    ios:"https://twitter.com",
 }
}