export class model_preferenceStep2 {
    manglik_id:number;
    religion_id:number;
    caste_id:number;
    star_id:number;
}
export class model_religion {
    religion_id:number;
    religion_hi:string;
    religion_en:string;
    
}
export class model_caste {
    caste_id:number;
    caste_hi:string;
    caste_en:string;
}
export class model_manglik {
    manglik_id:number;
    manglik_hi:string;
    manglik_en:string;
}
export class model_star {
    star_id:number;
    star_hi:string;
    star_en:string;
}
