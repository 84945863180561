import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { model_login_response } from 'app/model/tbl-login';
import { LoginService } from 'app/services/login.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // canActivate( activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      // alert("calling gurad")
    var currentUser: model_login_response = this.loginService.isLoggedIn() //|| null;
    if (currentUser == null) {
      this.router.navigate(['/login']);
      return false;
    } else {
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) == -1) {
        // this._router.navigate(['/pages/miscellaneous/not-authorized']);
        console.warn("res:" + route.data.roles.indexOf(currentUser.token))
        alert("Unauthorized Access")
        this.router.navigate(['/login']);
        return false;
      } else {
        return true;
        this.router.navigateByUrl("/profiles");
      }
    }
  }
}
