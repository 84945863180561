<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
<!-- <img alt="bihav matrimony" style="max-width: 160px;" src="http://admin.bihav.co.in/uploads/apps/logo-nav.png"> -->
          <img src="{{base.admin_app_url}}/uploads/apps/logo-nav.png" alt="bihav matrimony" width="66" />
        </span>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
  <!-- <p id="root"></p> -->
    <!-- Bookmark -->
  <img alt="bihav matrimony" style="max-width: 160px;" src="{{base.admin_app_url}}/uploads/apps/logo-nav.png" alt="bihav matrimony" width="110" />

<!-- <div [ngSwitch]="param_value">
  <img *ngSwitchCase="'profiles'" alt="bihav matrimony" style="max-width: 160px;" src="{{base.admin_app_url}}/uploads/apps/logo-nav.png" alt="bihav matrimony" width="110" />
  <h3 *ngSwitchCase="'profiles?pt=interest'">{{'MENU.PROFILES.ALLSENT' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=sent-pending'" >{{'MENU.PROFILES.SENTPENDING' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=sent-rejected'" >{{'MENU.PROFILES.SENTREJECTED' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=sent-accepted'" >{{'MENU.PROFILES.SENTACCEPTED' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=all-received'" >{{'MENU.PROFILES.ALLRECEIVED' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=received-pending'" >{{'MENU.PROFILES.RECEIVEDPENDING' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=received-rejected'" >{{'MENU.PROFILES.RECEIVEDREJECTED' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=received-accepted'" >{{'MENU.PROFILES.RECEIVEDACCEPTED' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=shortlist'" >{{'MENU.PROFILES.SHORTLIST' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=blocklist'" >{{'MENU.PROFILES.BLOCKLIST' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=iviewedprofile'" >{{'MENU.PROFILES.IVIEWEDPROFILE' | translate}}</h3>
 <h3 *ngSwitchCase="'profiles?pt=myviewedprofile'" >{{'MENU.PROFILES.MYVIEWEDPROFILE' | translate}}</h3>     
 <h3 *ngSwitchCase="'chat'" >{{'MENU.PROFILES.CHAT' | translate}}</h3>     
 <h3 *ngSwitchCase="'photogallery'" >{{'MENU.PROFILES.PHOTOGALLERY' | translate}}</h3> 
</div> -->

    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i>
        <span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a>

      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i>{{languageOptions[lang].title}}
        </a>
      </div>
    </li>
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    <!--/ Toggle skin -->

    <!-- Search -->
    <!-- <app-navbar-search></app-navbar-search> -->
    <!--/ Search -->

    <!-- Cart -->
    <!-- <app-navbar-cart></app-navbar-cart> -->
    <!--/ Cart -->

    <!-- Notification -->
    <app-navbar-notification></app-navbar-notification>
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">{{ currentUser.name | titlecase }}</span>
            <!-- <span class="user-status">{{ this.currentUser.role }}</span> -->
          </div>
          <span class="avatar">
            <!-- <img class="round" src="assets/images/portrait/small/{{ this.currentUser?.photo }}"  -->
            <img class="round" src="{{base.cdn_profile_image}}/{{currentUser?.photo }}" 
            alt="avatar" height="40" width="40"/>
            <!-- <img class="round" src="{{base.cdn_profile_image}}/{{profileData.profile_details[0].photo_background}}" 
            alt="avatar" height="40" width="40" /> -->
              <!-- <span class="avatar-status-online"></span> -->
          </span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/profile-preview']">
          <span [data-feather]="'user'" [class]="'mr-50'"></span>
          <span translate='MENU.PROFILES.PROFILE'></span> 
        </a>
        <a ngbDropdownItem [routerLink]="['/profile']">
          <span [data-feather]="'mail'" [class]="'mr-50'"></span> 
          <span translate='MENU.PROFILES.EDITPROFILE'></span></a>
        <a ngbDropdownItem [routerLink]="['/delete-account']">
          <span [data-feather]="'trash-2'" [class]="'mr-50'"></span> 
          <span translate='MENU.PROFILES.DELETEPROFILE'></span></a>
        <a ngbDropdownItem [routerLink]="['/change-password']">
          <span [data-feather]="'check-square'" [class]="'mr-50'"></span> 
          <span translate='MENU.PROFILES.CHANGEPASSWORD'></span></a>
       <!--  <a ngbDropdownItem [routerLink]="'/membership'">
                <span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats</a>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem [routerLink]="['/pages/account-settings']"><span [data-feather]="'settings'"
            [class]="'mr-50'"></span> Settings</a>
        <a ngbDropdownItem [routerLink]="['/pages/pricing']"><span [data-feather]="'credit-card'" [class]="'mr-50'"></span>
          Pricing</a> -->
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem [routerLink]="'/privacy-setting'"> 
            <span [data-feather]="'help-circle'" [class]="'mr-50'"></span> 
          <span translate='MENU.PROFILES.PRIVACYSETTING'></span>
        </a>
          <a ngbDropdownItem [routerLink]="['/member-ship']">
            <span [data-feather]="'eye'" [class]="'mr-50'"></span>
          <span translate='MENU.PROFILES.MEMBERSHIP'></span>
        </a>
          <a ngbDropdownItem (click)="logout()">
            <span [data-feather]="'power'" [class]="'mr-50'"></span> 
          <span translate='MENU.PROFILES.LOGOUT'></span>
        </a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
