<h2 class="card-title mb-1">{{'MENU.CHANGE_PASSWORD.TITLE' | translate}}</h2>
<div class="card mb-1 shadow-none w-100">
    <div class="card-body">
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordFormData()">
            <div class="form-group">
                <label for="register-password" class="form-label">{{'MENU.CHANGE_PASSWORD.OLD_PASSWORD' |
                    translate}}</label>
                <div class="input-group input-group-merge form-password-toggle">
                    <input [type]="passwordTextType ? 'text' : 'password'" class="form-control form-control-merge"
                        placeholder="············" tabindex="3"
                        [ngClass]="{'is-invalid': ( controls.old_password.touched || submitted ) && controls.old_password.invalid}"
                        formControlName="old_password" required maxlength="15"/>
                    <div class="input-group-append">
                        <span class="input-group-text cursor-pointer">
                            <i class="feather font-small-4"
                                [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType, 'is-invalid': ( controls.old_password.touched || submitted ) && controls.old_password.invalid}"
                                (click)="togglePasswordTextType()"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="( controls.old_password.touched || submitted )" class="text-danger">
                    <div *ngIf="controls.old_password.errors?.required && controls.old_password.invalid">
                        {{'MENU.CHANGE_PASSWORD.PASSWORD_VALIDATION_REQUIRED' | translate}}</div>
                    <div *ngIf="controls.old_password.errors?.pattern">
                        {{'MENU.CHANGE_PASSWORD.ASSWORD_VALIDATION_PATTERN' | translate}} </div>
                </div>
            </div>
            <div class="form-group">
                <label for="register-password" class="form-label">{{'MENU.CHANGE_PASSWORD.NEW_PASSWORD' |
                    translate}}</label>
                <div class="input-group input-group-merge form-password-toggle">
                    <input [type]="passwordTextType ? 'text' : 'password'" class="form-control form-control-merge"
                        id="register-password" placeholder="············" aria-describedby="register-password"
                        tabindex="3"
                        [ngClass]="{'is-invalid': ( controls.password.touched || submitted ) && controls.password.invalid}"
                        formControlName="password" required required maxlength="15" />
                    <div class="input-group-append">
                        <span class="input-group-text cursor-pointer">
                            <i class="feather font-small-4"
                                [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                (click)="togglePasswordTextType()"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="( controls.password.touched || submitted )" class="text-danger">
                    <div *ngIf="controls.password.errors?.required && controls.password.invalid">
                        {{'MENU.CHANGE_PASSWORD.PASSWORD_VALIDATION_REQUIRED' | translate}}</div>
                    <div *ngIf="controls.password.errors?.pattern">
                        {{'MENU.CHANGE_PASSWORD.ASSWORD_VALIDATION_PATTERN' | translate}} </div>
                </div>
            </div>
            <div class="form-group">
                <label for="register-password" class="form-label">{{'MENU.CHANGE_PASSWORD.CONFIRM_PASSWORD' |
                    translate}}</label>
                <div class="input-group input-group-merge form-password-toggle">
                    <input [type]="passwordTextType ? 'text' : 'password'" class="form-control form-control-merge"
                        id="register-password" placeholder="············" aria-describedby="register-password"
                        tabindex="3"
                        [ngClass]="{'is-invalid': ( controls.confirm_password.touched || submitted ) && controls.confirm_password.invalid}"
                        formControlName="confirm_password" required maxlength="15" />
                    <div class="input-group-append">
                        <span class="input-group-text cursor-pointer">
                            <i class="feather font-small-4"
                                [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                (click)="togglePasswordTextType()"></i>
                        </span>
                    </div>
                </div>
                <span class="text-danger" *ngIf="controls.confirm_password.touched && controls.confirm_password.errors?.required">
                    {{'MENU.RESET_PASSWORD.CONFIRM_REQIURED' |
                    translate}}
                </span>
                <div class="text-danger" *ngIf="controls.confirm_password.errors?.mustMatch">
                    {{'MENU.RESET_PASSWORD.CONFIRM_MUSTMATCH' |
                    translate}}
                </div>
            </div>
            <!-- <span *ngIf="message">{{message}}</span> -->
            <button type="submit" class="btn btn-primary btn-block" tabindex="5" [disabled]="!changePasswordForm.valid"
                rippleEffect>{{'MENU.CHANGE_PASSWORD.BUTTON_CHANGE_PASSWORD' | translate}}</button>
        </form>
    </div>
</div>