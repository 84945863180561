import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { profile } from 'node:console';
import { concat, Observable, Subject } from 'rxjs';
import { RegistService } from '../services/regist.service';
import { TestService } from '../services/test.service';
import { TblProfile, TblProfile2, TblProfile3, TblProfile4, TblProfile5 } from '../../app/model/tbl-profile';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Email } from 'app/main/apps/email/email.model';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class DashboardComponent implements OnInit {

  public folders;
  public labels;
  public openComposeRef;
  public draftCount;
  public unReadInboxCount;

// email list  ----
  public emails: Email[];
  public hasSelectedMails;
  public isIndeterminate;

  private _unsubscribeAll: Subject<any>;

  public selected;
  email: Email;

  /**
   *
   * @param {EmailService} _emailService
   */

   onSelectedChange() {
   // this._emailService.toggleSelectedMail(this.email.id);
  }

  /**
   * Toggle Starred
   */
  toggleStarred() {
   // this._emailService.toggleStarred(this.email);
  }


//------email list end


  // number=[];

  //   myForm = new FormGroup({
  //       name: new FormControl('', [Validators.required, Validators.minLength(3)]),
  //       file: new FormControl('', [Validators.required]),
  //       fileSource: new FormControl('', [Validators.required])
  //     });

  openCompose() {
   // this.openComposeRef = true;
   // this._emailService.composeEmail(this.openComposeRef);
   // this._coreSidebarService.getSidebarRegistry('email-sidebar').toggleOpen();
  }

   /**
   * Toggle Sidebar
   *
   * @param nameRef
   */
    toggleSidebar(nameRef): void {
     // this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
    }

     /**
   * Query Update
   *
   * @param queryValue
   */
  queryUpdate(queryValue) {
    //this._emailService.updateSearchText(queryValue.target.value);
  }

  /**
   * Toggle Select All
   */
   toggleSelectAll() {
   // this._emailService.toggleSelectAll();
  }

/**
   * Update Folder On Selected Emails
   *
   * @param folderRef
   */
 updateFolderOnSelectedMails(folderRef) {
  //this._emailService.updateFolderOnSelectedEmails(folderRef);
}
updateLabelOnSelectedEmails(){

}

unRead(){

}

openEmail(){
  
}





    ngOnInit() {

        
    }
  
    // shortLink: string = "";
    // loading: boolean = false; // Flag variable
    // file: File = null;

    constructor(private testService: TestService) { 

        // for(let i=0;i<1000;i++){
        //     this.number.push(i);
        // }
        // console.warn(this.number);
    this._unsubscribeAll = new Subject();
     

    }

    // onChange(event) {
    //     this.file = event.target.files[0];
    // }
  
    // onUpload() {
    //     this.loading = !this.loading;
    //     console.log(this.file);
    //     this.testService.upload(this.file).subscribe(
    //         (event: any) => {
    //             if (typeof (event) === 'object') {
  
    //                 // Short link via api response
    //                 this.shortLink = event.link;
  
    //                 this.loading = false; // Flag variable 
    //             }
    //         }
    //     );
    // }

  // get f(){
  //   return this.myForm.controls;
  // }
     
  // onFileChange(event:any) {
  
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     this.myForm.patchValue({
  //       fileSource: file
  //     });
  //   }
  // }
     
  // submit(){
  //   const formData = new FormData();
  //   formData.append('file', this.myForm.get('fileSource')?.value);

  //        console.log(formData);
   
    // this.http.post('http://localhost:8001/upload.php', formData)
    //   .subscribe(res => {
    //     console.log(res);
    //     alert('Uploaded Successfully.');
    //   })
    // }




    //prot;
    //tabOne;
    //tab2;
    //tab3;
    //tab4;
    //tab5;
    //public country_en;
    //// public tabOne;
    //// public
    //public contentHeader: object;
    //multipleselection: FormGroup;
    ////  basicDetailForm: FormGroup;
    //// public TDNameVar;
    ////public TDEmailVar;
    ////public TDFirstNameVar;
    ////public TDLastNameVar;
    ////public twitterVar;
    ////public facebookVar;
    ////public googleVar;
    ////public linkedinVar;
    ////public landmarkVar;
    ////public addressVar; 
    //public first_name;
    //public profile_first_name;
    //public middle_name;
    //public last_name;
    //public height_id;
    //public weight_id;
    //public mother_tongue_id;
    //public marital_status_id;
    //public skin_type_id;
    //public body_type_id;
    //public eating_habit_id;
    //public drinking_habit_id;
    //public smoking_habit_id;
    //public blood_group_id;
    //public birth_place;
    //public birth_time;
    //public birth_date;
    //public about_me;
    //public hobby;

    //public religion_id;
    //public caste_id;
    //public subcaste;
    //public manglik_id;
    //public horoscope_id;
    //public star_id;
    //public moonsign_id;
    //public gothra_id;

    //public address;
    //public country_id;
    //public state_id;
    //public city_id;
    //public mobile;
    //public phone;
    //public residence_id;

    //public education_id;
    //public employed_in_id;
    //public designation_id;
    //public occupation_id;
    //public annual_income_id;

    //public family_type_id;
    //public family_status_id;
    //public father_name;
    //public f_occupation_id;
    //public mother_Name;
    //public m_occupation_id;
    //public total_brothers;
    //public total_sisters;
    //public total_married_brother;
    //public total_married_sister;
    //public about_family;




    //// TblProfile = profile_step_1

    ////res
    ////res.staus
    ////.data1[
    ////    profile_step_1[

    ////    ],
    ////mst_height[
    ////height_en
    ////    height_id

    ////]
    ////]
    ////.message

    ////data2 = data1;

    ////public itemsHeight = [
    ////    { height_en: '5 ft', height_id: 1 },
    ////    { height_en: '6 ft', height_id: 2 },
    ////    { height_en: '7 ft', height_id: 3 },
    ////    { height_en: '8 ft', height_id: 4 }
    ////];

    //public itemsHeight = [
    //    { name: '5 ft' },
    //    { name: '6 ft' },
    //    { name: '7 ft' },
    //    { name: '8 ft' }
    //];
    ////public itemsWeight = [
    ////    { name: '50kg' },
    ////    { name: '60kg' },
    ////    { name: '70kg' },
    ////    { name: '80kg' }
    ////];
    //public itemsMotherTongue = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsMaritalStatus = [
    //    { name: 'Unmarried' },
    //    { name: 'married' }
    //];
    //public itemsSkinType = [
    //    { name: 'Wheatish Brown' },
    //    { name: 'Brown' },
    //    { name: 'Yellow' },
    //    { name: 'Skin' }
    //];
    //public itemsBodyType = [
    //    { name: 'highted' },
    //    { name: 'normal' },
    //    { name: 'motu' },
    //    { name: 'patlu' }
    //];
    //public itemsEatingHabit = [
    //    { name: 'veg' },
    //    { name: 'non veg' },
    //    { name: 'both' },
    //    { name: 'other' }
    //];
    //public itemsDrinkingHabit = [
    //    { name: 'No' },
    //    { name: 'Yes' }
    //];
    //public itemsSmockingHabit = [
    //    { name: 'No' },
    //    { name: 'Yes' }
    //];
    //public itemsBloodGroup = [
    //    { name: 'A+' },
    //    { name: 'A-' },
    //    { name: 'B+' },
    //    { name: 'B-' },
    //    { name: 'O+' },
    //    { name: 'O-' }
    //];
    //public itemsBirthPlace = [
    //    { name: 'Chhattisgarh' },
    //    { name: 'MP' },
    //    { name: 'UP' },
    //    { name: 'Chhandigarh' },
    //    { name: 'Haryana' },
    //    { name: 'Hyderabad' }
    //];
    //public itemsBirthtime = [
    //    { name: '1' },
    //    { name: '2' },
    //    { name: '3' },
    //    { name: '4' },
    //    { name: '5' },
    //    { name: '6' }
    //];
    //public itemsSelectMultiOtherKnownLanguage: Array<any> = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public selectMultiSelectedLanguage;

    //public itemsReligion = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsCaste = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsManglik = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsHoroscope = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsStar = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsMoonsign = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsCountry = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsState = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsCity = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsResidenceStatus = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsSelectMultiEducation = [
    //    { id: 1, name: 'BE' },
    //    { id: 2, name: 'Btech' },
    //    { id: 3, name: 'Mtech' },
    //    { id: 4, name: 'MBA' }
    //];
    //public selectMultiSelectedEducation;

    //public itemsEmployedIn = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsDesignation = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsOccupation = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsIncome = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsFamilyType = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsFamilyStatus = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsBrother = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsSister = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsMariedBrother = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];
    //public itemsMarriedSister = [
    //    { name: 'Chhattisgarhi' },
    //    { name: 'Hindi' },
    //    { name: 'English' },
    //    { name: 'Marathi' }
    //];

    //profile_step_1;
    //mst_height;
    //ProfileTabOneHeightData;
    //ProfileTabOneWeightData;
    //ProfileTabOneMotherToungData;
    //ProfileTabOneMaritalStatusData;
    //ProfileTabOneSkinTypeData
    //ProfileTabOneBodyTypeData;
    //ProfileTabOneEatingHabitData;
    //ProfileTabOneDrinkingHabitData;
    //ProfileTabOneSmokingHabitData;
    //ProfileTabOneBloodGroupData;

    //ProfileTab2religiousData;
    //ProfileTab2casteData;
    //ProfileTab2manglikData;
    //ProfileTab2gothraData;
    //ProfileTab2horoscopeData;
    //ProfileTab2starData;
    //ProfileTab2moonsignData;

    //ProfileTab3CountriesData;
    //ProfileTab3StatesData;
    //ProfileTab3CitiesData;
    //ProfileTab3Residence_statusData;

    //ProfileTab4EducationData;
    //ProfileTab4Employed_inData;
    //ProfileTab4DesignationData;
    //ProfileTab4OccupationData;
    //ProfileTab4Annual_incomeData;

    //ProfileTab5Family_typeData;
    //ProfileTab5Family_statusData;
    //ProfileTab5OccupationData;
    //ProfileTab5M_occupationData;







    ////get firstName() {
    ////    return this.basicDetailForm.get("firstName");
    ////}
    ////get middleName() {
    ////    return this.basicDetailForm.get("middleName");
    ////}
    ////get lastName() {
    ////    return this.basicDetailForm.get("lastName");
    ////}
    ////get height() {
    ////    return this.basicDetailForm.get("height");
    ////}
    ////get weight() {
    ////    return this.basicDetailForm.get("weight");
    ////}
    ////get motherTongue() {
    ////    return this.basicDetailForm.get("motherTongue");
    ////}
    ////get maritalStatus() {
    ////    return this.basicDetailForm.get("maritalStatus");
    ////}
    ////get skinTone() {
    ////    return this.basicDetailForm.get("skinTone");
    ////}
    ////get bodyType() {
    ////    return this.basicDetailForm.get("bodyType");
    ////}
    ////get eatingHabit() {
    ////    return this.basicDetailForm.get("drinkingHabit");
    ////}
    ////get drinkingHabit() {
    ////    return this.basicDetailForm.get("drinkingHabit");
    ////}
    ////get smockingHabit() {
    ////    return this.basicDetailForm.get("smockingHabit");
    ////}
    ////get bloodGroup() {
    ////    return this.basicDetailForm.get("bloodGroup");
    ////}
    ////get birthPlace() {
    ////    return this.basicDetailForm.get("birthPlace");
    ////}
    ////get birthtime() {
    ////    return this.basicDetailForm.get("birthtime");
    ////}
    ////get OtherKnownLanguage() {
    ////    return this.basicDetailForm.get("OtherKnownLanguage");
    ////}
    ////get aboutMe() {
    ////    return this.basicDetailForm.get("aboutMe");
    ////}
    ////get hobby() {
    ////    return this.basicDetailForm.get("hobby");
    ////}
    ////get religion() {
    ////    return this.basicDetailForm.get("religion");
    ////}
    ////get caste() {
    ////    return this.basicDetailForm.get("caste");
    ////}
    ////get subcaste() {
    ////    return this.basicDetailForm.get("subcaste");
    ////}
    ////get manglik() {
    ////    return this.basicDetailForm.get("manglik");
    ////}
    ////get horoscope() {
    ////    return this.basicDetailForm.get("horoscope");
    ////}
    ////get star() {
    ////    return this.basicDetailForm.get("star");
    ////}
    ////get moonsign() {
    ////    return this.basicDetailForm.get("moonsign");
    ////}
    ////get gothra() {
    ////    return this.basicDetailForm.get("gothra");
    ////}
    ////get address() {
    ////    return this.basicDetailForm.get("address");
    ////}
    ////get country() {
    ////    return this.basicDetailForm.get("country");
    ////}
    ////get state() {
    ////    return this.basicDetailForm.get("state");
    ////}
    ////get city() {
    ////    return this.basicDetailForm.get("city");
    ////}
    ////get mobileNo() {
    ////    return this.basicDetailForm.get("mobileNo");
    ////}
    ////get phone() {
    ////    return this.basicDetailForm.get("phone");
    ////}
    ////get timeToCall() {
    ////    return this.basicDetailForm.get("timeToCall");
    ////}
    ////get residenceStatus() {
    ////    return this.basicDetailForm.get("residenceStatus");
    ////}
    ////get eduaction() {
    ////    return this.basicDetailForm.get("education");
    ////}
    ////get employedIn() {
    ////    return this.basicDetailForm.get("employedIn");
    ////}
    ////get designation() {
    ////    return this.basicDetailForm.get("designation");
    ////}
    ////get occupation() {
    ////    return this.basicDetailForm.get("occupation");
    ////}
    ////get income() {
    ////    return this.basicDetailForm.get("income");
    ////}
    ////get familyType() {
    ////    return this.basicDetailForm.get("familyType");
    ////}
    ////get familyStatus() {
    ////    return this.basicDetailForm.get("familyStatus");
    ////}
    ////get fatherName() {
    ////    return this.basicDetailForm.get("fatherName");
    ////}
    ////get fatherOccupation() {
    ////    return this.basicDetailForm.get("fatherOccupation");
    ////}
    ////get motherName() {
    ////    return this.basicDetailForm.get("montherName");
    ////}
    ////get motherOccupation() {
    ////    return this.basicDetailForm.get("motherOccupation");
    ////}
    ////get brothers() {
    ////    return this.basicDetailForm.get("brothers");
    ////}
    ////get sisters() {
    ////    return this.basicDetailForm.get("sisters");
    ////}
    ////get marriedBrother() {
    ////    return this.basicDetailForm.get("marriedBrother");
    ////}
    ////get marriedSister() {
    ////    return this.basicDetailForm.get("marriedSister");
    ////}
    ////get aboutFamily() {
    ////    return this.basicDetailForm.get("aboutFamily");
    ////}



    ////public selectBasic = [
    ////    { name: 'UK' },
    ////    { name: 'USA' },
    ////    { name: 'Spain' },
    ////    { name: 'France' },
    ////    { name: 'Italy' },
    ////    { name: 'Australia' }
    ////];

    ////public selectMulti = [{ name: 'English' }, { name: 'French' }, { name: 'Spanish' }];
    ////public selectMultiSelected;

    //// private
    ////private horizontalWizardStepper: Stepper;
    ////private verticalWizardStepper: Stepper;
    ////private modernWizardStepper: Stepper;

    //private modernVerticalWizardStepper: Stepper;
    //private bsStepper;
    //modernWizardStepper: any;
    //horizontalWizardStepper: Stepper;
    //verticalWizardStepper: Stepper;

    //dropDownList;
    //getOne;
    //weight;

    ///**
    // * Horizontal Wizard Stepper Next
    // *
    //  @param data
    // **/
    ////horizontalWizardStepperNext(data) {
    ////    if (data.form.valid === true) {
    ////        this.horizontalWizardStepper.next();
    ////    }
    ////}
    ///**
    // * Horizontal Wizard Stepper Previous
    // */
    ////horizontalWizardStepperPrevious() {
    ////    this.horizontalWizardStepper.previous();
    ////}

    ///**
    // * Vertical Wizard Stepper Next
    // */
    ////verticalWizardNext() {
    ////    this.verticalWizardStepper.next();
    ////}
    ///**
    // * Vertical Wizard Stepper Previous
    // */
    ////verticalWizardPrevious() {
    ////    this.verticalWizardStepper.previous();
    ////}
    ///**
    // * Modern Horizontal Wizard Stepper Next
    // */
    ////modernHorizontalNext() {
    ////    this.modernWizardStepper.next();
    ////}
    ///**
    // * Modern Horizontal Wizard Stepper Previous
    // */
    ////modernHorizontalPrevious() {
    ////    this.modernWizardStepper.previous();
    ////}
    ///**
    // * Modern Vertical Wizard Stepper Next
    // */
    ///**
    // * Horizontal Wizard Stepper Next
    // *
    //  @param dataPro
    // **/
    //modernVerticalNext() {
    //    this.modernVerticalWizardStepper.next();
    //}
    //modernVerticalNextp(dataPro) {

    //    if (dataPro.form.valid === true) {
    //        this.modernVerticalWizardStepper.next();

    //    }
    //}
    ///**
    // * Modern Vertical Wizard Stepper Previous
    // */
    //modernVerticalPrevious() {
    //    this.modernVerticalWizardStepper.previous();
    //}

    ///**
    // * On Submit
    // */
    //onSubmit() {
    //    return false;
    //}

    //constructor(private formBuilder: FormBuilder, private registService: RegistService) { }


    //// Lifecycle Hooks
    //// -----------------------------------------------------------------------------------------------------

    ///**
    // * On Init
    // */
    //ngOnInit() {

    //    //this.basicDetailForm = new FormGroup({
    //    //    first_name: new FormControl(this.profile_step_1[0].profile_first_name),
    //    //    middle_name: new FormControl(this.profile_step_1[0].profile_middle_name),
    //    //    last_name: new FormControl(this.profile_step_1[0].profile_last_name),
    //    //    height_id: new FormControl(''),
    //    //    weight_id: new FormControl(''),
    //    //    mother_tongue_id: new FormControl(''),
    //    //    marital_status_id: new FormControl(''),
    //    //    skin_type_id: new FormControl(''),
    //    //    body_type_id: new FormControl(''),
    //    //    eating_habit_id: new FormControl(''),
    //    //    drinking_habit_id: new FormControl(''),
    //    //    smoking_habit_id: new FormControl(''),
    //    //    blood_group_id: new FormControl(''),
    //    //    birth_place: new FormControl(''),
    //    //    birth_time: new FormControl(''),
    //    //    other_known_language: new FormControl(''),
    //    //    about_me: new FormControl(''),
    //    //    // hobby: new FormControl('', [Validators.required]),
    //    //    // religion: new FormControl('', [Validators.required]),
    //    //    // caste: new FormControl('', [Validators.required]),
    //    //    //    subcaste: new FormControl('', [Validators.required]),
    //    //    //    manglik: new FormControl('', [Validators.required]),
    //    //    //    horoscope: new FormControl('', [Validators.required]),
    //    //    //    star: new FormControl('', [Validators.required]),
    //    //    //    moonsign: new FormControl('', [Validators.required]),
    //    //    //    gothra: new FormControl('', [Validators.required]),
    //    //    //    address: new FormControl('', [Validators.required]),
    //    //    //    country: new FormControl('', [Validators.required]),
    //    //    //    state: new FormControl('', [Validators.required]),
    //    //    //    city: new FormControl('', [Validators.required]),
    //    //    //    mobileNo: new FormControl('', [Validators.required]),
    //    //    //    phone: new FormControl('', [Validators.required]),
    //    //    //    timeToCall: new FormControl('', [Validators.required]),
    //    //    //    residenceStatus: new FormControl('', [Validators.required]),
    //    //    //    education: new FormControl('', [Validators.required]),
    //    //    //    employedIn: new FormControl('', [Validators.required]),
    //    //    //    designation: new FormControl('', [Validators.required]),
    //    //    //    occupation: new FormControl('', [Validators.required]),
    //    //    //    income: new FormControl('', [Validators.required]),
    //    //    //    familyType: new FormControl('', [Validators.required]),
    //    //    //    familyStatus: new FormControl('', [Validators.required]),
    //    //    //    fatherName: new FormControl('', [Validators.required]),
    //    //    //    fatherOccupation: new FormControl('', [Validators.required]),
    //    //    //    motherName: new FormControl('', [Validators.required]),
    //    //    //    motherOccupation: new FormControl('', [Validators.required]),
    //    //    //    brothers: new FormControl('', [Validators.required]),
    //    //    //    sisters: new FormControl('', [Validators.required]),
    //    //    //    mariedBrother: new FormControl('', [Validators.required]),
    //    //    //    marriedSister: new FormControl('', [Validators.required]),
    //    //    //    aboutFamily: new FormControl('', [Validators.required]),
    //    //})

    //    this.dropDownList = this.getData();
    //    this.weight = this.getWeight();
    //    this.initForm();
    //    this.getProfileOneData();
    //    this.getProfileTabTwoData();
    //    this.getProfileTabThreeData();
    //    this.getProfileTabFourData();
    //    this.getProfileTabFiveData();
    //    this.onSelectEducation(event);
    //    //this.tk();

    //    //this.getProfileTabFiveData();


    //    this.getmulti();
    //    // this.profileOneForm();


    //    //this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});

    //    //this.verticalWizardStepper = new Stepper(document.querySelector('#stepper2'), {
    //    //    linear: false,
    //    //    animation: true
    //    //});

    //    //this.modernWizardStepper = new Stepper(document.querySelector('#stepper3'), {
    //    //    linear: false,
    //    //    animation: true
    //    //});

    //    this.modernVerticalWizardStepper = new Stepper(document.querySelector('#stepper4'), {
    //        linear: false,
    //        animation: true
    //    });

    //    this.bsStepper = document.querySelectorAll('.bs-stepper');

    //    // content header
    //    this.contentHeader = {
    //        headerTitle: 'Form Wizard',
    //        actionButton: true,
    //        breadcrumb: {
    //            type: '',
    //            links: [
    //                {
    //                    name: 'Home',
    //                    isLink: true,
    //                    link: '/'
    //                },
    //                {
    //                    name: 'Forms',
    //                    isLink: true,
    //                    link: '/'
    //                },
    //                {
    //                    name: 'Form Wizard',
    //                    isLink: false
    //                }
    //            ]
    //        }
    //    };
    //}
    //onSelectEducation(event) {

    //    console.warn(this.education_id);
    //}
    ////Tab1
    //onItemSelect(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectHeight(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectWeight(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectMotherTongue(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectMaritalStatus(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectSkinType(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectBodyType(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectEatingHabit(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectDrinkingHabit(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectSmokingHabit(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectBloodGroup(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}


    ////Tab2

    //onSelectReligion(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectCaste(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectManglik(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectHoroscope(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectStar(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectMoonsign(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}

    //onSelectGothra(event) {
    //    console.warn('$event is:', event);
    //    //let data = JSON.stringify(event);
    //    //this.data1 = data;
    //}




    //getData(): Array<any> {

    //    return [
    //        { id: 1, name: 'BE' },
    //        { id: 2, name: 'B Tech' },
    //        { id: 3, name: 'M Tech' },
    //        { id: 4, name: 'MBA' }
    //    ];
    //}
    //getWeight() {



    //}
    //submitMultivalue(data) {
    //    console.warn('data is:', data);
    //    //  console.log(this.blood_group_id)
    //    let one = JSON.stringify(data);
    //    this.getOne = one;
    //    this.multipleselection.reset();
    //}

    ////profileOneForm() {

    ////    this.basicDetailForm = this.formBuilder.group({
    ////        profile_first_name: ['']
    ////    })

    ////}



    //initForm() {
    //    this.multipleselection = this.formBuilder.group({
    //        course: ['', [Validators.required]],
    //        profileFirstName: ['', [Validators.required]]
    //    })
    //}

    //getmulti() {
    //    this.registService.getProfile().subscribe(response => {
    //        // let value = response;
    //        // this.multipleselection.controls['profile_first_name'].setValue(this.tabOne.data.profileData.profile_step_1.profile_first_name);
    //        // this.employeeForm.controls['LastName'].setValue(employee.LastName);

    //    })
    //}

    //getProfileOneData() {
    //    // debugger
    //    this.registService.getProfile().subscribe(res => {
    //        //  console.warn(res);
    //        this.tabOne = res;

    //        // if (res) {
    //        //     let tempAD: any = [];
    //        //     this.profile_step_1 = this.tabOne.data.profileData;
    //        //     for (let tem of this.profile_step_1) {
    //        //         this.registService.getProfileBy(tem.profile_first_name).subscribe(res => {
    //        //             tempAD.push(res.list[0])
    //        //         })
    //        //     }
    //        //     //let tempAD: any = [];

    //        //     //for (let temp of this.profile_step_1) {
    //        //     //    this.registService.getProfile().subscribe(Response: any => {
    //        //     //        tempAD.push(Response.profileData[0])
    //        // }
    //        // //)
    //        //   //  }
    //        //// }

    //        this.profile_step_1 = this.tabOne.data.profileData[0];
    //        // console.warn(this.profile_step_1.profile_first_name);
    //        //console.warn(this.profile_step_1);

    //        this.ProfileTabOneHeightData = this.tabOne.data.heightData

    //        this.ProfileTabOneWeightData = this.tabOne.data.weightData;
    //        // console.warn(this.ProfileTabOneWeightData);

    //        this.ProfileTabOneMotherToungData = this.tabOne.data.languageData;

    //        this.ProfileTabOneMaritalStatusData = this.tabOne.data.martialStatusData;

    //        this.ProfileTabOneSkinTypeData = this.tabOne.data.skinTypeData;

    //        this.ProfileTabOneBodyTypeData = this.tabOne.data.bodyTypeData;

    //        this.ProfileTabOneEatingHabitData = this.tabOne.data.eatingHabitData;

    //        this.ProfileTabOneDrinkingHabitData = this.tabOne.data.drinkingHabitData;

    //        this.ProfileTabOneSmokingHabitData = this.tabOne.data.smokingHabitData;

    //        this.ProfileTabOneBloodGroupData = this.tabOne.data.bloodGroupData;



    //        //for (let PD of this.PDD) {
    //        //    const PDDa = PD;
    //        //    console.warn(PDDa.weight_hi);
    //        //}
    //        //this.mst_height = this.tabOne.data.mst_height;
    //        // const myJD = JSON.parse();
    //        // console.warn(onj.data.profile_step_1[1].first_name);
    //        //console.warn(this.profile_step_1[0].first_name);
    //        //this.prot = this.tabOne.data.profile_step_1.first_name;

    //    })
    //}

    //getProfileTabTwoData() {
    //    //  debugger
    //    this.registService.getProfileTab2().subscribe(res => {
    //        console.warn(res);
    //        this.tab2 = res;

    //        // this.profile_step_1 = this.tabOne.data.profileData[0];
    //        // this.ProfileTabOneHeightData = this.tabOne.data.heightData

    //        //   this.ProfileTabOneWeightData = this.tabOne.data.weightData;
    //        //  console.warn(this.ProfileTabOneWeightData);

    //        this.ProfileTab2religiousData = this.tab2.data.religiousData;
    //        console.warn(this.ProfileTab2religiousData);



    //        this.ProfileTab2casteData = this.tab2.data.casteData;

    //        this.ProfileTab2manglikData = this.tab2.data.manglikData;

    //        this.ProfileTab2gothraData = this.tab2.data.gothraData;

    //        this.ProfileTab2horoscopeData = this.tab2.data.horoscopeData;

    //        this.ProfileTab2starData = this.tab2.data.starData;

    //        this.ProfileTab2moonsignData = this.tab2.data.moonsignData;

    //    })
    //}

    //getProfileTabThreeData() {
    //    // debugger
    //    this.registService.getProfileTab3().subscribe(res => {
    //        console.warn(res);
    //        this.tab3 = res;

    //        //this.profile_step_1 = this.tabOne.data.profileData[0];
    //        //this.ProfileTabOneHeightData = this.tabOne.data.heightData


    //        this.ProfileTab3CountriesData = this.tab3.data.countriesData;

    //        this.ProfileTab3StatesData = this.tab3.data.statesData;

    //        this.ProfileTab3CitiesData = this.tab3.data.citiesData;

    //        this.ProfileTab3Residence_statusData = this.tab3.data.residence_statusData;


    //    })
    //}

    ////#region "Profile Tab 1"



    ////#endregion

    //getProfileTabFourData() {
    //    // debugger
    //    this.registService.getProfileTab4().subscribe(res => {
    //        console.warn(res);
    //        this.tab4 = res;

    //        // this.profile_step_1 = this.tabOne.data.profileData[0];
    //        //this.ProfileTabOneHeightData = this.tabOne.data.heightData

    //        // this.ProfileTabOneWeightData = this.tabOne.data.weightData;
    //        //console.warn(this.ProfileTabOneWeightData);

    //        this.ProfileTab4EducationData = this.tab4.data.educationData;

    //        this.ProfileTab4Employed_inData = this.tab4.data.employed_inData;

    //        this.ProfileTab4DesignationData = this.tab4.data.designationData;

    //        this.ProfileTab4OccupationData = this.tab4.data.occupationData;

    //        this.ProfileTab4Annual_incomeData = this.tab4.data.annual_incomeData;


    //    })
    //}

    //getProfileTabFiveData() {
    //    // debugger
    //    this.registService.getProfileTab5().subscribe(res => {
    //        console.warn(res);
    //        this.tab5 = res;

    //        //this.profile_step_1 = this.tabOne.data.profileData[0];
    //        //this.ProfileTabOneHeightData = this.tabOne.data.heightData

    //        //this.ProfileTabOneWeightData = this.tabOne.data.weightData;
    //        //console.warn(this.ProfileTabOneWeightData);

    //        this.ProfileTab5Family_typeData = this.tab5.data.family_typeData;

    //        this.ProfileTab5Family_statusData = this.tab5.data.family_statusData;

    //        this.ProfileTab5OccupationData = this.tab5.data.occupationData;

    //        this.ProfileTab5M_occupationData = this.tab5.data.m_occupationData;



    //    })
    //}


    //postProfileTabOneData(data: TblProfile) {

    //    console.warn(data.height_id["height_id"]);
    //    debugger;
    //    //const params = new HttpParams()
    //    //    .set('first_name', data.first_name)
    //    //    .set('middle_name', data.middle_name)
    //    //    .set('last_name', data.last_name)
    //    //    .set('height_id', data.height_id[0].height_id)
    //    //    .set('weight_id', data.weight_id[0].weight_id)
    //    //    .set('mother_tongue_id', data.mother_tongue_id[0].mother_tongue_id)
    //    //    .set('marital_status_id', data.marital_status_id[0].marital_status_id)
    //    //    .set('skin_type_id', data.skin_type_id[0].skin_type_id)
    //    //    .set('body_type_id', data.body_type_id[0].body_type_id)
    //    //    .set('eating_habit_id', data.eating_habit_id[0].eating_habit_id)
    //    //    .set('drinking_habit_id', data.drinking_habit_id[0].drinking_habit_id)
    //    //    .set('smoking_habit_id', data.smoking_habit_id[0].smoking_habit_id)
    //    //    .set('blood_group_id', data.blood_group_id[0].blood_group_id)
    //    //    .set('birth_place', data.birth_place)
    //    //    .set('birth_date', data.birth_date)
    //    //    .set('birth_time', data.birth_time)
    //    //    .set('about_me', data.about_me)
    //    //    .set('hobby', data.hobby);

    //    var postData: TblProfile
    //    postData = new TblProfile();
    //    postData.user_id = 13;
    //    postData.first_name = data.first_name;
    //    postData.middle_name = data.middle_name;
    //    postData.last_name = data.last_name;
    //    postData.height_id = data.height_id["height_id"];
    //    postData.weight_id = data.weight_id["weight_id"];
    //    postData.mother_tongue_id = data.mother_tongue_id["mother_tongue_id"];
    //    postData.marital_status_id = data.marital_status_id["marital_status_id"];
    //    postData.skin_type_id = data.skin_type_id["skin_type_id"];
    //    postData.body_type_id = data.body_type_id["body_type_id"];
    //    postData.eating_habit_id = data.eating_habit_id["eating_habit_id"];
    //    postData.drinking_habit_id = data.drinking_habit_id["drinking_habit_id"];
    //    postData.smoking_habit_id = data.smoking_habit_id["smoking_habit_id"];
    //    postData.blood_group_id = data.blood_group_id["blood_group_id"];
    //    postData.birth_place = data.birth_place;
    //    postData.birth_date = data.birth_date;
    //    postData.birth_time = data.birth_time;
    //    postData.about_me = data.about_me;
    //    postData.hobby = data.hobby;

    //    console.log(postData)

    //    this.registService.postProfileTabOne(postData).subscribe(res => {
    //        console.warn(res);
    //    })
    //}

    //putProfileTabTwoData(data: TblProfile2) {

    //    var putData: TblProfile2
    //    putData = new TblProfile2();

    //    putData.user_id = 10;
    //    putData.religion_id = data.religion_id["religion_id"];
    //    putData.caste_id = data.caste_id["caste_id"];
    //    putData.subcaste = data.subcaste;
    //    putData.manglik_id = data.manglik_id["manglik_horoscope_id"];
    //    putData.horoscope_id = data.horoscope_id["horoscope_id"];
    //    putData.star_id = data.star_id["star_id"];
    //    putData.moonsign_id = data.moonsign_id["moonsign_id"];
    //    putData.gothra_id = data.gothra_id["gothra_id"];


    //    console.log(putData)

    //    this.registService.putProfileTabTwo(putData).subscribe(res => {
    //        console.warn(res);
    //    })

    //}

    //putProfileTabThreeData(data: TblProfile3) {

    //    var putDataThree: TblProfile3
    //    putDataThree = new TblProfile3();

    //    putDataThree.user_id = 10;
    //    putDataThree.address = data.address;
    //    putDataThree.country_id = data.country_id["country_id"];
    //    putDataThree.state_id = data.state_id["state_id"];
    //    putDataThree.city_id = data.city_id["city_id"];
    //    putDataThree.mobile = data.mobile;
    //    putDataThree.phone = data.phone;
    //    putDataThree.residence_id = data.residence_id["residence_id"];


    //    console.log(putDataThree)

    //    this.registService.putProfileTabThree(putDataThree).subscribe(res => {
    //        console.warn(res);
    //    })

    //}
    //putProfileTabFourData(data: TblProfile4) {

    //    var putDataFour: TblProfile4
    //    putDataFour = new TblProfile4();
    //    putDataFour.user_id = 1;
    //    // putDataFour.education_id = data.education_id["education_id"];
    //    putDataFour.education_id = this.education_id;
    //    putDataFour.employed_in_id = data.employed_in_id["employed_in_id"];
    //    putDataFour.designation_id = data.designation_id["designation_id"];
    //    putDataFour.occupation_id = data.occupation_id["occupation_id"];
    //    putDataFour.annual_income_id = data.annual_income_id["income_id"];

    //    console.log(putDataFour)

    //    console.log(this.education_id)


    //    this.registService.putProfileTabFour(putDataFour).subscribe(res => {
    //        console.warn(res);
    //    })

    //}

    //putProfileTabFiveData(data) {

    //    var putDataFive: TblProfile5
    //    putDataFive = new TblProfile5();
    //    putDataFive.user_id = 1;
    //    //putDataFour.education_id = education_id["education_id"];
    //    putDataFive.family_type_id = data.family_type_id["family_type_id"];
    //    putDataFive.family_status_id = data.family_status_id["family_status_id"];
    //    putDataFive.father_name = data.father_name;
    //    putDataFive.father_occupation_id = data.f_occupation_id["f_occupation_id"];
    //    putDataFive.mother_Name = data.mother_Name;
    //    putDataFive.mother_occupation_id = data.m_occupation_id["m_occupation_id"];
    //    putDataFive.total_brothers = data.total_brothers;
    //    putDataFive.total_sisters = data.total_sisters;
    //    putDataFive.total_married_brother = data.total_married_brother;
    //    putDataFive.total_married_sister = data.total_married_sister;
    //    putDataFive.about_family = data.about_family;

    //    console.log(putDataFive)

    //    //  console.log(this.education_id)


    //    this.registService.putProfileTabFive(putDataFive).subscribe(res => {
    //        console.warn(res);
    //    })


    //}

  





}
