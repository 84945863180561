<div class="content-wrapper">
    <div class="content-body">
        <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->
        <h4>{{'MENU.PROFILE_DETAILS.TITLE' | translate}}</h4>
        <section class="modern-vertical-wizard">
            <div id="stepper4" class="bs-stepper vertical wizard-modern modern-vertical-wizard-example">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#account-details-vertical-modern">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="file-text" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.TITLE' | translate}}</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#address-step-vertical-modern">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="link" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.TITLE' | translate}}</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#social-links-vertical-modern">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="map-pin" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.TITLE' | translate}}</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#social-links-vertical-modern2">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="book" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">{{'MENU.PROFILE_DETAILS.EDUCATION_PROFESSION_DETAILS.TITLE' | translate}}</span>
                            </span>
                        </button>
                    </div>
                    <div class="step" data-target="#social-links-vertical-modern3">
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-box">
                                <i data-feather="user-plus" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.TITLE' | translate}}</span>
                            </span>
                        </button>
                    </div>
                    <div class="step">
                        <button type="button" class="step-trigger" [routerLink]="'/preferences'">
                            <span class="bs-stepper-box">
                                <i data-feather="user-plus" class="font-medium-3"></i>
                            </span>
                            <span class="bs-stepper-label">
                                <span class="bs-stepper-title">{{'MENU.PREFERENCE_DETAILS.TITLE' | translate}}</span>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <form #tabOne="ngForm" (ngSubmit)="tabOne.form.valid; postProfileTabOneData(tabOne.value)">
                        <div id="account-details-vertical-modern" class="content">
                            <div class="content-header">
                            <h5 class="mb-0">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.TITLE' | translate}}({{'MENU.PROFILE_DETAILS.BASIC_DETAILS.fields_marked_with_asterisk' | translate}}(<span style="color:red"> * </span>){{'MENU.PROFILE_DETAILS.BASIC_DETAILS.are_mendatory' | translate}})</h5>
                            </div>
                            <!-- <p class="card-text"><span style="color:red">* </span>{{'MENU.PROFILE_DETAILS.MANDATORY' | translate}}</p> -->
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="first_name">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.FIRST_NAME' | translate}} <span style="color:red">
                                            *</span></label>
                                    <input type="text" id="first_name" class="form-control" [(ngModel)]="first_name" name="first_name"
                                        #FirstNameRef="ngModel"
                                        [class.error]="!FirstNameRef.valid && FirstNameRef.touched && tabOne.submitted"
                                        placeholder="Enter first name"  required minlength="2" maxlength="30" />
                                    <div *ngIf="!FirstNameRef.valid && FirstNameRef.touched || tabOne.submitted" class="invalid-form">
                                        <small class="form-text text-danger"
                                            *ngIf="FirstNameRef?.errors?.required">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.FIRST_NAME_REQUIRED'
                                            | translate}}</small>
                                        <!-- <small class="form-text text-danger"
                                            *ngIf="FirstNameRef.errors.maxlength">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.FIRST_NAME_REQUIRED'
                                            | translate}}</small> -->
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-middleName">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.MIDDLE_NAME' |
                                        translate}}</label>
                                    <input type="text" id="vertical-modern-middleName" class="form-control" [(ngModel)]="middle_name"
                                        name="middle_name" #MiddleNameRef="ngModel"
                                        [class.error]="!MiddleNameRef.valid && MiddleNameRef.touched && tabOne.submitted "
                                        placeholder="Enter middle name" maxlength="50" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="last_name">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.LAST_NAME' |
                                        translate}}<span style="color:red">
                                            *</span></label>
                                    <input type="text" id="last_name" class="form-control" [(ngModel)]="last_name" name="last_name"
                                        #LastNameRef="ngModel" [class.error]="!LastNameRef.valid && LastNameRef.touched && tabOne.submitted"
                                        placeholder="Enter last name" required minlength="4" maxlength="30" />
                                    <div *ngIf="!LastNameRef.valid && LastNameRef.touched || tabOne.submitted" class="invalid-form">
                                        <small class="form-text text-danger"
                                            *ngIf="LastNameRef?.errors?.required">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.LASTNAME_REQUIRED' |
                                            translate}}</small>
                                        <!-- <small class="form-text text-danger"
                                            *ngIf="LastNameRef.errors.maxlength">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.LASTNAME_REQUIRED' |
                                            translate}}</small> -->
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.HEIGHT' | translate}}</label>
                                    <ng-select [items]="ProfileTabOneHeightData" bindLabel="height_en" [(ngModel)]="height_id"
                                        name="height_id" bindValue="height_id" placeholder="Select height"></ng-select>
                                </div> 
                            </div> 
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-weight">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.WEIGHT' |
                                        translate}}</label>
                                    <ng-select [items]="ProfileTabOneWeightData" bindLabel="weight_en" bindValue="weight_id"
                                        [(ngModel)]="weight_id" name="weight_id" placeholder="Select Weight"></ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-motherTongue">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.MOTHER_TONGUE' |
                                        translate}}</label>
                                    <ng-select [items]="ProfileTabOneMotherToungData" [(ngModel)]="mother_tongue_id"
                                        bindLabel="mother_tongue_en" name="mother_tongue_id" bindValue="mother_tongue_id"
                                        placeholder="Select language"></ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-maritalStatus">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.MARITAL_STATUS' |
                                        translate}}</label>
                                    <ng-select [items]="ProfileTabOneMaritalStatusData" [(ngModel)]="marital_status_id"
                                        bindLabel="marital_status_en" name="marital_status_id" bindValue="marital_status_id"
                                        placeholder="Select status"></ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-skinType">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.SKIN_TYPE' |
                                        translate}}</label>
                                    <ng-select [items]="ProfileTabOneSkinTypeData" [(ngModel)]="skin_type_id" bindLabel="skin_type_en"
                                        name="skin_type_id" bindValue="skin_type_id" placeholder="Select"></ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-bodyType">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.BODY_TYPE' |
                                        translate}}</label>
                                    <ng-select [items]="ProfileTabOneBodyTypeData" [(ngModel)]="body_type_id" bindLabel="body_type_en"
                                        name="body_type_id" bindValue="body_type_id" placeholder="Select body type"></ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-eatingHabit">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.EATING_HABIT' |
                                        translate}}</label>
                                    <ng-select [items]="ProfileTabOneEatingHabitData" [(ngModel)]="eating_habit_id"
                                        bindLabel="eating_habit_en" name="eating_habit_id" bindValue="eating_habit_id"
                                        placeholder="Select habit"></ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-drinkingHabit">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.DRINKING_HABIT' |
                                        translate}}</label>
                                    <ng-select [items]="ProfileTabOneDrinkingHabitData" [(ngModel)]="drinking_habit_id"
                                        bindLabel="drinking_habit_en" name="drinking_habit_id" bindValue="drinking_habit_id"
                                        placeholder="Select habit"></ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-smockingHabit">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.SMOKING_HABIT' |
                                        translate}}</label>
                                    <ng-select [items]="ProfileTabOneSmokingHabitData" [(ngModel)]="smoking_habit_id"
                                        bindLabel="smoking_habit_en" name="smoking_habit_id" bindValue="smoking_habit_id"
                                        placeholder="Select habit"></ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-bloodGroup">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.BLOOD_GROUP' |
                                        translate}}</label>
                                    <ng-select [items]="ProfileTabOneBloodGroupData" [(ngModel)]="blood_group_id" bindLabel="blood_group_en"
                                        name="blood_group_id" bindValue="blood_group_id" placeholder="Select blood group"></ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-birthPlace">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.BIRTH_PLACE' |
                                        translate}}</label>
                                    <input type="text" id="vertical-modern-birthPlace" [(ngModel)]="birth_place" name="birth_place"
                                        class="form-control" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-birthtime">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.BIRTH_TIME' |
                                        translate}}</label>
                                    <input type="time" id="vertical-modern-birthtime" [(ngModel)]="birth_time" name="birth_time"
                                        class="form-control" />
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label"
                                        for="vertical-modern-birthDate">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.BIRTH_DATE' |
                                        translate}}</label>
                                    <input type="date" id="vertical-modern-birthDate" [(ngModel)]="birth_date" name="birth_date"
                                        class="form-control" placeholder="yyyy-mm-dd" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label for="aboutMe">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.ABOUT_ME' | translate}}</label>
                                    <fieldset class="form-group">
                                        <textarea class="form-control" id="aboutMe" [(ngModel)]="about_me" name="about_me" rows="4"
                                            placeholder="Enter details"></textarea>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label for="hobby">{{'MENU.PROFILE_DETAILS.BASIC_DETAILS.HOBBY' | translate}}</label>
                                    <fieldset class="form-group">
                                        <textarea class="form-control" id="hobby" [(ngModel)]="hobby" name="hobby" rows="4"
                                            placeholder="Enter hobbies"></textarea>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-outline-secondary btn-prev" disabled rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.PREVIOUS' |
                                        translate}}</span>
                                </button>

                                <button class="btn btn-primary btn-submit"  (click)="modernVerticalNext()" rippleEffect>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.NEXT' | translate}}</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <form #tabTwo="ngForm" (ngSubmit)="putProfileTabTwoData(tabTwo.value)">
                        <div id="address-step-vertical-modern" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.TITLE' | translate}}({{'MENU.PROFILE_DETAILS.BASIC_DETAILS.fields_marked_with_asterisk' | translate}}(<span style="color:red"> * </span>){{'MENU.PROFILE_DETAILS.BASIC_DETAILS.are_mendatory' | translate}})</h5>
                                <!--<small>Add Religious Information.</small>-->
                            </div>
                            <!-- <p class="card-text"><span style="color:red">* </span>{{'MENU.PROFILE_DETAILS.MANDATORY' | translate}}</p> -->
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="religion">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.RELIGION' | translate}} <span style="color:red">
                                            *</span></label>
                                    <ng-select [items]="ProfileTab2religiousData" bindLabel="religion_en"
                                        (onSelect)="onSelectReligion($event)" [(ngModel)]="religion_id"
                                        name="religion_id?" #ReligionRef="ngModel"
                                        [class.error]="!ReligionRef.valid && tabTwo.submitted && ReligionRef.touched"
                                        bindValue="religion_id" placeholder="Select Religion"> </ng-select>
                                    <div *ngIf="tabTwo.submitted && !ReligionRef.valid && ReligionRef.touched"
                                        class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="ReligionRef.errors.required">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.RELIGION_REQUIRED' | translate}}</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-caste">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.CASTE' | translate}} <span style="color:red">
                                            *</span></label>
                                    <ng-select [items]="ProfileTab2casteData" bindLabel="caste_en"
                                        (onSelect)="onSelectCaste($event)" [(ngModel)]="caste_id" bindValue="caste_id"
                                        name="caste_id" #CasteRef="ngModel"
                                        [class.error]="!CasteRef.valid && tabTwo.submitted && CasteRef.touched"
                                        placeholder="Select Caste" required> </ng-select>
                                    <div *ngIf="tabTwo.submitted && !CasteRef.valid && CasteRef.touched"
                                        class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="CasteRef.errors.required">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.CASTE_REQUIRED' | translate}}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="subcaste">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.SUBCASTE' | translate}}</label>
                                    <input type="text" id="vertical-modern-subcaste" class="form-control"
                                        [(ngModel)]="subcaste" name="subcaste" value="subcaste"
                                        placeholder="Write Your Subcaste"/>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="gothra">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.GOTHRA' | translate}}</label>
                                    <ng-select [items]="ProfileTab2gothraData" (onSelect)="onSelectGothra($event)"
                                        bindLabel="gothra_en" [(ngModel)]="gothra_id" bindValue="gothra_id"
                                        name="gothra_id" placeholder="Select Gothra"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-horoscope">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.HOROSCOPE' | translate}}</label>
                                    <ng-select [items]="ProfileTab2horoscopeData" (onSelect)="onSelectHoroscope($event)"
                                        bindLabel="horoscope_en" [(ngModel)]="horoscope_id" bindValue="horoscope_id"
                                        name="horoscope_id" placeholder="Select horoscope"> </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-manglik">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.MANGLIK' | translate}}</label>
                                    <ng-select [items]="ProfileTab2manglikData" bindLabel="manglik_en"
                                        [(ngModel)]="manglik_id" bindValue="manglik_id" name="manglik_id"
                                        placeholder="Select Mangalic"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-star">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.STAR' | translate}}</label>
                                    <ng-select [items]="ProfileTab2starData" (onSelect)="onSelectStar($event)"
                                        bindLabel="star_en" [(ngModel)]="star_id" bindValue="star_id" name="star_id"
                                        placeholder="Select star"> </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-moonsign">{{'MENU.PROFILE_DETAILS.RELIGION_DETAILS.MOONSIGN' | translate}}</label>
                                    <ng-select [items]="ProfileTab2moonsignData" (onSelect)="onSelectMoonsign($event)"
                                        bindLabel="moonsign_en" [(ngModel)]="moonsign_id" bindValue="moonsign_id"
                                        name="moonsign_id" placeholder="Select moonsign"> </ng-select>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()"
                                    rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.PREVIOUS' | translate}}</span>
                                </button>
                                <button class="btn btn-primary btn-submit" (click)="modernVerticalNext()" rippleEffect>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.NEXT' | translate}}</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <form #tabThree="ngForm" (ngSubmit)="putProfileTabThreeData(tabThree.value)">
                        <div id="social-links-vertical-modern" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.TITLE' | translate}}</h5>
                                <!--<small>Enter Your Social Links.</small>-->
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="address">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.ADDRESS' | translate}}</label>
                                    <fieldset class="form-group">
                                        <textarea class="form-control" id="address" rows="2"
                                            [(ngModel)]="modelStep3.address" name="address" #AddressRef="ngModel"
                                            [class.error]="!AddressRef.valid && tabThree.submitted && AddressRef.touched"
                                            placeholder="Enter address"></textarea>
                                    </fieldset>
                                    <!-- <div *ngIf="tabThree.submitted && !AddressRef.valid && AddressRef.touched"
                                        class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="AddressRef.errors.required">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.TITLE' | translate}}This
                                            field is required!</small>
                                    </div> -->
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="country">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.COUNTRY' | translate}}</label>
                                    <ng-select [items]="ProfileTab3CountriesData" bindLabel="country_en"
                                        [(ngModel)]="country_id" name="country_id" #CountryRef="ngModel"
                                        bindValue="country_id" placeholder="Select country"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="state">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.STATE' | translate}}</label>
                                    <ng-select [items]="ProfileTab3StatesData" bindLabel="state_en"
                                        [(ngModel)]="modelStep3.state_id" name="state_id" #StateRef="ngModel"
                                        bindValue="state_id" [class.error]="!StateRef.valid && StateRef.touched"
                                        placeholder="Select state"> </ng-select>
                                    <!-- <div *ngIf="tabThree.submitted && !StateRef.valid && StateRef.touched"
                                        class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="StateRef.errors.required">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.TITLE' | translate}}This field
                                            is required!</small>
                                    </div> -->
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="city">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.CITY' | translate}}</label>
                                    <ng-select [items]="ProfileTab3CitiesData" bindLabel="city_en"
                                        [(ngModel)]="modelStep3.city_id" name="city_id" #CityRef="ngModel"
                                        bindValue="city_id" [class.error]="!CityRef.valid && CityRef.touched"
                                        placeholder="Select city"> </ng-select>
                                    <!-- <div *ngIf="!CityRef.valid && CityRef.touched" class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="CityRef.errors.required">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.TITLE' | translate}}This field
                                            is required!</small>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-mobileNo">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.MOBILE_NO' | translate}}</label>
                                    <input type="number" id="vertical-modern-mobile" class="form-control"
                                        [(ngModel)]="modelStep3.mobile" name="mobile" #MobileNoRef="ngModel"
                                        [class.error]="!MobileNoRef.valid && tabThree.submitted && MobileNoRef.touched"
                                        placeholder="+91" maxlength="10" />
                                    <!-- <div *ngIf="tabThree.submitted && !MobileNoRef.valid && MobileNoRef.touched"
                                        class="invalid-form">
                                        <small class="form-text text-danger" *ngIf="MobileNoRef.errors.required">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.TITLE' | translate}}This
                                            field is required!</small>
                                    </div> -->
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-phone">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.PHONE' | translate}}</label>
                                    <input type="number" id="vertical-modern-phone" class="form-control"
                                        [(ngModel)]="modelStep3.phone" name="phone" placeholder="Enter phone number"
                                        maxlength="10" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-residenceStatus">{{'MENU.PROFILE_DETAILS.LOCATION_DETAILS.RESIDENCE_STATUS' | translate}}</label>
                                    <ng-select [items]="ProfileTab3Residence_statusData" bindLabel="residence_en"
                                        [(ngModel)]="modelStep3.residence_id" name="residence_id"
                                        bindValue="residence_id" placeholder="Select residenceStatus"> </ng-select>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()"
                                    rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.PREVIOUS' | translate}}</span>
                                </button>
                                <button class="btn btn-primary btn-submit" (click)="modernVerticalNext()" rippleEffect>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.NEXT' | translate}}</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <form #tabFour="ngForm" >
                        <div id="social-links-vertical-modern2" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">{{'MENU.PROFILE_DETAILS.EDUCATION_PROFESSION_DETAILS.TITLE' | translate}}</h5>
                                <!--<small>Enter Your Social Links.</small>-->
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-education">{{'MENU.PROFILE_DETAILS.EDUCATION_PROFESSION_DETAILS.EDUCATION' | translate}}</label>
                                    <ng-select [items]="ProfileTab4EducationData" bindLabel="education_en"
                                        [multiple]="true" [closeOnSelect]="false" [searchable]="false"
                                        [(ngModel)]="education_id" name="education_id" bindValue="education_id"
                                        (onSelect)="onSelectEducation($event)" [disabled]="isFormDisabled"
                                        placeholder="Select people">
                                        <!-- <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">

                                               </ng-template> -->
                                    </ng-select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-employedIn">{{'MENU.PROFILE_DETAILS.EDUCATION_PROFESSION_DETAILS.EMPLOYED_IN' | translate}}</label>
                                    <ng-select [items]="ProfileTab4Employed_inData" bindLabel="employed_in_en"
                                        [(ngModel)]="employed_in_id" bindValue="employed_in_id" name="employed_in_id"
                                        placeholder="Select status"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-designation">{{'MENU.PROFILE_DETAILS.EDUCATION_PROFESSION_DETAILS.DESIGNATION' | translate}}</label>
                                    <ng-select [items]="ProfileTab4DesignationData" bindLabel="designation_en"
                                        [(ngModel)]="designation_id" name="designation_id" bindValue="designation_id"
                                        placeholder="Select your designation"> </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-occupation">{{'MENU.PROFILE_DETAILS.EDUCATION_PROFESSION_DETAILS.OCCUPATION' | translate}}</label>
                                    <ng-select [items]="ProfileTab4OccupationData" bindLabel="occupation_en"
                                        [(ngModel)]="occupation_id" name="occupation_id" bindValue="occupation_id"
                                        placeholder="Write occupation"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-income">{{'MENU.PROFILE_DETAILS.EDUCATION_PROFESSION_DETAILS.ANNUAL_INCOME' | translate}}</label>
                                    <ng-select [items]="ProfileTab4Annual_incomeData" bindLabel="income_range"
                                        [(ngModel)]="annual_income_id" name="annual_income_id" bindValue="income_id"
                                        placeholder="Select annual income"> </ng-select>
                                </div>
                                <!--<div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-occupation">Occupation</label>
                                    <ng-select [items]="occupation" bindLabel="name" placeholder="Write occupation"> </ng-select>
                                </div>-->
                            </div>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()"
                                    rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.PREVIOUS' | translate}}</span>
                                </button>
                                <button class="btn btn-primary btn-next" (click)="putProfileTabFourData(tabFour.value)" rippleEffect>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.NEXT' | translate}}</span>
                                    <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <form #tabFive="ngForm">
                        <div id="social-links-vertical-modern3" class="content">
                            <div class="content-header">
                                <h5 class="mb-0">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.TITLE' | translate}}</h5>
                                <!--<small>Enter Your Social Links.</small>-->
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-familyType">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.FAMILY_TYPE' | translate}}</label>
                                    <ng-select [items]="ProfileTab5Family_typeData" bindLabel="family_type_en"
                                        [(ngModel)]="family_type_id" name="family_type_id" bindValue="family_type_id"
                                        placeholder="Family type"> </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-familyStatus">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.FAMILY_STATUS' | translate}}</label>
                                    <ng-select [items]="ProfileTab5Family_statusData" bindLabel="family_status_en"
                                        [(ngModel)]="family_status_id" name="family_status_id"
                                        bindValue="family_status_id" placeholder="Select family status"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-fatherName">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.FATHER_NAME' | translate}}</label>
                                    <input type="text" id="vertical-modern-fatherName" [(ngModel)]="father_name"
                                        name="father_name" class="form-control" placeholder="Enter Father Name" />
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-fatherOccupation">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.FATHER_OCCUPATION' | translate}}</label>
                                    <ng-select [items]="ProfileTab5OccupationData" bindLabel="f_occupation_en"
                                        [(ngModel)]="father_occupation_id" name="father_occupation_id" bindValue="f_occupation_id"
                                        placeholder="Father Occupation"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-motherName">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.MOTHER_NAME' | translate}}</label>
                                    <input type="text" id="vertical-modern-motherName" [(ngModel)]="mother_Name"
                                        name="mother_Name" class="form-control" placeholder="Enter Mother Name" />
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-motherOccupation">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.MOTHER_OCCUPATION' | translate}}</label>
                                    <ng-select [items]="ProfileTab5M_occupationData" bindLabel="m_occupation_en"
                                        [(ngModel)]="mother_occupation_id" name="mother_occupation_id" bindValue="m_occupation_id"
                                        placeholder="Mother Occupation"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-brothers">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.NO_OF_BORTHER' | translate}}</label>
                                    <input type="number" id="vertical-modern-brothers" [(ngModel)]="total_brothers"
                                        name="total_brothers" class="form-control" placeholder="Enter" />
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-sisters">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.NO_OF_SISTER' | translate}}</label>
                                    <input type="number" id="vertical-modern-sisters" [(ngModel)]="total_sisters"
                                        name="total_sisters" class="form-control" placeholder="Enter" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-mariedBrother">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.MARRIED_BORTHER' | translate}}</label>
                                    <input type="number" id="vertical-modern-mariedBrother"
                                        [(ngModel)]="total_married_brother" name="total_married_brother"
                                        class="form-control" placeholder="Enter" />
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label" for="vertical-modern-mariedsisters">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.MARRIED_SISTER' | translate}}</label>
                                    <input type="number" id="vertical-modern-mariedsisters"
                                        [(ngModel)]="total_married_sister" name="total_married_sister"
                                        class="form-control" placeholder="Enter" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="aboutFamily">{{'MENU.PROFILE_DETAILS.FAMILY_DETAILS.ABOUT_FAMILY' | translate}}</label>
                                    <fieldset class="form-group">
                                        <textarea class="form-control" id="aboutFamily" [(ngModel)]="about_family"
                                            name="about_family" rows="4" placeholder="Write About family"></textarea>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-primary btn-prev" (click)="modernVerticalPrevious()"
                                    rippleEffect>
                                    <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">{{'MENU.PROFILE_DETAILS.PREVIOUS' | translate}}</span>
                                </button>
                                <button class="btn btn-success btn-submit" rippleEffect (click)="putProfileTabFiveData(tabFive.value);tabFive.reset()">{{'MENU.PROFILE_DETAILS.save' | translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</div>