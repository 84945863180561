import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FileUploadModule } from 'ng2-file-upload';
import { ChartsModule } from 'ng2-charts';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';
import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';
import { DatePipe } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
import { ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OtpFormComponent } from './otp-form/otp-form.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreDirectivesModule } from '../@core/directives/directives';
import { PreferencesComponent } from './preferences/preferences.component';
import { TokenInterceptor } from '../app/services/token.interceptor';
import { ProfileComponent } from './profile/profile.component';
import { ShowStep1Component } from './show-step1/show-step1.component';
import { ProfilesModule } from 'app/profiles/profiles.module';
import { ProfileUpdateComponent } from './profile-update/profile-update.component';
import { ProfileUpdateModule } from 'app/profile-update/profile-update.module';
import { TestComponent } from './test/test.component';
import { ProfilePreviewComponent } from './profile-preview/profile-preview.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TranslateService } from '@ngx-translate/core';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { MemberShipComponent } from './member-ship/member-ship.component';
import { SubscriptionConfirmComponent } from './subscription-confirm/subscription-confirm.component';
import { PGComponent } from './pg/pg.component';
import { PaymentGatwayComponent } from './payment-gatway/payment-gatway.component';
import { PaymentGatewayResponseComponent } from './payment-gateway-response/payment-gateway-response.component';
import { PrivacySettingComponent } from './privacy-setting/privacy-setting.component';
import { AuthenticationGuardGuard } from 'app/authenticationGuards/authentication-guard.guard';
import { Role } from './model/role';
import { LoginService } from './services/login.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { environment } from "../environments/environment";
import { initializeApp, FirebaseApp } from "firebase/app";
import { AngularFirestoreModule } from '@angular/fire/compat/firestore/';
import { OtpForgotPasswordComponent } from './otp-forgot-password/otp-forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { LoginWithOtpComponent } from './login-with-otp/login-with-otp.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefunAndCancellationPolicyComponent } from './refun-and-cancellation-policy/refun-and-cancellation-policy.component';
import { ShippingAndDeliveryComponent } from './shipping-and-delivery/shipping-and-delivery.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { IndexComponent } from './index/index.component';
// import { FeHeaderComponent } from './index/fe-header/fe-header.component';
// import { FeFooterComponent } from './index/fe-footer/fe-footer.component';
import { FeIndexComponent } from './fe-index/fe-index.component';
//{
//  path: 'dashboard',
//  loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
//},
// { path: '', component: LoginComponent, pathMatch: 'full' },
initializeApp(environment.firebase);
const appRoutes: Routes = [
  // { path: '',    pathMatch: 'full', loadChildren: () => import('./front-end/front-end.module').then(m => m.FrontEndModule) },
  // { path: 'front-end', loadChildren: () => import('./front-end/front-end.module').then(m => m.FrontEndModule) },
  
  
  {    path: '',    redirectTo: 'index',    pathMatch: 'full'  },
  // {    path: 'home',    component: FeHeaderComponent  },
  {    path: 'index',    component: IndexComponent  },

  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full'
  // },
  {
    path: 'login',
    component: LoginComponent,
    // queryParams:{ newOrdNum: '123'}
  },
  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'otp-form',
    component: OtpFormComponent
  },
  {
    path: 'otp-forgot-password',
    component: OtpForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'login-with-otp',
    component: LoginWithOtpComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'refun-and-cancellation-policy',
    component: RefunAndCancellationPolicyComponent
  },
  {
    path: 'shipping-and-delivery',
    component: ShippingAndDeliveryComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'profile-preview',
    component: ProfilePreviewComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'member-ship',
    component: MemberShipComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'subscription-confirm',
    component: SubscriptionConfirmComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'preferences',
    component: PreferencesComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'show-step1',
    component: ShowStep1Component,
    canActivate: [AuthenticationGuardGuard], data: { roles: [Role.member] }
  },
  {
    path: 'delete-account',
    component: DeleteAccountComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'payment-gatway',
    component: PaymentGatwayComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'payment-gateway-response',
    component: PaymentGatewayResponseComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'privacy-setting',
    component: PrivacySettingComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'profiles',
    loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule),
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.member] }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    // redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];
@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    LoginComponent,
    ForgotPasswordComponent,
    OtpFormComponent,
    DashboardComponent,
    PreferencesComponent,
    ProfileComponent,
    ShowStep1Component,
    ProfileUpdateComponent,
    TestComponent,
    ProfilePreviewComponent,
    MyProfileComponent,
    ChangePasswordComponent,
    DeleteAccountComponent,
    MemberShipComponent,
    SubscriptionConfirmComponent,
    PGComponent,
    PaymentGatwayComponent,
    PaymentGatewayResponseComponent,
    PrivacySettingComponent,
    PageNotFoundComponent,
    OtpForgotPasswordComponent,
    ResetPasswordComponent,
    LoginWithOtpComponent,
    PrivacyPolicyComponent,
    RefunAndCancellationPolicyComponent,
    ShippingAndDeliveryComponent,
    TermsAndConditionsComponent,
    IndexComponent,
    // FeHeaderComponent,
    // FeFooterComponent,
    FeIndexComponent,
  ],
  imports: [
    BrowserModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    NgSelectModule,
    ProfileUpdateModule,
    CoreDirectivesModule,
    FileUploadModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
    ChartsModule, AngularFirestoreModule,
    NgxSpinnerModule
  ],
  exports: [
    TranslateModule,
  ],

  providers: [
    LoginService,
    AuthenticationGuardGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    fakeBackendProvider,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
