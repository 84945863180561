import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { base } from 'base/base';
import{ TblBlacklist } from 'app/model/tbl-blacklist';
import { BehaviorSubject, Observable } from 'rxjs';
import { model_response } from 'app/model/tbl-profile';

@Injectable({
  providedIn: 'root'
})
export class BlacklistService {

  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  profile_detail_data: Observable<any> = this.obsArray.asObservable();
  public onOpenedProfileChanged: BehaviorSubject<any>;


  constructor(private http: HttpClient) {
    this.onOpenedProfileChanged = new BehaviorSubject([]);
   }

  getToken(){
    return localStorage.getItem('ltn')
  }

  blockListData(profile_id){
   return this.http.post<TblBlacklist>(`${base.apiUrl}/blocklist?profile_id=${profile_id}`,'')
  }
  getBlockListData(pageSize: number, pageNumber: number){
   return this.http.get<model_response>(`${base.apiUrl}/blocklist/get_blocked_list?page_size=${pageSize}&current_page=${pageNumber}`)
  }
  removeBlockListedData(profile_id){
   return this.http.get<TblBlacklist>(`${base.apiUrl}/blocklist/delete_blocklist?profile_id=${profile_id}`);
  }
  profile_update(data) {
    this.obsArray.next(data)
  }
  openProfileDetails(data) {
    this.onOpenedProfileChanged.next(data);
    
  }

}
