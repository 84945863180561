import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title, DomSanitizer, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile } from 'app/model/user-profile';
import { MatrimontyServiceService } from 'app/services/matrimonty-service.service';

@Component({
  selector: 'app-fe-index',
  templateUrl: './fe-index.component.html',
  styleUrls: ['./fe-index.component.scss']
})
export class FeIndexComponent implements OnInit {
  navElement!: HTMLElement;

  isDrawerOpen!: boolean;
  @Output()
  drawerToggleEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  submitted=false;
  Form_Group: FormGroup;
  formData:UserProfile = new UserProfile();
  // metadata1:metadata[] =[];
  fakeArray = new Array(3);
  data1:any;
  l:any;
  metadatas:any;
  Base:any;
  data: any;
  status1:any;
  respo:any;
  selected_language:any;
  selected_language_id:any;
  browserlang: any;
  lat: number = 21.233733;
  lng: number = 81.599756;
  zoom:number = 15;
  // supptlanguage=['en', 'hi'];
  supptlanguage = [{code: 'en', label: 'English'}, {code: 'hi', label: 'Hindi'}];
  isLoaded = false;
  photo_gallery:any = [];
  gallery_type:string = 'all';
  video_link:any = null;
  constructor(private _coreConfigService: CoreConfigService, private formBuilder: FormBuilder,private _MatrimontyServiceService:MatrimontyServiceService,
    private http: HttpClient, private router: Router,public translate: TranslateService,private title: Title,
      public sanitizer: DomSanitizer,
      private meta: Meta,  @Inject(DOCUMENT) private document: Document ) 
       { 
        console.warn('index page - constructor');
          this._coreConfigService.config = {
    layout: {
        navbar: {
            hidden: true
        },
        menu: {
            hidden: true
        },
        footer: {
            hidden: true
        },
        customizer: false,
        enableLocalStorage: false
    }
};



  //   this.Form_Group = this.formBuilder.group({
  //   name:['', [Validators.required, Validators.maxLength(20)]],
  //   email:['',[Validators.required, Validators.pattern("^([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})$"), Validators.minLength(10)]],
  //   mobile:['', [ Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10)]],      
  //   subject:['', [Validators.required, Validators.maxLength(100)]],
  //   feedback:['', [Validators.required, Validators.maxLength(100)]],
  // })

  // if(localStorage.getItem("lang")==null){
  //   translate.setDefaultLang('hi');
  //   localStorage.setItem("lang",'hi');
  //   this.selected_language = "हिन्दी"; this.selected_language_id = 2; 
  // } 
  // else
  // {
  //   this.l =localStorage.getItem("lang");
  //   translate.setDefaultLang(this.l);
  //   this.get_language_full_name(this.l);
  // }
 }

  ngOnInit(): void {
  }

}
