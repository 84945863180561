import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { base } from '../../base/base';
import { throwError } from 'rxjs';
import { model_response } from 'app/model/tbl-profile';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  constructor(private http: HttpClient) { }

  getPreferenceTabOne() {
    return this.http.get<model_response>(`${base.apiUrl}/profile_preference_1/get_basic_preference`)
  }

  getPreferenceTabTwo() {
    return this.http.get<model_response>(`${base.apiUrl}/profile_preference_2/get_religious_preference`)
  }

  getPreferenceTabThree() {
    return this.http.get<model_response>(`${base.apiUrl}/profile_preference_3/get_location_preference`)
  }

  getPreferenceTabFour() {
    return this.http.get<model_response>(`${base.apiUrl}/profile_preference_4/get_profession_preference`)
  }

  putPreferenceTabOne(data) {
    return this.http.post(`${base.apiUrl}/profile_preference_1/post_basic_preference`, data);
  }
  
  putPreferenceTabTwo(data) {
    return this.http.post(`${base.apiUrl}/profile_preference_2/post_religious_preference`, data);
  }

  putPreferenceThree(data) {
    return this.http.post(`${base.apiUrl}/profile_preference_3/post_location_preference`, data);
  }

  putPreferenceTabFour(data) {
    return this.http.post(`${base.apiUrl}/profile_preference_4/post_profession_preference`, data);
  }

  errorHandle(error: HttpErrorResponse) {
    window.alert("Internal server error occured during processing your request. Please try again.")
    return throwError('Server error step1')
  }
}
