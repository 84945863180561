import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreConfigService } from '../../@core/services/config.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl, UntypedFormBuilder } from '@angular/forms';
import { RegistService } from '../services/regist.service';
import { TblLogin } from 'app/model/tbl-login';
import { base_methods } from 'base/base';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPasswordValidator } from 'app/auth/helpers/custom-validators';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {
    // pattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    // pass = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}';
    //pass = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$){6, 100}$';
    pass = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$';
    submitted = false;
    base: any;
    public changePasswordForm: FormGroup;
    //    changePasswordForm = new FormGroup({
    //          old_password: new FormControl('', [Validators.required,  Validators.minLength(6), Validators.maxLength(100), Validators.pattern(this.pass)]),
    //         password: new FormControl('', [Validators.required, Validators.pattern(this.pass), Validators.minLength(6), Validators.maxLength(100)]),
    //     //    confirm_password: new FormControl('', [Validators.required, Validators.pattern(this.pass), Validators.minLength(6), Validators.maxLength(100)]),
    //        confirm_password: new FormControl (['', [Validators.required]]),

    //    },
    //        {
    //         // validator: ConfirmPasswordValidator('password', 'confirm_password')
    //       }
    //    )

    //   this.form_group = this._formBuilder.group({
    //   password: ['', [Validators.required, Validators.pattern(this.base.PasswordValidator)]],
    //  confirm_password: ['', [Validators.required]],
    // },
    //   {
    //     validator: ConfirmPasswordValidator('password', 'confirm_password')
    //   }
    //   )


    public coreConfig: any;
    public passwordTextType: boolean;
    //mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CoreConfigService} _coreConfigService
     */
    constructor(private _coreConfigService: CoreConfigService, private router: Router, private _formBuilder: UntypedFormBuilder,
        private registService: RegistService, private toastr: ToastrService) {
        this._unsubscribeAll = new Subject();
        this.base = base_methods;

        // Configure the layout
        // this._coreConfigService.config = {
        //     layout: {
        // navbar: {
        //     hidden: true
        // },
        // menu: {
        //     hidden: true
        // },
        // footer: {
        //     hidden: false
        // },
        // customizer: false,
        // enableLocalStorage: false
        //     }
        // };

        this.changePasswordForm = this._formBuilder.group({
            old_password: ['', [Validators.required, Validators.pattern(this.pass)]],
            password: ['', [Validators.required, Validators.pattern(this.pass), Validators.minLength(6)]],
            //    confirm_password: new FormControl('', [Validators.required, Validators.pattern(this.pass), Validators.minLength(6), Validators.maxLength(100)]),
            confirm_password: ['', [Validators.required]],

        },
            {
                validator: ConfirmPasswordValidator('password', 'confirm_password')
            }
        )
    }

    /**
     * Toggle password
     */
    togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
            // alert(JSON.stringify(  this.coreConfig))
        });
    }
    get controls() {
        return this.changePasswordForm.controls;
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    message: any
    loginData: TblLogin = new TblLogin();
    changePasswordFormData() {
        try {
         this.loginData.old_password = this.changePasswordForm.value.old_password;
         this.loginData.password = this.changePasswordForm.value.password;
            this.loginData.confirm_password = this.changePasswordForm.value.confirm_password;
            if (this.changePasswordForm.valid) {
                this.registService.changePassword(this.loginData).subscribe(res => {
                    if (res.status == 'Success') {
                        this.toastr.success(res.message, "Success!");
                        localStorage.removeItem("currentUser");
                        localStorage.removeItem("ltn");
                        localStorage.removeItem("membership");
                        localStorage.removeItem("config");
                        localStorage.removeItem("user");
                        this.router.navigate(['/login']);
                    } else this.toastr.error(res.message, 'Error!');
                });//,(error: any) => { alert(JSON.stringify("alert" + error) )});
            } else this.toastr.error('Input details are not valid','Error!')
        } catch (err: any) { throw err; }
    }

    getOtpIfSuccess(data) {
        this.registService.getOtp(data).subscribe(res => {
            //this.router.navigate(['/otp-form/{' + data + '}']);
            this.router.navigate(['/otp-form']);
        });
        
    };

    isInputNumber(event): boolean {
        const ch = (event.which) ? event.which : event.keyCode;
        if (ch > 31 && (ch < 48 || ch > 57)) {
            console.warn("char code restricted is :" + ch);
            return false;
        }
        return true;
    }

}


