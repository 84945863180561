import { Token } from '@angular/compiler';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from 'app/login/login.component';
import { TblLogin, model_login_response } from 'app/model/tbl-login';
import { model_response } from 'app/model/tbl-profile';
import { Subject } from 'rxjs';
import { base } from 'base/base';
import { takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '../../@core/services/config.service';
import { RegistService } from '../services/regist.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-otp-form',
    templateUrl: './otp-form.component.html',
    styleUrls: ['./otp-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OtpFormComponent implements OnInit {
    public otp: number;
    registerationData;
    public base: any;
    public coreConfig: any;
    private _unsubscribeAll: Subject<any>;
    default_language_id = 2;
     loginData: TblLogin = new TblLogin();
    /**
     * @param {CoreConfigService} _coreConfigService
     */
    constructor(private _coreConfigService: CoreConfigService, private router: Router, private registService: RegistService, private toastr: ToastrService,
        public translate: TranslateService) {
        this.base = base;
        this.base = base
        if (localStorage.getItem("ltn")) {
            this.router.navigate(['/profiles']);
        }
        this._unsubscribeAll = new Subject();
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    otpIsInputNumber(event) {
        const ch = (event.which) ? event.which : event.keyCode;
        if (ch > 31 && (ch < 48 || ch > 57)) {
            console.warn("char code restricted is :" + ch);
            return false;
        }
        return true;
    }
   
    otpSubmit(data) {
        try {
            // {"name":"lomesh","gender_id":2,"caste_id":1602,"mobile":"9340414187","email":"lomesh@gmail.com","password":"Shivam@123"}
            this.loginData.name = this.registerationData.name;
            this.loginData.gender_id = this.registerationData.gender_id;
            this.loginData.caste_id = this.registerationData.caste_id;
            this.loginData.mobile = this.registerationData.mobile;
            this.loginData.email = this.registerationData.email ? this.registerationData.email : null ;
            this.loginData.password = this.registerationData.password;
            this.loginData.default_language_id = this.default_language_id;
            this.loginData.token_push_notification = localStorage.getItem("token_push_notification");
            this.loginData.source_app = Capacitor.getPlatform();
            
  
            let value = data.otp;
            console.log(JSON.stringify(this.loginData))
            this.registService.validateOTP(value, this.loginData).subscribe((res: model_login_response) => {
                let token = res.token;
                //  console.log(JSON.stringify(res))
                if (token) {
                    this.toastr.success(res.message, 'success!');
                    localStorage.setItem('ltn', token);
                    localStorage.setItem("user", JSON.stringify(res))
                    localStorage.setItem("currentUser", JSON.stringify(res));
                    localStorage.setItem("config", '{"app":{"appName":"Bihav Matrimony","appTitle":"Bihav Matrimony","appLogoImage":"assets/images/logo/logo.svg"},"layout":{"skin":"default","type":"vertical","menu":{"hidden":false,"collapsed":false},"navbar":{"hidden":false,"type":"floating-nav","background":"navbar-light","customBackgroundColor":true,"backgroundColor":""},"footer":{"hidden":false,"type":"footer-static","background":"footer-light","customBackgroundColor":false,"backgroundColor":""},"enableLocalStorage":true,"customizer":false,"scrollTop":true,"buyNow":false}}')
                    
                    // localStorage.setItem("currentUser", '{"id":1, "user_id":'+res.user_id+', "email":"admin@test.com","firstName":"John","lastName":"Doe","avatar":"avatar-s-11.jpg","role":"Admin","token":"fake-jwt-token.1"}')
                    // localStorage.setItem("config", '{"app":{"appName":"Bihav Matrimony","appTitle":"Bihav Matrimony","appLogoImage":"assets/images/logo/logo.svg"},"layout":{"skin":"default","type":"vertical","menu":{"hidden":false,"collapsed":true},"navbar":{"hidden":false,"type":"floating-nav","background":"navbar-light","customBackgroundColor":true,"backgroundColor":""},"footer":{"hidden":false,"type":"footer-static","background":"footer-light","customBackgroundColor":false,"backgroundColor":""},"enableLocalStorage":true,"customizer":false,"scrollTop":true,"buyNow":false}}')
                   localStorage.removeItem('token_push_notification');
                    this.router.navigate(['/profile']);
                } else {this.toastr.error(res.message, 'Error!');}
            }, error => this.toastr.error(JSON.stringify(error)));//this.toastr.error(JSON.stringify(error)));
        } catch (err: any) { throw err; alert(err.message)}

        // validateOTP_forgetpassword

    }
    ngOnInit(): void {
        this.registerationData = JSON.parse(localStorage.getItem('registerationData') || '{}');
        //   var source_app = Capacitor.getPlatform();
        // console.warn( source_app)
        // console.warn("gi "+  this.registerationData.gender_id)
        // console.warn("nam "+  this.registerationData.name)
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
