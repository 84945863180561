<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <a class="brand-logo" href="javascript:void(0);">
            <img src="{{base.admin_app_url}}/uploads/apps/logo-login.png" alt="bihav Matrimony" height="40" />
        </a>
        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <img class="img-fluid" style="width: 100%; height:auto"
                    src="{{base.admin_app_url}}/uploads/apps/login-bg.png" alt="Login V2" />
                
            </div>
        </div>
        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title font-weight-bold mb-1">{{'MENU.LOGIN.TITLE' | translate }}</h2>
                <p class="card-text mb-2">{{'MENU.LOGIN.SUB_TITLE' | translate }}</p>

                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                    <div class="alert-body">
                        <p>{{ error }}</p>
                    </div>
                </ngb-alert>
<!-- (keypress)="isMobileNumber($event)"  maxlength="10"-->
                <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="form-label" for="login-email">{{'MENU.LOGIN.MOBILE_OR_EMAIL' | translate
                            }}</label>
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                            placeholder="{{'MENU.LOGIN.MOBILE_EMAIL_PLACEHOLDER' | translate }}"
                            aria-describedby="login-email" autofocus=""
                             tabindex="1" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">{{'MENU.LOGIN.MOBILE_REQUIRE' | translate }}</div>
                            <div *ngIf="f.email.errors.pattern">{{'MENU.LOGIN.VALID_MOBILE_REQUIRE' | translate }}</div>
                            <div *ngIf="f.email.errors.pattern">{{'MENU.LOGIN.VALID_EMAIL_REQUIRE' | translate }}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label for="login-password">{{'MENU.LOGIN.PASSWORD' | translate }}</label>
                            <a routerLink="../forgot-password">
                                <small>{{'MENU.LOGIN.FORGOT_PASSWORD' | translate }}</small>
                            </a>
                        </div>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password"
                                class="form-control form-control-merge"
                                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
                                placeholder="{{'MENU.LOGIN.PASSWORD_PLACEHOLDER' | translate }}"
                                aria-describedby="login-password" tabindex="2" />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer">
                                    <i class="feather font-small-4"
                                        [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                        (click)="togglePasswordTextType()"></i>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback"
                            [ngClass]="{ 'd-block': submitted && f.password.errors }">
                            <div *ngIf="f.password.errors.required">{{'MENU.LOGIN.PASSWORD_REQUIRED' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" id="remember-me" type="checkbox" tabindex="3"
                                formControlName="remember_me" />
                            <label class="custom-control-label" for="remember-me">{{'MENU.LOGIN.REMEMBER_ME' | translate
                                }}</label>
                        </div>
                    </div>
                    <!-- <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
                        <span *ngIf="loading"
                            class="spinner-border spinner-border-sm mr-1"></span>{{'MENU.LOGIN.SIGN_IN' | translate }}
                    </button> -->
                    <button [disabled]="loginForm.invalid" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
                         <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{'MENU.LOGIN.SIGN_IN' | translate }}
                    </button>
                </form>
                <div class="divider my-2">
                    <div class="divider-text">{{'MENU.LOGIN.OR' | translate }}</div>
                </div>
                <div class="auth-footer-btn d-flex justify-content-center">
                    <a routerLink="../login-with-otp">{{'MENU.LOGIN.login_with_otp' | translate }}</a>
                    <!-- <a routerLink="../forgot-password">
                                                <small>{{'MENU.LOGIN.FORGOT_PASSWORD' | translate }}</small>
                                            </a> -->
                
                    <!-- <a class="btn btn-facebook" href="javascript:void(0)"><i data-feather="facebook"></i></a><a
                                        class="btn btn-twitter white" href="javascript:void(0)"><i data-feather="twitter"></i></a><a
                                        class="btn btn-google" href="javascript:void(0)"><i data-feather="mail"></i></a><a
                                        class="btn btn-github" href="javascript:void(0)"><i data-feather="github"></i></a> -->
                </div>
                <p class="text-center mt-2">
                    <span>{{'MENU.LOGIN.REGISTER_NEW_USER' | translate }}</span><a routerLink="../registration">
                        <span>&nbsp;{{'MENU.LOGIN.CREATE_AN_ACCOUNT' | translate }}</span>
                    </a>
                </p>
               
            </div>
        </div>
    </div>
</div>