import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProfileListService } from '../services/profile-list.service';
import { base } from 'base/base';
import { model_response, model_listOne, TblProfile, TblProfile4 } from 'app/model/tbl-profile';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-show-step1',
  templateUrl: './show-step1.component.html',
  styleUrls: ['./show-step1.component.scss']
})
export class ShowStep1Component implements OnInit {

  public contentHeader: object;
 // labelForm:FormGroup;

  constructor(private http: HttpClient, private profileListService: ProfileListService, private formBuilder:UntypedFormBuilder) { }

  ngOnInit(): void {

    this.contentHeader = {
      headerTitle: 'Form Wizard',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Forms',
            isLink: true,
            link: '/'
          },
          {
            name: 'Form Wizard',
            isLink: false
          }
        ]
      }
    };

  //   this.labelForm = this.formBuilder.group({
  //     first_name: ['']
  // })

    this.getProfileOneData();

  }

//   get first_name() {
//     return this.labelForm.get('first_name')
// }

/*get profile step 1 data   */
  modelProfileStep1:model_response;
  modify:model_listOne;
  getProfileOneData() {
     this.profileListService.profilelist().pipe(map((res: model_response) => <model_response>res),
    retry(2)
).subscribe((res:model_response) => {
        this.modelProfileStep1 = res;
        console.warn(this.modelProfileStep1);
       if (this.modelProfileStep1.status == "Success") {
         let modifyData = JSON.parse(this.modelProfileStep1.data.toString())
         console.warn(modifyData);
         this.modify= modifyData.Table[0];
         
        }
        
       // console.warn(this.modelProfileStep1.data.profileData[0])
      //  this.labelForm.controls['first_name'].setValue(this.modelProfileStep1.data.profileData[0].first_name);
      },(error) => {
        // retry(2),
        window.alert("Internal server error occured during processing your request. Please try again!");

    }
      );
    }
  /* /get profile step 1 data   */

  

  /* get profile step 2 data   */
  
  /* /get profile step 2 data   */


  /*  get profile step 3 data   */
  
  /*  /get profile step 3 data   */


  /* get profile step 4 data   */
  
  /* /get profile step 4 data  */


  /*  get profile step 5 data */
  
  /*  /get profile step 5 data */


    }
