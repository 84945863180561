import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TblLogin, model_login_response } from 'app/model/tbl-login';
import { Subject } from 'rxjs';
import { base } from 'base/base';
import { first, map, retry, takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '../../@core/services/config.service';
import { AuthenticationService } from '../auth/service';
import { LoginService } from '../services/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationGuardGuard } from 'app/authenticationGuards/authentication-guard.guard';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
    public coreConfig: any;
    public testVarriable = "testing ruun";
    public loginForm: UntypedFormGroup;
    public loading = false;
    public submitted = false;
    public returnUrl: string;
    public error = '';
    public base: any;
    public passwordTextType: boolean;
    user: model_login_response = new model_login_response();
    private _unsubscribeAll: Subject<any>;
    defaultLanguage: any; // This language will be used as a fallback when a translation isn't found in the current language


    /**
     * @param {CoreConfigService} _coreConfigService
     */

    constructor(private _coreConfigService: CoreConfigService, private _formBuilder: UntypedFormBuilder, private toastr: ToastrService,
        private _route: ActivatedRoute, private router: Router, private loginService: LoginService,
        public translate: TranslateService, private _authenticationService: AuthenticationService,
        private authenticationGuardGuard: AuthenticationGuardGuard) {
        this.base = base
        if (localStorage.getItem("ltn")) {
            this.router.navigate(['/profiles']);
            // this._route.snapshot;
        }
        this._unsubscribeAll = new Subject();
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                menu: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                customizer: false,
                enableLocalStorage: false
            }
        };
    }

    get f() {
        return this.loginForm.controls;
    }

    togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }
loginData: TblLogin = new TblLogin();
    onSubmit() {
        try {
            this.loading = true;
            this.submitted = true;
            // var loginData: TblLogin;
            // this.loginData = this.loginForm.value
            // alert(JSON.stringify(this.loginForm.value))
            this.loginData.email = this.loginForm.value.email;
            this.loginData.password = this.loginForm.value.password;
            this.loginData.remember_me = this.loginForm.value.remember_me;
            if (this.loginForm.invalid) {
                this.toastr.error('Input details are not valid', 'Error!')
                return;
            }
            // else this.toastr.error('Please enter the valid data')
            
            this.loginService.login(this.loginData).pipe(map((res: model_login_response) => <model_login_response>res),
                retry(2)
            ).subscribe((res: any) => {
                //    console.log(JSON.stringify(res))
                if (res?.token != null) {
                    this.user.token = res.token;
                    this.user.expiration = res.expiration;
                    this.user.user_id = res.user_id;
                    localStorage.setItem("ltn", res.token)
                    localStorage.setItem("user", JSON.stringify(res))
                    localStorage.setItem("currentUser", JSON.stringify(res));
                    localStorage.setItem("config", '{"app":{"appName":"Bihav Matrimony","appTitle":"Bihav Matrimony","appLogoImage":"assets/images/logo/logo.svg"},"layout":{"skin":"default","type":"vertical","menu":{"hidden":false,"collapsed":false},"navbar":{"hidden":false,"type":"floating-nav","background":"navbar-light","customBackgroundColor":true,"backgroundColor":""},"footer":{"hidden":false,"type":"footer-static","background":"footer-light","customBackgroundColor":false,"backgroundColor":""},"enableLocalStorage":true,"customizer":false,"scrollTop":true,"buyNow":false}}')
                    // localStorage.setItem("currentUser", '{"id":1, "language":"hi", "user_id":100035, "email":"admin@demo.com","firstName":"John","lastName":"Doe","avatar":"avatar-s-11.jpg","role":"Admin","token":"fake-jwt-token.1"}')
                     // localStorage.setItem("config", '{"app":{"appName":"Bihav Matrimony","appTitle":"Bihav Matrimony","appLogoImage":"assets/images/logo/logo.svg"},"layout":{"skin":"default","type":"vertical","menu":{"hidden":false,"collapsed":true},"navbar":{"hidden":false,"type":"floating-nav","background":"navbar-light","customBackgroundColor":true,"backgroundColor":""},"footer":{"hidden":false,"type":"footer-static","background":"footer-light","customBackgroundColor":false,"backgroundColor":""},"enableLocalStorage":true,"customizer":false,"scrollTop":true,"buyNow":false}}')

                     localStorage.removeItem('token_push_notification');
                    this.router.navigateByUrl("profiles")
                    // this.router.navigate(['profiles']);f
                }
                else{ this.loading = false; this.toastr.error(res.message, 'Error!');}
                // else this.toastr.error('Please enter valid login credentials', 'Error!');
            }
                // this.toastr.error('Please enter valid login credentials', 'Error!'));
                , (error: any) => { this.toastr.error(JSON.stringify(error)) });//JSON.stringify(error)});
        } catch (err: any) { throw err; alert(err.message) }
    }


    isMobileNumber(event): boolean {
        const ch = (event.which) ? event.which : event.keyCode;
        if (ch > 31 && (ch < 48 || ch > 57)) {
            console.warn("char code restricted is :" + ch);
            return false;
        }
        return true;
    }

    ngOnInit(): void {
        // this.ativatedRoute.queryParamMap.subscribe((params) => {
        //     this.defaultLanguage = +params.get('lang') ;
        //     alert("lang1 : "+this.defaultLanguage)
        //   });

        // this._route.queryParamMap.subscribe((paramss) => {
        //    this.defaultLanguage = paramss.get('lang')           
        //         this.defaultLanguage = this.defaultLanguage != null ? this.defaultLanguage : 'hi';
        //   });


        this.base = base;
        
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(this.base.mobile_emailvalidator)]],
            password: ['', Validators.required],
            remember_me: [false, Validators.required]
        });
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
