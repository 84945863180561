import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title, DomSanitizer, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile } from 'app/model/user-profile';
import { MatrimontyServiceService } from 'app/services/matrimonty-service.service';
import {base } from '../../base/base';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  navElement!: HTMLElement;

  isDrawerOpen!: boolean;
  @Output()
  drawerToggleEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  submitted=false;
  Form_Group: FormGroup;
  formData:UserProfile = new UserProfile();
  // metadata1:metadata[] =[];
  fakeArray = new Array(3);
  data1:any;
  l:any;
  metadatas:any;
  Base:any;
  data: any;
  status1:any;
  respo:any;
  selected_language:any;
  selected_language_id:any;
  browserlang: any;
  lat: number = 21.233733;
  lng: number = 81.599756;
  zoom:number = 15;
  // supptlanguage=['en', 'hi'];
  supptlanguage = [{code: 'en', label: 'English'}, {code: 'hi', label: 'Hindi'}];
  isLoaded = false;
  photo_gallery:any = [];
  gallery_type:string = 'all';
  video_link:any = null;
  constructor(private _coreConfigService: CoreConfigService, private formBuilder: FormBuilder,private _MatrimontyServiceService:MatrimontyServiceService,
    private http: HttpClient, private router: Router,public translate: TranslateService,private title: Title,
      public sanitizer: DomSanitizer,
      private meta: Meta,  @Inject(DOCUMENT) private document: Document ) 
       { 
        console.warn('index page - constructor');
          this._coreConfigService.config = {
    layout: {
        navbar: {
            hidden: true
        },
        menu: {
            hidden: true
        },
        footer: {
            hidden: true
        },
        customizer: false,
        enableLocalStorage: false
    }
};



    this.Form_Group = this.formBuilder.group({
    name:['', [Validators.required, Validators.maxLength(20)]],
    email:['',[Validators.required, Validators.pattern("^([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})$"), Validators.minLength(10)]],
    mobile:['', [ Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10)]],      
    subject:['', [Validators.required, Validators.maxLength(100)]],
    feedback:['', [Validators.required, Validators.maxLength(100)]],
  })

 
  

  if(localStorage.getItem("lang")==null){
    translate.setDefaultLang('hi');
    localStorage.setItem("lang",'hi');
    this.selected_language = "हिन्दी"; this.selected_language_id = 2; 
  } 
  else
  {
    this.l =localStorage.getItem("lang");
    translate.setDefaultLang(this.l);
    this.get_language_full_name(this.l);
  }
 }
 

 @HostListener('window:scroll', [])  onWindowScroll() {
  if (document.body.scrollTop > 530 ||    document.documentElement.scrollTop > 530) {
    document?.getElementById('nav')?.classList.add('smaller');
  }
  else  document?.getElementById('nav')?.classList.remove('smaller');
  // console.warn('scroll : ' + document.documentElement.scrollTop)
}
  ngOnInit(): void {
       this.getdata();
      this.Base = base;
    this.isDrawerOpen = false;

      }


  getdata(){
    this._MatrimontyServiceService.getdata(this.selected_language_id).subscribe((res:any)=>{
      if(res.status == 'Success'){
        this.data =res.data;
        this.photo_gallery = res.data.photo_gallery;
      this.data1 =res.data.subscription_plans.plan_id;
      this. metadatas =res.data.metadata;
      this.title.setTitle(this.metadatas[0].title);
     //if(res.data?.video_link != '' || res.data?.video_link != null) this.video_link=this.sanitizer.bypassSecurityTrustResourceUrl( 'https://www.youtube.com/embed/' + base.get_youtube_video_id(res.data?.video_link) + '?autoplay=1&mute=1');
     if(res.data.office_address?.video_link != '' || res.data.office_address?.video_link != null) this.video_link=this.sanitizer.bypassSecurityTrustResourceUrl( 'https://www.youtube.com/embed/' + base.get_youtube_video_id( res.data.office_address[0].video_link) + '?autoplay=1&mute=1');
  // src="https://www.youtube.com/embed/y6PH9u0wnww?autoplay=1&mute=1"
  this.meta.updateTag({ name: 'keyword', content: this. metadatas[0].keywords });
      this.meta.updateTag({ name: 'description', content: this. metadatas[0].description });
      this.isLoaded = true;
      }
      else alert(res.message);
    });
  }

  
  filter_gallery(type:any){
    this.gallery_type = type;
    var d = [] = this.data.photo_gallery
    if(type == 'all')     this.photo_gallery = d;
  else    this.photo_gallery = d.filter((e:any) => e.gallery_type === type);
  }


  get f() {
    return this.Form_Group.controls;
  }

  get_language_full_name(lang :any){
    switch(lang)
    {
      case "en": this.selected_language = "English"; this.selected_language_id = 1; break;
      case "hi": this.selected_language = "हिन्दी"; this.selected_language_id = 2; break;
      default: this.selected_language = "छत्तीसगढ़ी"; this.selected_language_id = 3; break;       
    }
  }
  switchLang(lang: string) {  
    this.translate.use(lang); 
    localStorage.setItem("lang",lang);
    this. get_language_full_name(lang);
  }


  toggleNavDrawer(isDrawerOpen: boolean) {
    // debugger;
    this.isDrawerOpen = isDrawerOpen;
    this.drawerToggleEmitter.emit(this.isDrawerOpen);
  }

  valueSubmit() {
    // this.submitted=true;
    //  if (this.Form_Group.valid) {
    //   let form_data = this.Form_Group.value;
    //     this.formData.name = form_data.name;
    //     this.formData.mobile = form_data.mobile;
    //     this.formData.email = form_data.email;
    //     this.formData.feedback=form_data.feedback;
    //     this.formData.subject=form_data.subject;
    //     this.http.post("http://mmapi.shivaminfosoft.in/api/front_end_home/contact_us_insert",this.formData).subscribe((res: any) => {
    //       if (res.status == "Success") {
    //         this.status1 = res.status
    //         console.warn(this.status1);
    //         this.respo = res.message;
    //         let form_data = this.Form_Group.reset();
    //       } else {
    //         this.status1 = res.status
    //         this.respo = res.message;
    //       }      
    //   });
    //   } else {
    //     alert('Entered details is not valid.');
    //   } 
    }
  }
 
    //  if (localStorage.getItem("orderData")) {
      //  this._MatrimontyServiceService.(this.formData).subscribe((res: any) => {
        //  if (res.token) {
          //  localStorage.setItem("ltn", JSON.stringify(res.token));
         
        //    this.http.post("http://mmapi.shivaminfosoft.in/api/front_end_home/contact_us_insert",this.formData).subscribe((res: any) => {
        //      // alert(JSON.stringify(res))
        //      if (res.status == "success") {
        //        console.warn(res.status);
        //      } else {
        //        alert("Your Order is not placed sucessfully! Try again")
        //      }
        //    });
 
        //  }
        //   else {
        //    alert("Please Check userId and password")
        //  }
      //  });
      
     
  // }
// }
  //  this.Form_Group = this.formBuilder.group({
  //   //  email: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10)]],
  //    email: ['', [Validators.required, Validators.pattern("^(((\\+91-?)|0)?[0-9]{10})$|^([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4})$"), Validators.minLength(10)]],
  //    mobile:['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10)]],      
  //    subject:['', [Validators.required, Validators.maxLength(10)]],
  //    Feedback:['', [Validators.required, Validators.maxLength(20)]],
  //    name:['', [Validators.required, Validators.maxLength(10)]],

  //  }) // get fordata (){
  //   alert(this.Form_Group)
  //   return this.Form_Group.controls;
  // }// constructor(private _MatrimontyServiceService:MatrimontyServiceService,private formBuilder: FormBuilder,
  //   private http: HttpClient, private router: Router){ }

     // get name(){return this.Form_Group.get('name' )}
  // get mobile(){return  this.Form_Group.get('mobile')}
  // get email(){return this.Form_Group.get('email')}
  // get feedback(){return this.Form_Group.get('feedback')}
  // get subject(){return this.Form_Group.get('subject')}
