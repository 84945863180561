import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { Profilcount } from 'app/model/profilcount';
import { ProfileListService } from 'app/services/profile-list.service';
import { model_response } from 'app/model/tbl-profile';

import { RegistService } from 'app/services/regist.service';
@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;
  profileCount: Profilcount;
  @Input() layout = 'vertical';
  @Input() menu: any;

  private _unsubscribeAll: Subject<any>;

  /**
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService, private profileListService: ProfileListService,
    private RegistService: RegistService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

      this.menu = this._coreMenuService.getCurrentMenu();
      //  alert(JSON.stringify(this.menu))
      this._changeDetectorRef.markForCheck();
    });
    //  this.getProfileCount();
    this.RegistService.qs.subscribe(data => {
      if (data?.type == 'menu') {
        this.menu = data?.data; this._changeDetectorRef.markForCheck();
      }
    })
  }

  getProfileCount() {
    try {
      // this.profileListService.profilesCountData().subscribe((res: model_response) => {
      //   console.warn(JSON.stringify(this.profileCount))
      //   this.profileCount = res.data.profile_counts
      //    localStorage.setItem("plan_details",  res.data.plan_details);
      //       this.menu[2].counts=this.profileCount[0].shortListCount;
      //       this.menu[1].children[0].counts = this.profileCount[0].sentIntAllCount;
      //       this.menu[1].children[1].counts = this.profileCount[0].sentIntPendingCount;
      //       this.menu[1].children[2].counts = this.profileCount[0].sentIntRejectedCount;
      //       this.menu[1].children[3].counts = this.profileCount[0].sentIntAcceptedCount;
      //       this.menu[1].children[4].counts = this.profileCount[0].recievedntAllCount;
      //       this.menu[1].children[5].counts = this.profileCount[0].recievedIntPendingCount;
      //       this.menu[1].children[6].counts = this.profileCount[0].recievedIntRejectedCount;
      //       this.menu[1].children[7].counts = this.profileCount[0].recievedIntAcceptedCount;
      //       this.menu[3].counts=this.profileCount[0].blockedListCount;
      //       this.menu[4].counts=this.profileCount[0].viewedByMeCount;
      //       this.menu[5].counts=this.profileCount[0].viewedByOtherCount;
      //     this._changeDetectorRef.markForCheck();
      //   });
    } catch (er: any) { throw er }
  }
}
