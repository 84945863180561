<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <!-- Forgot Password v1 -->
        <div class="card mb-0">
            <div class="card-body">
                <a href="javascript:void(0);" class="brand-logo">
                    <img src="{{base.admin_app_url}}/uploads/apps/logo-login.png" alt="bihav matrimony" height="40" />
                </a>

                <h4 class="card-title mb-1">{{'MENU.FORGOT_PASSWORD.TITLE' | translate }}</h4>
                <p class="card-text mb-2">{{'MENU.FORGOT_PASSWORD.SUB_TITLE' | translate }}</p>

                <form class="auth-forgot-password-form mt-2" [formGroup]="form_group" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="forgot-password-email" class="form-label">{{'MENU.FORGOT_PASSWORD.EMAIL' | translate}}</label>

                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                            placeholder="{{'MENU.FORGOT_PASSWORD.EMAIL_PLACEHOLDER' | translate }}"
                            aria-describedby="login-email" autofocus="" tabindex="1" />

                        <span class="text-danger"
                            *ngIf="(f.email.touched || submitted) && f.email.errors?.required">{{'MENU.FORGOT_PASSWORD.EMAIL_RQUIRED'
                            | translate }}</span>
                        <span class="text-danger"
                            *ngIf="(f.email.touched || submitted) && f.email.errors?.pattern">{{'MENU.FORGOT_PASSWORD.INVALID_EMAIL'
                            | translate }}</span>
                    </div>
                    <button class="btn btn-primary btn-block" tabindex="2"
                        rippleEffect>{{'MENU.FORGOT_PASSWORD.SEND_OTP' | translate }}</button>
                </form>


                <p class="text-center mt-2">
                    <a routerLink="/"> <i data-feather="chevron-left"></i> {{'MENU.FORGOT_PASSWORD.BACK_TO_LOGIN' |
                        translate }}</a>
                </p>
            </div>
        </div>
    </div>
</div>