import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(private http:HttpClient) { }

  upload(data){

    return this.http.post(`http://localhost:9631/api/Authenticate/login`,data)


  }
}
