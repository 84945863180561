export class TblProfile {
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  height_id: number | null;
  weight_id: number | null;
  mother_tongue_id: number | null;
  marital_status_id: number | null;
  skin_type_id: number | null;
  body_type_id: number | null;
  eating_habit_id: number | null;
  drinking_habit_id: number | null;
  smoking_habit_id: number | null;
  blood_group_id: number | null;
  birth_place: string | null;
  birth_date: Date | null;
  birth_time: string | null;
  about_me: string | null;
  hobby: string | null;
  photo: string;
}

export class model_listOne {
  first_name: string;
  middle_name: string;
  last_name: string;
  height_hi: string;
  weight_hi: string;
  mother_tongue_hi: string;
  marital_status_hi: string;
  skin_type_hi: string;
  body_type_hi: string;
  eating_habit_hi: string;
  drinking_habit_hi: string;
  smoking_habit_hi: string;
  blood_group_hi: string;
  birth_place: string;
  birth_time: string;
  birth_date: Date;
  about_me: string;
  hobby: string;
  religion_hi: string;
  caste_hi: string;
  subcaste: string;
  gothra_hi: string;
  horoscope_hi: string;
  manglik_hi: string;
  star_hi: string;
  moonsign_hi: string;
  address: string;
  country_hi: string;
  state_hi: string;
  city_hi: string;
  mobile: number;
  phone: number;
  residence_hi: string;
  educations: string;
  employed_in_hi: string;
  designation_hi: string;
  occupation_hi: string;
  income_range: number;
  family_type_hi: string;
  family_status_hi: string;
  father_name: string;
  father_occupation: string;
  mother_Name: string;
  mother_occupation: string;
  total_brothers: number;
  total_sisters: number;
  total_married_brother: number;
  total_married_sister: number;
  about_family: string;
}

export class TblProfile2 {
  religion_id: number;
  caste_id: number;
  subcaste: string;
  manglik_id: number;
  horoscope_id: number;
  star_id: number;
  moonsign_id: number;
  gothra_id: number;
}

export class TblProfile3 {
  address: string;
  country_id: number;
  state_id: number;
  city_id: number;
  mobile: number;
  phone: number;
  residence_id: number;
  status: string;
  message: string;
  data: string[];
  profileData: string[];
}

export class TblProfile4 {
  status: string;
  message: string;
  data: string;
  Table: string[];
  education_id: number[];
  employed_in_id: number;
  designation_id: number;
  occupation_id: number;
  annual_income_id: number;
}
export class Education {
  education_id: number[];
  education_hi: string;
  education_en: string;
}

export class TblProfile5 {
  family_type_id: number;
  family_status_id: number;
  father_name: string;
  father_occupation_id: number;
  mother_Name: string;
  mother_occupation_id: number;
  total_brothers: number;
  total_sisters: number;
  total_married_brother: number;
  total_married_sister: number;
  about_family: string;
}

export class model_response {
  status: string;
  message: string;
  data: any;
  

}

export class TblError {
    id?: number;
    role?: string;
    user_id?: number;
    statusCode?: string;
    message?: string;
    innerException?: string;
    stackTrace?: string;
    source?: string | null;
    source_app?: string;
    create_date?: Date;
    status?: number;
    is_resolve?: number;  
}

export class model_country {
  country_id: number;// | '';
  country_en: string;
  country_hi: string;
}
export class model_state {
  state_id: number;// | '';
  state_en: string;
  state_hi: string;
}
export class model_city {
  city_id: number;// | '';
  city_en: string;
  city_hi: string;
}
export class model_residence {
  residence_id: number;// | '';
  residence_en: string;
  residence_hi: string;
}

export class model_Step3 {
  address: string;
  country_id: number;
  state_id: number;
  city_id: number;
  mobile: number;
  phone: number;
  residence_id: number;
}

export interface basicPreferencess {
  // CountryId: string;
  // CountryName: string;
}
export interface cityn {
  // CountryId: string;
  // CountryName: string;
}
export interface model_gender {
  gender_id: number;
  gender: string
}






