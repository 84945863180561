<section>
<h2 class="card-title mb-1">{{'MENU.PROFILES.privacy_policy' | translate}}</h2> 
                    <div class="cart">
                              <!-- <div class="cart-item"> -->
                                        <section class="content">
                                                  <!-- <div class="bg-light mb-4 pl-3 pr-3 pt-3 pb-0 osahan-privacy-item"> -->
                                                  <!-- <div class="osahan-privacy bg-white rounded shadow p-4 mt-n5"> -->
                                                  <div id="intro" class="mb-1">
                                                            <div class="mb-2">
                                                                      <h2 class="h5 text-primary">Privacy Policy</h2>
                                                            </div>

                                                            <p>At Sevagudi, we value and respect your privacy. This
                                                                      Privacy Policy
                                                                      explains how we collect, use, disclose, and
                                                                      safeguard your
                                                                      personal information when you visit our website,
                                                                      app or use
                                                                      our
                                                                      services. By accessing or using our website, app
                                                                      and
                                                                      services,
                                                                      you signify your understanding and acceptance of
                                                                      this
                                                                      Privacy Policy.
                                                            </p>
                                                  </div>
                                                  <div id="services" class="mb-1">

                                                            <div class="mb-2">
                                                                      <h3 class="h5 text-primary">1. Information We
                                                                                Collect</h3>
                                                            </div>

                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">1.1 Personal
                                                                                Information:</h4>
                                                            </div>

                                                            <p>We may collect certain personally identifiable
                                                                      information
                                                                      ("Personal Information") when you voluntarily
                                                                      provide it to
                                                                      us. This may include your name, email address,
                                                                      phone number,
                                                                      billing and shipping address, and other
                                                                      information
                                                                      necessary to provide our services to you.</p>


                                                            <div id="information" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">1.2 Usage Data:</h4>
                                                            </div>

                                                            <p>We also collect non-personal information about your
                                                                      interactions
                                                                      with our website, app and services, such as your
                                                                      IP
                                                                      address,
                                                                      browser type, device information, pages visited,
                                                                      and
                                                                      referring/exit pages. This information is
                                                                      collected
                                                                      automatically through cookies, web beacons, and
                                                                      other
                                                                      tracking technologies.</p>
                                                  </div>
                                                  <div id="privacy" class="mb-1">

                                                            <div class="mb-2">
                                                                      <h3 class="h5 text-primary">2. How We Use Your
                                                                                Information
                                                                      </h3>
                                                            </div>

                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary"> 2.1 Provide and
                                                                                Improve
                                                                                Services:</h4>
                                                            </div>

                                                            <p>We use your Personal Information to provide and
                                                                      personalize our
                                                                      services, process your orders, communicate with
                                                                      you, and
                                                                      respond to your inquiries. We may also use your
                                                                      information
                                                                      to analyze and improve our website, app and
                                                                      services.</p>
                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">2.2 Marketing
                                                                                Communications:
                                                                      </h4>
                                                            </div>

                                                            <p>With your consent, we may send you promotional emails,
                                                                      newsletters,
                                                                      and other marketing communications. You can
                                                                      opt-out of
                                                                      receiving these communications at any time by
                                                                      following the
                                                                      unsubscribe instructions provided in the email or
                                                                      contacting
                                                                      us directly.</p>
                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">2.3 Legal Obligations:
                                                                      </h4>
                                                            </div>

                                                            <p>We may use and disclose your information as required by
                                                                      law, court
                                                                      order, or government regulation, or to protect our
                                                                      rights,
                                                                      property, or safety, or the rights, property, or
                                                                      safety of
                                                                      others.</p>
                                                  </div>


                                                  <div id="yourContent" class="active">

                                                            <div class="mb-1">
                                                                      <h3 class="h5 text-primary">3. Information Sharing
                                                                                and
                                                                                Disclosure
                                                                      </h3>
                                                            </div>
                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">3.1 Service Providers:
                                                                      </h4>
                                                            </div>
                                                            <p>We may engage trusted third-party service providers to
                                                                      perform
                                                                      various functions on our behalf, such as payment
                                                                      processing,
                                                                      shipping, and marketing. These service providers
                                                                      have access
                                                                      to your Personal Information only to the extent
                                                                      necessary to
                                                                      perform their functions and are obligated to
                                                                      maintain the
                                                                      confidentiality and security of your information.
                                                            </p>
                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">3.2 Business
                                                                                Transfers:</h4>
                                                            </div>
                                                            <p>In the event of a merger, acquisition, or sale of all or
                                                                      a portion
                                                                      of our assets, your information may be transferred
                                                                      as part
                                                                      of the transaction. We will notify you via email
                                                                      and/or a
                                                                      prominent notice on our website, app of any change
                                                                      in
                                                                      ownership
                                                                      or use of your Personal Information.</p>
                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">3.3 Aggregate or
                                                                                Anonymous Data:
                                                                      </h4>
                                                            </div>
                                                            <p>We may share aggregated or anonymized information that
                                                                      does not
                                                                      directly identify you with third parties for
                                                                      various
                                                                      purposes, such as analytics, research, or
                                                                      improving our
                                                                      services.</p>

                                                  </div>
                                                  <div id="yourContent" class="active">

                                                            <div class="mb-1">
                                                                      <h3 class="h5 text-primary">4. Data Security
                                                                      </h3>
                                                            </div>
                                                            <p>We implement reasonable security measures to protect your
                                                                      information from unauthorized access, disclosure,
                                                                      alteration, or destruction. However, please be
                                                                      aware that no
                                                                      method of transmission over the internet or
                                                                      electronic
                                                                      storage is 100% secure. We cannot guarantee the
                                                                      absolute
                                                                      security of your information.</p>

                                                  </div>
                                                  <div id="yourContent" class="active">

                                                            <div class="mb-1">
                                                                      <h3 class="h5 text-primary">5. Your Choices
                                                                      </h3>
                                                            </div>
                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">5.1 Access and Update:
                                                                      </h4>
                                                            </div>
                                                            <p>You have the right to access and update your Personal
                                                                      Information
                                                                      held by us. You can do this by logging into your
                                                                      account or
                                                                      contacting us directly.</p>

                                                  </div>
                                                  <div id="yourContent" class="active">


                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">5.2 Opt-Out: </h4>
                                                            </div>
                                                            <p>You can opt-out of receiving marketing communications
                                                                      from us by
                                                                      following the unsubscribe instructions provided in
                                                                      the email
                                                                      or contacting us directly.</p>
                                                  </div>
                                                  <div id="yourContent" class="active">


                                                            <div id="personal-data" class="mb-2 active">
                                                                      <h4 class="h6 text-primary">5.3 Cookies:</h4>
                                                            </div>
                                                            <p>Most web browsers are set to accept cookies by default.
                                                                      You can
                                                                      modify your browser settings to block cookies or
                                                                      notify you
                                                                      when cookies are being sent. However, please note
                                                                      that
                                                                      blocking or deleting cookies may impact your
                                                                      experience
                                                                      using our website, app and services.</p>
                                                  </div>
                                                  <div id="yourContent" class="active">

                                                            <div class="mb-2">
                                                                      <h3 class="h5 text-primary">6. Children's Privacy
                                                                      </h3>
                                                            </div>
                                                            <p>Our website, app and services are not intended for
                                                                      children under
                                                                      the
                                                                      age of 13. We do not knowingly collect or solicit
                                                                      personal
                                                                      information from children. If we discover that we
                                                                      have
                                                                      collected personal information from a child
                                                                      without parental
                                                                      consent, we will promptly delete it.</p>
                                                  </div>
                                                  <div id="yourContent" class="active">

                                                            <div class="mb-2">
                                                                      <h3 class="h5 text-primary">7. Changes to This
                                                                                Privacy
                                                                                Policy
                                                                      </h3>
                                                            </div>
                                                            <p>We may update this Privacy Policy from time to time. The
                                                                      updated
                                                                      policy will be posted on this page with the
                                                                      revised date. We
                                                                      encourage you to review this page periodically to
                                                                      stay
                                                                      informed about our privacy practices.</p>
                                                  </div>
                                                  <div id="yourContent" class="active">

                                                            <div class="mb-2">
                                                                      <h3 class="h5 text-primary">8. Contact Us
                                                                      </h3>
                                                            </div>
                                                            <p>If you have any questions or concerns about this Privacy
                                                                      Policy or
                                                                      our privacy practices, please contact us at <br>
                                                                      Email: <strong>contact@sevagudi.com</strong> <br>
                                                                      Phone: <strong>+91 85179 69727</strong><br>
                                                                      Office Address: <strong>HN-53, W-20, Nr. Sahu
                                                                                Furniture,Bhnd RTO
                                                                                office, Aakash Nagar,
                                                                                Rawabhata, Birgaon, Raipur, Chattisgarh,
                                                                                India,
                                                                                493221</strong>.<br><br></p>

                                                  </div>


                                                  <!-- </div>
    </div> -->
                                        </section>
                              <!-- </div> -->
                    </div>
</section>