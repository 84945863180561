import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileUpdateRoutingModule } from './profile-update-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProfileUpdateRoutingModule
  ]
})
export class ProfileUpdateModule { }



// import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { RouterModule, Routes } from '@angular/router';

// import { CoreSidebarModule } from '@core/components';
// import { CorePipesModule } from '@core/pipes/pipes.module';
// import { CoreCommonModule } from '@core/common.module';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgSelectModule } from '@ng-select/ng-select';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { QuillModule } from 'ngx-quill';
// import { SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2'

// import { ProfilesComponent } from './profiles.component';
// import { ProfileComposeComponent } from './profile-compose/profile-compose.component';
// import { ProfileListComponent } from './profile-list/profile-list.component';
// import { ProfileDetailsComponent } from './profile-details/profile-details.component';
// import { ProfileSidebarComponent } from './profile-sidebar/profile-sidebar.component';
// import { ProfileEditComponent } from 'app/profile-edit/profile-edit.component';


// import { ProfilesService } from './profiles.service';
// import { ProfileListItemComponent } from './profile-list/profile-list-item/profile-list-item.component';
// import { ProfileComponent } from 'app/profile/profile.component';
// import { TestingComponent } from './testing/testing.component';
// import { ShortListComponent } from './short-list/short-list.component';
// import { BlockListComponent } from './block-list/block-list.component';
// import { ShortListItemComponent } from './short-list/short-list-item/short-list-item.component';
// import { BlockListItemComponent } from './block-list/block-list-item/block-list-item.component';
// import { VisualComponent } from './visual/visual.component';
// import { IViewedProfileComponent } from './iviewed-profile/iviewed-profile.component';
// import { MyViewedProfileComponent } from './my-viewed-profile/my-viewed-profile.component';
// import { ViewedListComponent } from './iviewed-profile/viewed-list/viewed-list.component';

// import { ProfileUpdateRoutingModule } from './profile-update-routing.module';


// @NgModule({
//   declarations: [],
//   imports: [
//     CommonModule,
//     ProfileUpdateRoutingModule
//   ]
// })
// export class ProfileUpdateModule { }

