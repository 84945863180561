import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { model_response } from 'app/model/tbl-profile';
import { base } from 'base/base';
import { model_privacy_setting } from 'app/model/tbl-membership';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(private http: HttpClient){ }

  subscription_plan_get(){
    return this.http.get<model_response>(`${base.apiUrl}/payment/bank_get`);
  }
  subscription_payment_insert(data){
    return this.http.post<model_response>(`${base.apiUrl}/payment/payment_insert`,data);
  }
  payment_update(data){
    return this.http.post<model_response>(`${base.apiUrl}/payment/payment_update`,data);
  }
  privacy_post_data(data:model_privacy_setting){
    return this.http.post<model_response>(`${base.apiUrl}/dashboard/privacy_update`, data);
  }
  privacy_get(){
    return this.http.get<model_response>(`${base.apiUrl}/dashboard/privacy_get`);
  }

}
