import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProfileService } from 'app/services/profile.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileListService } from 'app/services/profile-list.service';
import { base } from 'base/base';
import { model_response, model_listOne, TblProfile } from 'app/model/tbl-profile';
import { ListDetail } from 'app/model/list-detail';
import { map, retry } from 'rxjs/operators';
import { TblShortlist } from 'app/model/tbl-shortlist';
import { ShortlistService } from 'app/services/shortlist.service';
import { InterestService } from 'app/services/interest.service';
import { BlacklistService } from 'app/services/blacklist.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SendmessageService } from 'app/services/sendmessage.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { RegistService } from 'app/services/regist.service';
import { model_login_response } from 'app/model/tbl-login';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ProfilePreviewComponent implements OnInit {
  profile: ListDetail;
  base: any = base;
  currentUser: model_login_response;
  modelProfileStep1: model_response;
  modify: model_listOne;
  profile_id: TblShortlist;
  id: any;
  shortListStatus = false;
  public isloded = false;
  fetched_profile_data: Array<ListDetail>;
  @Input() currentPage: number;
  private _unsubscribeAll: Subject<any>;
  profileData: any;
  profileDataNew: any;

  public getScreenWidth: any;
  public getScreenHeight: any;
  public deviceType: number = 1;   //  1 for mobile and 2 for desktop


  /** *
   * @param {ProfileService} profileService
   */
  /**Constructer */
  constructor(private profileService: ProfileService, private profileListService: ProfileListService, private shortlistService: ShortlistService,
    private interestService: InterestService, private blacklistService: BlacklistService, private toster: ToastrService,
    public sendmessageService: SendmessageService, public translate: TranslateService, private registService: RegistService) {
    this._unsubscribeAll = new Subject();
  }

  toggleDetails() {
    this.profileService.closeEmailDetails();
    this.profileService.deselectEmails();


    this.profileListService.closeEmailDetails();
  }

  /*** Update Folder on Selected Emails *
   * @param folderRef */
  updateFolderOnSelectedMails(folderRef) {
    this.profileService.updateFolderOnSelectedEmails(folderRef);
  }

  /*** Update Labels On Selected Emails  *
   * @param labelRef  */
  updateLabelOnSelectedEmails(labelRef) {
    this.profileService.updateLabelOnSelectedEmails(labelRef);
  }

  unRead() {
    this.profileService.markAsUnread();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.profilePreview();
    this.profileListService.profile_detail_data.subscribe((val: any) => {
      this.fetched_profile_data = val
    })
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth < 767.98) {
      this.deviceType = 1; //1 for mobile
    } else {
      this.deviceType = 2;  //2 for window
    }

    this.profileService.onEmailDetailChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      setTimeout(() => {
      });
    });

    // this.profileService.onOpenedEmailChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(email => {
    //   this.openedEmail = email;
    //   if (Object.keys(this.openedEmail).length > 0) {
    //     this.profileService.setOpenedEmail(this.openedEmail);
    //   }
    // });   
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth < 767.98) {
      this.deviceType = 1; //1 for mobile
    } else {
      this.deviceType = 2;  //2 for window
    }
  }

  profilePreview() {
    try {
      this.profileListService.profile_details_get(this.currentUser.user_id).subscribe((res: model_response) => {
        if (res.status == 'Success') {
          this.profileData = res.data
          if (this.profileData != null) this.isloded = true;
          // console.warn("lomesh" ,JSON.stringify(this.profileData))
          // this.profileDataNew = res.data.profile_details[0]
        } else this.toster.error(res.message, 'Error!');
      });//, (error: any) => { throw error; window.alert(error.message);});
    } catch (err: any) { throw err; }

  }

  // getProfileOneData() {
  //   this.profileListService.profilelist().pipe(map((res: model_response) => <model_response>res),
  //     retry(2)
  //   ).subscribe((res: model_response) => {
  //     this.modelProfileStep1 = res;
  //     if (this.modelProfileStep1.status == "Success") {
  //       let modifyData = JSON.parse(this.modelProfileStep1.data.toString())
  //       this.modify = modifyData.Table[0];
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     window.alert(error.message);
  //   }
  //   );
  // }

  shortList_click(profile_id) {
    // this.shortlistService.postShortListData(profile_id).pipe(map((res: any) => <model_response>res),
    //   retry(2)).subscribe((res: model_response) => {
    //     if (res.status === 'Success') {
    //       this.profileData.profile_details[0].has_shortlisted = 1
    //       this.shortListStatus = true;
    //       const index: number = this.fetched_profile_data.findIndex(item => item.user_id == profile_id)
    //       if (index !== -1) {
    //         this.fetched_profile_data[index].has_shortlisted = 1;
    //       }
    //     }
    //   },(error:HttpErrorResponse) => {
    //     window.alert(error.message);
    //   });
    const index: number = this.fetched_profile_data.findIndex(item => item.user_id == profile_id)
    // if(index !== -1 && this.fetched_profile_data[index].has_shortlisted == null){
    if (this.profileData.profile_details[0].has_shortlisted == null) {
      this.shortlistService.postShortListData(profile_id).pipe(map((res: any) => <model_response>res),
        retry(2)).subscribe((res: model_response) => {
          if (res.status === 'Success') {
            if (index != -1) {
              this.fetched_profile_data[index].has_shortlisted = 2;
            }
            this.profileData.profile_details[0].has_shortlisted = 2;
            console.log(this.profileData.profile_details[0].has_shortlisted);

          }
        }, (error: HttpErrorResponse) => {
          window.alert(error.message);
        });
    } else {

      if (this.profileData.profile_details[0].has_shortlisted != null) {
        this.shortlistService.deleteShortListData(profile_id).pipe(map((res: any) => <model_response>res),
          retry(2)).subscribe((res: model_response) => {
            if (res.status === 'Success') {
              this.shortListStatus = true;
              this.profileData.profile_details[0].has_shortlisted = null
              if (index != -1) {
                this.fetched_profile_data[index].has_shortlisted = null;
              }
            }
          }, (error: HttpErrorResponse) => {
            window.alert(error.message);
          });
      }
    }
  }
  sendInterest_click(profile_id) {
    // this.interestService.postInterestData(profile_id).pipe(map((res: model_response) => <model_response>res),
    //   retry(2)).subscribe((res: model_response) => {
    //     if (res.status === 'Success') {
    //       this.profileData.profile_details[0].has_sent_interest = 1
    //       const index: number = this.fetched_profile_data.findIndex(item => item.user_id == profile_id)
    //       if (index !== -1) {
    //         this.fetched_profile_data[index].has_sent_interest = 1;

    //       }
    //     }
    //   },(error:HttpErrorResponse) => {
    //     window.alert(error.message);
    //   });

    const index: number = this.fetched_profile_data.findIndex(item => item.user_id == profile_id)
    if (this.profileData.profile_details[0].has_sent_interest == null) {
      this.interestService.postInterestData(profile_id).pipe(map((res: model_response) => <model_response>res),
        retry(2)).subscribe((res: model_response) => {
          if (res.status === 'Success') {
            if (index != -1) {
              this.profileData.profile_details[0].has_sent_interest = 0
              this.fetched_profile_data[index].has_sent_interest = 0;
            }
          }
        }, (error: HttpErrorResponse) => {
          window.alert(error.message);
        }
        );
    } else {
      if (this.profileData.profile_details[0].has_sent_interest != null) {
        this.interestService.accept_reject_delete_interest(profile_id, 2).pipe(map((res: model_response) => <model_response>res),
          retry(2)).subscribe((res: model_response) => {
            if (res.status === 'Success') {
              const index: number = this.fetched_profile_data.findIndex(item => item.user_id == profile_id)
              if (index != -1) {
                this.profileData.profile_details[0].has_sent_interest = null
                this.fetched_profile_data[index].has_sent_interest = null;
              }
            }
          }, (error: HttpErrorResponse) => {
            window.alert(error.message);
          }
          );
      }
    }
  }

  blockList_click(profile_id) {
    this.blacklistService.blockListData(profile_id).pipe(map((res: any) => <model_response>res),
      retry(2)).subscribe((res: model_response) => {
        if (res.status === 'Success') {
          const index: number = this.fetched_profile_data.findIndex(item => item.user_id == profile_id)
          if (index !== -1) {
            this.fetched_profile_data.splice(index, 1);
            // this.fetched_profile_data[index].has_blocked = 1;
            this.profileData.profile_details[0].has_blocked = 1

            this.currentPage -= 1;
          }
        }
      }, (error: HttpErrorResponse) => {
        window.alert(error.message);
      });
  }
  // get_all_profile_details_data(all:number) {
  //   alert("gsdg"+this.profile.user_id)
  //   this.profileDetailsAllData = this.profileListService.profile_details_getabcd(all)
  //   console.warn(this.profileDetailsAllData);
  // }

  sendMessage(data) {
    Swal.fire({
      text: 'Please Write your message',
      input: 'textarea',
      inputPlaceholder: 'Message',
      confirmButtonText: 'Send',
      showCancelButton: true,
      // progressSteps: ['1', '2', '3'],
      customClass: {
        confirmButton: 'btn btn-info',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      console.warn(result.value)
      let message = result.value;
      this.sendmessageService.sendMessageData(data, message).subscribe((res: any) => {
      });
      //   this.sendmessageService.sendMessageData(data,result.value)
      //       .subscribe((res: any) => {
      //           if (res===true) {
      //               console.log("it worked with msg: " + res);
      //               // swal('It worked', 'stuff was updated', 'success');
      //           }
      //           else {
      //               console.log("it died with error: " + res);
      //               // swal('It failed', 'nothing updated', 'error');
      //           }
      //       }
      //  );


      // }
    });
  }

  photo: TblProfile[];
  public vh = new Array();
  val: any;
  fileName = '';
  profile_step_1: TblProfile;

  onFileSelected(event, photo_type: string) {
    try {
      const filedata: File = event.target.files[0];
      if (filedata) {
        this.fileName = filedata.name;
        const file = new FormData();
        file.append("thumbnail", filedata);
        this.registService.upload_profile_photo(file, photo_type).subscribe((response) => {
          if (response.status == 'Success') {
            if (photo_type == "profile_image") {
              this.profileData.profile_details[0].photo = response.data;
              var currentUser = JSON.parse(localStorage.getItem("user") || '{}');
              currentUser.photo = response.data;
              if (response.data != null) this.registService.set_new_localStorage_data({ data: currentUser});

              localStorage.setItem("user", JSON.stringify(currentUser));
            }
            else this.profileData.profile_details[0].photo_background = response.data;
            // localStorage.setItem('default_profile_image_name', response.data)

            // if (currentUser != null) {
            // var new_currentUser = JSON.parse(localStorage.getItem('user'));
            // alert("setdata=" + JSON.stringify(new_currentUser))
            //   return;
            // }
          } else { this.toster.error(response.message, 'Error!'); }

        });
      }
    } catch (error: any) { throw error; }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
