import { Capacitor } from "@capacitor/core";

export class TblLogin {
    default_language_id: number;
    user_id: number;
    name: string;
    mobile: number;
    email: string = null;
    password: string;
    old_password: string;
    confirm_password: string;
    gender_id: number;
    caste_id: number;
    photo: string;
    remember_me: any;
    otp: string | undefined;
    otp_for: string | undefined;
    token_push_notification: any = localStorage.getItem("token_push_notification");
    source_app: string = Capacitor.getPlatform();  
}

   
export class tbl_change_language {
      default_language_id: string;
      source_app: string = Capacitor.getPlatform(); 
}

export class model_login_response {
    name: string;
    user_id: number;
    token: any;
    expiration: string;
    default_language_id: number = 1;
    plan_id: number;
    plan_validity: string;
    caste_id: number;
    gender_id: number;
    country_id: number;
    state_id: number;
    city_id: number;
    role: string;
    status: any;
    message: any;
}
export class model_delete_account {
    delete_reason: string;//required
    delete_remark: string;
    app_rating: number;//required
}
