import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { base } from 'base/base';
import { model_response } from 'app/model/tbl-profile';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProfileFilterModel, md_filter } from 'app/model/profile-filter-model';
import { TblSubscriptionPlan } from 'app/model/tbl-subscription-plan';
import { ListDetail } from 'app/model/list-detail';

@Injectable({
  providedIn: 'root'
})
export class ProfileListService {
  data: any
  newdata: any
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  profile_detail_data: Observable<any> = this.obsArray.asObservable();
  
  obsArray1: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(['dtaafdfjdnk']);
  profile_detail_data1: Observable<any> = this.obsArray1.asObservable();

  public onOpenedProfileChanged: BehaviorSubject<any>;
  public detaildata: BehaviorSubject<any>;
  public isEmailDetailOpen
  public onEmailDetailChanged: BehaviorSubject<boolean>;

  name: any;    
  public content = new BehaviorSubject<any>(name);    
  public share = this.content.asObservable(); 

  

  getLatestValue(data) {    
    this.content.next(data);    
    // this.Employeename = data;    
    // console.log(this.Employeename);    
  } 
  // currentUser:any = JSON.parse(localStorage.getItem("currentUser") || null)


  constructor(private http: HttpClient) {
    this.onOpenedProfileChanged = new BehaviorSubject([]);
    this.onEmailDetailChanged = new BehaviorSubject(false);
    this.detaildata = new BehaviorSubject(this.newdata);
    this.isEmailDetailOpen = false;
  }

  profilelist() {
    return this.http.get<model_response>(`${base.apiUrl}/home2`).pipe(map((res: model_response) => {
      return res as model_response;
    })
    );
  }

  getCommonFilterRecord() {
    var search_value: ProfileFilterModel = JSON.parse(localStorage.getItem('FilterRecord')) || new ProfileFilterModel()
    return search_value.search_value;
  }
  
  profileslistData(actionName: string, pageSize: number, pageNumber: number, search_value: string) {
    var flrValues: md_filter = JSON.parse(localStorage.getItem('FilterRecord')) || new md_filter()
    delete flrValues['filter_apply'];
    var search_value = search_value == null ? '' : search_value;
    // return this.http.post<model_response>(`${base.apiUrl}/dashboard/get_profiles?page_size=${pageSize}&current_page=${pageNumber}`);
      //  console.warn('flrValues', JSON.stringify(flrValues))
      //  console.warn('search_value= ' , JSON.stringify(search_value))
    return this.http.post<model_response>(`${base.apiUrl}/${actionName}?page_size=${pageSize}&current_page=${pageNumber}&search_value=${search_value}`,
      flrValues
    );
  }

  profilesCountData() {
    return this.http.get<model_response>(`${base.apiUrl}/dashboard/get_profile_count`);
  }

  shortlistData(pageSize: number, pageNumber: number) {
    return this.http.get<model_response>(`${base.apiUrl}/dashboard/get_shortlisted_profile?page_size=${pageSize}&current_page=${pageNumber}&search_value=${this.getCommonFilterRecord()}`);
  }

  iViewedProfiles(pageSize: number, pageNumber: number) {
    return this.http.get<model_response>(`${base.apiUrl}/viewed_profile/iviewed_profiles?page_size=${pageSize}&current_page=${pageNumber}&search_value=${this.getCommonFilterRecord()}`);
  }

  myViewedProfiles(pageSize: number, pageNumber: number) {
    return this.http.get<model_response>(`${base.apiUrl}/viewed_profile/myviewed_profiles?page_size=${pageSize}&current_page=${pageNumber}&search_value=${this.getCommonFilterRecord()}`);
  }

  openProfileDetails(data) {
    this.onOpenedProfileChanged.next(data);
  }

  closeEmailDetails() {
    this.isEmailDetailOpen = false;
    this.onOpenedProfileChanged.next(this.isEmailDetailOpen)
  }
  profile_update(data) {
    this.obsArray.next(data)
  }

  profile_details_get(profile_id: number) {

    return this.http.get<model_response>(`${base.apiUrl}/dashboard/profile_details_get?user_id=${profile_id}`)
  }
  subscription_plan_get(){
    return this.http.get<model_response>(`${base.apiUrl}/dashboard/subscription_plan_get`);
  }
  get_photo_gallery(user_id:number){
    return this.http.get<model_response>(`${base.apiUrl}/photo_gallary/get_photo_gallery?user_id=${user_id}`);
    // .pipe(
    //   catchError(this.errorMgmt)
    // );
  }
  post_photo_gallary(data:ListDetail){
    return this.http.get<model_response>(`${base.apiUrl}/photo_gallary`);
  }
  photo_gallary_upload_photo(formData: FormData) {
    return this.http.post<any>(`${base.apiUrl}/photo_gallary/upload_photo`, formData);
  }
  delete_photo_gallary(data:number){
    return this.http.delete<model_response>(`${base.apiUrl}/photo_gallary/delete_photo_gallary?id=${data}`);
  }

  // errorMgmt(error: HttpErrorResponse) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     // Get client-side error
  //     errorMessage = error.error.message;
  //   } else {
  //     // Get server-side error
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   alert(errorMessage);
  //   return throwError(errorMessage);
  // }


}


// res_api:any = null;

// profile_details_get(profile_id: number) {
//   this.http.get<model_response>(`${base.apiUrl}/dashboard/profile_details_get?user_id=${profile_id}`).pipe(map((res: model_response) => <model_response>res),
//    retry(2)).subscribe((res: model_response) => {
//      alert("res"+ JSON.stringify( res))
//      this.res_api = res.status == 'Success' ? res.data : null;
//    }, (error: HttpErrorResponse) => {
//      window.alert(error.message);
//    })
//    return this.res_api;
// }
