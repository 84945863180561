export class model_PreferenceStep1 {
    age_from_id:number;
    age_to_id:number;
    height_from_id:number;
    height_to_id:number;
    expectations:string;
    marital_status_id:number;
    mother_tongue_id:number;
    body_type_id:number;
    eating_habit_id:number;
    smoking_habit_id:number;
    drinking_habit_id:number;
    skin_type_id:number;
}

export class model_marital_status {
    marital_status_id:number;
    marital_status_hi:string;
    marital_status_en:string;
}

export class model_ageFrom {
    age_from_id:number;
    age_hi:string;
    age_en:string;
}

export class model_ageTo {
    age_to_id:number;
    age_hi:string;
    age_en:string;
}

export class model_heightFrom {
    height_from_id:number;
    height_hi:string;
    height_en:string;
}

export class model_heightTo {
    height_to_id:number;
    height_hi:string;
    height_en:string;
}

export class model_mother_tongue {
    mother_tongue_id:number;
    mother_tongue_hi:string;
    mother_tongue_en:string;
}

export class model_body_type{
    body_type_id:number;
    body_type_hi:string;
    body_type_en:string;
}

export class model_eating_habit {
    eating_habit_id:number;
    eating_habit_hi:string;
    eating_habit_en:string;
}

export class model_smoking_habit {
    smoking_habit_id:number;
    smoking_habit_hi:string;
    smoking_habit_en:string;
}

export class model_drinking_habit {
    drinking_habit_id:number;
    drinking_habit_hi:string;
    drinking_habit_en:string;
}

export class model_skin_type {
    skin_type_id:number;
    skin_type_hi:string;
    skin_type_en:string;
}
