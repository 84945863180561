<h2 class="card-title mb-1">{{'MENU.PRIVACY_SETTING.TITLE' | translate}}</h2>
<div class="card mb-1 shadow-none w-100">
    <div class="card-body">
        <form [formGroup]="pivacySettingForm" (ngSubmit)="pivacySettingFormData()">
            <div class="form-group pb-0 mb-0">
                <span for="register-password" class="form-label"><b>{{'MENU.PRIVACY_SETTING.QUESTION_1' |
                        translate}}</b></span>
                <!-- <div class="item-group  mt-50 ml-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" formControlName="privacy_profile" [value]="1"
                                        id="flexRadioDefault1" checked>
                                    <span class="form-check-label" for="flexRadioDefault1">
                                        {{'MENU.PRIVACY_SETTING.OPTION1' | translate}}
                                    </span>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" formControlName="privacy_profile" [value]="2"
                                        id="flexRadioDefault2">
                                    <span class="form-check-label" for="flexRadioDefault2">
                                        {{'MENU.PRIVACY_SETTING.OPTION2' | translate}}
                                    </span>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" formControlName="privacy_profile" [value]="3"
                                        id="flexRadioDefault3">
                                    <span class="form-check-label" for="flexRadioDefault3">
                                        {{'MENU.PRIVACY_SETTING.OPTION3' | translate}}
                                    </span>
                                </div>
                            </div>  -->
                <div class="col-md-12 ">
                    <div class="card-body mb-0 pb-0">
                        <ul class="list-unstyled user-status">
                            <div class="form-group col-md-6">
                                <li class="pb-0">
                                    <div class="custom-control custom-control-primary custom-radio">
                                        <input type="radio" id="flexRadioDefaultt1" checked class="custom-control-input"
                                            formControlName="privacy_profile" [value]="1" />
                                        <label class="custom-control-label ml-25"
                                            for="flexRadioDefaultt1">{{'MENU.PRIVACY_SETTING.OPTION1' |
                                            translate}}</label>
                                    </div>
                                </li>
                            </div>
                            <div class="form-group col-md-6">
                                <li class="pb-0 ">
                                    <div class="custom-control custom-control-primary custom-radio">
                                        <input type="radio" id="flexRadioDefaultt2" class="custom-control-input"
                                            formControlName="privacy_profile" [value]="2"  />
                                        <label class="custom-control-label ml-25" for="flexRadioDefaultt2">
                                            {{'MENU.PRIVACY_SETTING.OPTION2' | translate}}</label>
                                    </div>
                                </li>
                            </div>
                            <!-- <div class="form-group col-md-6">
                                            <li class="pb-0">
                                                <div class="custom-control custom-control-primary custom-radio">
                                                    <input type="radio" class="custom-control-input" formControlName="privacy_profile" [value]="3"
                                                        id="flexRadioDefault3" />
                                                    <label class="custom-control-label ml-25"
                                                        id="flexRadioDefault3">{{'MENU.PRIVACY_SETTING.OPTION3' | translate}}</label>
                                                </div>
                                            </li>
                                        </div> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="form-group mt-0 pb-0">
                <span for="register-password" class="form-label"><b>{{'MENU.PRIVACY_SETTING.QUESTION_2' |
                        translate}}</b></span>
                <!-- <div class="item-group  mt-50 ml-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" formControlName="privacy_contact" [value]="1"
                                        id="flexRadioDefault1" checked>
                                    <span class="form-check-label" for="flexRadioDefault1">
                                        {{'MENU.PRIVACY_SETTING.OPTION2_1' | translate}}
                                    </span>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" formControlName="privacy_contact" [value]="2"
                                        id="flexRadioDefault2">
                                    <span class="form-check-label" for="flexRadioDefault2">
                                        {{'MENU.PRIVACY_SETTING.OPTION2_2' | translate}}
                                    </span>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" formControlName="privacy_contact" [value]="3"
                                        id="flexRadioDefault3">
                                    <span class="form-check-label" for="flexRadioDefault3">
                                        {{'MENU.PRIVACY_SETTING.OPTION2_3' | translate}}
                                    </span>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" formControlName="privacy_contact" [value]="4"
                                        id="flexRadioDefault3">
                                    <span class="form-check-label" for="flexRadioDefault3">
                                        {{'MENU.PRIVACY_SETTING.OPTION2_4' | translate}}
                                    </span>
                                </div>
                            </div>  -->

                <div class="col-md-12 ">
                    <div class="card-body mb-0 pb-0">
                        <ul class="list-unstyled user-status">
                            <div class="form-group col-md-6">
                                <li class="pb-0">
                                    <div class="custom-control custom-control-primary custom-radio">
                                        <input type="radio" checked class="custom-control-input"
                                            formControlName="privacy_contact" [value]="1" id="flexRadioDefault1" />
                                        <label class="custom-control-label ml-25"
                                            for="flexRadioDefault1">{{'MENU.PRIVACY_SETTING.OPTION2_1' |
                                            translate}}</label>
                                    </div>
                                </li>
                            </div>
                            <div class="form-group col-md-6">
                                <li class="pb-0 ">
                                    <div class="custom-control custom-control-primary custom-radio">
                                        <input type="radio" id="flexRadioDefault2" class="custom-control-input"
                                            formControlName="privacy_contact" [value]="2" id="flexRadioDefault2" />
                                        <label class="custom-control-label ml-25" for="flexRadioDefault2">
                                            {{'MENU.PRIVACY_SETTING.OPTION2_2' | translate}}</label>
                                    </div>
                                </li>
                            </div>
                            <div class="form-group col-md-6">
                                <li class="pb-0">
                                    <div class="custom-control custom-control-primary custom-radio">
                                        <input type="radio" class="custom-control-input"
                                            formControlName="privacy_contact" [value]="3" id="flexRadioDefault3"  />
                                        <label class="custom-control-label ml-25"
                                            for="flexRadioDefault3">{{'MENU.PRIVACY_SETTING.OPTION2_3' |
                                            translate}}</label>
                                    </div>
                                </li>
                            </div>
                            <div class="form-group col-md-6">
                                <li class="pb-0">
                                    <div class="custom-control custom-control-primary custom-radio">
                                        <input type="radio" class="custom-control-input"
                                            formControlName="privacy_contact" [value]="4" id="flexRadioDefault4"   />
                                        <label class="custom-control-label ml-25"
                                            for="flexRadioDefault4">{{'MENU.PRIVACY_SETTING.OPTION2_4' |
                                            translate}}</label>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <span class="text-success" *ngIf="message">{{message}}</span>
            <div class="row mt-2">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-primary btn-block" tabindex="5"
                        rippleEffect>{{'MENU.PRIVACY_SETTING.SAVE_SETTING' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>